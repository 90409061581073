import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { CategoriesData, Category } from '../../lib/categories';
import { sharedState } from '../shared-state';


export const useLicenseeVesselDocumentCategories = (priority?: number | boolean) => {
    const [licenseeVesselDocumentCategoriesData, setLicenseeVesselDocumentCategoriesData] = useState<CategoriesData>();
    const vesselIds = sharedState.vesselIds.use(priority);

    useEffect(() => {
        setLicenseeVesselDocumentCategoriesData(undefined);
        if (vesselIds?.length && priority) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselDocumentCategories'),
                    where('vesselId', 'in', vesselIds),
                    where('state', '==', 'active')
                ),
                (snapshot) => {
                    const byId: { [id: string]: Category } = {};
                    const ids: string[] = [];
                    snapshot.forEach((doc) => {
                        const system = {
                            id: doc.id,
                            ...doc.data(),
                        } as Category;
                        byId[system.id] = system;
                        ids.push(system.id);
                    });
                    setLicenseeVesselDocumentCategoriesData({ byId, ids });
                },
                (error) => {
                    console.error('Error fetching licensee vesselSopCategories:', error);
                }
            );
        }

        return () => {
            setLicenseeVesselDocumentCategoriesData(undefined);
        };
    }, [vesselIds, priority]);

    return licenseeVesselDocumentCategoriesData;
};