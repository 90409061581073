import { sharedState } from "../shared-state";
import { canView } from "../Core/userPermissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { getDayOffsetMillis, warnDays } from '../../lib/datesAndTime';
import { registerFiles, registerRichText } from "../FileSyncSystem/filesToCache";
import { VesselDocument } from './vesselDocuments';
import { useEffect, useMemo, useState } from 'react';
import { useLicenseeVesselDocumentCategories } from "./useLicenseeVesselDocumentCategories";
import { renderVesselName } from "../Core/vessels";
import { renderCategoryName } from "../../lib/categories";


export type VesselDocumentsData = { 
    all: VesselDocument[],
    prioritised: VesselDocument[],
    byId: {
        [id: string]: VesselDocument
    },
    byCategoryId: {
        [id: string]: { name: string, vesselName: string, vesselId: string, items: VesselDocument[] }
    }
};

export const useLicenseeVesselDocuments = (priority?: number | boolean, vesselIds?: string[]) => {
    const [vesselDocuments, setVesselDocuments] = useState<VesselDocumentsData>();
    const _vesselIds = sharedState.vesselIds.use(priority);
    const licenseeId = sharedState.licenseeId.use(priority);
    const licenseeVesselDocumentCategories = useLicenseeVesselDocumentCategories(priority);

    const selectedVesselIds = useMemo(() => {
        if (vesselIds) {
            return vesselIds;
        }
        return _vesselIds;
    }, [vesselIds, _vesselIds]);

    useEffect(() => {
        setVesselDocuments(undefined);
        
        if (selectedVesselIds && licenseeVesselDocumentCategories && canView('vesselDocuments')) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselDocuments'),
                    where('vesselId', 'in', selectedVesselIds),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    const documents = snap.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    } as VesselDocument));

                    const all: VesselDocument[] = [];
                    const byId: { [id: string]: VesselDocument } = {};
                    const byCategoryId: { [id: string]: { name: string, vesselName: string, vesselId: string, items: VesselDocument[] } } = {};
                    documents.forEach((document) => {
                        registerFiles(document.files, 'vesselDocuments', document);
                        registerRichText(document.sfdoc, 'vesselDocuments');
                        byId[document.id] = document;
                        all.push(document);
                        if (!byCategoryId[document.categoryId]) {
                            byCategoryId[document.categoryId] = { name: renderCategoryName(document.categoryId, licenseeVesselDocumentCategories), vesselName: renderVesselName(document.vesselId), vesselId: document.vesselId, items: [] };
                        }
                        byCategoryId[document.categoryId].items.push(document);
                    });

                    let prioritised = [...documents].sort((a, b) => (
                        (a.type === 'renewable' ? a.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) -
                        (b.type === 'renewable' ? b.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER)
                    ));

                    const maxWhenExpires = getDayOffsetMillis(
                        warnDays.vesselDocuments[warnDays.vesselDocuments.length - 1]
                    );
                    
                    prioritised = prioritised.filter(doc => 
                        doc.type !== 'nonExpiring' && 
                        (doc.whenExpires as number) < maxWhenExpires
                    );

                    const sortedByCategoryId = Object.keys(byCategoryId)
                        .sort((a, b) => {
                            const vesselNameA = byCategoryId[a].vesselName;
                            const vesselNameB = byCategoryId[b].vesselName;
                            return vesselNameA.localeCompare(vesselNameB);
                        })
                        .reduce((acc, key) => {
                            acc[key] = byCategoryId[key];
                            return acc;
                        }, {} as { [id: string]: { name: string, vesselName: string, vesselId: string, items: VesselDocument[] } });

                    setVesselDocuments({
                        all,
                        prioritised,
                        byId,
                        byCategoryId: sortedByCategoryId
                    });
                },
                (error) => {
                    console.log(
                        `Failed to access vesselDocuments for licensee ${licenseeId}`,
                        error
                    );
                }
            );
        }
    }, [licenseeId, licenseeVesselDocumentCategories, selectedVesselIds]);

    return vesselDocuments;
};