import React, { useMemo, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, formatInterval, warnDays } from '../../../../lib/datesAndTime';
import { renderFullName } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import { canEdit, permissionLevels } from '../../../../shared-state/Core/userPermissions';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import EditUserDrills from '../EditUserDrills/EditUserDrills';
import './ViewUserDrills.css';

interface ViewUserDrillsProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedUser: UserType | undefined;
    newDrillsModel: Drill[];
    level?: number;
}

const ViewUserDrills: React.FC<ViewUserDrillsProps> = ({ showModal, setShowModal, selectedUser, newDrillsModel, level = 1 }) => {

    const [showEditUserDrillsModal, setShowEditUserDrillsModal] = useState(false);

    const unassignedDrills = useMemo(() => {
        if (!newDrillsModel || !selectedUser) return [];
        return newDrillsModel.filter((drill) => selectedUser?.id && drill.notAssignedTo?.includes(selectedUser?.id));
    }, [selectedUser, newDrillsModel]);

    const assignedDrills = useMemo(() => {
        if (!newDrillsModel || !selectedUser) return [];
        return newDrillsModel.filter((drill) => selectedUser?.id && !drill.notAssignedTo?.includes(selectedUser?.id));
    }, [selectedUser, newDrillsModel]);

    return (
        <SeaModal title={`${renderFullName(selectedUser)} - Drills`} showModal={showModal} setShowModal={setShowModal} size="semi-thin">
            <SeaGrid>
                <SeaGridCell label="Excluded from drills" w="100">
                    {formatValue(unassignedDrills.map((drill) => drill.name).join(', '))}
                </SeaGridCell>
            </SeaGrid>

            <div className={'view-modal-buttons'}>
                <RequirePermissions role="drills" level={permissionLevels.EDIT}>
                    <SeaButton
                        zone="white"
                        onClick={(e) => {
                            setShowEditUserDrillsModal(true);
                        }}
                    >
                        <SeaIcon icon="edit" slot="start" />
                        Edit Assigned Drills
                    </SeaButton>
                </RequirePermissions>
            </div>

            <div className="end-info-line"></div>

            <div className="columns">
                <h2>Assigned Drills</h2>
            </div>

            {/* <SeaNoData dataName="drill" isUsingFilter={true} isLoading={false} hasNoData={userDrills.length === 0} /> */}

            <div className={`drill-user-history reveal`}>
                <div className="sea-row-history headings-history">
                    <div className={'drill-heading'}>Drill</div>
                    <div className={'interval-heading'}>Interval</div>
                    <div>Last Completed</div>
                    <div>Next Due</div>
                </div>

                {assignedDrills.map((drill) => {
                    const crewData = (
                        drill?.crew &&
                        selectedUser?.id &&
                        drill.crew[selectedUser.id] ? drill.crew[selectedUser.id] : { whenDue: 0, whenLastCompleted: 0 }
                    );

                    const whenDue = crewData.whenDue ?? 0; // Use ?? instead of !! and ternary
                    const whenLastCompleted = crewData.whenLastCompleted ?? 0;

                    return (
                        <div key={drill.id} className="sea-card sea-row-history ">
                            <div className={'drill truncate-3'}>{formatValue(drill.name)}</div>
                            <div className={'interval truncate-3'}>{formatInterval(drill.interval)}</div>
                            <div>{whenLastCompleted > 0 && formatDate(whenLastCompleted)}</div>
                            {!!whenDue && <SeaStatusDueDate whenDue={whenDue} warnDays={warnDays.drills[0]} inline={true} />}
                        </div>
                    );
                })}
            </div>
            {showModal && canEdit('drills') &&
                <EditUserDrills
                    showModal={showEditUserDrillsModal}
                    setShowModal={setShowEditUserDrillsModal}
                    user={selectedUser}
                    userDrills={assignedDrills}
                    level={level + 1}
                />
            }
        </SeaModal>
    );
};

export default ViewUserDrills;
