import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { sharedState } from "../shared-state";
import { ScheduledMaintenanceTask } from "./maintenanceSchedule";
import { useEnginesFromVessels } from './useEnginesFromVessels';
import { useLicenseeVesselSystems } from "./useLicenseeVesselSystems";
import { useLicenseeEquipment } from "./useLicenseeEquipment";
import { renderVesselName } from "../Core/vessels";

const makeTasks = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    return docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        } as ScheduledMaintenanceTask;
    });
};

type ScheduledMaintenanceTaskData = {
    prioritised: ScheduledMaintenanceTask[];
    byId: { [id: string]: ScheduledMaintenanceTask };
    bySystemId: { 
        [id: string]: { 
            name: string, 
            vesselName: string, 
            vesselId: string, 
            tasks: ScheduledMaintenanceTask[] 
        } 
    };
};

export const useMaintenanceTasksForVessels = (
    isActive: boolean,
    selectedVesselIds?: string[],
) => {
    const todayMillis = sharedState.todayMillis.use()!;
    const engines = useEnginesFromVessels(isActive ? selectedVesselIds : undefined)!;
    const vesselSystems = useLicenseeVesselSystems(isActive ? selectedVesselIds : undefined)!;
    const equipment = useLicenseeEquipment(isActive ? selectedVesselIds : undefined)!;
    const [reportingMaintenanceTasks, setReportingMaintenanceTasks] = useState<ScheduledMaintenanceTaskData>();

    

    useEffect(() => {
        setReportingMaintenanceTasks(undefined);
        if (
            isActive &&
            selectedVesselIds?.length &&
            engines &&
            vesselSystems &&
            equipment
        ) {
            return setupArrayQueryListener(
                'scheduledMaintenanceTasks',
                collection(firestore, 'scheduledMaintenanceTasks'),
                [where('state', '==', 'active')],
                'vesselId',
                'in',
                selectedVesselIds,
                [],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => {
                    let all = makeTasks(docs);
                    const filteredTasks: ScheduledMaintenanceTask[] = [];
                    const byId: { [id: string]: ScheduledMaintenanceTask } = {};
                    // const byVesselId: { [id: string]: { name: string, bySystemId: { [id: string]: { name: string, vesselId: string, tasks: ScheduledMaintenanceTask[] }}}} = {};
                    const bySystemId: { [id: string]: { name: string, vesselName: string, vesselId: string, tasks: ScheduledMaintenanceTask[] } } = {};

                    all.forEach(task => {
                        task.equipment = equipment.byId[task.equipmentId];
                        let priority = Number.MAX_SAFE_INTEGER;
                        if (task.whenDue) {
                            const daysAway = (task.whenDue - todayMillis) / (24 * 60 * 60 * 1000);
                            priority = daysAway;
                        }

                        if (task.engineId && task.engineHoursDue && engines?.byId[task.engineId]?.hours !== undefined) {
                            task.engineHoursLeft = task.engineHoursDue - engines.byId[task.engineId].hours;
                            const engineDaysOrder = (task.engineHoursLeft / 50) * 7;
                            if (engineDaysOrder < priority) {
                                priority = engineDaysOrder;
                            }
                        }
                        if (task.equipment?.systemId) {
                            const system = vesselSystems.byId[task.equipment.systemId];
                            if (system) {
                                task.searchText += ` ${system.name}`;
                            }
                            if (bySystemId[task.equipment.systemId] === undefined) {
                                bySystemId[task.equipment.systemId] = { name: system.name, vesselName: renderVesselName(system.vesselId), vesselId: system.vesselId, tasks: [] };
                            }
                            bySystemId[task.equipment.systemId].tasks.push(task);
                        }

                        filteredTasks.push({
                            ...task,
                            priority
                        });
                        byId[task.id] = task;
                    });

                    // Filter by criticality
                    let finalTasks = filteredTasks.sort((a, b) => (a.priority || 0) - (b.priority || 0));

                    if (isCombined) {
                        filteredTasks.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                    }

                    const sortedBySystemId = Object.keys(bySystemId)
                        .sort((a, b) => {
                            const vesselNameA = bySystemId[a].vesselName;
                            const vesselNameB = bySystemId[b].vesselName;
                            return vesselNameA.localeCompare(vesselNameB);
                        })
                        .reduce((acc, key) => {
                            acc[key] = bySystemId[key];
                            return acc;
                        }, {} as { [id: string]: { name: string, vesselName: string, vesselId: string, tasks: ScheduledMaintenanceTask[] } });
                    
                    setReportingMaintenanceTasks({
                        prioritised: finalTasks,
                        byId,
                        bySystemId: sortedBySystemId,
                    });
                }
            );
        }
    }, [isActive, todayMillis, selectedVesselIds, engines, vesselSystems, equipment]);

    return reportingMaintenanceTasks;
};
