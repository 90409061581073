import React, { useCallback, useMemo, useState } from 'react';
import { LinkType } from '../../shared-state/Core/links';
import { getExternalUrl, getLinksByCollectionFromIds, renderLinkLabelFromCollection } from '../../lib/links';
import { Risk } from '../../shared-state/HealthSafety/risks';
import { VesselDocument } from '../../shared-state/VesselDocuments/vesselDocuments';
import { SOP } from '../../shared-state/VesselDocuments/vesselSOPS';
import { ScheduledMaintenanceTask } from '../../shared-state/VesselMaintenance/maintenanceSchedule';
import { Drill } from '../../shared-state/VesselSafety/drills';
import { canView, getPermissionRoleFromCollection, PermissionRole } from '../../shared-state/Core/userPermissions';
import { useFetchSingleItem } from '../../shared-state/Core/fetchSingleItem';
import { TrainingTask } from '../../shared-state/Crew/trainingTasks';
import { CompanyDocument } from '../../shared-state/CompanyDocuments/companyDocuments';
import { CustomForm } from '../../shared-state/CompanyDocuments/CustomForms/customForms';
import SeaGridCell from '../SeaGridCell/SeaGridCell';
import ViewRiskAssessment from '../../modals/HealthSafety/RiskRegister/ViewRiskAssessment/ViewRiskAssessment';
import ViewSOP from '../../modals/VesselDocuments/SOPs/ViewSOP/ViewSOP';
import ViewVesselDocument from '../../modals/VesselDocuments/VesselDocuments/ViewVesselDocument/ViewVesselDocument';
import ViewMaintenanceSchedule from '../../modals/VesselMaintenance/MaintenanceSchedule/ViewMaintenanceSchedule/ViewMaintenanceSchedule';
import ViewDrill from '../../modals/VesselSafety/Drills/ViewDrill/ViewDrill';
import ViewTrainingTask from '../../modals/Crew/CrewTraining/ViewTrainingTask/ViewTrainingTask';
import ViewCustomForm from '../../modals/CompanyDocuments/CustomForms/ViewCustomForm/ViewCustomForm';
import ViewCompanyDocument from '../../modals/CompanyDocuments/CompanyDocuments/ViewCompanyDocument/ViewCompanyDocument';
import './SeaLinks.css';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface LinkSide {
    id: string;
    linkId: string;
    collection: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SeaLinksProps<T> {
    links?: LinkType[];
    ids: string[];
    level?: number;
    viewOnly?: boolean;
    width?: '100' | '75' | '66' | '50' | '33' | '25';
}

export const SeaLinks = <T extends Record<string, any>>({ links, ids, level = 1, viewOnly, width = '33' }: SeaLinksProps<T>) => {
    const byCollection = getLinksByCollectionFromIds(links, ids)
    const [selectedLink, setSelectedLink] = useState<LinkSide>();
    const [selectedLinkItem, setSelectedLinkItem] = useState<T>();


    const handleSelected = useCallback((link: LinkSide, item?: T) => {
        setSelectedLinkItem(item);
        setSelectedLink(link);
    }, []);

    if (!byCollection || !links) {
        return null;
    }

    return (
        <>
            {Object.entries(byCollection).map(([collection, linkSides]) => {
                const role = getPermissionRoleFromCollection(collection);
                const hasPermission = collection === 'external' ? true : canView(role as PermissionRole);

                return (
                    <SeaGridCell key={collection} label={renderLinkLabelFromCollection(collection, true)} w={width}>
                        {hasPermission ? (
                            linkSides.map((link, index) => <SeaLinkItem key={link.id} link={link} collection={collection} viewOnly={viewOnly} handleSelected={handleSelected} index={index} totalItems={linkSides.length} />)
                        ) : (
                            <span className="sea-link text disabled">
                                {linkSides.length} x {renderLinkLabelFromCollection(collection)}
                            </span>
                        )}
                    </SeaGridCell>
                );
            })}
             {selectedLinkItem && selectedLink?.collection === 'SOPs' ? (
                <ViewSOP
                    viewOnly
                    selectedItem={selectedLinkItem as unknown as SOP}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    level={level+1}
                />
            ) : null}

            {selectedLinkItem && selectedLink?.collection === 'vesselDocuments' ? (
                <ViewVesselDocument
                    viewOnly
                    selectedItem={selectedLinkItem as unknown as VesselDocument}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    level={level+1}
                />
            ) : null}

            {selectedLinkItem && selectedLink?.collection === 'companyDocuments' ? (
                <ViewCompanyDocument
                    viewOnly
                    selectedItem={selectedLinkItem as unknown as CompanyDocument}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    level={level+1}
                />
            ) : null}

            {selectedLinkItem && selectedLink?.collection === 'risks' ? (
                <ViewRiskAssessment
                    viewOnly
                    selectedRisk={selectedLinkItem as unknown as Risk}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    setRiskMatrixModal={() => null}
                    setShowEditItemModal={() => null}
                    level={level+1}
                />
            ) : null}

            {selectedLinkItem && selectedLink?.collection === 'scheduledMaintenanceTasks' ? (
                <ViewMaintenanceSchedule
                    viewOnly
                    selectedItem={selectedLinkItem as unknown as ScheduledMaintenanceTask}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    level={level+1}
                />
            ) : null}

             {selectedLinkItem && selectedLink?.collection === 'drills' ? (
                <ViewDrill
                    viewOnly
                    selectedItem={selectedLinkItem as unknown as Drill}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    level={level+1}
                />
            ) : null}

            {selectedLinkItem && selectedLink?.collection === 'trainingTasks' ? (
               <ViewTrainingTask
                    viewOnly
                    selectedTask={selectedLinkItem as unknown as TrainingTask}
                    showModal={true}
                    setShowModal={() => setSelectedLink(undefined)}
                    level={level+1}
               />
           ) : null}

           {selectedLinkItem && selectedLink?.collection === 'customForms' ? (
              <ViewCustomForm
                   viewOnly
                   selectedCustomForm={selectedLinkItem as unknown as CustomForm}
                   showModal={true}
                   setShowModal={() => setSelectedLink(undefined)}
                   level={level+1}
              />
          ) : null}
        </>
    );
};

interface SeaLinkItemProps<T> {
    link: LinkSide;
    collection: string;
    viewOnly?: boolean;
    handleSelected?: (link: LinkSide, item: T | undefined) => void;
    className?: string;
    index: number;
    totalItems: number;
}

export const SeaLinkItem = <T extends Record<string, any>>({
    link,
    collection,
    viewOnly,
    handleSelected,
    className,
    index,
    totalItems
}: SeaLinkItemProps<T> & { index: number; totalItems: number }) => {
    const item = useFetchSingleItem(link.collection, link.linkId) as T | undefined;
    const isDeleted = item === null || item?.state === 'deleted' || item?.state === 'archived';

    const style = useMemo(() => {
        if (index < totalItems - 1) {
            return { paddingRight: '6px' };
        }
        return {};
    }, [index, totalItems]);
    
    const getLabelFromItem = useCallback((item: Record<string, any>): string => {
        const labelMapping: Record<string, keyof typeof item> = {
            customForms: 'title',
            SOPs: 'title',
            vesselDocuments: 'title',
            companyDocuments: 'title',
            scheduledMaintenanceTasks: 'task',
            trainingTasks: 'task',
        };

        const field = labelMapping[collection] || 'name';
        return (item[field] || '') as string;
    }, [collection]);

    const label = useMemo((): string => {
        if (collection === 'external') {
            if (index < totalItems - 1) {
                return link.linkId + ', ';
            }
            return link.linkId;
        }
        
        if (item === null) {
            return '(Deleted)';
        }
        
        if (!item) {
            return '';
        }

        let result = getLabelFromItem(item);
        
        if (item.state === 'archived' || item.state === 'deleted') {
            result += ' (Deleted)';
        }

        if (index < totalItems - 1) {
            result += ', ';
        }

        return result;
    }, [collection, getLabelFromItem, item, link.linkId, index, totalItems]);

    if (label === '') {
        return null;
    }

    if (collection === 'external') {
        return (
            <a
                className={`sea-link text ${className}`}
                style={style}
                href={getExternalUrl(link.linkId)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {label}
            </a>
        );
    }

    if (viewOnly || isDeleted) {
        return (
            <span className={`sea-link text ${className} disabled`} style={style}>
                {label}
            </span>
        );
    }

    return (
        <span
            className={`sea-link text ${className}`}
            style={style}
            onClick={() => handleSelected?.(link, item)}
        >
            {label}
        </span>
    );
};