import React, { useState, useEffect, useMemo } from 'react';
import { formatTextAreaText, formatValue } from '../../../../lib/util';
import { formatDate, formatInterval, warnDays } from '../../../../lib/datesAndTime';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { SplittableBatch } from '../../../../lib/firebase';
import { WriteBatch } from 'firebase/firestore';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { AlertMessage, alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { TrainingTask } from '../../../../shared-state/Crew/trainingTasks';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { TrainingTaskReport, TrainingTaskReportsData, useTrainingTaskReports } from '../../../../shared-state/Crew/useTrainingTaskReports';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import EditTrainingTask from '../EditTrainingTask/EditTrainingTask';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import './ViewTrainingTask.css';

interface ViewTrainingTaskProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    trainingTaskReports?: TrainingTaskReportsData,
    selectedTask: TrainingTask,
    vesselId?: string, // default vessel in edit form
    level?: number
    viewOnly?: boolean,
}

const ViewTrainingTask: React.FC<ViewTrainingTaskProps> = ({showModal, setShowModal, trainingTaskReports, selectedTask, vesselId, level = 1, viewOnly}) => {
    const _trainingTaskReports = useTrainingTaskReports(selectedTask.vesselId, showModal ? 1 : 101);
    const links = useItemLinks(selectedTask?.id);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditTrainingTaskModal, setShowEditTrainingTaskModal] = useState(false);

    const taskHistory = useMemo(() => {
        const reports = trainingTaskReports ?? _trainingTaskReports;
        const _taskHistory: TrainingTaskReport[] = [];
        reports?.all.forEach((report) => {
            if (report.taskId === selectedTask.id) {
                _taskHistory.push(report);
            };
        });
        return _taskHistory;
        
    }, [_trainingTaskReports, selectedTask.id, trainingTaskReports]);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onEditTask = () => {
        setShowEditTrainingTaskModal(true);
    };

    const onViewFullNotes = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, notes: string | undefined) => {
        event.stopPropagation();
        alertMessage(formatValue(formatTextAreaText(notes, true)) as AlertMessage); 
    };

    const onViewFullCrewNames = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, names: string) => {
        event.stopPropagation();
        alertMessage(names); 
    };

    const formatCompletedByCrew = (report: TrainingTaskReport) => {
        let returnArray: string[] = [];
        report.completedBy?.forEach((crewId: string) => {
            returnArray.push(renderFullNameForUserId(crewId));
        });
        return returnArray.join(', ')
    };

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {selectedTask?.task}
                    </span>
                    {selectedTask?.whenDue &&
                        <SeaStatusDueDate
                            whenDue={selectedTask.whenDue}
                            warnDays={warnDays.crewTraining[0]}
                            //hasFault={selectedItem.hasFault}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
            viewOnly={viewOnly}
            level={level}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedTask?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Vessel" w="33">
                    {renderVesselName(selectedTask?.vesselId)}
                </SeaGridCell>
                <SeaGridCell label="Retraining Interval" w="33">
                    {formatInterval(selectedTask?.interval)}
                </SeaGridCell>
                {selectedTask?.whenDue &&
                    <SeaGridCell label="Next Due" w="33">
                        {formatDate(selectedTask?.whenDue)}
                    </SeaGridCell>
                }
                <SeaLinks links={links} ids={selectedTask?.id ? [selectedTask?.id] : []} level={level+1} viewOnly={viewOnly} />
            </SeaGrid>

            {!viewOnly && <div className="view-modal-buttons">
                <RequirePermissions
                    role="crewTraining"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditTask()}><SeaIcon icon="edit" slot="start" />Edit Training Task</SeaButton>
                    <RequirePermissions
                        role="crewTraining"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                //onDeleteTask(selectedTask, 'trainingTasks', true);
                                //setRequestToDeleteItem({item: item, collection: collectionName, closeModal: closeModal})
                                deleteIfConfirmed(
                                    'trainingTasks',
                                    selectedTask.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'trainingTasks');
                                        setShowModal(false);
                                    },
                                    'training task',
                                    selectedTask?.task,
                                    vesselId ? [vesselId] : []
                                );
                            }}
                        >
                            Delete Training Task
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>}

            <div className="end-info-line"></div>
            
            <div className="columns">
                <h2>Training History</h2>
                {/* <div className="right">
                    <SeaButton zone="white" onClick={(e) => onAddNewDrillReport()}><SeaIcon icon="notes" slot="start" />Create Drill Report</SeaButton>
                </div> */}
            </div>

            <SeaNoData
                dataName="training task"
                isHistory={true}
                isLoading={!taskHistory}
                hasNoData={taskHistory && taskHistory.length === 0}
            />
            <div className={`training-task-history ${taskHistory && taskHistory.length > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Completed By</div>
                    <div>Trainer</div>
                    <div>Notes</div>
                </div>
                <div className="sea-card sea-history-group">
                    {mapArrayWithLimit(taskHistory, (report) => {
                        return (
                            <div key={`${report.id}`} className="sea-row-history">
                                <div>{formatDate(report.whenCompleted)}</div>
                                <div className="truncate-3"  onClick={(e) => onViewFullCrewNames(e, formatCompletedByCrew(report))}>{formatCompletedByCrew(report)}</div>
                                <div className="truncate-3">{report.trainer}</div>
                                <div className="truncate-3" onClick={(e) => onViewFullNotes(e, report.notes)}>{formatValue(formatTextAreaText(report.notes))}</div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
            {showModal &&
                <EditTrainingTask
                    vesselId={vesselId}
                    itemToUpdate={selectedTask}
                    showModal={showEditTrainingTaskModal}
                    setShowModal={setShowEditTrainingTaskModal}
                    level={2}
                    taskHistory={taskHistory} 
                />
            }
        </SeaModal>
    );
};

export default ViewTrainingTask;
