import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { sharedState } from "../shared-state";
import { SafetyCheckCompleted } from './useCompletedSafetyCheckItems';


export type useSafetyCheckItemsData = Record<string, SafetyCheckCompleted[]> | undefined;

export const useVesselCompletedSafetyCheckItems = (dateRange: DateRange | undefined): useSafetyCheckItemsData => {
    const [completedSafetyChecksById, setCompletedSafetyChecksById] = useState<Record<string, SafetyCheckCompleted[]>>();
    const vesselId = sharedState.vesselId.use();

    useEffect(() => {
        setCompletedSafetyChecksById(undefined);
    
        if (vesselId && dateRange) {
            let _completedSafetyChecksBySafetyCheckId: Record<string, SafetyCheckCompleted[]> = {};
    
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckCompleted'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenCompleted', '>=', dateRange.from),
                    where('whenCompleted', '<=', dateRange.to + (24*60*60*1000)),
                    orderBy('whenCompleted', 'desc')
                ),
                (snap) => {
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyCheckCompleted;
                        if (!_completedSafetyChecksBySafetyCheckId[item.safetyCheckId]) {
                            _completedSafetyChecksBySafetyCheckId[item.safetyCheckId] = [];
                        }
                        _completedSafetyChecksBySafetyCheckId[item.safetyCheckId].push(item);
                    });
                    setCompletedSafetyChecksById(_completedSafetyChecksBySafetyCheckId);
                },
                (error) => {
                    console.log(`Failed to fetch completed safety checks for vesselId=${vesselId}`, error.message, error, {
                        vesselId,
                        dateRange,
                    });
                }
            );
        }
    }, [vesselId, dateRange]);

    return completedSafetyChecksById;
};