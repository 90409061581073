import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import { arrayRemove, arrayUnion, doc, serverTimestamp } from 'firebase/firestore';
import { UserType } from '../../../../shared-state/Core/user';
import { logAction } from '../../../../shared-state/General/actionLog';
import Yup from '../../../../lib/yup';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';

interface EditUserDrillsProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    user?: UserType;
    userDrills: Drill[];
    level?: number;
}

const EditUserDrills: React.FC<EditUserDrillsProps> = ({ showModal, setShowModal, user, userDrills, level = 1 }) => {
    const vesselDrills = sharedState.vesselDrills.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        return { assignedDrillIds: userDrills.map((drill) => drill.id) };
    }, [userDrills]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();

        setValues(initialValues);
    };

    const { handleSubmit, values, setValues, setFieldValue, resetForm, isSubmitting, isValid, validateForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            assignedDrillIds: Yup.array().of(Yup.string()),
        }),
        onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('drill')) {
                return;
            }
            // Process form
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'drills');

            const existingDrillIds = userDrills.map((drill) => drill.id);
            const newDrillIds = data.assignedDrillIds;

            const addedDrillIds = newDrillIds.filter((drillId) => !existingDrillIds.includes(drillId)); // New drills that have been assigned
            const removedDrillIds = existingDrillIds.filter((drillId) => !newDrillIds.includes(drillId)); // Drills that have been removed from the user

            if (!user) {
                // Should never happen
                throw new Error('No user provided to edit user drills');
            }

            addedDrillIds.forEach((drillId) => {
                const drill = userDrills.find((drill) => drill.id === drillId);
                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = drillId;

                // Remove User ID from the `notAssignedTo` array on the drill
                batch.set(
                    doc(firestore, 'drills', drillId),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        touched: serverTimestamp(),
                        notAssignedTo: arrayRemove(user.id),
                    },
                    { merge: true }
                );

                logAction(batch, 'Update', 'drills', drillId, drill?.name ?? '', [vesselId ?? '']);
            });

            removedDrillIds.forEach((drillId) => {
                const drill = userDrills.find((drill) => drill.id === drillId);
                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = drillId;

                // Add User ID to the `notAssignedTo` array on the drill
                batch.set(
                    doc(firestore, 'drills', drillId),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        touched: serverTimestamp(),
                        notAssignedTo: arrayUnion(user.id),
                    },
                    { merge: true }
                );

                logAction(batch, 'Update', 'drills', drillId, drill?.name ?? '', [vesselId ?? '']);
            });

            batchTrace.data = {
                userId,
                addedDrillIds,
                removedDrillIds
            };

            onCollectionUpdated(batch, 'drills');

            batchTrace.save(`Update userDrills for ${userId}`);
            batch
                .commit()
                .then(() => {
                    batchTrace.reportSuccess();
                })
                .catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

            setShowModal(false);
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    const toggleAssignedDrill = (drillId: string, checked: boolean) => {
        const newDrills = vesselDrills?.all?.filter((drill) => {
            if (drill.id === drillId) {
                return checked;
            }
            return values.assignedDrillIds.includes(drill.id);
        });
        const newDrillIds = newDrills?.map((drill) => drill.id);
        setFieldValue('assignedDrillIds', newDrillIds);
    };

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    // Validate on every change - required as interval was showing required error despite being defined...
    useEffect(() => {
        validateForm(values);
    }, [values, validateForm]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);

    return (
        <SeaModal title="Edit Assigned Drills" showModal={showModal} setShowModal={setShowModal} isDirty={isModalDirty} onOpened={onOpened} level={level} size={'thin'}>
            <form onSubmit={handleSubmit}>
                {vesselDrills?.all.map((drill) => {
                    return (
                        <div key={drill.id} style={{ marginTop: '8px' }}>
                            <SeaCheckbox
                                name="assignedDrillIds"
                                checked={values.assignedDrillIds.includes(drill.id)}
                                setChecked={(checked) => {
                                    toggleAssignedDrill(drill.id, checked);
                                }}
                            >
                                {drill.name}
                            </SeaCheckbox>
                        </div>
                    );
                })}
                <div className="grid-row-spacer"></div>
                <SeaFormHasErrors hasSubmitted={hasSubmitted} isValid={isValid} />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        Update Assigned Drills
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditUserDrills;
