import React, { useState, useEffect, useCallback } from 'react';
import { editFileFullscreen } from '../../managers/FileViewerManager/FileViewerManager';
import { convertBlobToBase64, getFileTrappedSrc, getImgSrcFromExt, getImgSrcFromSeaFile, SeaFile } from '../../lib/files';
import { getCachedImgSrcs } from '../../shared-state/FileSyncSystem/filesToLoad';
import { FileCollection } from '../../shared-state/FileSyncSystem/cachedFiles';
import { isPlatform } from '@ionic/core';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaFileSelector from '../SeaFileSelector/SeaFileSelector';
import SeaDraggables from '../SeaDraggables/SeaDraggables';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaFileUpload.css';


interface SeaFileUploadProps {
    files: SeaFile[],
    setFiles: (files: SeaFile[]) => void,
    collection: FileCollection, // collection this file will be attached to
    field: string,      // collection.field this will be attached to
    label?: string,
    allowMultiple?: boolean,
    maxUploadMB?: number,
    contained?: boolean,
    disableDrop?: boolean
}

const SeaFileUpload: React.FC<SeaFileUploadProps> = ({
    files,
    setFiles,
    collection,
    field,
    label,
    allowMultiple = true,
    maxUploadMB = 100,
    contained = false,
    disableDrop = false
}) => {
    const [addFileClickEvent, setAddFileClickEvent] = useState<React.MouseEvent | undefined>(undefined);
    const [srcs, setSrcs] = useState<string[]>();
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [isInternalDrag, setIsInternalDrag] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    // useEffect(() => {
    //     if (fileToTrash && files?.length > 0) {
    //         for (let i = 0; i < files.length; i++) {
    //             if (files[i] === fileToTrash) {
    //                 //console.log('Found file to trash', fileToTrash);
    //                 const _files = [...files];
    //                 _files.splice(i, 1);
    //                 setFiles(_files);
    //                 exitFullscreen();
    //                 return;
    //             }
    //         }
    //     }
    // }, [fileToTrash, exitFullscreen, files, setFiles]);

    const onTrashFile = useCallback((fileToTrash: SeaFile | undefined) => {
        if (!fileToTrash) return;
        for (let i = 0; i < files.length; i++) {
            if (files[i] === fileToTrash) {
                //console.log('Found file to trash', fileToTrash);
                const _files = [...files];
                _files.splice(i, 1);
                setFiles(_files);
                // exitFullscreen(); <-- do this in FileViewerManager
                return;
            }
        }
    }, [files, setFiles]);

    const onEditFile = useCallback((fileToEdit: SeaFile) => {
        editFileFullscreen(fileToEdit, onTrashFile);
    }, [onTrashFile]);

    useEffect(() => {
        //setSrcs(undefined);
        if (files && files.length > 0) {
            const filesToLoad = [] as string[];
            for (let i = 0; i < files.length; i++) {
                if (files[i].id) {
                    // Previously uploaded, therefore could have been cached
                    filesToLoad.push(`${files[i].state}${files[i].id}.${files[i].ext}`);
                } else {
                    filesToLoad.push('');
                }
            }
            return getCachedImgSrcs(filesToLoad, 'F', (_srcs) => {
                const promises = [] as Promise<void>[];
                const newSrcs = [] as string[];
                _srcs.forEach((src, index) => {
                    if (src === 'nonImage') {
                        newSrcs[index] = getImgSrcFromExt(files[index].ext, 'medium');
                    } else if (src === 'noCache') {
                        if (files[index].state === 0) { // Not yet uploaded by another device
                            newSrcs[index] = getFileTrappedSrc('medium');
                        } else {
                            promises.push(
                                getImgSrcFromSeaFile(files[index], 'medium').then((_src: string) => {
                                    newSrcs[index] = _src;
                                })
                            );
                        }
                    } else {
                        newSrcs[index] = src;
                    }
                });

                Promise.all(promises).then(() => {
                    setSrcs(newSrcs);
                });
            });
        }
    }, [files]);

    const fileToUnique = (file: SeaFile) => {
        if (file.id) {
            return file.id;
        }
        return file.unique;
    }

    const onFilesSelected = useCallback((_files: SeaFile[]) => {   
        if (_files && _files.length > 0) {
            // Add to any existing files
            setFiles([...files, ..._files]);
        }
    }, [files, setFiles]);

    const onReorder = (reorderedFiles: SeaFile[]) => {
        setFiles(reorderedFiles);
    };

    const onDragOver = useCallback((e: DragEvent | React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (isInternalDrag || isDragging) {
           return;
        }
        setIsDragging(true);
    }, [isInternalDrag, isDragging]);

    const onDragLeave = useCallback((e: DragEvent | React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!isDragging || isInternalDrag) return;
        
        // Only trigger dragLeave if we're moving to an element outside our component
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;
        
        if (x <= rect.left || x >= rect.right || y <= rect.top || y >= rect.bottom) {
            setIsDragging(false);
        }
    }, [isInternalDrag, isDragging]);

    const onDrop = useCallback((e: DragEvent | React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (isInternalDrag) return;
        setError(undefined);
        setIsDragging(false);
        if (!e.dataTransfer) return;
        const droppedFiles = Array.from(e.dataTransfer.files);

        const seaFilePromises = droppedFiles.map(file => {
            const dotIndex = file.name.lastIndexOf('.');

            if (dotIndex === -1 || dotIndex >= file.name.length - 1) {
                alert(`${file.name} is an unusual file name. Needs to be something like *.*`);
                return Promise.resolve(null); // Return a resolved promise with null to handle this case
            }

            const ext = file.name.substring(dotIndex + 1);
            return convertBlobToBase64(file)
            .then((base64) => {
                const strippedBase64 = (base64 as string).replace(/^data:.*;base64,/, '');
                return {
                    collection: collection,
                    field: field,
                    name: file.name,
                    ext: ext,
                    contentType: file.type,
                    lastModified: file.lastModified,
                    unique: (''+Math.random()).substring(2,12),
                    base64: strippedBase64 as string
                } as SeaFile;
            })
            .catch((error) => {
                setError('File type is likely not supported in this version');
                return null;
            });
        });

        Promise.all(seaFilePromises).then(seaFiles => {
            const validSeaFiles = seaFiles.filter(file => file !== null) as SeaFile[];
            onFilesSelected(validSeaFiles);
        });
    }, [collection, field, isInternalDrag, onFilesSelected]);


    useEffect(() => {
        if (!disableDrop && !contained && !isInternalDrag) {
            window.addEventListener('dragover', onDragOver);
            window.addEventListener('drop', onDrop);
            window.addEventListener('dragleave', onDragLeave);
        }

        return () => {
            if (!disableDrop && !contained && !isInternalDrag) {
                window.removeEventListener('dragover', onDragOver);
                window.removeEventListener('drop', onDrop);
                window.removeEventListener('dragleave', onDragLeave);
            }
        };
    }, [files, collection, field, onDrop, onDragOver, onDragLeave, disableDrop, contained, isInternalDrag]);

    return (
        <div
            onDragOver={contained && !isInternalDrag ? onDragOver : undefined}
            onDrop={contained && !isInternalDrag ? onDrop : undefined}
            onDragLeave={contained && !isInternalDrag ? onDragLeave : undefined}
            className={contained ? 'contained' : ''}
            style={{ position: contained ? 'relative' : 'static' }}
        >
            {!disableDrop && <div className={`drag-overlay ${isDragging ? 'dragging' : ''}`}>
                <SeaIcon icon="cloudUpload" forceFontSize={contained ? '24px' : '48px'} />
                Drop files to attach
            </div>}
            {label && (
                <div className="label-with-help">
                    <SeaLabel
                        help={{
                            text: isPlatform('desktop') ? "Click 'Add' or drop files to attach" : undefined,
                        }}
                    >{label}</SeaLabel>
                </div>
            )}
            <div className="sea-previews">
            <SeaDraggables
                items={files}
                onReorder={onReorder}
                setIsInternalDrag={setIsInternalDrag}
                nonDraggableEndComponent={(allowMultiple || files.length === 0) &&
                <div className="card" onClick={(e: React.MouseEvent) => setAddFileClickEvent(e)}>
                    <div>
                        <div className="doc-icon"><SeaIcon icon="add"/></div>
                        Add
                    </div>
                </div>
            }
            >
                {srcs && files?.map((file: SeaFile, index) => (
                    <img
                        key={fileToUnique(file)}
                        src={srcs[index]}
                        className="card"
                        onClick={(e) => onEditFile(files[index])}
                        alt="Preview"
                    />
                ))}
            </SeaDraggables>
            </div>
            <SeaInputError alignLeft>{error}</SeaInputError>
            <SeaFileSelector
                clickEvent={addFileClickEvent}
                onFilesSelected={onFilesSelected}
                collection={collection}
                field={field}
                allowMultiple={allowMultiple}
                maxUploadMB={maxUploadMB}
            />
        </div>
    );
};

export default SeaFileUpload;
