import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from './voyages';
import { useState, useEffect } from 'react';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { DateRange } from "../../components/SeaDateRange/SeaDateRange";
import { sharedState } from "../shared-state";

export type VoyagesData = {
    byId: {
        [id: string]: Voyage
    },
    all: Voyage[]
};

export const useVoyages = (tripType: 'singleDay' | 'multiDay' | 'multiTrip', dateRange?: DateRange) => {
    const vesselId = sharedState.vesselId.use();
    const [voyages, setVoyages] = useState<VoyagesData>();

    useEffect(() => {
        setVoyages(undefined);
        if (vesselId &&tripType && dateRange) {
            return onSnapshot(
                query(
                    collection(firestore, 'voyages'),
                    where('vesselId', '==', vesselId),
                    where('tripType', '==', tripType),
                    where('whenAdded', '>=', dateRange.from),
                    where('whenAdded', '<', dateRange.to + (24*60*60*1000)),
                    where('state', '==', 'completed'),
                    orderBy('whenAdded', 'desc')
                ),
                (snap) => {
                    const byId = {} as {
                        [id: string]: Voyage
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as Voyage;
                        byId[o.id] = o;
                        registerFiles(o.files, 'voyages', o);
                        return o;
                    });
                    setVoyages({
                        byId,
                        all
                    });
                }, (error) => {
                    console.log(`Failed to access voyages for vesselId=${vesselId} with tripType=${tripType} and dateRange=${dateRange}`, error.message, error, {
                        vesselId,
                        tripType,
                        dateRange
                    });
                }
            );
        }
    }, [vesselId, tripType, dateRange]);

    return voyages;
};
