import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption, isPlatform, IonSpinner } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../lib/firebase';
import { collection, doc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { formatDp, hasArrayChanged, haveValuesChanged, haveObjectsChanged, toMillis, cleanupStringArray, toInt, toFloat, pulseElementById, formatTextAreaText, getObjectWithoutFirestoreFieldValues, preventMultiTap } from '../../../lib/util';
import { formatSeaDatetime, formatTime, formatSeaDate, combineDateAndHours24 } from '../../../lib/datesAndTime';
import { formatCoords } from '../../../lib/mapping';
import { logAction } from '../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { Vessel } from '../../../shared-state/Core/vessel';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../managers/ToastManager/ToastManager';
import { reportError, makeBatchTrace } from '../../../managers/ErrorsManager/ErrorsManager';
import { CustomFields, EngineHour, Voyage, VoyageDay, VoyageStop, VoyageTrip } from '../../../shared-state/VesselLogbook/voyages';
import { handleUploadError, uploadFiles } from '../../../managers/FileUploadManager/FileUploadManager';
import { hasSignatureChanged, makeSignature, saveFileRefs, SeaFile, signatureToValue } from '../../../lib/files';
import { canDelete, canView } from '../../../shared-state/Core/userPermissions';
import { debugApp, debugCount } from '../../../shared-state/Core/debugging';
import {
    PreparedVoyage,
    PreparedVoyageStop,
    CheckType,
    PreparedSeaCrewHourData,
    PreparedVoyageDay,
    PreparedSeaCrewHour,
    makeInitialCustomFields,
    makeInitialChecks,
    prepareSeaTimeHours,
    prepareForecastData,
    prepareTripsData,
} from './voyage-utils';
import SeaWindyMap, { ForecastData } from '../../../components/SeaWindyMap/SeaWindyMap';
import SeaEngines, { EditEngine } from '../../../components/SeaEngines/SeaEngines';
import SeaCrewHours, { SeaCrewHourData } from '../../../components/SeaCrewHours/SeaCrewHours';
import Yup, { notTooOld } from '../../../lib/yup';
import VoyageTrips, { StopsErrors, StopsTouched, TripError, TripFieldTouched, TripStopError, TripStopTouched, TripsErrors, TripsTouched } from './VoyageTrips/VoyageTrips';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaDatetime from '../../../components/SeaDatetime/SeaDatetime';
import SeaSelectOrOther from '../../../components/SeaSelectOrOther/SeaSelectOrOther';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../components/SeaTextarea/SeaTextarea';
import SeaLinkButton from '../../../components/SeaLinkButton/SeaLinkButton';
import SeaMultiSelect from '../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaChecks from '../../../components/SeaChecks/SeaChecks';
import SeaCustomFields from '../../../components/SeaCustomFields/SeaCustomFields';
import SeaSignature from '../../../components/SeaSignature/SeaSignature';
import VoyageFormsAndDocumentsTab from './VoyageFormsAndDocumentsTab/VoyageFormsAndDocumentsTab';
import VoyageSafetyChecksTab from './VoyageSafetyChecksTab/VoyageSafetyChecksTab';
import VoyageMaintenanceTab from './VoyageMaintenanceTab/VoyageMaintenanceTab';
import SeaLabel from '../../../components/SeaLabel/SeaLabel';
import SeaWindyForecastTable from '../../../components/SeaWindyForecastTable/SeaWindyForecastTable';
import SeaModalScrollableArea from '../../../components/SeaModalScrollableArea/SeaModalScrollableArea';
import SeaDate from '../../../components/SeaDate/SeaDate';
import SeaTagsInput from '../../../components/SeaTagsInput/SeaTagsInput';
import VoyageDays from './VoyageDays/VoyageDays';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaCheckbox from '../../../components/SeaCheckbox/SeaCheckbox';
import _ from 'lodash';
import './EditVoyage.css';

interface EditVoyageProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    activeVoyage?: Voyage | PreparedVoyage;
}

export type VoyageCustomFieldErrors = {
    [key: number]: {
        [key: number]: {value: string, error: string, touched: boolean};
    };
}

const checkForRequiredFields = (customTextFieldsErrors: CustomFields[], requiredIndexes: number[]) => {
    let i = 0;
    for (const field of customTextFieldsErrors) {
        if (requiredIndexes.includes(i)) {
            if (field?.isRequired && !field?.value) {
                return true;
            }
        }
        i++;
    }
    return false;
}

const EditVoyage: React.FC<EditVoyageProps> = ({ showModal, setShowModal, activeVoyage }) => {
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal)!;
    const vessel = sharedState.vessel.use(showModal) as Vessel;
    const voyages = sharedState.voyages.use(showModal);
    const engines = sharedState.engines.use(showModal);
    const [tab, setTab] = useState('start');
    const [voyageTab, setVoyageTab] = useState('details');
    const [isActive, setIsActive] = useState(false);
    const [stops, setStops] = useState<VoyageStop[] | PreparedVoyageStop[]>([]);
    const [location, setLocation] = useState({
        latitude: -41.2924, // Near Wellington (so we centre in on NZ by default)
        longitude: 174.7787,
        zoom: 5,
        accuracy: 0,
    });
    const [forecastData, setForecastData] = useState<ForecastData>({});
    const [personnelInvolved, setPersonnelInvolved] = useState<string[]>([]);
    const [startupChecks, setStartupChecks] = useState<CheckType[]>();
    const [showCommonActions, setShowCommonActions] = useState(false);
    const [customTextFields, setCustomTextFields] = useState<CustomFields[]>();
    const [customHourFields, setCustomHourFields] = useState<CustomFields[]>();
    const [totalHours, setTotalHours] = useState(0);
    const [shutdownChecks, setShutdownChecks] = useState<CheckType[]>();
    const [editEngines, setEditEngines] = useState<EditEngine[]>([]);
    const [crewHours, setCrewHours] = useState<SeaCrewHourData | PreparedSeaCrewHourData>({}); // map of objects by userId
    const [signature, setSignature] = useState<SeaFile>();
    const [safetyChecksNumber, setSafetyChecksNumber] = useState(0);
    const [maintenanceNumber, setMaintenanceNumber] = useState(0);
    const [days, setDays] = useState<PreparedVoyageDay[] | VoyageDay[]>([{}]);
    const [dayTab, setDayTab] = useState('0');
    const [trips, setTrips] = useState<Partial<VoyageTrip>[]>([{}]);
    const [tripsErrors, setTripsErrors] = useState<TripsErrors>({});
    const [tripsTouched, setTripsTouched] = useState<TripsTouched>({});
    const [customTextFieldsErrors, setCustomTextFieldsErrors] = useState<VoyageCustomFieldErrors>({});
    const [tripTab, setTripTab] = useState('0');
    const hasErrorsRef = useRef({
        start: false,
        voyage: false,
        end: false,
    });
    const [startTabHasErrors, setStartTabHasErrors] = useState(false);
    const [voyageTabHasErrors, setVoyageTabHasErrors] = useState(false);
    const [endTabHasErrors, setEndTabHasErrors] = useState(false);
    const [personnelInvolvedIncludingMasters, setPersonnelInvolvedIncludingMasters] = useState<string[]>([]);
    const defaultWhenArrivedRef = useRef('');
    const formRef = useRef<HTMLFormElement>(null);
    const completeVoyageRef = useRef(false); // Was submit to complete voyage? (rather than just save progress)
    const forceCustomDataValidationRef = useRef(false);
    const isAutoSavingRef = useRef(false);
    const jobIdRef = useRef(0);
    const actionLogIdRef = useRef<string>();

    const [autoSaveSetting, setAutoSaveSetting] = useState<boolean>(false);
    const [unsavedValues, setUnsavedValues] = useState<any>();

    debugCount('renderEditVoyage');

    // Find the previous active voyage ignoring currently active
    const previousVoyage = useMemo(() => {
        if (voyages && voyages.all && voyages.all.length > 0) {
            return voyages.all.find((voyage) => voyage.state !== 'started');
        }
        return undefined;
    }, [voyages]);

    const requiredCustomTextFieldsIndexes = useMemo(() => {
        const _indexes = [] as number[];
        if (vessel?.logbookSettings?.customTextFieldsData) {
            for (let i = 0; i < vessel?.logbookSettings?.customTextFieldsData.length; i++) {
                const field = vessel?.logbookSettings?.customTextFieldsData[i];
                if (field.isRequired) {
                    _indexes.push(i);
                }
            }
        }
        return _indexes;
    }, [vessel?.logbookSettings?.customTextFieldsData]);

    useEffect(() => {
        if (activeVoyage) {
            setTab('voyage');
            onOpened(); // so isDirty will work
        } else {
            // No active voyage, therefore should be on first tab
            setTab('start');
        }
    }, [activeVoyage]);

    const makeEditEngines = useCallback(() => {
        if (!engines) {
            return undefined;
        }
        if (engines.all.length > 0) {
            const _editEngines: EditEngine[] | undefined =
                engines.all.map((engine) => {
                    let hours = '' + engine.hours;
                    let startHours = engine.hours;
                    activeVoyage?.engineHours?.forEach((item) => {
                        if (item.id === engine.id) {
                            if (item.hours !== undefined) {
                                hours = '' + item.hours;
                            }
                            startHours = item.startHours;
                        }
                    });
                    return {
                        id: engine.id,
                        name: engine.name,
                        initialHours: hours,
                        hours: hours,
                        startHours: startHours,
                        error: '',
                    };
                }) || [];
            return _editEngines;
        }
        return [];
    }, [engines, activeVoyage]);

    // Create array of engineHours[{id, hours}...] to save to firestore from editEngines
    const compileEngineHours = () => {
        const _engineHours: EngineHour[] = [];
        editEngines?.forEach((item) => {
            _engineHours.push({
                id: item.id,
                hours: completeVoyageRef.current || item.hours !== item.initialHours ? toInt(item.hours, 0) : undefined,
                startHours: item.startHours,
            } as EngineHour);
        });
        return _engineHours;
    };

    const makeCrewHours = useCallback(
        (
            parent?: PreparedVoyageDay | PreparedVoyage // The object within which crewHours and totalHours is contained
        ) => {
            if (users?.byVesselId[vesselId] && userId) {
                let totalHours = 0;
                if (parent && parent.totalHours !== undefined) {
                    totalHours = parent.totalHours;
                }
                const _crewHours: PreparedSeaCrewHourData = {};
                users.byVesselId[vesselId].forEach((u) => {
                    if (parent?.crewHours && Array.isArray(parent.crewHours) && parent.crewHours.length > 0) {
                        for (let i = 0; i < parent.crewHours.length; i++) {
                            if (parent.crewHours[i].id === u.id) {
                                const item = parent.crewHours[i];
                                // Using saved data
                                _crewHours[u.id!] = {
                                    name: renderFullName(u),
                                    isEdited: item.hours !== totalHours, // If not the same as totalHours, was probably edited
                                    initialHours: '' + item.hours,
                                    hours: '' + item.hours,
                                    error: '',
                                };
                                return;
                            }
                        }
                    }
                    // Use default values
                    _crewHours[u.id!] = {
                        name: renderFullName(u),
                        isEdited: false,
                        initialHours: '' + totalHours,
                        hours: '' + totalHours,
                        error: '',
                    };
                });
                return _crewHours;
            }
            return {};
        },
        [users, vesselId, userId]
    );

    // Create array of crewHours[{id, hours}, ...] to save to firestore from crewHours
    // Should only include crew in personnelInvolvedIncludingMasters
    const compileCrewHours = (
        crewHours: PreparedSeaCrewHourData | PreparedSeaCrewHour[] | undefined, // Map of crew hours {<userId>: <hours>, ...}
        totalHours?: number
    ) => {
        const array = crewHours && Array.isArray(crewHours) ? crewHours : undefined;
        const object = crewHours && !Array.isArray(crewHours) ? crewHours : undefined;

        const _crewHours = [] as PreparedSeaCrewHour[];
        personnelInvolvedIncludingMasters?.forEach((userId: string) => {
            if (
                completeVoyageRef.current ||
                (object && object?.[userId] && object[userId].hours && (object[userId].hours as unknown) !== '' + totalHours) ||
                (array && array?.length > 0 && array?.find((item) => item.id === userId)?.hours && (array?.find((item) => item.id === userId)?.hours as unknown) !== '' + totalHours)
            ) {
                _crewHours.push({
                    id: userId,
                    hours: object ? toFloat(object?.[userId]?.hours, 0) : array?.find((item) => item.id === userId)?.hours ? toFloat(array?.find((item) => item.id === userId)?.hours, 0) : 0,
                } as PreparedSeaCrewHour);
            }
        });
        return _crewHours;
    };

    const prepareDaysData = (days: PreparedVoyageDay[]) => {
        const _days = [] as PreparedVoyageDay[];
        if (days && days.length > 0) {
            days.forEach((day) => {
                _days.push({
                    notes: day.notes ? '' + day.notes : '',
                    customText: day.customText,
                    customHours: day.customHours,
                    crewHours: compileCrewHours(day.crewHours || [], day.totalHours),
                    totalHours: day.totalHours ? day.totalHours : 0,
                });
            });
        }
        return _days;
    };

    const makeDays = useCallback(() => {
        if (activeVoyage?.days && activeVoyage.days.length > 0) {
            const _days: PreparedVoyageDay[] = [];
            activeVoyage.days.forEach((day) => {
                _days.push({
                    ...day,
                    crewHours: makeCrewHours(day as PreparedVoyageDay),
                } as PreparedVoyageDay);
            });
            return _days;
        }
        return [
            {
                notes: '',
                customText: makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], (activeVoyage as Voyage | undefined)?.customTextFields || [], ''),
                customHours: makeInitialCustomFields(vessel?.logbookSettings?.customHourFields || [], (activeVoyage as Voyage | undefined)?.customHourFields || [], '0'),
                crewHours: makeCrewHours(undefined),
                totalHours: 0,
            } as PreparedVoyageDay,
        ];
    }, [activeVoyage, makeCrewHours, vessel]);

    const makeTrips = useCallback(() => {
        if (activeVoyage?.trips) {
            const _trips: VoyageTrip[] = [];
            activeVoyage.trips.forEach((trip) => {
                const _stops: VoyageStop[] = [];
                if (trip.stops && trip.stops.length > 0) {
                    trip.stops.forEach((stop) => {
                        _stops.push({
                            location: stop.location ? '' + stop.location : '',
                            whenArrived: stop.whenArrived ? formatTime(stop.whenArrived) : '',
                            paxOn: stop.paxOn ? '' + stop.paxOn : '0',
                            paxOff: stop.paxOff ? '' + stop.paxOff : '0',
                            whenDeparted: stop.whenDeparted ? formatTime(stop.whenDeparted) : '',
                        });
                    });
                }
                _trips.push({
                    masterId: trip.masterId ? '' + trip.masterId : '',
                    crewInvolved: trip.crewInvolved,
                    customText: trip.customText ?? makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], trip.customText || [], ''),
                    departureFrom: trip.departureFrom ? trip.departureFrom : '',
                    whenDeparted: trip.whenDeparted ? formatTime(trip.whenDeparted) : '',
                    paxOn: trip.paxOn ? '' + trip.paxOn : '0',
                    destinationTo: trip.destinationTo ? '' + trip.destinationTo : '',
                    whenArrived: trip.whenArrived ? formatTime(trip.whenArrived) : '',
                    stops: _stops,
                });
            });
            return _trips;
        }
        return [
            {
                masterId: userId,
                crewInvolved: [],
                customText: makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], (activeVoyage as Voyage | undefined)?.customTextFields || [], ''),
                departureFrom: previousVoyage?.departureFrom ? '' + previousVoyage.departureFrom : '',
                whenDeparted: formatTime(),
                paxOn: '0',
                destinationTo: previousVoyage?.destinationTo ? '' + previousVoyage.destinationTo : '',
                whenArrived: '',
            },
        ];
    }, [activeVoyage, previousVoyage?.departureFrom, previousVoyage?.destinationTo, userId, vessel?.logbookSettings?.customTextFields]);

    const renderMasterIds = (masterIds: string[]) => {
        if (masterIds && masterIds.length > 0) {
            const masters = [] as string[];
            masterIds.forEach((masterId: string) => {
                masters.push(renderFullNameForUserId(masterId));
            });
            return masters.join(', ');
        }
        return 'Voyage';
    };

    const initialValues = useMemo(() => {
        defaultWhenArrivedRef.current = formatSeaDatetime();
        if (activeVoyage) {
            return {
                // start tab
                tripType: activeVoyage.tripType ? '' + activeVoyage.tripType : 'singleDay',
                name: activeVoyage.name ? '' + activeVoyage.name : '',
                masterId: activeVoyage.masterIds?.length > 0 ? '' + activeVoyage.masterIds[0] : '',
                whenDeparted: formatSeaDatetime(activeVoyage.whenDeparted),
                departureFrom: activeVoyage.departureFrom ? '' + activeVoyage.departureFrom : '',
                //skipper: activeVoyage.skipper ? ''+activeVoyage.skipper : '',
                operation: activeVoyage.operation ? '' + activeVoyage.operation : '',
                fuelStart: activeVoyage.fuelStart ? '' + activeVoyage.fuelStart : '',
                safetyBriefCompleted: activeVoyage.safetyBriefCompleted ? true : false,
                //stopsOrRouteDetails: activeVoyage.stopsOrRouteDetails ? ''+activeVoyage.stopsOrRouteDetails : '',
                //tripReported: activeVoyage.tripReported ? true : false,
                //tripReportedTo: activeVoyage.tripReportedTo ? ''+activeVoyage.tripReportedTo : '',
                //whenTripReported: formatSeaDatetime(activeVoyage.whenTripReported),
                pax: activeVoyage.pax !== undefined ? '' + activeVoyage.pax : '',
                //showSafetyBriefing: activeVoyage.showSafetyBriefing ? true : false,
                //additionalPassengersOrDetails: activeVoyage.additionalPassengersOrDetails ? ''+activeVoyage.additionalPassengersOrDetails : '',
                // weatherForecast: activeVoyage.weatherForecast ? ''+activeVoyage.weatherForecast : '',
                // windDirection: activeVoyage?.weatherObservation?.windDirection ? ''+activeVoyage.weatherObservation.windDirection : '',
                // windStrength: activeVoyage?.weatherObservation?.windStrength ? ''+activeVoyage.weatherObservation.windStrength : '',
                // seaState: activeVoyage?.weatherObservation?.seaState ? ''+activeVoyage.weatherObservation.seaState : '',
                // visibility: activeVoyage?.weatherObservation?.visibility ? ''+activeVoyage.weatherObservation.visibility : '',
                startupNotes: activeVoyage.startupNotes ? '' + activeVoyage.startupNotes : '',
                // voyage tab
                voyageNotes: activeVoyage.voyageNotes ? '' + activeVoyage.voyageNotes : '',
                // end tab
                destinationTo: activeVoyage.destinationTo ? '' + activeVoyage.destinationTo : '',
                whenArrived: activeVoyage.whenArrived ? formatSeaDatetime(activeVoyage.whenArrived) : defaultWhenArrivedRef.current,
                fuelBunkered: activeVoyage.fuelBunkered !== undefined ? '' + activeVoyage.fuelBunkered : '',
                fuelExported: activeVoyage.fuelExported !== undefined ? '' + activeVoyage.fuelExported : '',
                fuelBunkeredCost: activeVoyage.fuelBunkeredCost ? formatDp(activeVoyage.fuelBunkeredCost, 2) : '',
                fuelEnd: activeVoyage.fuelEnd ? '' + activeVoyage.fuelEnd : '',
                shutdownNotes: activeVoyage.shutdownNotes ? '' + activeVoyage.shutdownNotes : '',
            };
        } else {
            return {
                // start tab
                tripType: previousVoyage?.tripType ? '' + previousVoyage.tripType : 'singleDay',
                name: '',
                masterId: userId ? userId : previousVoyage?.masterIds && previousVoyage?.masterIds?.length > 0 ? '' + (previousVoyage?.masterIds[0] ?? '') : '',
                whenDeparted: formatSeaDatetime(),
                departureFrom: previousVoyage?.departureFrom ? '' + previousVoyage.departureFrom : '',
                //skipper: ''+renderFullName(user),
                operation: previousVoyage?.operation ? '' + previousVoyage.operation : '',
                fuelStart: previousVoyage?.fuelEnd ? '' + previousVoyage.fuelEnd : '',
                safetyBriefCompleted: false,
                //stopsOrRouteDetails: '',
                //tripReported: false,
                //tripReportedTo: '',
                //whenTripReported: formatSeaDatetime(),
                pax: '',
                //showSafetyBriefing: false,
                //additionalPassengersOrDetails: '',
                // weatherForecast: '',
                // windDirection: '',
                // windStrength: '',
                // seaState: '',
                // visibility: '',
                startupNotes: '',
                // voyage tab
                voyageNotes: '',
                // end tab
                destinationTo: previousVoyage?.destinationTo ? '' + previousVoyage.destinationTo : '',
                whenArrived: defaultWhenArrivedRef.current,
                fuelBunkered: '0',
                fuelBunkeredCost: '',
                fuelExported: '',
                fuelEnd: '',
                shutdownNotes: '',
            };
        }
    }, [activeVoyage, voyages]);

    const setupUnsavedData = useCallback(
        (values: any, _customTextFields?: CustomFields[], _customHourFields?: CustomFields[], _editEngines?: EditEngine[]) => {
            setUnsavedValues(() => ({
                values: _.cloneDeep(values),
                stops: _.cloneDeep(stops),
                personnelInvolved: _.cloneDeep(personnelInvolved),
                startupChecks: _.cloneDeep(startupChecks),
                customTextFields: _.cloneDeep(_customTextFields ?? customTextFields),
                customHourFields: _.cloneDeep(_customHourFields ?? customHourFields),
                totalHours: totalHours,
                shutdownChecks: _.cloneDeep(shutdownChecks),
                editEngines: _.cloneDeep(_editEngines ?? editEngines),
                crewHours: _.cloneDeep(crewHours),
                signature: _.cloneDeep(signature),
                days: _.cloneDeep(days),
                trips: _.cloneDeep(trips),
            }));
        },
        [crewHours, customHourFields, customTextFields, days, editEngines, personnelInvolved, shutdownChecks, signature, startupChecks, stops, totalHours, trips]
    );

    const onOpened = () => {
        completeVoyageRef.current = false;
        resetForm();
        setAutoSaveSetting(window.localStorage.getItem(`_${sharedState.userId.current}_autosave`) === 'true');
        isAutoSavingRef.current = false;
        setValues(initialValues);
        setStops(activeVoyage?.stops || []);
        setPersonnelInvolved(activeVoyage?.personnelInvolved ? [...activeVoyage.personnelInvolved] : []);
        setStartupChecks(makeInitialChecks(vessel?.logbookSettings?.startupChecks || [], (activeVoyage as PreparedVoyage | undefined)?.startupChecks || []));
        const initialCustomTextFields = makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], (activeVoyage as PreparedVoyage | undefined)?.customTextFields || [], '');
        setCustomTextFields(initialCustomTextFields);
        const initialCustomHourFields = makeInitialCustomFields(vessel?.logbookSettings?.customHourFields || [], (activeVoyage as PreparedVoyage | undefined)?.customHourFields || [], '0');
        setCustomHourFields(initialCustomHourFields);
        setTotalHours(activeVoyage?.totalHours || 0);
        setShutdownChecks(makeInitialChecks(vessel?.logbookSettings?.shutdownChecks || [], (activeVoyage as PreparedVoyage | undefined)?.shutdownChecks || []));
        setCrewHours(makeCrewHours(activeVoyage as PreparedVoyage | undefined));
        setSignature(makeSignature(activeVoyage?.signature));
        setTab(activeVoyage ? 'voyage' : 'start');
        setVoyageTab('details');
        setDays(makeDays());
        setTrips(makeTrips());
        setTripsErrors({});
        setTripsTouched({});
        setTripTab(activeVoyage?.trips ? '' + (activeVoyage.trips.length - 1) : '0');
        setDayTab(activeVoyage?.days ? '' + (activeVoyage.days.length - 1) : '0');

        const setupEngines = (attempt = 0) => {
            if (engines) {
                const _editEngines = makeEditEngines();
                setEditEngines(_editEngines as EditEngine[]);
                setupUnsavedData(initialValues, initialCustomTextFields, initialCustomHourFields, _editEngines);
            } else if (attempt < 10) {
                // Limit to 10 attempts to prevent infinite loop
                setTimeout(() => {
                    setupEngines(attempt + 1);
                }, 50 * Math.pow(2, attempt)); // Exponential backoff
            } else {
                console.error('Failed to load engines data after multiple attempts');
                // Optionally, handle the error case here
            }
        };

        setupEngines();
    };

    const onClosed = () => {
        setIsActive(false);
        setAutoSaveSetting(false); // Prevent auto-save on close
        setCustomHourFields(undefined);
        setCustomTextFields(undefined);
        setCustomTextFieldsErrors({});
        setUnsavedValues(undefined);
        forceCustomDataValidationRef.current = false;
        actionLogIdRef.current = undefined;
    };

    const addCommonAction = (action: string) => {
        let s = '';
        if (values.voyageNotes && values.voyageNotes.length > 0) {
            s += values.voyageNotes + '\n';
        }
        s += `${formatTime()} - ${action}`;
        setFieldValue('voyageNotes', s);
        pulseElementById('voyageNotes', 0);
    };

    const deleteVoyage = () => {
        // Delete Voyage
        if (!activeVoyage) {
            return;
        }
        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'voyages', 'delete', activeVoyage.id);

        batch.set(
            doc(firestore, 'voyages', activeVoyage.id),
            {
                state: 'deleted',
                whenDeleted: batchTrace.whenAction,
                deletedBy: userId,
                touched: serverTimestamp(),
            },
            { merge: true }
        );

        onCollectionUpdated(batch, 'voyages');
        logAction(batch, 'Delete', 'voyages', activeVoyage.id, `${renderMasterIds(activeVoyage.masterIds)}${activeVoyage?.name ? ` - ${activeVoyage.name}` : ''}`, [vesselId], activeVoyage.personnelInvolved);

        batchTrace.data = {
            activeVoyage,
        };
        batchTrace.save(`Delete voyage ${renderMasterIds(activeVoyage.masterIds)}`);
        batch
            .commit()
            .then(() => {
                batchTrace.reportSuccess();
            })
            .catch((error) => {
                batchTrace.reportError(error.message, error);
            });

        showToast(`Your current voyage has been deleted!`);
        setShowModal(false);
    };

    const validationSchema = () => {
        return Yup.object({
            // start tab
            tripType: Yup.string().max(10).required(),
            //name: Yup.string().max(500).required(),
            masterId: Yup.string()
                .max(20)
                .when('tripType', { is: 'singleDay', then: (schema: Yup.StringSchema) => schema.required() })
                .when('tripType', { is: 'multiDay', then: (schema: Yup.StringSchema) => schema.required() }),
            whenDeparted: Yup.date()
                .required()
                .min(...notTooOld),
            departureFrom: Yup.string().max(500),
            //skipper: Yup.string().max(500),
            operation: Yup.string().max(500),
            safetyBriefCompleted: Yup.boolean(),
            //stopsOrRouteDetails: Yup.string().max(5000),
            //tripReported: Yup.boolean(),
            //tripReportedTo: Yup.string().max(500),
            //whenTripReported: Yup.date().min(...notTooOld),
            pax: Yup.number().max(100000),
            //showSafetyBriefing: Yup.boolean(),
            //additionalPassengersOrDetails: Yup.string().max(5000),
            // weatherForecast: Yup.string().max(5000),
            // windDirection: Yup.string().max(500),
            // windStrength: Yup.string().max(500),
            // seaState: Yup.string().max(500),
            // visibility: Yup.string().max(500),
            startupNotes: Yup.string().max(5000),
            // voyage tab
            voyageNotes: Yup.string().max(5000),
            // end tab
            destinationTo: Yup.string().max(500),
            whenArrived: Yup.date().min(...notTooOld),
            fuelStart: vessel?.logbookSettings?.fuel === 'mandatory' ? Yup.number().max(1000000).required() : Yup.number().max(1000000),
            fuelEnd: vessel?.logbookSettings?.fuel === 'mandatory' && !isAutoSavingRef.current && completeVoyageRef.current ? Yup.number().max(1000000).required() : Yup.number().max(1000000),
            fuelBunkered: Yup.number().max(1000000),
            fuelBunkeredCost: Yup.number().max(1000000),
            fuelExported: Yup.number().max(1000000),
            shutdownNotes: Yup.string().max(5000),
            customTextFields: Yup.array().of(
                Yup.object().shape({
                    value: Yup.string().test('is-required', 'This field is required', function (value) {
                        const { path, createError } = this;
                        const index = parseInt(path.split('[')[1].split(']')[0]);
                        const isRequired = requiredCustomTextFieldsIndexes.includes(index);
                        if (isRequired && (!value || value.trim() === '')) {
                            return createError({ path, message: 'This field is required' });
                        }
                        return true;
                    }),
                })
            ),
        });
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validate: (values) => {
            const errors: any = {};

            if (startTabHasErrors) {
                errors.startTab = 'Form has validation errors in the Start tab';
            } else if (!isAutoSavingRef.current && completeVoyageRef.current && (voyageTabHasErrors || endTabHasErrors)) {
                if (voyageTabHasErrors) {
                    errors.voyageTab = 'Form has validation errors in the Voyage tab';
                }
                if (endTabHasErrors) {
                    errors.endTab = 'Form has validation errors in the End tab';
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            debugApp('logbook', `Submitting form. autoSave is ${autoSaveSetting ? 'on' : 'off'}.`);
            debugCount('submitVoyage');
            if (preventMultiTap('voyage', 3000) || Object.keys(errors).length > 0) {
                // If autosaving after complete has been triggered, there are some acceptable errors that can be ignored
                const acceptableErrors = isAutoSavingRef.current ? ['fuelEnd', 'voyageTab', 'endTab'] : [];
                const isOnlyAcceptableErrors = Object.keys(errors).every((key) => acceptableErrors.includes(key));

                if (!isOnlyAcceptableErrors) {
                    isAutoSavingRef.current = false;
                    return;
                }
            }
            const isAutoSaving = Boolean(isAutoSavingRef.current);
            const isCompleting = !isAutoSaving && completeVoyageRef.current;

            // Sanity Checks
            if (isCompleting && (hasErrorsRef.current.voyage || hasErrorsRef.current.end || hasErrorsRef.current.start)) {
                isAutoSavingRef.current = false;
                return;
            }
            const itemsToCheck = values.tripType === 'multiDay' ? days.map((day) => day.customText) : values.tripType === 'multiTrip' ? trips.map((trip) => trip.customText) : [customTextFields];
            if (itemsToCheck) {
                for (const item of itemsToCheck) {
                    if (checkForRequiredFields(item as CustomFields[], requiredCustomTextFieldsIndexes)) {
                        isAutoSavingRef.current = false;
                        return;
                    }
                }
            }

            
            let masterIds = [data.masterId];
            uploadFiles(signature ? [signature] : [])
                .then(() => {
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'voyages');
                    const possibleDestinations = [] as string[];
                    const cleanedStops = cleanupStringArray(stops as unknown as string[]);
                    const possibleStops = cleanedStops ? [...cleanedStops] : [];
                    const enginesEdited: string[] = [];
                    let newData: any;
                    if (activeVoyage) {
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = activeVoyage.id;
                        // Update existing voyage
                        newData = {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            state: isCompleting ? 'completed' : 'started',
                            // Start tab
                            //tripType: data.tripType, (set duting start up)
                            //name: data.name,
                            masterIds: masterIds,
                            whenDeparted: data.whenDeparted ? toMillis(data.whenDeparted) : deleteValue,
                            departureFrom: data.departureFrom?.trim() ? data.departureFrom.trim() : deleteValue,
                            //skipper: data.skipper ? data.skipper : deleteValue,
                            operation: data.operation ? data.operation : deleteValue,
                            fuelStart: toFloat(data.fuelStart, deleteValue),
                            //stopsOrRouteDetails: data.stopsOrRouteDetails ? data.stopsOrRouteDetails : deleteValue,
                            //tripReported: data.                        //tripReportedTo: (data.tripReported && data.tripReportedTo) ? data.tripReportedTo : deleteValue,
                            //whenTripReported: (data.tripReported && data.whenTripReported) ? toMillis(data.whenTripReported) : deleteValue,
                            pax: toFloat(data.pax, deleteValue),
                            //showSafetyBriefing: data.showSafetyBriefing ? true : false,
                            //additionalPassengersOrDetails: data.additionalPassengersOrDetails ? data.additionalPassengersOrDetails : deleteValue,
                            // weatherForecast: data.weatherForecast ? data.weatherForecast : deleteValue,
                            // weatherObservation: {
                            //     windDirection: data.windDirection? data.windDirection : deleteValue,
                            //     windStrength: data.windStrength ? data.windStrength : deleteValue,
                            //     seaState: data.seaState ? data.seaState : deleteValue,
                            //     visibility: data.visibility ? data.visibility : deleteValue,
                            // },
                            startupChecks: startupChecks,
                            startupNotes: data.startupNotes ? data.startupNotes : deleteValue,
                            // tidalData: (showTides && tides) ? tides : deleteValue,
                            // Voyage tab
                            // ... Handled differently depending on tripType, see next code block below...
                            // End tab
                            fuelBunkered: toFloat(data.fuelBunkered, deleteValue),
                            fuelBunkeredCost: toFloat(data.fuelBunkeredCost, deleteValue),
                            fuelExported: toFloat(data.fuelExported, deleteValue),
                            fuelEnd: toFloat(data.fuelEnd, deleteValue),
                            shutdownChecks: shutdownChecks,
                            shutdownNotes: data.shutdownNotes ? data.shutdownNotes : deleteValue,
                            engineHours: compileEngineHours(),
                            signature: signatureToValue(signature),
                            touched: serverTimestamp(),
                        } as Partial<Voyage>;

                        if (data.tripType !== 'multiDay') {
                            newData.crewHours = compileCrewHours(crewHours as PreparedSeaCrewHourData, totalHours);
                        }

                        if (data.tripType === 'multiTrip') {
                            newData.voyageNotes = data.voyageNotes ? data.voyageNotes : deleteValue;
                            // Determine departureFrom, destinationTo, whenDeparted, whenArrived
                            // Compile masterIds, personnelInvolved
                            // Calculate pax summing all paxOn
                            masterIds = [];
                            const personnelInvolved = [] as string[];
                            const hasMasterId: {
                                [key: string]: boolean;
                            } = {};
                            const hasPersonnelInvolved: {
                                [key: string]: boolean;
                            } = {};
                            let pax = 0;
                            let baseDate = 0; // millis for start of day
                            trips.forEach((trip, tripIndex) => {
                                if (trip.departureFrom?.trim() && possibleDestinations.indexOf(trip.departureFrom.trim()) === -1) {
                                    possibleDestinations.push(trip.departureFrom.trim());
                                }
                                if (trip.destinationTo?.trim() && possibleDestinations.indexOf(trip.destinationTo.trim()) === -1) {
                                    possibleDestinations.push(trip.destinationTo.trim());
                                }
                                if (tripIndex === 0) {
                                    if (data.whenDeparted && trip.departureFrom && trip.whenDeparted) {
                                        newData.departureFrom = trip.departureFrom;
                                        baseDate = toMillis(formatSeaDate(toMillis(trip.whenDeparted))) as number;
                                        newData.whenDeparted = combineDateAndHours24(baseDate, trip.whenDeparted);
                                    }
                                }
                                if (tripIndex === trips.length - 1 && trip.whenArrived) {
                                    newData.destinationTo = trip.destinationTo;
                                    newData.whenArrived = combineDateAndHours24(baseDate, trip.whenArrived);
                                }
                                if (trip.masterId && !hasMasterId[trip.masterId]) {
                                    hasMasterId[trip.masterId] = true;
                                    masterIds.push(trip.masterId);
                                }
                                trip.crewInvolved?.forEach((userId: string) => {
                                    if (!hasPersonnelInvolved[userId]) {
                                        hasPersonnelInvolved[userId] = true;
                                        personnelInvolved.push(userId);
                                    }
                                });
                                pax += toInt(trip.paxOn, 0);
                                trip?.stops?.forEach((stop) => {
                                    pax += toInt(stop.paxOn, 0);
                                    // Add to possibleStops array if not already there (to be added to logbookSettings.stops)
                                    if (stop.location && possibleStops.indexOf(stop.location.trim()) === -1) {
                                        possibleStops.push(stop.location.trim());
                                    }
                                });
                            });
                            newData.trips = prepareTripsData(baseDate, trips as VoyageTrip[]);
                            newData.masterIds = masterIds;
                            newData.personnelInvolved = personnelInvolved;
                            newData.pax = pax;
                        } else {
                            // singleDay or multiDay
                            newData.name = data.name?.trim() ? data.name.trim() : deleteValue;
                            newData.personnelInvolved = personnelInvolved ? personnelInvolved : [];
                            newData.destinationTo = data.destinationTo?.trim() ? data.destinationTo.trim() : deleteValue;
                            newData.whenArrived = data.whenArrived && (isCompleting || data.whenArrived !== defaultWhenArrivedRef.current) ? toMillis(data.whenArrived) : deleteValue; // Don't want to record this unless user changed it, or we are completing the voyage
                            newData.safetyBriefCompleted = data.safetyBriefCompleted ? true : false;
                            newData.stops = cleanedStops;

                            if (data.tripType === 'singleDay') {
                                newData.voyageNotes = data.voyageNotes ? data.voyageNotes : deleteValue;
                                newData.customTextFields = customTextFields;
                                newData.customHourFields = customHourFields;
                                newData.totalHours = totalHours;
                            } else if (data.tripType === 'multiDay') {
                                newData.days = prepareDaysData(days as PreparedVoyageDay[]);
                            }
                        }

                        batch.set(doc(firestore, 'voyages', activeVoyage.id), newData as Voyage, { merge: true });

                        saveFileRefs(batch, signature ? [signature] : [], 'voyages', activeVoyage.id);

                        if (!isAutoSaving || actionLogIdRef.current === undefined) {
                            actionLogIdRef.current = logAction(batch, 'Update', 'voyages', activeVoyage.id, renderMasterIds(masterIds), [vesselId], personnelInvolved, isCompleting ? 'complete' : 'progress');
                        } else {
                            batch.set(
                                doc(firestore, 'actionLog', actionLogIdRef.current),
                                {
                                    when: Date.now(),
                                    personnelIds: personnelInvolved,
                                    touched: serverTimestamp(),
                                },
                                { merge: true }
                            );
                            onCollectionUpdated(batch, 'actionLog');
                        }

                        // Update engines if appropriate
                        editEngines?.forEach((item) => {
                            if (item.id && item.hours !== item.initialHours && toInt(item.hours, 0) > (engines?.byId[item.id]?.hours ?? 0)) {
                                enginesEdited.push(item.id);
                                batch.set(
                                    doc(firestore, 'engines', item.id),
                                    {
                                        hours: toInt(item.hours, 0),
                                        whenUpdated: batchTrace.whenAction,
                                        updatedVia: 'voyage',
                                        updatedBy: userId,
                                        touched: serverTimestamp(),
                                    },
                                    { merge: true }
                                );

                                onCollectionUpdated(batch, 'engines');
                                logAction(batch, 'Update', 'engines', item.id, `${item.name}, ${item.hours} hours`, [vesselId], item.personnelInvolved);
                            }
                        });

                        if (isCompleting) {
                            // Save companion seaTimeRecords document
                            batch.set(
                                doc(firestore, 'seaTimeRecords', activeVoyage.id),
                                {
                                    licenseeId: vessel.licenseeId,
                                    vesselId: vesselId,
                                    state: 'active',
                                    fromVoyage: true,
                                    whenVoyage: newData.whenDeparted,
                                    fromLocation: newData.departureFrom,
                                    toLocation: newData.destinationTo,
                                    days: newData.days && newData.days.length > 0 ? newData.days.length : 1,
                                    masterIds: newData.masterIds,
                                    crewIds: newData.personnelInvolved,
                                    hours: prepareSeaTimeHours(newData, data.tripType),
                                    touched: serverTimestamp(),
                                },
                                { merge: true }
                            );
                            onCollectionUpdated(batch, 'seaTimeRecords');
                        }
                        if (isAutoSaving) {
                            setupUnsavedData(data);
                            isAutoSavingRef.current = false;
                        } else {
                            setShowModal(false);
                        }
                    } else {
                        // Start new voyage
                        const voyageRef = doc(collection(firestore, 'voyages'));
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = voyageRef.id;
                        newData = {
                            vesselId: vesselId,
                            licenseeId: vessel.licenseeId,
                            addedBy: userId,
                            whenAdded: batchTrace.whenAction,
                            state: 'started',
                            // Start tab
                            tripType: data.tripType,
                            name: data.name?.trim() ? data.name.trim() : undefined,
                            masterIds: masterIds,
                            whenDeparted: data.whenDeparted ? toMillis(data.whenDeparted) : undefined,
                            departureFrom: data.departureFrom?.trim() ? data.departureFrom.trim() : undefined,
                            //skipper: data.skipper ? data.skipper : undefined,
                            operation: data.operation ? data.operation : undefined,
                            fuelStart: toFloat(data.fuelStart, undefined),
                            //stopsOrRouteDetails: data.stopsOrRouteDetails ? data.stopsOrRouteDetails : undefined,
                            stops: cleanedStops,
                            //tripReported: data.tripReported ? true : false,
                            //tripReportedTo: (data.tripReported && data.tripReportedTo) ? data.tripReportedTo : undefined,
                            //whenTripReported: (data.tripReported && data.whenTripReported) ? toMillis(data.whenTripReported) : undefined,
                            pax: toFloat(data.pax, undefined),
                            //showSafetyBriefing: data.showSafetyBriefing ? true : false,
                            //additionalPassengersOrDetails: data.additionalPassengersOrDetails ? data.additionalPassengersOrDetails : undefined,
                            // weatherForecast: data.weatherForecast ? data.weatherForecast : undefined,
                            // weatherObservation: {
                            //     windDirection: data.windDirection? data.windDirection : undefined,
                            //     windStrength: data.windStrength ? data.windStrength : undefined,
                            //     seaState: data.seaState ? data.seaState : undefined,
                            //     visibility: data.visibility ? data.visibility : undefined,
                            // },
                            forecast: prepareForecastData(forecastData, location),
                            startupChecks: startupChecks,
                            startupNotes: data.startupNotes ? data.startupNotes : undefined,
                            // tidalData: (showTides && tides) ? tides : undefined,
                            engineHours: compileEngineHours(),
                            // End tab
                            destinationTo: data.destinationTo?.trim() ? data.destinationTo.trim() : undefined,
                            fuelBunkered: toFloat(data.fuelBunkered, undefined),
                            fuelExported: toFloat(data.fuelExported, undefined),
                            touched: serverTimestamp(),
                        };

                        if (values.tripType !== 'multiTrip') {
                            newData.personnelInvolved = personnelInvolved ? personnelInvolved : undefined;
                            newData.destinationTo = data.destinationTo?.trim() ? data.destinationTo.trim() : undefined;
                            newData.safetyBriefCompleted = data.safetyBriefCompleted ? true : false;
                            newData.stops = cleanedStops;
                        }

                        batch.set(voyageRef, newData);

                        logAction(batch, 'Add', 'voyages', voyageRef.id, renderMasterIds(masterIds), [vesselId], personnelInvolved);
                    }

                    if (data.departureFrom?.trim()) {
                        possibleDestinations.push(data.departureFrom.trim());
                    }
                    if (data.destinationTo?.trim()) {
                        possibleDestinations.push(data.destinationTo.trim());
                    }

                    // Save any new destinations to vessel.logbookSettings.destinations
                    const newDestinations: string[] = [];
                    possibleDestinations.forEach((destination: string) => {
                        if (!vessel?.logbookSettings?.destinations || vessel.logbookSettings.destinations.indexOf(destination) === -1) {
                            newDestinations.push(destination);
                            batch.set(
                                doc(firestore, 'vessels', vesselId),
                                {
                                    logbookSettings: {
                                        destinations: arrayUnion(destination),
                                    },
                                },
                                { merge: true }
                            );
                        }
                    });

                    // Save any new stops to vessel.logbookSettings.stops
                    const newStops: string[] = [];
                    possibleStops.forEach((stop: string) => {
                        if (!vessel?.logbookSettings?.stops || vessel.logbookSettings.stops.indexOf(stop) === -1) {
                            newStops.push(stop);
                            batch.set(
                                doc(firestore, 'vessels', vesselId),
                                {
                                    logbookSettings: {
                                        stops: arrayUnion(stop),
                                    },
                                },
                                { merge: true }
                            );
                        }
                    });

                    onCollectionUpdated(batch, 'voyages');

                    batchTrace.data = {
                        data,
                        engineHours: compileEngineHours(),
                        crewHours: data.tripType !== 'multiDay' ? (compileCrewHours(crewHours as PreparedSeaCrewHourData, totalHours) as PreparedSeaCrewHour[]) : undefined,
                        signature: signatureToValue(signature),
                        personnelInvolved,
                        startupChecks: startupChecks,
                        customTextFields: customTextFields,
                        customHourFields: customHourFields,
                        totalHours: totalHours,
                        newData: getObjectWithoutFirestoreFieldValues(newData),
                        newDestinations: newDestinations,
                        newStops: newStops,
                        enginesEdited: enginesEdited,
                        // tides
                    };

                    batchTrace.save(`${activeVoyage ? 'Update' : 'Start new'} voyage with master ${renderMasterIds(masterIds)}`);

                    debugCount('commitVoyage');

                    batch
                        .commit()
                        .then(() => {
                            debugCount('commitVoyageSuccess');
                            batchTrace.reportSuccess();
                            isAutoSavingRef.current = false;
                        })
                        .catch((error) => {
                            batchTrace.reportError(error.message, error);
                            isAutoSavingRef.current = false;
                        });
                })
                .catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload file(s) for voyage`, error.message, error, {
                            signature: signatureToValue(signature),
                            data,
                            crewHours,
                            totalHours,
                            days,
                            trips,
                        });
                    }
                    isAutoSavingRef.current = false;
                });
        },
    });

    // Make sure selected master is not in personnelInvolved
    useEffect(() => {
        if (values.masterId && personnelInvolved && personnelInvolved.length > 0) {
            for (let i = 0; i < personnelInvolved.length; i++) {
                if (personnelInvolved[i] === values.masterId) {
                    const _personnelInvoled = [...personnelInvolved];
                    _personnelInvoled.splice(i, 1);
                    setPersonnelInvolved(_personnelInvoled);
                    break;
                }
            }
        }
    }, [values.masterId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const crew: string[] = [];
        if (values.tripType === 'multiTrip') {
            // Gather personnel (master + crewInvolved) from all trips
            trips?.forEach((trip) => {
                if (trip.masterId && crew.indexOf(trip.masterId) === -1) {
                    crew.push(trip.masterId);
                }
                trip.crewInvolved?.forEach((crewId: string) => {
                    if (crew.indexOf(crewId) === -1) {
                        crew.push(crewId);
                    }
                });
            });
        } else {
            // Combine master and personnelInvolved
            if (values.masterId) {
                crew.push(values.masterId);
            }
            personnelInvolved?.forEach((userId: string) => {
                if (crew.indexOf(userId) === -1) {
                    crew.push(userId);
                }
            });
        }
        setPersonnelInvolvedIncludingMasters(crew);
    }, [personnelInvolved, values.tripType, values.masterId, trips]);

    useEffect(() => {
        let hasErrors = false;
        if (
            (touched.name && errors.name) ||
            (touched.masterId && errors.masterId) ||
            (touched.whenDeparted && errors.whenDeparted) ||
            (touched.departureFrom && errors.departureFrom) ||
            (touched.destinationTo && errors.destinationTo) ||
            // (touched.skipper && errors.skipper) ||
            (touched.operation && errors.operation) ||
            (touched.fuelStart && errors.fuelStart) ||
            (touched.safetyBriefCompleted && errors.safetyBriefCompleted) ||
            // (touched.stopsOrRouteDetails && errors.stopsOrRouteDetails) ||
            // (touched.tripReported && errors.tripReported) ||
            // (touched.tripReportedTo && errors.tripReportedTo) ||
            // (touched.additionalPassengersOrDetails && errors.additionalPassengersOrDetails) ||
            // (touched.weatherForecast && errors.weatherForecast) ||
            // (touched.windDirection && errors.windDirection) ||
            // (touched.windStrength && errors.windStrength) ||
            // (touched.seaState && errors.seaState) ||
            // (touched.visibility && errors.visibility) ||
            (touched.startupNotes && errors.startupNotes)
        ) {
            hasErrors = true;
        }
        hasErrorsRef.current.start = hasErrors;
        setStartTabHasErrors(hasErrors);
    }, [touched, errors]);


    useEffect(() => {
        let hasErrors = false;
        if (activeVoyage) {
            // Check customHourFields to make sure they are numbers (or blank)
            if (customHourFields) {
                for (let i = 0; i < customHourFields.length; i++) {
                    if (Array.isArray(customHourFields[i].value) && isNaN(+customHourFields[i].value)) {
                        hasErrors = true; // That field was not a number (SeaCustomField should already be showing the error)
                    }
                }
            }

            // check custom text fields if voyage is complete
            if (!hasErrors && completeVoyageRef.current && requiredCustomTextFieldsIndexes.length > 0 && (values?.tripType === 'multiTrip' || values?.tripType === 'multiDay')) {
                const items = values?.tripType === 'multiTrip' ? trips : days;
                const customTextFields = items.map((item) => item.customText);
                for (const fields of customTextFields) {
                    hasErrors = checkForRequiredFields(fields as CustomFields[], requiredCustomTextFieldsIndexes);
                    if (hasErrors) break;
                }
                
                // if (items) {    
                //     for (let i = 0; i < items.length; i++) {
                //         const item = items[i];
                //         for (let j = 0; j < vessel?.logbookSettings?.customTextFieldsData.length; j++) {
                //             const data = vessel?.logbookSettings?.customTextFieldsData[j];
                //             if (data?.isRequired && !item.customText?.[j]?.value) {
                //                 hasErrors = true;
                //                 break; 
                //             }
                //         }
                //         if (hasErrors) break;
                //     }
                // }
            }
            if (!hasErrors && customTextFieldsErrors) {
                Object.values(customTextFieldsErrors).forEach((errorFields) => {
                    for (const error of Object.values(errorFields)) {
                        if ((error.touched || completeVoyageRef.current) && error?.error !== null && error?.error !== '') {
                            hasErrors = true;
                        }
                    }
                });
            }
        }
        if (!hasErrors && values?.tripType === 'multiTrip' && trips && tripsTouched.any && tripsErrors.any) {
            if (tripsTouched.all) {
                hasErrors = true;
            }
            // Need to see if any touched field as an error
            for (let tripIndex = 0; tripIndex < trips.length; tripIndex++) {
                if (tripsTouched[tripIndex] && (tripsTouched[tripIndex] as TripsTouched).any && tripsErrors[tripIndex] && tripsErrors[tripIndex].any) {
                    const keys = Object.keys(tripsErrors[tripIndex]) as (keyof Pick<TripsErrors, 'any' | 'stops' | 'anyStops'>)[];
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        if (key === 'any') continue;
                        if (key === 'stops') {
                            // Need to see if any touched stop field as an error
                            if ((tripsErrors[tripIndex].stops as TripError)?.any && (tripsTouched[tripIndex] as TripFieldTouched).stops) {
                                for (let stopIndex = 0; stopIndex < trips[tripIndex].stops!.length; stopIndex++) {
                                    if (((tripsErrors[tripIndex] as TripError).stops as StopsErrors)[stopIndex]) {
                                        const stopKeys = Object.keys((tripsErrors[tripIndex].stops as StopsErrors)[stopIndex]) as (keyof TripStopError)[];
                                        for (let j = 0; j < stopKeys.length; j++) {
                                            const stopKey = stopKeys[j];
                                            if (stopKey === 'any') continue;
                                            if (
                                                ((tripsTouched[tripIndex] as TripFieldTouched).stops as StopsTouched)[stopIndex] &&
                                                (((tripsTouched[tripIndex] as TripFieldTouched).stops as StopsTouched)[stopIndex] as TripStopTouched)[stopKey]
                                            ) {
                                                hasErrors = true;
                                                break; 
                                            }
                                        }
                                    }
                                    if (hasErrors) break;
                                }
                            }
                        } else if ((tripsTouched[tripIndex] as TripFieldTouched)[key]) {
                            hasErrors = true;
                            break;
                        }
                        if (hasErrors) break;
                    }
                }
                if (hasErrors) break;
            }
        }

        // Check multiday crew hours are ok
        if (!hasErrors && values?.tripType === 'multiDay' && completeVoyageRef.current && vessel?.logbookSettings?.crewHours === 'mandatory' && days?.length > 0) {
            for (let i = 0; i < personnelInvolvedIncludingMasters.length; i++) {
                for (let j = 0; j < days.length; j++) {
                    const day = days[j];
                    for (let k = 0; k < personnelInvolvedIncludingMasters.length; k++) {
                        if (toFloat((day as VoyageDay)?.crewHours?.[personnelInvolvedIncludingMasters[k]].hours, 0) <= 0) {
                            hasErrors = true;
                        }
                    }
                }
            }
        }
        hasErrorsRef.current.voyage = hasErrors;
        setVoyageTabHasErrors(hasErrors);
    }, [activeVoyage, tripsTouched, tripsErrors, vessel?.logbookSettings?.crewHours, personnelInvolvedIncludingMasters, values?.tripType, trips, days, customHourFields, customTextFieldsErrors, requiredCustomTextFieldsIndexes]);

    useEffect(() => {
        let hasErrors = false;
        if (
            (touched.whenArrived && errors.whenArrived) ||
            (touched.fuelBunkered !== undefined && errors.fuelBunkered) ||
            (touched.fuelBunkeredCost !== undefined && errors.fuelBunkeredCost) ||
            (touched.fuelEnd !== undefined && errors.fuelEnd) ||
            (touched.shutdownNotes !== undefined && errors.shutdownNotes)
        ) {
            hasErrors = true;
        }
        if (!hasErrors && editEngines && editEngines.length > 0) {
            for (let i = 0; i < editEngines.length; i++) {
                if (editEngines[i].error) {
                    hasErrors = true;
                }
            }
        }
        if (!hasErrors && completeVoyageRef.current && signature === undefined) {
            hasErrors = true;
        }

        if (!hasErrors && completeVoyageRef.current && vessel?.logbookSettings?.crewHours === 'mandatory' && values.tripType !== 'multiDay' && personnelInvolvedIncludingMasters?.length > 0) {
            for (let i = 0; i < personnelInvolvedIncludingMasters.length; i++) {
                if (toFloat(crewHours[personnelInvolvedIncludingMasters[i]].hours, 0) <= 0) {
                    hasErrors = true;
                }
            }
        }
        hasErrorsRef.current.end = hasErrors;
        setEndTabHasErrors(hasErrors);
    }, [vessel?.logbookSettings, editEngines, signature, crewHours, personnelInvolvedIncludingMasters, touched, errors, values.tripType]);

    const validateCustomField = useCallback(
        (value: string | number, index: number) => {
            const field = vessel?.logbookSettings?.customTextFieldsData?.[index];
            if (field?.isRequired && !value) {
                return 'This field is required';
            }
            return null;
        },
        [vessel?.logbookSettings?.customTextFieldsData]
    );

    const totalPaxOnboard = useMemo(() => {
        // Calculate by adding all paxOn - paxOff for the last trip only
        if (values?.tripType === 'multiTrip' && trips?.length > 0) {
            const lastTrip = trips[trips.length - 1];
            let pax = 0;
            pax += toInt(lastTrip.paxOn, 0);
            lastTrip.stops?.forEach((stop) => {
                pax -= toInt(stop.paxOff, 0);
                pax += toInt(stop.paxOn, 0);
            });
            return pax;
        }
        return 0;
    }, [values?.tripType, trips]);

    const isModalDirty = useCallback(() => {
        if (!showModal || !engines) return false;
        const currentState = {
            values,
            stops,
            personnelInvolved,
            startupChecks,
            customTextFields,
            customHourFields,
            totalHours,
            shutdownChecks,
            editEngines,
            crewHours,
            signature,
            days,
            trips,
        };

        const initialState = unsavedValues || {};
        const isDirty =
            haveValuesChanged(currentState.values, initialState.values) ||
            hasArrayChanged(currentState.startupChecks, makeInitialChecks(vessel?.logbookSettings?.startupChecks || [], initialState.startupChecks || [])) ||
            hasArrayChanged(currentState.shutdownChecks, makeInitialChecks(vessel?.logbookSettings?.shutdownChecks || [], initialState.shutdownChecks || [])) ||
            hasSignatureChanged(currentState.signature, initialState.signature) ||
            (values.tripType === 'singleDay' &&
                (hasArrayChanged(currentState.customTextFields, makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], initialState.customTextFields || [], '')) ||
                    hasArrayChanged(currentState.customHourFields, makeInitialCustomFields(vessel?.logbookSettings?.customHourFields || [], initialState.customHourFields || [], '0')))) ||
            (values.tripType !== 'multiTrip' && hasArrayChanged(currentState.personnelInvolved, initialState.personnelInvolved)) ||
            (values.tripType !== 'multiDay' && haveObjectsChanged(currentState.crewHours, initialState.crewHours)) ||
            (values.tripType === 'multiDay' && haveObjectsChanged(currentState.days, initialState.days)) ||
            (values.tripType === 'multiTrip' && haveObjectsChanged(currentState.trips, initialState.trips)) ||
            hasArrayChanged(currentState.editEngines, initialState.editEngines) ||
            hasArrayChanged(currentState.stops, initialState.stops);
        return isDirty;
    }, [
        showModal,
        values,
        stops,
        personnelInvolved,
        startupChecks,
        customTextFields,
        customHourFields,
        totalHours,
        engines,
        shutdownChecks,
        editEngines,
        crewHours,
        signature,
        days,
        trips,
        unsavedValues,
        vessel?.logbookSettings?.startupChecks,
        vessel?.logbookSettings?.shutdownChecks,
        vessel?.logbookSettings?.customTextFields,
        vessel?.logbookSettings?.customHourFields,
    ]);

    const attemptFormSubmission = useCallback(() => {
        if (completeVoyageRef.current) {
            // Complete voyage
            if (values?.tripType === 'multiTrip') {
                setTripsTouched({
                    ...tripsTouched,
                    all: true,
                    any: true,
                });
            }
            forceCustomDataValidationRef.current = true;
            setTimeout(() => {
                formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }, 1);
        } else {
            // Save Progress
            setTimeout(() => {
                formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }, 1);
        }
    }, [tripsTouched, values?.tripType]);

    // Auto-save functionality
    useEffect(() => {
        if (completeVoyageRef.current || !autoSaveSetting || isAutoSavingRef.current === true || !activeVoyage || !showModal) {
            return;
        }
        if (autoSaveSetting && isModalDirty()) {
            const myId = ++jobIdRef.current;
            debugCount('triggerVoyageAutoSave');
            const timeoutId = setTimeout(() => {
                if (jobIdRef.current === myId) {
                    // Compare with the ref value
                    // Trigger form submission or save progress
                    completeVoyageRef.current = false;
                    isAutoSavingRef.current = true;
                    setTimeout(() => {
                        if (jobIdRef.current === myId) {
                            debugCount('voyageAutoSave');
                            formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                        }
                    }, 1); // Ensures isAutoSaving is set to false
                }
            }, 5 * 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [activeVoyage, attemptFormSubmission, autoSaveSetting, isModalDirty, showModal]);

    const vesselUserOptions = useMemo(() => {
        return (
            users?.byVesselId[vesselId] &&
            users.byVesselId[vesselId].map((u) => {
                return {
                    id: u.id as string,
                    name: renderFullName(u),
                };
            })
        );
    }, [users, vesselId]);

    const crewOnboardOptions = useMemo(() => {
        if (vesselUserOptions && vesselUserOptions.length > 0 && values?.masterId) {
            const options = [...vesselUserOptions];
            for (let i = options.length - 1; i >= 0; i--) {
                if (options[i].id === values.masterId) {
                    options.splice(i, 1);
                    break;
                }
            }
            return options;
        }
        return [];
    }, [vesselUserOptions, values.masterId]);

    const tabsPanel = useMemo(() => {
        return (
            <>
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" fullWidth>
                    <SeaTab tab="start" hasErrors={startTabHasErrors} mode="forms">
                        Start up
                    </SeaTab>
                    <SeaTab tab="voyage" disabled={!activeVoyage} disabledMessage={`To access the other tabs, please ${isPlatform('mobile') ? 'tap' : 'click'} "Start New Voyage" first.`} hasErrors={voyageTabHasErrors} mode="forms">
                        Voyage
                    </SeaTab>
                    <SeaTab tab="end" disabled={!activeVoyage} disabledMessage={`To access the other tabs, please ${isPlatform('mobile') ? 'tap' : 'click'} "Start New Voyage" first.`} hasErrors={endTabHasErrors} mode="forms">
                        Shut down
                    </SeaTab>
                </SeaTabsGroup>
                <SeaTabContent tab="voyage" selectedTab={tab}>
                    <div className="voyage-detail-tabs">
                        <SeaTabsGroup selectedTab={voyageTab} setTab={setVoyageTab} mode="forms" mini={true}>
                            <SeaTab tab="details" hasErrors={voyageTabHasErrors} mode="forms">
                                Voyage Details
                            </SeaTab>
                            <SeaTab tab="formsDocuments" mode="forms">
                                Forms / Documents
                            </SeaTab>
                            <SeaTab tab="safetyChecks" mode="forms" notificationNumber={safetyChecksNumber} requireRole="safetyEquipmentChecks">
                                Safety Checks
                            </SeaTab>
                            {/* <SeaTab
                                    tab="drills"
                                    mode="forms"
                                    //notificationNumber={24}
                                >
                                    Drills
                                </SeaTab> */}
                            <SeaTab tab="maintenance" mode="forms" notificationNumber={maintenanceNumber} requireRole="maintenanceSchedule">
                                Maintenance
                            </SeaTab>
                            {/* <SeaTab
                                    tab="crewTraining"
                                    mode="forms"
                                >
                                    Crew Training
                                </SeaTab> */}
                        </SeaTabsGroup>
                    </div>
                </SeaTabContent>
            </>
        );
    }, [activeVoyage, endTabHasErrors, maintenanceNumber, safetyChecksNumber, startTabHasErrors, tab, voyageTab, voyageTabHasErrors]);

    return (
        <SeaModal
            title={'New Voyage'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onFullyOpened={() => {
                setIsActive(true);
            }}
            onClosed={onClosed}
            size="wide"
            tabsPanel={tabsPanel}
            actionPanel={
                <div className="action-panel-group">
                    <div className="primary-actions">
                        {!activeVoyage && (
                            <SeaButton
                                zone="white"
                                onClick={(e) => {
                                    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                                }}
                            >
                                Start New Voyage
                            </SeaButton>
                        )}
                        {activeVoyage && (
                            <div style={{ display: 'inline-block', marginRight: '6px' }}>
                                <SeaButton
                                    zone="white"
                                    disabled={isAutoSavingRef.current || (!isModalDirty() && autoSaveSetting)}
                                    onClick={(e) => {
                                        completeVoyageRef.current = false;
                                        attemptFormSubmission();
                                    }}
                                    size={activeVoyage && tab === 'end' ? 'small' : 'standard'}
                                >
                                    {isAutoSavingRef.current ? (
                                        <IonSpinner name="crescent" className="sea-spinner" style={{ width: '24px', position: 'relative', top: '16px', left: '16px' }} />
                                    ) : !isModalDirty() && autoSaveSetting ? (
                                        <>
                                            <span className="text long">Progress Saved</span>
                                            <span className="text short">Saved</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="text long">Save Progress</span>
                                            <span className="text short">Save</span>
                                        </>
                                    )}
                                </SeaButton>
                            </div>
                        )}
                        {activeVoyage && tab === 'end' && (
                            <SeaButton
                                zone="white"
                                disabled={isAutoSavingRef.current}
                                onClick={(e) => {
                                    completeVoyageRef.current = true;
                                    attemptFormSubmission();
                                }}
                            >
                                <span className="text long">Complete Voyage</span>
                                <span className="text short">Complete</span>
                            </SeaButton>
                        )}
                        {activeVoyage && canDelete('logbook') && (
                            <div className="delete-voyage-box">
                                <SeaLinkButton
                                    mode="standard-link"
                                    onClick={(e) => {
                                        confirmAction('Are you sure you want to delete this voyage?', 'Yes, delete voyage')
                                            .then(() => {
                                                deleteVoyage();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    <span className="text long">Delete Voyage</span>
                                    <span className="text short">
                                        <SeaIcon icon="trash" />
                                    </span>
                                </SeaLinkButton>
                            </div>
                        )}
                        {activeVoyage && (
                            <div className="autosave-voyage-box small">
                                <SeaCheckbox
                                    help={{ text: 'If this is checked, Sea Flux we will automatically save your changes' }}
                                    checked={autoSaveSetting}
                                    setChecked={(checked) => {
                                        if (showModal) {
                                            window.localStorage.setItem(`_${sharedState.userId.current}_autosave`, '' + checked);
                                            setAutoSaveSetting(checked);
                                        }
                                    }}
                                >
                                    <span>Auto Save</span>
                                </SeaCheckbox>
                            </div>
                        )}
                    </div>

                    {activeVoyage && (
                        <div className="autosave-voyage-box large">
                            <SeaCheckbox
                                help={{ text: 'If this is checked, Sea Flux we will automatically save your changes' }}
                                checked={autoSaveSetting}
                                setChecked={(checked) => {
                                    if (showModal) {
                                        window.localStorage.setItem(`_${sharedState.userId.current}_autosave`, '' + checked);
                                        setAutoSaveSetting(checked);
                                    }
                                }}
                            >
                                <span>Auto Save</span>
                            </SeaCheckbox>
                        </div>
                    )}
                </div>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="start" selectedTab={tab}>
                    <IonGrid className="form-grid" style={{ marginTop: '8px' }}>
                        {previousVoyage?.shutdownNotes && (
                            <IonRow>
                                <IonCol size="12">
                                    <SeaLabel>Shutdown notes from previous voyage</SeaLabel>
                                    <div
                                        className="sea-card sea-row-history"
                                        style={{
                                            padding: '10px 12px 10px 12px',
                                            marginTop: '0px',
                                        }}
                                    >
                                        {formatTextAreaText(previousVoyage.shutdownNotes)}
                                    </div>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol size="4">
                                <SeaSelect label="Trip Type" name="tripType" value={values.tripType} onchange={handleChange} onblur={handleBlur} error={touched.tripType ? errors.tripType : ''} disabled={activeVoyage ? true : false}>
                                    <IonSelectOption value="singleDay">Single day voyage</IonSelectOption>
                                    <IonSelectOption value="multiDay">Multi day voyage</IonSelectOption>
                                    <IonSelectOption value="multiTrip">Multi trip voyage</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                            {values.tripType === 'multiTrip' && (
                                <IonCol size="4">
                                    <SeaDate
                                        label="Date"
                                        name="whenDeparted"
                                        value={values.whenDeparted ? values.whenDeparted.substring(0, 10) : ''}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenDeparted ? errors.whenDeparted : ''}
                                    />
                                </IonCol>
                            )}
                            <IonCol size="4">
                                <SeaSelect label="Operation" name="operation" value={values.operation} onchange={handleChange} onblur={handleBlur} error={touched.operation ? errors.operation : ''}>
                                    <IonSelectOption value="">Not Set</IonSelectOption>
                                    <IonSelectOption value="commercial">Commercial</IonSelectOption>
                                    <IonSelectOption value="passenger">Passenger</IonSelectOption>
                                    <IonSelectOption value="private">Private</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                            {values.tripType !== 'multiTrip' && (
                                <IonCol size="4">
                                    <SeaInput label="Voyage Name" name="name" value={values.name} onchange={handleChange} onblur={handleBlur} zone="white" type="text" inputmode="text" error={touched.name ? errors.name : ''} />
                                </IonCol>
                            )}
                        </IonRow>
                        <IonRow>
                            {values.tripType !== 'multiTrip' && (
                                <>
                                    <IonCol size="4">
                                        <SeaSelect label="Master" name="masterId" value={values.masterId} onchange={handleChange} onblur={handleBlur} error={touched?.masterId ? (errors.masterId as string) || '' : ''}>
                                            <IonSelectOption value="">Not Set</IonSelectOption>
                                            {vesselUserOptions?.map((option: { id: string | undefined; name: string }) => {
                                                return (
                                                    <IonSelectOption key={option.id} value={option.id}>
                                                        {option.name}
                                                    </IonSelectOption>
                                                );
                                            })}
                                        </SeaSelect>
                                    </IonCol>
                                    <IonCol size="8">
                                        <SeaMultiSelect label="Crew Onboard" values={personnelInvolved} setValues={setPersonnelInvolved} options={crewOnboardOptions} useAllOption={false} emptyText="Not Set" mode="popover" />
                                    </IonCol>
                                    <IonCol size="4">
                                        <SeaSelectOrOther
                                            label="Departure Location"
                                            name="departureFrom"
                                            options={vessel?.logbookSettings?.destinations || []}
                                            value={values.departureFrom}
                                            otherPlaceholder="Add New Location"
                                            onchange={handleChange}
                                            onblur={handleBlur}
                                            error={touched.departureFrom ? errors.departureFrom : ''}
                                        />
                                    </IonCol>
                                    <IonCol size="4">
                                        <SeaTagsInput
                                            label="Stops / anchorages"
                                            tags={stops as unknown as string[]}
                                            setTags={setStops as unknown as (tags: string[]) => void}
                                            options={vessel?.logbookSettings?.stops}
                                            sortOptions={true}
                                            addTagText="Add stop / anchorage"
                                            editTagText="Edit stop / anchorage"
                                            newTagPlaceholder="New stop / anchorage"
                                        />
                                    </IonCol>
                                    <IonCol size="4">
                                        <SeaDatetime
                                            label="Departure time"
                                            name="whenDeparted"
                                            value={values.whenDeparted}
                                            disabled={activeVoyage?.tripType === 'multiDay'}
                                            onchange={handleChange}
                                            onblur={handleBlur}
                                            zone="white"
                                            error={touched.whenDeparted ? errors.whenDeparted : ''}
                                            id="whenDeparted"
                                            setToCurrentTime={() => {
                                                setFieldValue('whenDeparted', formatSeaDatetime());
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="4">
                                        <SeaInput label="Passengers" name="pax" value={values.pax} onchange={handleChange} onblur={handleBlur} zone="white" type="number" inputmode="numeric" error={touched.pax ? errors.pax : ''} />
                                    </IonCol>
                                </>
                            )}
                            {vessel?.logbookSettings?.fuel !== 'hidden' && (
                                <IonCol size="4">
                                    <SeaInput
                                        label="Fuel - Start (L)"
                                        name="fuelStart"
                                        value={values.fuelStart}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                        error={touched.fuelStart ? errors.fuelStart : ''}
                                    />
                                </IonCol>
                            )}
                            {values.tripType !== 'multiTrip' && (
                                <IonCol size="4">
                                    <SeaLabel
                                        help={
                                            vessel?.logbookSettings?.safetyBriefing
                                                ? {
                                                      text: vessel.logbookSettings.safetyBriefing,
                                                  }
                                                : undefined
                                        }
                                    >
                                        Safety Briefing
                                    </SeaLabel>
                                    <SeaCheckbox name="safetyBriefCompleted" checked={values.safetyBriefCompleted} setFieldValue={setFieldValue} error={touched.safetyBriefCompleted ? errors.safetyBriefCompleted : ''}>
                                        Completed
                                    </SeaCheckbox>
                                </IonCol>
                            )}
                            <IonCol size="12">
                                {activeVoyage ? (
                                    <>
                                        <SeaLabel>Weather Forecast {location && <>({formatCoords(location.latitude, location.longitude)})</>}</SeaLabel>
                                        <div style={{ height: '8px' }}></div>
                                        {activeVoyage.forecast ? (
                                            <SeaModalScrollableArea>
                                                <SeaWindyForecastTable mode="view-only" forecastData={activeVoyage.forecast} marginLeft="16px" />
                                            </SeaModalScrollableArea>
                                        ) : (
                                            <div style={{ paddingBottom: '8px' }}>None (Failed to get a forecast at the time this voyage was started)</div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <SeaLabel>Weather</SeaLabel>
                                        <SeaWindyMap isActive={isActive} location={location} setLocation={setLocation} forecastData={forecastData} setForecastData={setForecastData} />
                                    </>
                                )}
                            </IonCol>
                            <IonCol size="12">
                                <SeaChecks label="Startup Checks" note="Please Note: You can edit these in your logbook settings." data={startupChecks} setData={setStartupChecks} />
                            </IonCol>
                            <IonCol size="12">
                                <SeaTextarea
                                    label="Startup Notes"
                                    name="startupNotes"
                                    value={values.startupNotes}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    height={83}
                                    inputmode="text"
                                    error={touched.startupNotes ? errors.startupNotes : ''}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SeaTabContent>
                <SeaTabContent tab="voyage" selectedTab={tab}>
                    <SeaTabContent tab="details" selectedTab={voyageTab}>
                        <IonGrid className="form-grid">
                            <IonRow>
                                {values.tripType !== 'multiDay' && (
                                    <IonCol size="12">
                                        <div className="columns">
                                            <div style={{ flex: '1 1 0' }}>
                                                <SeaTextarea
                                                    label="Voyage Notes"
                                                    id="voyageNotes"
                                                    name="voyageNotes"
                                                    value={values.voyageNotes}
                                                    onchange={handleChange}
                                                    onblur={handleBlur}
                                                    height={300}
                                                    zone="white"
                                                    inputmode="text"
                                                    error={touched.voyageNotes ? errors.voyageNotes : ''}
                                                />
                                                {(vessel?.logbookSettings?.actions || [])?.length > 0 && (
                                                    <>
                                                        <div className="input-link no-select">
                                                            <div
                                                                onClick={(e) => {
                                                                    setShowCommonActions((previous: boolean) => {
                                                                        return !previous;
                                                                    });
                                                                }}
                                                            >
                                                                {showCommonActions ? 'Hide' : 'Show'} common actions&nbsp;
                                                                <SeaIcon icon={showCommonActions ? 'moveUp' : 'moveDown'} />
                                                            </div>
                                                        </div>
                                                        <div className={`common-actions ${showCommonActions ? 'show' : 'hide'}`}>
                                                            <div>
                                                                {vessel?.logbookSettings?.actions?.map((action: string) => {
                                                                    return (
                                                                        <div key={action} className="pushy no-select" onClick={(e) => addCommonAction(action)}>
                                                                            {action}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {values.tripType === 'multiTrip' && (
                                                <div className="total-pax-onboard" style={{ flex: '0 1 150px', maxWidth: '40%', paddingLeft: 'var(--grid-gutter)' }}>
                                                    <SeaLabel>Total PAX Onboard</SeaLabel>
                                                    <div className="sea-input">
                                                        <span>{totalPaxOnboard}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </IonCol>
                                )}
                                {values.tripType === 'singleDay' && customTextFields && customHourFields && (
                                    <>
                                        <SeaCustomFields
                                            data={customTextFields}
                                            setData={setCustomTextFields}
                                            errors={customTextFieldsErrors?.[0]}
                                            // eslint-disable-next-line no-useless-computed-key
                                            setErrors={(errors) => setCustomTextFieldsErrors({[0]: errors})}
                                            validateField={validateCustomField}
                                            forceValidation={forceCustomDataValidationRef.current}
                                            inputmode="text"
                                            type="text"
                                            maxLength={500}
                                            requiredIndexes={requiredCustomTextFieldsIndexes}
                                        />
                                        <IonCol size="12">
                                            <div className="form-line"></div>
                                        </IonCol>
                                        <SeaCustomFields data={customHourFields} setData={setCustomHourFields} inputmode="numeric" type="number" maxLength={10} isHours={true} totalHours={totalHours} setTotalHours={setTotalHours} />
                                    </>
                                )}
                            </IonRow>
                        </IonGrid>
                        {values.tripType === 'multiTrip' && (
                            <VoyageTrips
                                showModal={showModal}
                                trips={trips as VoyageTrip[]}
                                setTrips={setTrips}
                                tripsErrors={tripsErrors}
                                setTripsErrors={setTripsErrors}
                                tripsTouched={tripsTouched}
                                setTripsTouched={setTripsTouched}
                                vesselUserOptions={vesselUserOptions || []}
                                tripTab={tripTab}
                                setTripTab={setTripTab}
                                customTextFields={customTextFields}
                                setCustomTextFields={setCustomTextFields}
                                customTextFieldsErrors={customTextFieldsErrors}
                                setCustomTextFieldsErrors={setCustomTextFieldsErrors}
                                validateCustomField={validateCustomField}
                                forceCustomDataValidation={forceCustomDataValidationRef.current}
                                requiredIndexes={requiredCustomTextFieldsIndexes}
                            />
                        )}
                        {values.tripType === 'multiDay' && (
                            <VoyageDays
                                showModal={showModal}
                                days={days as VoyageDay[]}
                                setDays={(days) => {
                                    setDays(days);
                                }}
                                dayTab={dayTab}
                                setDayTab={setDayTab}
                                activeVoyage={activeVoyage as Voyage}
                                personnelInvolvedIncludingMasters={personnelInvolvedIncludingMasters}
                                makeCrewHours={makeCrewHours}
                                whenDeparted={activeVoyage?.whenDeparted!}
                                makeInitialCustomFields={makeInitialCustomFields}
                                isCompletingVoyage={isSubmitting && !isAutoSavingRef.current && completeVoyageRef.current}
                                customTextFields={customTextFields}
                                setCustomTextFields={setCustomTextFields}
                                customTextFieldsErrors={customTextFieldsErrors}
                                setCustomTextFieldsErrors={setCustomTextFieldsErrors}
                                validateCustomField={validateCustomField}
                                forceCustomDataValidation={forceCustomDataValidationRef.current}
                                requiredIndexes={requiredCustomTextFieldsIndexes}
                            />
                        )}
                    </SeaTabContent>
                    <SeaTabContent tab="formsDocuments" selectedTab={voyageTab}>
                        <VoyageFormsAndDocumentsTab showModal={showModal} selectedVoyage={activeVoyage as Voyage} />
                    </SeaTabContent>
                    {canView('safetyEquipmentChecks') && (
                        <SeaTabContent tab="safetyChecks" selectedTab={voyageTab}>
                            <VoyageSafetyChecksTab showModal={showModal} selectedVoyage={activeVoyage as Voyage} setSafetyChecksNumber={setSafetyChecksNumber} />
                        </SeaTabContent>
                    )}
                    {canView('maintenanceSchedule') && (
                        <SeaTabContent tab="maintenance" selectedTab={voyageTab}>
                            <VoyageMaintenanceTab showModal={showModal} selectedVoyage={activeVoyage as Voyage} setMaintenanceNumber={setMaintenanceNumber} />
                        </SeaTabContent>
                    )}
                </SeaTabContent>
                <SeaTabContent tab="end" selectedTab={tab}>
                    <IonGrid className="form-grid" style={{ marginTop: '8px' }}>
                        <IonRow>
                            {!(values?.tripType === 'multiTrip') && (
                                <>
                                    <IonCol size="4">
                                        <SeaSelectOrOther
                                            label="Arrival Destination"
                                            name="destinationTo"
                                            options={vessel?.logbookSettings?.destinations || []}
                                            value={values.destinationTo}
                                            otherPlaceholder="Add New Location"
                                            onchange={handleChange}
                                            onblur={handleBlur}
                                            error={touched.destinationTo ? errors.destinationTo : ''}
                                        />
                                    </IonCol>
                                    <IonCol size="4">
                                        <SeaDatetime
                                            label="Arrival time"
                                            name="whenArrived"
                                            value={values.whenArrived}
                                            onchange={handleChange}
                                            onblur={handleBlur}
                                            zone="white"
                                            error={touched.whenArrived ? errors.whenArrived : ''}
                                            setToCurrentTime={() => {
                                                setFieldValue('whenArrived', formatSeaDatetime());
                                            }}
                                        />
                                    </IonCol>
                                    <IonCol size="12">
                                        <div className="form-line"></div>
                                    </IonCol>
                                </>
                            )}
                        </IonRow>
                        {vessel?.logbookSettings?.fuel !== 'hidden' && (
                            <IonRow>
                                <IonCol size="12">
                                    <div className="sea-label blue">Fuel</div>
                                </IonCol>
                                <IonCol size="3">
                                    <SeaInput
                                        label="Start (L)"
                                        name="fuelStart"
                                        value={values.fuelStart}
                                        // onchange={handleChange}
                                        // onblur={handleBlur}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                        disabled={true}
                                        error={touched.fuelStart ? errors.fuelStart : ''}
                                    />
                                </IonCol>
                                <IonCol size="3">
                                    <SeaInput
                                        label="Bunkered (L)"
                                        name="fuelBunkered"
                                        help={{ text: 'This is the total fuel bunkered during the voyage (Onboarded)' }}
                                        value={values.fuelBunkered}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                        error={touched.fuelBunkered ? errors.fuelBunkered : ''}
                                    />
                                </IonCol>
                                <IonCol size="3">
                                    <SeaInput
                                        label="Exported (L)"
                                        name="fuelExported"
                                        help={{ text: "This is the total fuel removed during the voyage (offloaded to another vessel or shore facility).\n This doesn't include fuel used" }}
                                        value={values.fuelExported}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                        error={touched.fuelExported ? errors.fuelExported : ''}
                                    />
                                </IonCol>
                                <IonCol size="3">
                                    <SeaInput
                                        label="Fuel Cost ($)"
                                        name="fuelBunkeredCost"
                                        help={{ text: 'This is the total cost of the fuel bunkered' }}
                                        value={values.fuelBunkeredCost}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                        prefix="$"
                                        error={touched.fuelBunkeredCost ? errors.fuelBunkeredCost : ''}
                                    />
                                </IonCol>
                                <IonCol size="3">
                                    <SeaInput
                                        label="Shut Down (L)"
                                        name="fuelEnd"
                                        value={values.fuelEnd}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                        error={touched.fuelEnd ? errors.fuelEnd : ''}
                                    />
                                </IonCol>
                                <IonCol size="3">
                                    <SeaInput
                                        label="Fuel used"
                                        name="fuelUsed"
                                        value={values.fuelStart && values.fuelEnd ? '' + (toFloat(values.fuelStart, 0) + toFloat(values.fuelBunkered, 0) - toFloat(values.fuelExported, 0) - toFloat(values.fuelEnd, 0)) : ''}
                                        disabled={true}
                                        zone="white"
                                        type="number"
                                        inputmode="text"
                                    />
                                </IonCol>
                                <IonCol size="12">
                                    <div className="form-line"></div>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol size="12">
                                <SeaChecks label="Shutdown Checks" note="Please Note: You can edit these in your logbook settings." data={shutdownChecks} setData={setShutdownChecks} />
                            </IonCol>
                            <IonCol size="12">
                                <SeaTextarea
                                    label="Shutdown Notes"
                                    name="shutdownNotes"
                                    value={values.shutdownNotes}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    height={83}
                                    inputmode="text"
                                    error={touched.shutdownNotes ? errors.shutdownNotes : ''}
                                />
                                <div
                                    className="sea-label note"
                                    style={{
                                        textAlign: 'right',
                                        marginBottom: '-8px',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    These notes will appear on the next voyage's "Start up" page
                                </div>
                            </IonCol>
                            <IonCol size="12">
                                <div className="form-line"></div>
                            </IonCol>
                            {editEngines && (
                                <>
                                    <IonCol size="12">
                                        <div className="sea-label blue">Engine Hours</div>
                                    </IonCol>
                                    <SeaEngines name="voyageEngines" editEngines={editEngines} setEditEngines={setEditEngines} colSize="3" />
                                </>
                            )}
                            {crewHours && values?.tripType !== 'multiDay' && !(vessel?.logbookSettings?.crewHours === 'hidden') && (
                                <>
                                    {personnelInvolvedIncludingMasters && personnelInvolvedIncludingMasters.length > 0 && (
                                        <IonCol size="12">
                                            <div className="sea-label blue">Crew Hours</div>
                                        </IonCol>
                                    )}
                                    <SeaCrewHours
                                        name="crewHours"
                                        crewHours={crewHours as SeaCrewHourData}
                                        setCrewHours={setCrewHours}
                                        personnelInvolved={personnelInvolvedIncludingMasters}
                                        totalHours={totalHours}
                                        colSize="3"
                                        isRequired={isSubmitting && !isAutoSavingRef.current && completeVoyageRef.current && vessel?.logbookSettings?.crewHours === 'mandatory'}
                                    />
                                </>
                            )}
                            <IonCol size="12">
                                <SeaSignature
                                    collection="voyages"
                                    file={signature}
                                    setFile={setSignature}
                                    label="Sign or initial below"
                                    isRequired={!isAutoSavingRef.current && completeVoyageRef.current}
                                    isRequiredError="Your signature is required to complete a voyage"
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div style={{ height: '20px' }}></div>
                </SeaTabContent>
            </form>
        </SeaModal>
    );
};

export default EditVoyage;
