import { $applyNodeReplacement, DOMConversionMap, DOMExportOutput, ElementNode, LexicalNode, SerializedElementNode, Spread } from "lexical";
import { globalActiveEditor } from "../../../../modals/Utilities/EditRichTextModal/EditRichTextModal";
import { DELETE_PAGE_COMMAND, INSERT_PAGE_BREAK_COMMAND, MERGE_PAGES_COMMAND } from "../../plugins/LexPage/LexPage";
import { sharedState } from "../../../../shared-state/shared-state";
import LexButton from "../../ui/LexButton/LexButton";
import LexIcon from "../../ui/LexIcon/LexIcon";


export type SerializedPageNode = Spread<
    {
        type: 'page';
        version: 1;
    },
    SerializedElementNode
>;

export class PageNode extends ElementNode {
    // Notes by Ben:
    // The way I implemented the add/delete/merge page controls might not be ideal...
    // It would've been nice to handle the UX using a DecoratorNode,
    // but a DecoratorNode can't have children.
    // This is why I went with an ElementNode and had to cobble to together the menu
    // interactions via sharedState.richTextPopoverMenu.

    static getType(): string {
        return 'page';
    }

    static clone(node: PageNode): PageNode {
        return new PageNode(node.__key);
    }

    static importJSON(serializedNode: SerializedPageNode): PageNode {
        return $createPageNode();
    }

    static importDOM(): DOMConversionMap | null {
        return {
            // hr: () => ({
            //     conversion: convertHorizontalRuleElement,
            //     priority: 0
            // })
            // I don't think there are any DOM elements to convert from
        };
    }

    exportJSON(): SerializedPageNode {
        return {
            ...super.exportJSON(),
            type: 'page',
            version: 1,
            // format: 'left',
            // direction: 'rtl',
            // indent: 0,
            // children: []
        };
    }

    exportDOM(): DOMExportOutput {
        // return {
        //     element: document.createElement('hr')
        // };
        const element = document.createElement('div');
        // element.setAttribute('width', '100px');
        // element.setAttribute('height', '10px');
        // element.style.padding = '4px';
        // element.style.backgroundColor = 'lime';
        // element.innerText = 'PageNode.exportDOM()';
        return {element};
    }

    createDOM(): HTMLElement {
        const page = document.createElement('div');
        page.style.position = 'relative';
        page.id = `page${this.getKey()}`;
        page.classList.add('lex-page');

        const plus = document.createElement('div');
        plus.contentEditable = 'false';
        plus.classList.add('lex-page-control');
        plus.classList.add('plus');
        plus.innerHTML = '+';
        plus.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (globalActiveEditor?.isEditable()) {
                globalActiveEditor.dispatchCommand(INSERT_PAGE_BREAK_COMMAND, this.getKey());
            }
        });
        page.appendChild(plus);

        const options = document.createElement('div');
        options.contentEditable = 'false';
        options.classList.add('lex-page-control');
        options.classList.add('options');
        options.innerHTML = '&mldr;';
        options.addEventListener('click', (event) => {
            globalActiveEditor.update(() => {
                sharedState.richTextPopoverMenu.set({
                    isOpen: true,
                    x: event.clientX - 4,
                    y: event.clientY - 4,
                    content: <>
                        <LexButton
                            onClick={() => {
                                globalActiveEditor?.dispatchCommand(DELETE_PAGE_COMMAND, this.getKey());
                                sharedState.richTextPopoverMenu.clear();
                            }}
                        >
                            <LexIcon icon="trash" mode="start"/>
                            Delete Page
                        </LexButton>
                        {this.getPreviousSibling() !== null && // Merge is not available if first page
                            <LexButton
                                onClick={() => {
                                    globalActiveEditor?.dispatchCommand(MERGE_PAGES_COMMAND, this.getKey());
                                    sharedState.richTextPopoverMenu.clear();
                                }}
                            >
                                <LexIcon icon="merge-pages" mode="start"/>
                                Merge with page above
                            </LexButton>
                        }
                    </>
                });
            });

        });
        page.appendChild(options);

        return page;
    }

    getTextContent() {
        return '\n';
    }

    isInline(): false {
        return false;
    }

    isShadowRoot() {
        return true;
    }

    collapseAtStart() {
        return true;
    }

    canBeEmpty() {
        return false;
    }

    canIndent() {
        return false;
    }

    updateDOM(): false {
        return false;
    }

    // decorate(): JSX.Element {
    //     // This irrelevant because this isn't a DecoratorNode
    //     return (
    //         <Suspense fallback={null}>
    //             <PageComponent
    //                 nodeKey={this.getKey()}
    //             />
    //         </Suspense>
    //     );
    // }
}


export function $createPageNode(): PageNode {
    return $applyNodeReplacement(new PageNode());
}


export function $isPageNode(
    node: LexicalNode | null | undefined,
): node is PageNode {
    return node instanceof PageNode;
}
