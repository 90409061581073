import React, { useState } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { formatValue } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { SplittableBatch } from '../../../../lib/firebase';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { SurveyReport } from '../../../../shared-state/VesselDocuments/vesselSurveyReports';
import { isPdf } from '../../../../lib/files';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditSurveyReport from '../EditSurveyReport/EditSurveyReport'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaPDFViewer from '../../../../components/SeaPDFViewer/SeaPDFViewer';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';

interface ViewSurveyReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: SurveyReport,
}

const ViewSurveyReport: React.FC<ViewSurveyReportProps> = ({showModal, setShowModal, selectedItem}) => {
    const vesselId = sharedState.vesselId.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    
    return (
        <SeaModal
            title={selectedItem?.title}
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
        >

            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Survey Date" w="50">
                    {(formatDate(selectedItem?.whenSurveyed))}
                </SeaGridCell>
                <SeaGridCell label="Surveyor" w="50">
                    {formatValue(selectedItem?.surveyor)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Personnel Present" w="100">
                    {formatValue(selectedItem?.personnelPresent)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Location" w="100">
                    {formatValue(selectedItem?.location)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="In or out of water" w="50">
                    {formatValue(selectedItem?.inOrOutWater)}
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="survey"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Report</SeaButton>
                    <RequirePermissions
                        role="survey"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'surveyReports',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'surveyReports');
                                        setShowModal(false);
                                    },
                                    'report',
                                    selectedItem?.title,
                                    vesselId ? [vesselId] : []
                                );
                            }}
                        >
                            Delete Report
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {isPdf(selectedItem?.files?.[0]) ? (
                <div className="sea-fill-container">
                    <div className="end-info-line"></div>
                    <SeaLabel>PDF Preview</SeaLabel>
                    <SeaPDFViewer file={selectedItem.files?.[0]} allowScroll /> 
                </div>
            ) : null}
            
            {showModal &&
                <EditSurveyReport
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                />
            }
        </SeaModal>
    );
};

export default ViewSurveyReport;
