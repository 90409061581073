import React, { useCallback, useEffect, useState } from 'react';
import { IonCol } from '@ionic/react';
import { toFloat } from '../../lib/util';
import SeaInput from '../SeaInput/SeaInput';
import './SeaCustomFields.css';

export interface CustomFields {
    [key: string]: any;
}

interface SeaCustomFieldsProps {
    data?: any[],
    setData?: React.Dispatch<React.SetStateAction<CustomFields[] | undefined>>,
    errors?: { [key: number]: {value: string, error: string, touched: boolean} },
    setErrors?: (errors: { [key: number]: {value: string, error: string, touched: boolean} }) => void,
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
    type?: 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
    minLength?: number,
    maxLength?: number,
    isHours?: boolean,
    totalHours?: number,
    setTotalHours?: (hours: number) => void,
    validateField?: (value: string | number, index: number) => string | null,
    forceValidation?: boolean,
    requiredIndexes?: number[]
}

const SeaCustomFields: React.FC<SeaCustomFieldsProps> = ({
    data,
    setData,
    errors,
    setErrors,
    inputmode = 'text',
    type = 'text',
    minLength,
    maxLength,
    isHours,
    totalHours,
    setTotalHours,
    validateField,
    forceValidation = false,
    requiredIndexes
}) => {
    const [localErrors, setLocalErrors] = useState<{ [key: number]: {value: string, error: string, touched: boolean} }>({});
    const effectiveErrors = errors || localErrors;
    const effectiveSetErrors = setErrors || setLocalErrors;

    const validateAndSetErrors = useCallback((value: string, index: number) => {
        let error: string | null = null;

        // Check if it's a valid number for hours fields
        if (isHours && value.length > 0 && isNaN(parseFloat(value))) {
            error = 'Must be a valid number';
        }

        // Run custom validation if provided
        if (!error && validateField) {
            error = validateField(value, index);
        }

        const newErrors = { ...effectiveErrors };
        if (error) {
            newErrors[index] = {value: value, error: error, touched: true};
        } else {
            delete newErrors[index];
        }

        // Only update errors if they have changed
        if (JSON.stringify(newErrors) !== JSON.stringify(effectiveErrors)) {
            effectiveSetErrors(newErrors);
        }
    }, [effectiveErrors, effectiveSetErrors, isHours, validateField]);

    const onChange = (e: CustomEvent<any>, name: string, index: number) => {
        if (data && data.length > 0 && setData) {
            const _data = data.map((item) => ({
                name: item.name,
                value: item.name === name ? e.detail.value : item.value
            }));
            setData(_data);
            validateAndSetErrors(name, e.detail.value);
            updateTotalHours(_data);
        }
    };

    const updateTotalHours = (data: CustomFields[]) => {
        if (isHours && setTotalHours) {
            let x = 0;
            data?.forEach((item) => {
                if (item.value.length > 0) {
                    x += toFloat(item.value, 0);
                }
            });
            setTotalHours(x);
        }
    };

    const handleBlur = (e: CustomEvent<any>, name: string, index: number) => {
        if (data && setData) {
            const _data = [...data];
            if (index > -1) {
                _data[index].value = e.detail.target.value;
            }
            validateAndSetErrors(e.detail.target.value, index);
            setData(_data);
        }
    };

    useEffect(() => {
        if (forceValidation && data) {
            data.forEach((item, index) => {
                validateAndSetErrors(item.value, index);
            });
        }
    }, [forceValidation, data, validateAndSetErrors]);

    if (data === undefined || data.length === 0) {
        return null;
    }

    return (
        <>
            {data?.map((item: any, index) => (
                <IonCol key={item.name} size="3">
                    <SeaInput
                        label={item.name}
                        value={item.value}
                        onchange={(e) => onChange(e, item.name, index)}
                        onblur={(e) => handleBlur(e, item.name, index)}
                        zone="white"
                        type={type}
                        inputmode={inputmode}
                        minLength={minLength}
                        maxLength={maxLength}
                        debounce={200}
                        required={requiredIndexes?.includes(index)}
                        error={effectiveErrors[index]?.error || ''}
                    />
                </IonCol>
            ))}
            {isHours && setTotalHours && data?.length > 0 &&
                <IonCol size="12">
                    <div style={{maxWidth: '320px'}}>
                        <SeaInput
                            label="Total Hours"
                            value={''+totalHours}
                            disabled={true}
                        />
                    </div>
                </IonCol>
            }
        </>
    );
};

export default SeaCustomFields;