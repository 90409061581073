import { SeaFluxError } from '../managers/ErrorsManager/ErrorsManager';
import { useEffect } from 'react';
import { create } from 'zustand';
import { userConfig, UserType } from './Core/user';
import { UserPermissions, userPermissionsSharedDataConfig } from './Core/userPermissions';
import { LicenseeSettings, licenseeSettingsConfig } from './Core/licenseeSettings';
import { Vessel, vesselConfig } from './Core/vessel';
import { userDetailsConfig, UserDetailsData } from './Crew/userDetails';
import { getDayOffsetMillis } from '../lib/datesAndTime';
import { usersConfig, UsersData } from './Core/users';
import { crewCertificatesConfig, CrewCertificatesData } from './Crew/crewCertificates';
import { archivedCrewCertificatesConfig, ArchivedCrewCertificatesData } from './Crew/archivedCrewCertificates';
import { unstable_batchedUpdates } from 'react-dom';
import { ServerInfo, serverInfoConfig } from './Core/serverInfo';
import { handleTimezoneConfig } from './Core/handleTimezone';
import { OnlineStatus, onlineStatusConfig } from './Core/onlineStatus';
import { AppState, appStateConfig } from './Core/appState';
import { SuperAdmin, superAdminConfig } from './Core/superAdmin';
import { actionLogConfig, ActionLogEntry } from './General/actionLog';
import { vesselsConfig, VesselsData } from './Core/vessels';
import { handleCachedDataInfoConfig, handleObsoleteCachedDataConfig } from './DataSyncSystem/cachedDataInfo';
import { handleOldSecurityClaimsConfig, handleSecurityClaimsFreshConfig, handleSecurityClaimsStaleConfig } from './Core/handleSecurityClaims';
import { handleSessionTimeout } from './Core/handleSessionTimeout';
import { userPermissionDefaultsConfig, UserPermissionDefaultsData } from './Crew/userPermissionDefaults';
import { CategoriesData } from '../lib/categories';
import { contactCategoriesConfig } from './Crew/contactCategories';
import { contactsConfig, ContactsData } from './Crew/contacts';
import { drillsConfig, DrillsData } from './VesselSafety/drills';
import { customFormCategoriesConfig } from './CompanyDocuments/CustomForms/customFormCategories';
import { customFormsConfig, CustomFormsData } from './CompanyDocuments/CustomForms/customForms';
import { customFormVersionsConfig, CustomFormVersionsData } from './CompanyDocuments/CustomForms/customFormVersions';
import { incidentsConfig, IncidentsData } from './HealthSafety/incidents';
import { incidentReviewsConfig, IncidentReviewsData } from './HealthSafety/incidentReviews';
import { incidentCategoriesConfig } from './HealthSafety/incidentCategories';
import { incidentCausesConfig } from './HealthSafety/incidentCauses';
import { injuryLocationsConfig } from './HealthSafety/injuryLocations';
import { injuryTypesConfig } from './HealthSafety/injuryTypes';
import { hazardRegistryConfig, HazardRegistryData } from './HealthSafety/hazardRegistry_deprecated';
import { riskCategoriesConfig } from './HealthSafety/riskCategories';
import { risksConfig, RisksData } from './HealthSafety/risks';
import { safetyMeetingReportsConfig, SafetyMeetingsData } from './HealthSafety/safetyMeetingReports';
import { dangerousGoodsConfig, DangerousGoodsData } from './HealthSafety/dangerousGoods';
import { trainingTasksConfig, TrainingTasksData } from './Crew/trainingTasks';
import { CompanyPlan, companyPlanConfig } from './CompanyDocuments/companyPlans';
import { companyDocumentsConfig, CompanyDocumentsData } from './CompanyDocuments/companyDocuments';
import { companyDocumentCategoriesConfig } from './CompanyDocuments/companyDocumentCategories';
import { DeviceInfo, deviceInfoConfig } from './Core/deviceInfo';
import { whenLicenseeTouchedConfig, WhenLicenseeTouchedData } from './DataSyncSystem/whenLicenseeTouched';
import { whenVesselTouchedConfig, WhenVesselTouchedData } from './DataSyncSystem/whenVesselTouched';
import { DataSyncStatus, dataSyncStatusConfig, DataSyncTask, dataSyncTasksQueueConfig, triggerProcessDataSyncTasksConfig } from './DataSyncSystem/dataSyncTasks';
import { enginesConfig, EnginesData } from './VesselMaintenance/engines';
import { dashboardSafetyCheckFaultedItemsConfig, dashboardSafetyCheckItemsConfig, DashboardSafetyCheckItemsData, dashboardSafetyCheckUpcomingItemsConfig } from './VesselDashboard/dashboardSafetyCheckItems';
import { vesselSafetyItemsConfig } from './VesselSafety/vesselSafetyItems';
import { dashboardJobsConfig, DashboardJobsData } from './VesselDashboard/dashboardJobs';
import { dashboardSafetyEquipmentItemsConfig, DashboardSafetyEquipmentItemsData } from './VesselDashboard/dashboardSafetyEquipmentExpiries';
import { dashboardVesselDrillsConfig, DashboardVesselDrillsData } from './VesselDashboard/dashboardVesselDrills';
import { dashboardVesselCertificatesConfig, DashboardVesselCertificatesData } from './VesselDashboard/dashboardVesselCertificates';
import { dashboardVesselDocumentsConfig, DashboardVesselDocumentsData } from './VesselDashboard/dashboardVesselDocuments';
import { dashboardVesselCrewCertificatesConfig, DashboardVesselCrewCertificatesData } from './VesselDashboard/dashboardVesselCrewCertificates';
import { dashboardVesselCrewTrainingConfig, DashboardVesselCrewTrainingData } from './VesselDashboard/dashboardVesselCrewTraining';
import { SafetyCheckItem, safetyCheckItemsConfig, SafetyCheckItemsData } from './VesselSafety/safetyCheckItems';
import { vesselDrillsConfig, VesselDrillsData } from './VesselSafety/vesselDrills';
import { drillReportsConfig, DrillReportsData } from './VesselSafety/drillReports';
import { safetyEquipmentItemsConfig, SafetyEquipmentItemsData } from './VesselSafety/safetyEquipmentItems';
import { vesselSystemsConfig } from './VesselMaintenance/vesselSystems';
import { scheduledMaintenanceTasksConfig, ScheduledMaintenanceTasksData } from './VesselMaintenance/maintenanceSchedule';
import { vesselLocationsConfig } from './VesselMaintenance/vesselLocations';
import { equipmentConfig, EquipmentData } from './VesselMaintenance/equipment';
import { jobsConfig, JobsData } from './VesselMaintenance/jobs';
import { equipmentManualDocumentsConfig, EquipmentManualDocumentsData } from './VesselDocuments/equipmentManualDocuments';
import { maintenanceTasksCompletedConfig, MaintenanceTasksCompletedData } from './VesselMaintenance/maintenanceTasksCompleted';
import { sparePartsConfig, SparePartsData } from './VesselMaintenance/spareParts';
import { vesselCertificatesConfig, VesselCertificatesData } from './VesselDocuments/vesselCertificates';
import { vesselDocumentsConfig, VesselDocumentsData } from './VesselDocuments/vesselDocuments';
import { voyagesConfig, VoyagesData } from './VesselLogbook/voyages';
import { customFormTemplatesConfig, CustomFormTemplatesData } from './CompanyDocuments/CustomForms/customFormTemplates';
import { FieldValue, Timestamp } from 'firebase/firestore';
import { archivedVesselCertificatesConfig, ArchivedVesselCertificatesData } from './VesselDocuments/archivedVesselCertificates';
import { vesselSOPsConfig, VesselSOPsData } from './VesselDocuments/vesselSOPS';
import { vesselSOPCategoriesConfig } from './VesselDocuments/vesselSOPCategories';
import { vesselSurveyReportsConfig, VesselSurveyReportsData } from './VesselDocuments/vesselSurveyReports';
import { vesselDocumentCategoriesConfig } from './VesselDocuments/vesselDocumentCategories';
import { safetyMeetingJobsConfig, SafetyMeetingJobsData } from './HealthSafety/safetyMeetingJobs';
import { LayoutMode, layoutModeConfig, ModalSpace, modalSpaceConfig, scrollableContentConfig, selectedSectionConfig, selectedSectionTabConfig, showBlankScreenConfig } from '../layouts/AppLayout/AppLayout';
import { SeaScrollable } from '../components/SeaScrollableArea/SeaScrollableArea';
import { ConfirmDialog, confirmDialogConfig } from '../managers/ConfirmDialogManager/ConfirmDialogManager';
import { toastMessageConfig } from '../managers/ToastManager/ToastManager';
import { AlertMessage, alertMessageConfig } from '../managers/AlertManager/AlertManager';
import { isSwipeEnabledConfig } from './General/swipeGestures';
import { safetyCheckCategoriesConfig } from './VesselSafety/safetyCheckCategories';
import { ContextualHelp, contextualHelpConfig } from '../managers/ContextualHelpManager/ContextualHelpManager';
import { AppReadyState, appReadyStateConfig } from './Core/appReadyState';
import { FileUpload, fileUploadConfig } from '../managers/FileUploadManager/FileUploadManager';
import { FileViewer, fileViewerConfig } from '../managers/FileViewerManager/FileViewerManager';
import { FileUploadStatus, fileUploadStatusConfig, triggerProcessFilesToUploadConfig } from './FileSyncSystem/filesToUpload';
import { isFileSyncReadyConfig } from './FileSyncSystem/initFileSync';
import { FileSyncStatus, fileSyncStatusConfig, triggerProcessFilesToCacheConfig } from './FileSyncSystem/filesToCache';
import { FileSyncDeviceSettings, fileSyncDeviceSettingsConfig } from './FileSyncSystem/fileSyncDeviceSettings';
import { AppActivity, appActivityConfig } from './General/appActivity';
import { handleRefreshProblemReportingConfig } from './General/diagnoseRefreshProblems';
import { userRolesConfig } from './Crew/userRoles';
import { vesselCertificateCategoriesConfig } from './VesselDocuments/vesselCertificateCategories';
import { crewCertificateTitlesConfig } from './Crew/crewCertificateTitles';
import { DiskSpaceStatus, diskSpaceStatusConfig } from './FileSyncSystem/diskSpaceStatus';
import { errorsOnHoldConfig, triggerProcessErrorsOnHoldConfig } from './General/errorsOnHold';
import { Debugging, debuggingConfig } from './Core/debugging';
import { FirestoreState, firestoreStateConfig } from './Core/firestoreState';
import { DebugViewConfig, debugViewConfig } from './General/debugView';
import { isProduction } from '../lib/firebase';
import { RichTextPopoverMenu, richTextPopoverMenuConfig } from './General/richTextPopoverMenu';
import { linksConfig, LinksData } from './Core/links';

// Notes:
// * All run methods must call done() otherwise lower priority sharedState may never run
//   Remember to still call done() if an error occurs
//   You should call done() at the first point in time when the first asynchronous operation finishes
//   i.e. when you've first heard back from an operation, but before you've processed it.
//

export const profileSharedState = !isProduction; // Should be false for production builds
export const sharedStateProfiling = {} as {
    [type in SharedStateType]?: {
        runTime: 0;
        loadTime: 0;
        runs: {
            [when: number]: number;
        };
        loads: {
            [when: number]: number;
        };
    };
}; // Data to store profiling info into.

export const sharedStateCategories = [
    'Core',
    'Vessel Dashboard',
    'Vessel Logbook',
    'Vessel Safety',
    'Vessel Maintenance',
    'Vessel Document Register',
    'Health & Safety',
    'Company Document Register',
    'Crew',
    'Data Sync System',
    'File Sync System',
    'General',
] as const;
export type SharedStateCategory = (typeof sharedStateCategories)[number];

export interface SyncableDocument {
    id: string;
    touched: Timestamp | FieldValue;
}

export interface CreateableDocument {
    id: string;
    whenAdded: number;
    addedBy: string;
}

export interface UpdateableDocument {
    id: string;
    whenUpdated?: number;
    updatedBy?: string;
}

export interface ArchivableDocument {
    id: string;
    whenArchived?: number;
    archivedBy?: string;
}

export interface UnarchivableDocument {
    id: string;
    whenUnarchived?: number;
    unarchivedBy?: string;
}

export interface DeleteableDocument {
    id: string;
    whenDeleted?: number;
    deletedBy?: string;
}

export interface TouchableDocument {
    id: string;
    touched: FieldValue;
}

const initShared = <T>(category: SharedStateCategory, config: SharedStateConfig<T>): SharedState<T> => {
    return {
        category: category,
        isActive: config?.isAlwaysActive ? true : false,
        isAlwaysActive: false,
        current: config?.default,
        default: config?.default,
        subscribers: 0,
        priority: config?.isAlwaysActive ? 1 : 0,
        hasRun: false,
        dependencies: [],
        triggers: [],
        run: (done, set, clear) => done(), // Should only be called via runSharedState
        countRuns: 0,
        countChanged: 0,
        cooldownN: 0,
        countLiveDocs: () => undefined,
        cleanup: () => undefined,
        equals: (a: T | undefined, b: T | undefined) => a === b,
        use: () => undefined, // React Hook that gets a state that will cause a rerender
        set: (value: T | undefined | ((current: T | undefined) => T | undefined)) => undefined, // set a value which will trigger state changes for anyone using the use() hook
        clear: () => undefined, // set value to its default which will trigger state changes for anyone using the use() hook
        ...config,
    };
};

export const sharedState = {
    // Core
    deviceId: initShared<string>('Core', { isAlwaysActive: true, notes: 'Source: App init' }),
    authUser: initShared<any>('Core', { isAlwaysActive: true, notes: 'Source: initAuthentication' }),
    deviceInfo: initShared<DeviceInfo>('Core', deviceInfoConfig),
    appState: initShared<AppState>('Core', appStateConfig),
    firestoreState: initShared<FirestoreState>('Core', firestoreStateConfig),
    navigateTo: initShared<string>('Core', {
        isAlwaysActive: true,
        default: '',
        notes: 'Setting this to a url triggers LayoutContext to navigate()',
    }),
    handleTimezone: initShared<string>('Core', handleTimezoneConfig),
    todayMillis: initShared<number>('Core', {
        isAlwaysActive: true,
        default: getDayOffsetMillis(0),
        notes: 'Source: App setInterval',
    }),
    onlineStatus: initShared<OnlineStatus>('Core', onlineStatusConfig),
    serverInfo: initShared<ServerInfo>('Core', serverInfoConfig),
    userPending: initShared<boolean>('Core', {
        isAlwaysActive: true,
        default: true,
        notes: "Source: AuthContext. Indicates we're in limbo waiting for authentication state to resolve",
    }),
    userId: initShared<string>('Core', { isAlwaysActive: true, notes: 'Source: onAuthStateChanged or onGoToAccount' }),
    user: initShared<UserType>('Core', userConfig),
    superAdminId: initShared<string>('Core', { isAlwaysActive: true, notes: 'Source: onAuthStateChanged' }),
    superAdmin: initShared<SuperAdmin>('Core', superAdminConfig),
    users: initShared<UsersData>('Core', usersConfig),
    userPermissions: initShared<UserPermissions>('Core', userPermissionsSharedDataConfig),
    securityClaims: initShared<any>('Core', {
        isAlwaysActive: true,
        notes: 'Source: handleSecurityClaimsStale, handleSecurityClaimsFresh',
    }),
    handleSecurityClaimsStale: initShared<string>('Core', handleSecurityClaimsStaleConfig),
    handleSecurityClaimsFresh: initShared<string>('Core', handleSecurityClaimsFreshConfig),
    handleOldSecurityClaims: initShared<string>('Core', handleOldSecurityClaimsConfig),
    handleSessionTimeout: initShared<string>('Core', handleSessionTimeout),
    appReadyState: initShared<AppReadyState>('Core', appReadyStateConfig),
    vesselId: initShared<string>('Core', {
        isAlwaysActive: true,
        notes: 'Source: FleetDashboard or URL /vessel/<vesselId>',
    }), // Selected vesselId we are viewing in the app
    vessel: initShared<Vessel>('Core', vesselConfig),
    vesselIds: initShared<string[]>('Core', { isAlwaysActive: true, notes: 'Source: sharedState.user.run' }), // List of vesselIds the logged in user has access to
    vessels: initShared<VesselsData>('Core', vesselsConfig),
    licenseeId: initShared<string>('Core', { isAlwaysActive: true, notes: 'Source: sharedState.user.run' }),
    licenseeSettings: initShared<LicenseeSettings>('Core', licenseeSettingsConfig),

    // Vessel Dashboard
    vesselSafetyItems: initShared<CategoriesData>('Vessel Dashboard', vesselSafetyItemsConfig),
    dashboardSafetyCheckItems: initShared<DashboardSafetyCheckItemsData>('Vessel Dashboard', dashboardSafetyCheckItemsConfig),
    dashboardSafetyCheckFaultedItems: initShared<SafetyCheckItem[]>('Vessel Dashboard', dashboardSafetyCheckFaultedItemsConfig),
    dashboardSafetyCheckUpcomingItems: initShared<SafetyCheckItem[]>('Vessel Dashboard', dashboardSafetyCheckUpcomingItemsConfig),
    dashboardSafetyEquipmentItems: initShared<DashboardSafetyEquipmentItemsData>('Vessel Dashboard', dashboardSafetyEquipmentItemsConfig),
    dashboardJobs: initShared<DashboardJobsData>('Vessel Dashboard', dashboardJobsConfig),
    dashboardVesselDrills: initShared<DashboardVesselDrillsData>('Vessel Dashboard', dashboardVesselDrillsConfig),
    dashboardVesselCertificates: initShared<DashboardVesselCertificatesData>('Vessel Dashboard', dashboardVesselCertificatesConfig),
    dashboardVesselDocuments: initShared<DashboardVesselDocumentsData>('Vessel Dashboard', dashboardVesselDocumentsConfig),
    dashboardVesselCrewCertificates: initShared<DashboardVesselCrewCertificatesData>('Vessel Dashboard', dashboardVesselCrewCertificatesConfig),
    dashboardVesselCrewTraining: initShared<DashboardVesselCrewTrainingData>('Vessel Dashboard', dashboardVesselCrewTrainingConfig),

    // Vessel Logbook
    voyages: initShared<VoyagesData>('Vessel Logbook', voyagesConfig),

    // Vessel Safety
    drills: initShared<DrillsData>('Vessel Safety', drillsConfig),
    vesselDrills: initShared<VesselDrillsData>('Vessel Safety', vesselDrillsConfig),
    drillReports: initShared<DrillReportsData>('Vessel Safety', drillReportsConfig),
    safetyCheckItems: initShared<SafetyCheckItemsData>('Vessel Safety', safetyCheckItemsConfig),
    safetyEquipmentItems: initShared<SafetyEquipmentItemsData>('Vessel Safety', safetyEquipmentItemsConfig),
    safetyCheckCategories: initShared<CategoriesData>('Vessel Safety', safetyCheckCategoriesConfig),

    // Vessel Maintenance
    engines: initShared<EnginesData>('Vessel Maintenance', enginesConfig),
    equipment: initShared<EquipmentData>('Vessel Maintenance', equipmentConfig),
    equipmentManualDocuments: initShared<EquipmentManualDocumentsData>('Vessel Maintenance', equipmentManualDocumentsConfig),
    jobs: initShared<JobsData>('Vessel Maintenance', jobsConfig),
    scheduledMaintenanceTasks: initShared<ScheduledMaintenanceTasksData>('Vessel Maintenance', scheduledMaintenanceTasksConfig),
    maintenanceTasksCompleted: initShared<MaintenanceTasksCompletedData>('Vessel Maintenance', maintenanceTasksCompletedConfig),
    spareParts: initShared<SparePartsData>('Vessel Maintenance', sparePartsConfig),
    vesselLocations: initShared<CategoriesData>('Vessel Maintenance', vesselLocationsConfig),
    vesselSystems: initShared<CategoriesData>('Vessel Maintenance', vesselSystemsConfig),

    // Vessel Document Register
    archivedVesselCertificates: initShared<ArchivedVesselCertificatesData>('Vessel Document Register', archivedVesselCertificatesConfig),
    vesselCertificates: initShared<VesselCertificatesData>('Vessel Document Register', vesselCertificatesConfig),
    vesselCertificateCategories: initShared<CategoriesData>('Vessel Document Register', vesselCertificateCategoriesConfig),
    vesselDocuments: initShared<VesselDocumentsData>('Vessel Document Register', vesselDocumentsConfig),
    vesselDocumentCategories: initShared<CategoriesData>('Vessel Document Register', vesselDocumentCategoriesConfig),
    vesselSOPCategories: initShared<CategoriesData>('Vessel Document Register', vesselSOPCategoriesConfig),
    vesselSOPs: initShared<VesselSOPsData>('Vessel Document Register', vesselSOPsConfig),
    vesselSurveyReports: initShared<VesselSurveyReportsData>('Vessel Document Register', vesselSurveyReportsConfig),

    // Health & Safety
    incidents: initShared<IncidentsData>('Health & Safety', incidentsConfig),
    incidentReviews: initShared<IncidentReviewsData>('Health & Safety', incidentReviewsConfig),
    incidentCategories: initShared<CategoriesData>('Health & Safety', incidentCategoriesConfig),
    incidentCauses: initShared<CategoriesData>('Health & Safety', incidentCausesConfig),
    injuryLocations: initShared<CategoriesData>('Health & Safety', injuryLocationsConfig),
    injuryTypes: initShared<CategoriesData>('Health & Safety', injuryTypesConfig),
    hazardRegistry: initShared<HazardRegistryData>('Health & Safety', hazardRegistryConfig),
    riskCategories: initShared<CategoriesData>('Health & Safety', riskCategoriesConfig),
    risks: initShared<RisksData>('Health & Safety', risksConfig),
    safetyMeetingReports: initShared<SafetyMeetingsData>('Health & Safety', safetyMeetingReportsConfig),
    safetyMeetingJobs: initShared<SafetyMeetingJobsData>('Health & Safety', safetyMeetingJobsConfig),
    dangerousGoods: initShared<DangerousGoodsData>('Health & Safety', dangerousGoodsConfig),

    // Company Document Register
    companyPlan: initShared<CompanyPlan>('Company Document Register', companyPlanConfig),
    companyDocumentCategories: initShared<CategoriesData>('Company Document Register', companyDocumentCategoriesConfig),
    companyDocuments: initShared<CompanyDocumentsData>('Company Document Register', companyDocumentsConfig),
    customFormCategories: initShared<CategoriesData>('Company Document Register', customFormCategoriesConfig),
    customForms: initShared<CustomFormsData>('Company Document Register', customFormsConfig),
    customFormTemplates: initShared<CustomFormTemplatesData>('Company Document Register', customFormTemplatesConfig),
    customFormVersions: initShared<CustomFormVersionsData>('Company Document Register', customFormVersionsConfig),
    customFormId: initShared<string>('Company Document Register', {
        isAlwaysActive: false,
        notes: 'Source: CustomFormTemplate page',
    }),

    // Crew
    userDetails: initShared<UserDetailsData>('Crew', userDetailsConfig),
    userPermissionDefaults: initShared<UserPermissionDefaultsData>('Crew', userPermissionDefaultsConfig),
    userRoles: initShared<CategoriesData>('Crew', userRolesConfig),
    crewCertificateTitles: initShared<CategoriesData>('Crew', crewCertificateTitlesConfig),
    crewCertificates: initShared<CrewCertificatesData>('Crew', crewCertificatesConfig),
    archivedCrewCertificates: initShared<ArchivedCrewCertificatesData>('Crew', archivedCrewCertificatesConfig),
    contactCategories: initShared<CategoriesData>('Crew', contactCategoriesConfig),
    contacts: initShared<ContactsData>('Crew', contactsConfig),
    trainingTasks: initShared<TrainingTasksData>('Crew', trainingTasksConfig),
    trainingTaskReports: initShared<TrainingTasksData>('Crew', trainingTasksConfig),

    // Data Sync System
    isDataSyncActive: initShared<boolean>('Data Sync System', {
        isAlwaysActive: true,
        notes: 'Source: handleCachedDataInfo.run (requires licenceeSettings.hasOffline)',
    }),
    handleCachedDataInfo: initShared<string>('Data Sync System', handleCachedDataInfoConfig),
    handleObsoleteCachedData: initShared<string>('Data Sync System', handleObsoleteCachedDataConfig),
    dataSyncStatus: initShared<DataSyncStatus>('Data Sync System', dataSyncStatusConfig),
    dataSyncTasksQueue: initShared<DataSyncTask[]>('Data Sync System', dataSyncTasksQueueConfig),
    triggerProcessDataSyncTasksConfig: initShared<string>('Data Sync System', triggerProcessDataSyncTasksConfig),
    whenLicenseeTouched: initShared<WhenLicenseeTouchedData>('Data Sync System', whenLicenseeTouchedConfig),
    whenVesselTouched: initShared<WhenVesselTouchedData>('Data Sync System', whenVesselTouchedConfig),

    // File Sync System
    isFileSyncReady: initShared<boolean>('File Sync System', isFileSyncReadyConfig),
    fileSyncDeviceSettings: initShared<FileSyncDeviceSettings>('File Sync System', fileSyncDeviceSettingsConfig),
    fileSyncStatus: initShared<FileSyncStatus>('File Sync System', fileSyncStatusConfig),
    fileUploadStatus: initShared<FileUploadStatus>('File Sync System', fileUploadStatusConfig),
    diskSpaceStatus: initShared<DiskSpaceStatus>('File Sync System', diskSpaceStatusConfig),
    triggerProcessFilesToCache: initShared<string>('File Sync System', triggerProcessFilesToCacheConfig),
    triggerProcessFilesToUpload: initShared<string>('File Sync System', triggerProcessFilesToUploadConfig),

    // General
    appActivity: initShared<AppActivity>('General', appActivityConfig),
    handleRefreshProblemReporting: initShared<string>('General', handleRefreshProblemReportingConfig),
    layoutMode: initShared<LayoutMode>('General', layoutModeConfig),
    isSwipeEnabled: initShared<boolean>('General', isSwipeEnabledConfig),
    selectedSection: initShared<string>('General', selectedSectionConfig),
    selectedSectionTab: initShared<string>('General', selectedSectionTabConfig),
    modalSpace: initShared<ModalSpace>('General', modalSpaceConfig),
    showBlankScreen: initShared<boolean>('General', showBlankScreenConfig),
    scrollableContent: initShared<SeaScrollable>('General', scrollableContentConfig),
    confirmDialog: initShared<ConfirmDialog>('General', confirmDialogConfig),
    alertMessage: initShared<AlertMessage>('General', alertMessageConfig),
    toastMessage: initShared<string>('General', toastMessageConfig),
    contextualHelp: initShared<ContextualHelp>('General', contextualHelpConfig),
    richTextPopoverMenu: initShared<RichTextPopoverMenu>('General', richTextPopoverMenuConfig),
    fileUpload: initShared<FileUpload>('General', fileUploadConfig),
    fileViewer: initShared<FileViewer>('General', fileViewerConfig),
    actionLog: initShared<ActionLogEntry[]>('General', actionLogConfig),
    debugView: initShared<DebugViewConfig>('General', debugViewConfig),
    debugging: initShared<Debugging>('General', debuggingConfig),
    errorsToShow: initShared<SeaFluxError[]>('General', {
        isAlwaysActive: true,
        default: [],
        notes: `Errors to present to the user - AKA "red screen" errors.`,
    }),
    errorsOnHold: initShared<SeaFluxError[]>('General', errorsOnHoldConfig),
    triggerProcessErrorsOnHold: initShared<boolean>('General', triggerProcessErrorsOnHoldConfig),
    links: initShared<LinksData>('General', linksConfig),
};

export type SharedStateConfig<T> = {
    isAlwaysActive?: boolean; // Set true if should not be contingent on having at least one subscriber
    default?: T | undefined;
    dependencies?: SharedStateType[]; // List of dataTypes that if changed will cause this to refresh
    run?: (done: () => void, set: (newValue: T | undefined) => void, clear: () => void) => (() => void) | void; // Function that may remake current value. Optionally returns a cleanup function to free any underlying resources
    equals?: (a: any, b: any) => boolean; // Function to test equality between 2 objects of the same type. This is used to avoid changing state when there is no material change
    notes?: string; // For debugging purposes only
    countLiveDocs?: () => number | undefined; // If this state is listening to live Firestore documents, this should return how many there are.
};

export type SharedStateUsePriority = 'LoadNow' | 'LazyLoad' | 'NoLoad'; // | 'Very Lazy';

export type SharedState<T> = {
    category: SharedStateCategory;
    isActive: boolean; // Should be true if isAlwaysActive OR has atleast one subscriber
    isAlwaysActive: boolean; // If true: isActive is not contingent on having at least one subscriber
    current: T | undefined;
    default: T | undefined;
    subscribers: number;
    priority: number; // The priority for which this is wanted (which will be the highest amoung all subscribers)
    hasRun: boolean;
    dependencies: SharedStateType[]; // List of dataTypes that if changed should cause this to refresh
    triggers: SharedStateType[]; // List of dataTypes that should refresh when this value changes
    run: (done: () => void, set: (newValue: T | undefined) => void, clear: () => void) => (() => void) | void; // Function that may remake current value. Optionally returns a cleanup function to free any underlying resources
    cleanup: () => void; // Function to release any underlying resources
    equals: (a: any, b: any) => boolean; // Function to test equality between 2 objects of the same type. This is used to help avoid changing state unnecessarily
    notes?: string; // For debugging purposes only
    countRuns: number; // Counts how many times run(...) has been called
    countChanged: number; // Count number of times the state has been set
    cooldownN: number; // Used to track cooldown timeouts so new cooldown timeouts replace previous timeouts.
    countLiveDocs: () => number | undefined;
    use: (priority?: number | boolean) => T | undefined; // hook that gets a state that will cause a rerender
    set: (valueOrFunction: T | undefined | ((current: T | undefined) => T | undefined)) => void; // set a value which will trigger state changes for anyone using the use() hook.
    clear: () => void; // set value to its default which will trigger state changes for anyone using the use() hook
};

export type SharedStateType = keyof typeof sharedState; // <--- yay! I succeeded in getting a list of types!

// Creates a base React Hook using Zustand which useSharedState can use
const useSharedStateBase = create(() => {
    const result = {} as any;
    Object.keys(sharedState).forEach((key) => {
        result[key] = sharedState[key as SharedStateType].default;
    });
    return result;
});

// All changes to shared data should use this function so that triggers work correctly
// Use sharedState.<dataType>.set(value) for correct typing
export const setSharedState = (sharedStateType: SharedStateType, value: any) => {
    const sharedStateItem = sharedState[sharedStateType];
    if (sharedStateItem.equals(value, sharedStateItem.current)) {
        return; // No need to update state because value is unchanged
    }
    // Update current value that is (outside of React life cycle)
    sharedStateItem.current = value;

    // Update data within zustand (so that state changes propagate)
    const data = {} as any;
    data[sharedStateType] = value;
    unstable_batchedUpdates(() => {
        // This wrapper might be needed to prevent zombie-child effect when React version < 18. See: https://docs.pmnd.rs/zustand/guides/event-handler-in-pre-react-18
        useSharedStateBase.setState((state: any) => data);
    });
    sharedStateItem.countChanged++;

    // Trigger other shared data items to run that are dependant on this shared data
    sharedStateItem.triggers.forEach((triggeredDataType: SharedStateType) => {
        // Only trigger sharedState that is active
        if (!sharedState[triggeredDataType].isActive) {
            return;
        }
        //console.log(`<> ${sharedStateType} triggered ${triggeredDataType}`);
        runSharedState(triggeredDataType);
    });
};

// Helper function to reset a sharedState item to its default value
// Use sharedState.<dataType>.clear() for correct typing
export const clearSharedState = (sharedStateType: SharedStateType) => {
    setSharedState(sharedStateType, sharedState[sharedStateType].default);
};

// To get correct typing without having manually specify it, use sharedState.<dataType>.use()
export const useSharedState = <T>(sharedStateType: SharedStateType, _priority: number | boolean): T | undefined => {
    const priority = typeof _priority === 'boolean' ? (_priority ? 1 : 0) : _priority;
    useEffect(() => {
        if (priority === 0) return; // data not wanted
        //console.log(`<> useShareData SUB ${sharedStateType} with priority ${priority}`);
        const item = sharedState[sharedStateType];
        item.subscribers++;
        const isActive = item.isActive;
        if (!item.isAlwaysActive) {
            // Set priority such that the highest priority of all subscribers is used
            if (item.priority === 0) {
                item.priority = priority;
            } else {
                item.priority = Math.min(item.priority, priority);
            }
        }
        if (!isActive) {
            //console.log(`<> useShareData ${sharedStateType} not active yet`);
            if (priority <= loadingSystem.loadingPriority) {
                //console.log(`<> useShareData ${sharedStateType} activating now`);
                activateSharedState(sharedStateType);
            } else {
                //console.log(`<> useShareData ${sharedStateType} queuing`);
                queueLoadingSharedState(sharedStateType);
                setTimeout(() => {
                    processLoadingQueue();
                });
            }
        }
        return () => {
            //console.log(`<> useShareData UNSUB ${sharedStateType} with priority ${priority}`);
            item.subscribers--;
            if (!item.isAlwaysActive) {
                setTimeout(() => {
                    // This is in a setTimeout so that if a component is instantly resubscribing due to just it's priority changing,
                    // rather than the component really being destroyed,
                    // this sharedStateType wont deactivate and reactivate causing a wasted run
                    if (item.subscribers <= 0 && item.isActive) {
                        //console.log(`<> UNSUB deactivating ${sharedStateType} item.isActive=${item.isActive}, item.priority=${item.priority}`);
                        // Deactivate and clear data if still no subs after 10 seconds (cooldown)
                        const cooldownN = ++item.cooldownN;
                        setTimeout(() => {
                            if (cooldownN === item.cooldownN && item.subscribers <= 0 && item.isActive) {
                                //console.log(`<> deactivateSharedState after cooldown for ${sharedStateType}`);
                                deactivateSharedState(sharedStateType);
                            }
                        }, 10 * 1000);
                    }
                });
            }
        };
    }, [sharedStateType, priority]);
    return useSharedStateBase((state: any) => {
        return state[sharedStateType] as T;
    });
};

export const useAllSharedStateForAnalysis = () => {
    // Allows SharedStateAnalysis to update when ANY state changes
    return useSharedStateBase((state: any) => state);
};

export const loadingSystem = {
    loadingPriority: 1, // Should always be >= 1
    countLoading: 0,
    currentlyLoading: {} as {
        [sharedStatetype in SharedStateType]: boolean;
    },
    queue: {} as {
        [sharedStatetype in SharedStateType]: boolean; // (priority)
    },
};

export const runSharedState = (sharedStateType: SharedStateType) => {
    // First cleanup shared data (if possible)
    try {
        sharedState[sharedStateType].cleanup();
    } catch (e) {
        console.error(`Failed to cleanup sharedState for ${sharedStateType.toString()}`, e);
    }
    if (!sharedState[sharedStateType].hasRun && !loadingSystem.currentlyLoading[sharedStateType]) {
        loadingSystem.countLoading++;
        loadingSystem.currentlyLoading[sharedStateType] = true;
        //console.log(`<> RUN Start running ${sharedStateType}. ${loadingSystem.countLoading} currently loading.`);
    }
    // Run shared data
    if (profileSharedState) {
        // Profiling mode
        const whenRun = performance.now();
        let alreadyDone = false;
        if (sharedStateProfiling[sharedStateType] === undefined) {
            sharedStateProfiling[sharedStateType] = {
                runTime: 0,
                loadTime: 0,
                runs: {},
                loads: {},
            };
        }
        const profiling = sharedStateProfiling[sharedStateType]!;
        sharedState[sharedStateType].run(
            () => {
                // done
                if (alreadyDone) return;
                alreadyDone = true;
                const loadTime = performance.now() - whenRun;
                profiling.loadTime += loadTime;
                profiling.loads[whenRun] = loadTime;
                if (!sharedState[sharedStateType].hasRun) {
                    sharedState[sharedStateType].hasRun = true;
                    loadingSystem.countLoading--;
                    delete loadingSystem.currentlyLoading[sharedStateType];
                    //console.log(`<> RUN Finished running (first time) ${sharedStateType}. ${loadingSystem.countLoading} currently running.`);
                    processLoadingQueue();
                }
            },
            (newValue: any) => {
                // set
                setSharedState(sharedStateType, newValue);
            },
            () => {
                // clear
                clearSharedState(sharedStateType);
            }
        );
        const runTime = performance.now() - whenRun;
        profiling.runTime += runTime;
        profiling.runs[whenRun] = runTime;
    } else {
        // Production mode
        sharedState[sharedStateType].run(
            () => {
                // done
                if (!sharedState[sharedStateType].hasRun) {
                    sharedState[sharedStateType].hasRun = true;
                    loadingSystem.countLoading--;
                    delete loadingSystem.currentlyLoading[sharedStateType];
                    //console.log(`<> RUN Finished running (first time) ${sharedStateType}. ${loadingSystem.countLoading} currently running.`);
                    processLoadingQueue();
                }
            },
            (newValue: any) => {
                // set
                setSharedState(sharedStateType, newValue);
            },
            () => {
                // clear
                clearSharedState(sharedStateType);
            }
        );
    }
    sharedState[sharedStateType].countRuns++; // Record stat
};

const activateSharedState = (sharedStateType: SharedStateType) => {
    const item = sharedState[sharedStateType];
    if (!item.isActive) {
        // console.log(`<> activateSharedState ${sharedStateType}`);
        item.isActive = true;
        delete loadingSystem.queue[sharedStateType];
        if (loadingSystem.countLoading === 0 && loadingSystem.loadingPriority < item.priority) {
            loadingSystem.loadingPriority = item.priority;
        }
        //console.log(`<> shared-state activated and running ${sharedStateType}`);
        runSharedState(sharedStateType);
        // Make sure all sharedState this depends on is also active
        item.dependencies.forEach((dependency: SharedStateType) => {
            activateSharedState(dependency);
        });
    }
};

const deactivateSharedState = (sharedStateType: SharedStateType) => {
    const item = sharedState[sharedStateType];
    if (item.isActive && !item.isAlwaysActive) {
        // First, let's check nothing depends on this that is still active
        for (let i = 0; i < item.triggers.length; i++) {
            const triggerItem = sharedState[item.triggers[i]];
            if (triggerItem.isActive && !triggerItem.isAlwaysActive) {
                // We found an active state that depends on us, therefore we can't deactivate this state.
                //console.log(`<> Can't deactivate state ${sharedStateType} because of trigger ${item.triggers[i]}`);
                return;
            }
        }
        // console.log(`<> deactivateSharedState ${sharedStateType} (priority=${item.priority})`);
        item.isActive = false;
        item.hasRun = false;
        item.priority = 0;
        clearSharedState(sharedStateType);
        // Finally, we should check if any of the states we depend on are no longer needed
        for (let i = 0; i < item.dependencies.length; i++) {
            const dependencyItem = sharedState[item.dependencies[i]];
            if (dependencyItem.subscribers <= 0 && dependencyItem.isActive && !dependencyItem.isAlwaysActive) {
                //console.log(`<> Also deactivating depedency ${item.dependencies[i]}`);
                deactivateSharedState(item.dependencies[i]);
            }
        }
    }
};

// Queues up a sahredDataType to be loaded later (after current higher priority sharedStateTypes have finished loading)
const queueLoadingSharedState = (sharedStateItem: SharedStateType) => {
    loadingSystem.queue[sharedStateItem] = true;
};

// Processes the current loadingSystem queue.
// Will load the next batch of sharedStateTypes to load
const processLoadingQueue = () => {
    if (loadingSystem.countLoading > 0) {
        return; // Queue not ready to be processed because there are still sharedStateTypes in the process of being loading
    }
    //console.log(`<> processLoadingQueue!`);
    // Find the next priority level to load
    let nextPriority = Number.MAX_SAFE_INTEGER;
    Object.keys(loadingSystem.queue).forEach((sharedStateType) => {
        if (loadingSystem.queue[sharedStateType as SharedStateType]) {
            nextPriority = Math.min(sharedState[sharedStateType as SharedStateType].priority, nextPriority);
        }
    });
    if (nextPriority === Number.MAX_SAFE_INTEGER) {
        //console.log(`<> processLoadingQueue nothing queued up to load`);
        return; // Nothing in queue to load
    }
    //console.log(`<> processLoadingQueue nextPriority=${nextPriority}`);
    // Set the next priority to load
    loadingSystem.loadingPriority = nextPriority;
    // Activate all the sharedStateItems that have a priority <= to the new priority level
    Object.keys(loadingSystem.queue).forEach((sharedStateType) => {
        if (loadingSystem.queue[sharedStateType as SharedStateType] && sharedState[sharedStateType as SharedStateType].priority <= loadingSystem.loadingPriority) {
            activateSharedState(sharedStateType as SharedStateType);
        }
    });
};

// Initialise shared data items
// * Decorate sharedState attributes: use(), set(), clear(), and triggers
const initSharedItems = () => {
    Object.keys(sharedState).forEach((sharedStateType) => {
        const item = sharedState[sharedStateType as SharedStateType];
        item.use = function useSpecificSharedState(priority = 1) {
            return useSharedState<any>(sharedStateType as SharedStateType, priority);
        };
        item.set = (value: any) => {
            // console.log(`set ${sharedStateType} typeof value=${typeof value}, value=${typeof value === 'function' ? (
            //     value(sharedState[sharedStateType as SharedStateType].current)
            // ) : (
            //     value
            // )}`);
            setSharedState(sharedStateType as SharedStateType, typeof value === 'function' ? value(sharedState[sharedStateType as SharedStateType].current) : value);
        };
        item.clear = () => clearSharedState(sharedStateType as SharedStateType);
        item.dependencies.forEach((dependency: SharedStateType) => {
            if (!sharedState[dependency].triggers.includes(sharedStateType as SharedStateType)) {
                sharedState[dependency].triggers.push(sharedStateType as SharedStateType);
            }
        });
    });
};
initSharedItems();
