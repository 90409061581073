import React, { useState, useMemo } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { renderCamelCase } from '../../../../lib/util';
import { canCreate, canDelete, canEdit, canView } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch, doc, serverTimestamp } from "firebase/firestore";
import { SplittableBatch, firestore } from '../../../../lib/firebase';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { dayDifferenceBetweenDates } from '../../../../lib/datesAndTime';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import EditIncident from '../EditIncident/EditIncident';
import ReviewIncident from '../ReviewIncident/ReviewIncident';
import CommonIncidentContent from './CommonIncidentContent';
import IncidentReviewContent from './IncidentReviewContent';
import IncidentEventReportPdf from '../../../../exports/HealthSafety/Incidents/IncidentEventReportPdf';

interface ViewIncidentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: Incident,
}

const ViewIncident: React.FC<ViewIncidentProps> = ({showModal, setShowModal, selectedItem}) => {
    const userId = sharedState.userId.use(showModal);
    const superAdmin = sharedState.superAdmin.use(showModal);
    const incidentReviews = sharedState.incidentReviews.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    const incidentReview = useMemo(() => {
        if (selectedItem && incidentReviews?.byId[selectedItem.id]) {
            return incidentReviews.byId[selectedItem.id];
        }
        return undefined;
    }, [incidentReviews, selectedItem]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onReviewItem = () => {
        setShowReviewModal(true);
    };
    return (
        <SeaModal
            // title={`Report - ${selectedItem?.name}`}
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        Report - {selectedItem?.name}
                    </span>
                    {selectedItem?.state &&
                        <div className={`sea-status inline incident ${selectedItem.state}`}>
                            {renderCamelCase(selectedItem.state)}
                        </div>
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            onPdf={exportType === 'pdf' && <IncidentEventReportPdf
                onCompleted={() => setExportType(undefined)}
                selectedItem={selectedItem}
            />}
            setExportType={setExportType}
        >
            <CommonIncidentContent
                showModal={showModal}
                incident={selectedItem}
            />

            <div
                className="view-modal-buttons"
                style={{ marginTop: '20px' }}
            >
                {( // To edit this report, it must be your draft (not state=completed), or you must have full permissions
                    (selectedItem?.addedBy === userId && selectedItem.state === 'draft') ||
                    canEdit('incidentAccidentMedicalRegister') ||
                    superAdmin
                ) &&
                    <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Report</SeaButton>
                }
                {( // To delete this report, it must be your draft, or you must have full permissions
                    (selectedItem?.addedBy === userId && selectedItem.state === 'draft') ||
                    canEdit('incidentAccidentMedicalRegister') ||
                    superAdmin
                ) &&
                    <SeaLinkButton
                        style={{ marginLeft: 50 }}
                        mode="standard-link"
                        onClick={(e) => {
                            deleteIfConfirmed(
                                'incidents',
                                selectedItem.id,
                                (batch: WriteBatch | SplittableBatch) => {
                                    if (selectedItem.state === 'inReview' || selectedItem.state === 'completed') {
                                        // Need to propagate state=deleted to review
                                        batch.set(doc(firestore, 'incidentReviews', selectedItem.id), {
                                            state: 'deleted',
                                            deletedBy: userId,
                                            whenDeleted: Date.now(),
                                            touched: serverTimestamp()
                                        }, { merge: true });
                                        onCollectionUpdated(batch, 'incidents');
                                        onCollectionUpdated(batch, 'incidentReviews');
                                    }
                                    setShowModal(false);
                                },
                                'report',
                                selectedItem?.name,
                                [selectedItem.vesselId]
                            );
                        }}
                    >
                        Delete Report
                    </SeaLinkButton>
                }
            </div>
            
            {(selectedItem && selectedItem.state !== 'draft') && canView('incidentAccidentMedicalRegister') &&
                <>
                    <div className="end-info-line" style={{ marginBottom: '40px' }}></div>
                    <h2>Review{selectedItem?.state === 'inReview' && ' (Incomplete)'}</h2>
                    {selectedItem?.state === 'forReview' &&
                        <SeaGrid>
                            <SeaGridCell>
                                <p>
                                    {(() => {
                                        const whenReport = selectedItem.whenUpdated ?? selectedItem.whenAdded;
                                        const countDays = dayDifferenceBetweenDates(whenReport, Date.now()) - 1;
                                        if (countDays > 1) {
                                            return `This report has been waiting to be reviewed for ${countDays} days.`;
                                        } else if (countDays === 1) {
                                            return 'This report has been waiting to be reviewed since yesterday.';
                                        }
                                        return 'This report is waiting to be reviewed.';
                                    })()}
                                </p>
                            </SeaGridCell>
                        </SeaGrid>
                    }
                    {selectedItem?.state === 'completed' && incidentReview === undefined &&
                        <SeaGrid>
                            <SeaGridCell>
                                <p>
                                    This report was never reviewed as it was created before the reviewing feature was added to Sea Flux in August 2023.
                                </p>
                            </SeaGridCell>
                        </SeaGrid>
                    }
                    {incidentReview &&
                        <IncidentReviewContent
                            showModal={showModal}
                            incident={selectedItem}
                            review={incidentReview}
                        />
                    }
                    {(
                        canEdit('incidentAccidentMedicalRegister') ||
                            (canCreate('incidentAccidentMedicalRegister') && (
                                (selectedItem?.state !== 'completed' && incidentReview?.addedBy === userId) ||
                                incidentReview === undefined
                            )
                        )
                    ) &&
                        <div
                            className="view-modal-buttons"
                            style={{ marginTop: '0px' }}
                        >
                            <SeaButton zone="white" type="submit" onClick={(e) => onReviewItem()}>
                                <SeaIcon icon="edit" slot="start" />
                                {(selectedItem?.state === 'forReview' || (incidentReview === undefined)) ? 'Start Review' : (
                                    (selectedItem?.state === 'inReview') ? 'Continue Review' : 'Edit Review'
                                )}
                            </SeaButton>
                            {canDelete('incidentAccidentMedicalRegister') && incidentReview &&
                                <SeaLinkButton
                                    style={{ marginLeft: 50 }}
                                    mode="standard-link"
                                    onClick={(e) => {
                                        deleteIfConfirmed(
                                            'incidentReviews',
                                            selectedItem.id,
                                            (batch: WriteBatch | SplittableBatch) => {
                                                // Need to change state of underlying report to forReview
                                                batch.set(doc(firestore, 'incidents', selectedItem.id), {
                                                    state: 'forReview',
                                                    touched: serverTimestamp()
                                                }, { merge: true });
                                                onCollectionUpdated(batch, 'incidents');
                                                onCollectionUpdated(batch, 'incidentReviews');
                                                // setShowModal(false);
                                            },
                                            'review',
                                            selectedItem?.name,
                                            [selectedItem.vesselId]
                                        );
                                    }}
                                >
                                    Delete Review
                                </SeaLinkButton>
                            }
                        </div>
                    }
                    <div style={{ height: '20px' }}></div>
                </>
            }
            {showModal &&
                <>
                    <EditIncident
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    <ReviewIncident
                        showModal={showReviewModal}
                        setShowModal={setShowReviewModal}
                        incident={selectedItem}
                        reviewToUpdate={incidentReview}
                        level={2}
                    />
                    
                </>
            }
        </SeaModal>
    );
};

export default ViewIncident;
