import React, { useState, useCallback, useMemo } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { firestore } from '../../../../lib/firebase';
import { writeBatch } from "firebase/firestore";
import { haveCategoriesChanged, makeCategoryCustomDataForEditing, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { OptionType } from '../../../CompanyDocuments/CustomForms/CompleteCustomForm/CompleteCustomForm';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';

interface EditCrewCertificateSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}



const EditCrewCertificateSettings: React.FC<EditCrewCertificateSettingsProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(showModal);
    const userRoles = sharedState.userRoles.use(showModal);
    const [titleIds, setTitleIds] = useState<string[]>();
    const [titleNames, setTitleNames] = useState<string[]>();
    const [customData, setCustomData] = useState<{[key: string]: string[]}[]>();

    const roleOptions = useMemo(() => {
        if (!userRoles) {
            return [];
        }
        const _roles = [] as OptionType[];
        const _userRoles = userRoles.byId;
        for (const [id, role] of Object.entries(_userRoles)) {
            if (role.state === 'active') {
                _roles.push({ id: id, name: role.name });
            }
        }
        return _roles;
    }, [userRoles]);

    const onOpened = () => {
        if (!crewCertificateTitles) {
            console.log('No crew certificate titles');
            return;
        }
        setTitleIds(makeCategoryIdsForEditing(crewCertificateTitles));
        setTitleNames(makeCategoryNamesForEditing(crewCertificateTitles));
        setCustomData(makeCategoryCustomDataForEditing(crewCertificateTitles));
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const batch = writeBatch(firestore);
        const batchTrace = makeBatchTrace(batch, 'crewCertificateTitles', 'update', licenseeId);

        saveCategoryChanges(
            batch,
            batchTrace,
            titleIds,
            titleNames,
            customData,
            crewCertificateTitles,
            'crewCertificateTitles',
            'licenseeId',
            licenseeId
        );

        batchTrace.save(`Update crew certificate titles`);
        batch.commit().then(() => {
            batchTrace.reportSuccess();
        }).catch((error) => {
            batchTrace.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        return haveCategoriesChanged(titleIds, titleNames, crewCertificateTitles);
    }, [titleIds, titleNames, crewCertificateTitles]);

    return (
        <SeaModal
            title={`Crew Certificate Settings`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-wide"
        >
            <form onSubmit={(e) => onSubmit(e)}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInputList
                                name="crewCertificateTitles"
                                label="Crew Certificate Titles"
                                maxWidth="350px"
                                ids={titleIds}
                                setIds={setTitleIds}
                                values={titleNames}
                                setValues={setTitleNames}
                                addNewText="Add Certificate Title"
                                confirmDelete={true}
                                customData={customData}
                                setCustomData={setCustomData}
                                customFields={[
                                    {
                                        fieldName: 'compulsoryForRoles',
                                        columnName: 'Mandatory for Roles / Departments',
                                        fieldType: 'string[]',
                                        fieldInputType: 'multiselect',
                                        fieldOptions: roleOptions,
                                        showLabel: true
                                    }
                                ]}

                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{`Save Settings`}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditCrewCertificateSettings;
