import { WriteBatch, collection, doc, onSnapshot, orderBy, query, serverTimestamp, where } from "firebase/firestore";
import { SplittableBatch, firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { getDayOffsetMillis } from "../../lib/datesAndTime";
import { onCollectionUpdated } from "../DataSyncSystem/dataSync";

//
// Loads action log when needed.
// Call logAction to save a new ActionLogEntry.
//

const logActionCodes = {
    Add: 'A',
    Update: 'U',
    Renew: 'N',
    Archive: 'V',
    Unarchive: 'R',
    Delete: 'D',
};
type LogActionType = keyof typeof logActionCodes;

export interface ActionLogEntry {
    id?: string;
    action: string;
    collection: string;
    detail: string;
    docId: string;
    licenseeId: string;
    personnelIds?: string[];
    type?: string | string[];
    userId: string;
    vesselIds: string[] | 'any';
    when: number;
}

export const actionLogConfig: SharedStateConfig<ActionLogEntry[]> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.actionLog.current?.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            const whenFrom = getDayOffsetMillis(-90);

            return onSnapshot(
                query(collection(firestore, 'actionLog'), where('licenseeId', '==', licenseeId), where('when', '>=', whenFrom), orderBy('when', 'desc')),
                (snap) => {
                    done();
                    const all = [] as ActionLogEntry[];
                    snap.docs.forEach((doc) => {
                        all.push({
                            id: doc.id,
                            ...doc.data(),
                        } as ActionLogEntry);
                    });
                    set(all);
                },
                (error) => {
                    done();
                    clear();
                    console.log(`Error getting action log for licensee ${licenseeId}`, error.message, error);
                }
            );
        }
    },
};

export const logAction = (
    batch: WriteBatch | SplittableBatch,
    actionType: LogActionType,
    _collection: string,
    docId: string,
    detail: string,
    vesselIds?: string[] | undefined,
    personnelIds?: string[] | undefined,
    type?: string | undefined
) => {
    const ref =  doc(collection(firestore, 'actionLog'));

    batch.set(
        ref,
        {
            licenseeId: sharedState.licenseeId.current,
            userId: sharedState.userId.current,
            vesselIds: vesselIds ? vesselIds : 'any',
            when: Date.now(),
            collection: _collection,
            docId: docId,
            action: logActionCodes[actionType],
            detail: detail ? detail : '',
            type: type,
            personnelIds: personnelIds,
            touched: serverTimestamp(),
        },
        { merge: true }
    );
    onCollectionUpdated(batch, 'actionLog');

    return ref.id;
};