import { SharedStateConfig } from "../shared-state";



export type RichTextPopoverMenu = {
    isOpen: boolean,
    content?: JSX.Element,
    x?: number,
    y?: number
};


export const richTextPopoverMenuConfig: SharedStateConfig<RichTextPopoverMenu> = {
    isAlwaysActive: true,
    default: {
        isOpen: false
    },
    notes: 'Utility to show rich text popover menus'
};
