import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { canComplete } from '../../../../shared-state/Core/userPermissions';
import { firestore, splittableBatch, deleteValue } from '../../../../lib/firebase';
import { collection, doc, increment, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, deepClone, toFloat, formatValue, formatTextAreaText } from '../../../../lib/util';
import { formatSeaDate, formatSeaDatetime, addInterval, formatShortTimeDurationHrsMinsOnly, hours24ToMillis } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { Job } from '../../../../shared-state/VesselMaintenance/jobs';
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaTime from '../../../../components/SeaTime/SeaTime';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSparePartsAdjustments from '../../../../components/SeaSparePartsAdjustments/SeaSparePartsAdjustments';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';

interface CompleteJobListProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowParentModal: (showModal: boolean) => void,
    selectedItem: Job,
    historyItemToUpdate?: MaintenanceTaskCompleted,
    level?: number
}

const CompleteJobList: React.FC<CompleteJobListProps> = ({ showModal, setShowModal, setShowParentModal, selectedItem, historyItemToUpdate, level }) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const safetyCheckItems = sharedState.safetyCheckItems.use(showModal);
    const spareParts = sharedState.spareParts.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [sparePartsStock, setSparePartsStock] = useState<{ [key: string]: { used?: number, added?: number } }>({});
    const licenseeSettings = sharedState.licenseeSettings.use();

    const initialSparePartsStock: { [key: string]: { used?: number, added?: number } } = useMemo(() => {
        if (historyItemToUpdate?.spareParts) {
            return deepClone(historyItemToUpdate.spareParts);
        } else {
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyItemToUpdate?.id, showModal])

    const equipmentSpareParts = useMemo(() => {
        return spareParts?.all?.filter((part) => {
            return selectedItem?.equipmentId && part.equipmentIds?.includes(selectedItem?.equipmentId);
        });
    }, [spareParts, selectedItem?.equipmentId]);

    const initialValues = useMemo(() => {
        if (historyItemToUpdate) {
            return {
                whenCompleted: historyItemToUpdate.whenCompleted ? formatSeaDatetime(historyItemToUpdate.whenCompleted) : '',
                notes: historyItemToUpdate.notes ? '' + historyItemToUpdate.notes : '',
                actualTime: historyItemToUpdate.actualTime ? formatShortTimeDurationHrsMinsOnly(historyItemToUpdate.actualTime) : ''
            };
        } else {
            return {
                whenCompleted: formatSeaDatetime(),
                notes: '',
                actualTime: '',
            };
        }
    }, [historyItemToUpdate]);

    const toggleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setSparePartsStock(historyItemToUpdate?.spareParts ? historyItemToUpdate.spareParts : {});
        setValues(initialValues);
        setFiles(makeSeaFiles(historyItemToUpdate?.files));
    };

    const onClosed = () => {
        setSparePartsStock({});
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            whenCompleted: Yup.date().required().min(...notTooOld),
            notes: Yup.string().min(1).max(5000),
        }), onSubmit: (data) => {
            uploadFiles(files).then(() => {
                if (!selectedItem.id) {
                    console.log('No job id');
                    return;
                }
                const _files = [...makeSeaFiles(selectedItem.files), ...files];

                const batch = splittableBatch(firestore, 20 - 1); // -1 because safetyCheckCompleted requires an extra security access call
                const batchTrace = makeBatchTrace(batch, 'maintenanceTasksCompleted', historyItemToUpdate ? 'update' : 'create');

                if (historyItemToUpdate) {
                    batchTrace.exampleDocId = historyItemToUpdate.id;
                    batch.set(
                        doc(firestore, 'maintenanceTasksCompleted', historyItemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            notes: data.notes ? data.notes : deleteValue,
                            whenCompleted: toMillis(data.whenCompleted),
                            spareParts: sparePartsStock ? sparePartsStock : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                            actualTime: hours24ToMillis(data.actualTime),
                        },
                        { merge: true }
                    );
                    saveFileRefs(batch, files, 'maintenanceTasksCompleted', historyItemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'maintenanceTasksCompleted',
                        historyItemToUpdate.id,
                        selectedItem.task,
                        [selectedItem.vesselId],
                        undefined,
                        'job'
                    );
                } else {
                    const newCompletedMaintenanceTaskRef = doc(collection(firestore, 'maintenanceTasksCompleted'));
                    batchTrace.exampleDocId = newCompletedMaintenanceTaskRef.id;
                    const selectedJobRef = doc(firestore, 'jobs', selectedItem.id);
                    batch.set(newCompletedMaintenanceTaskRef, {
                        completedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        vesselId: vesselId,
                        jobId: selectedItem.id,
                        equipmentId: selectedItem.equipmentId,
                        location: selectedItem.location,
                        isCritical: selectedItem.isCritical ? selectedItem.isCritical : undefined,
                        task: selectedItem.task,
                        description: selectedItem.description,
                        notes: data.notes ? data.notes : undefined,
                        whenCompleted: toMillis(data.whenCompleted),
                        files: _files.length ? seaFilesToValue(_files) : undefined,
                        spareParts: sparePartsStock,
                        state: 'completed',
                        type: 'job',
                        jobNum: selectedItem.jobNum,
                        touched: serverTimestamp(),
                        actualTime: hours24ToMillis(data.actualTime),
                    });
                    batch.set(selectedJobRef, {
                        whenCompleted: toMillis(data.whenCompleted),
                        completedBy: userId,
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        state: 'completed',
                        touched: serverTimestamp()
                    }, { merge: true });

                    saveFileRefs(batch, _files, 'maintenanceTasksCompleted', newCompletedMaintenanceTaskRef.id);

                    logAction(
                        batch,
                        'Add',
                        'maintenanceTasksCompleted',
                        newCompletedMaintenanceTaskRef.id,
                        selectedItem.task,
                        [selectedItem.vesselId],
                        undefined,
                        'job'
                    );
                }

                // Update spare parts stock
                for (const [sparePartId, adjustment] of Object.entries(sparePartsStock)) {
                    const initialAdjustment = initialSparePartsStock[sparePartId] || { used: 0, added: 0 };
                    let added = toFloat(adjustment.added) - toFloat(initialAdjustment.added);
                    let used = toFloat(adjustment.used) - toFloat(initialAdjustment.used);
                    let adjustmentFloat = added - used;
                    if (adjustment) {
                        batch.set(
                            doc(firestore, 'spareParts', sparePartId),
                            {
                                quantity: increment(adjustmentFloat),
                                updatedVia: 'completeJobList',
                                whenUpdated: batchTrace.whenAction,
                                updatedBy: userId,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );
                        onCollectionUpdated(batch, 'spareParts');
                    }
                }

                if (
                    selectedItem.addedFromSafetyCheckCompletedId &&
                    canComplete('safetyEquipmentChecks') &&
                    safetyCheckItems?.all
                ) {
                    // As this job was for a faulted safetyCheckItem, we need to complete that task
                    // First, we need to search for the relevant safetyCheckItem
                    for (let i = 0; i < safetyCheckItems.all.length; i++) {
                        if (safetyCheckItems.all[i].faultJobId && safetyCheckItems.all[i].faultJobId === selectedItem.id) {
                            const safetyCheckItem = safetyCheckItems.all[i];
                            if (!safetyCheckItem.id) {
                                console.error('safetyCheckItem.id is undefined');
                                return;
                            }
                            batch.set(
                                doc(firestore, 'safetyCheckItems', safetyCheckItem.id),
                                {
                                    whenLastChecked: toMillis(data.whenCompleted),
                                    whenDue: addInterval(toMillis(formatSeaDate(data.whenCompleted)) as number, safetyCheckItem.interval),
                                    hasFault: false,
                                    touched: serverTimestamp()
                                },
                                { merge: true }
                            );
                            const newRef = doc(collection(firestore, 'safetyCheckCompleted'));
                            batch.set(newRef, {
                                safetyCheckId: safetyCheckItem.id,
                                completedBy: userId,
                                vesselId: safetyCheckItem.vesselId,
                                whenAdded: batchTrace.whenAction,
                                addedBy: userId,
                                notes: data.notes ? data.notes : undefined,
                                whenCompleted: toMillis(data.whenCompleted),
                                shouldReportFault: false,
                                //files: undefined,
                                state: 'active',
                                touched: serverTimestamp(),
                                actualTime: hours24ToMillis(data.actualTime)
                            });

                            onCollectionUpdated(batch, 'safetyCheckItems');
                            onCollectionUpdated(batch, 'safetyCheckCompleted');
                            break;
                        }
                    }
                }

                batchTrace.data = {
                    files: seaFilesToValue(_files),
                    data,
                    selectedItem,
                };
                batchTrace.save(`${historyItemToUpdate ? 'Update' : 'Complete'} job ${selectedItem.task}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                showToast(`${historyItemToUpdate ? 'Completed Job has been updated' : 'Job has now been marked completed'}`);
                setShowModal(false);

                setTimeout(() => {
                    setShowParentModal(false);
                }, 250);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload complete job files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        selectedItem,
                    });
                }
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues)
            || files.length > 0;
    }, [initialValues, values, files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={historyItemToUpdate ? 'Edit Completed Job' : 'Complete Job'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size={(equipmentSpareParts?.length) ? 'semi-wide' : 'thin'}
            level={level}
            onClosed={onClosed}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        {selectedItem?.task ? <IonCol size="12">
                            <SeaGridCell label="Task" w="50">
                                {formatValue(formatTextAreaText(selectedItem?.task))}
                            </SeaGridCell>
                        </IonCol> : null}
                        <IonCol size={licenseeSettings?.hasMaintenanceTaskTime? '6': '12'}>
                            <SeaDatetime
                                label="When Completed"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>
                        {
                            licenseeSettings?.hasMaintenanceTaskTime &&
                            <IonCol size="6">
                                <SeaTime
                                    label="Actual Time"
                                    help={{ text: 'This is the actual time of how long this task took to perform.' }}
                                    name="actualTime"
                                    value={values.actualTime}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.actualTime ? errors.actualTime : ''}
                                />
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                placeholder="Add notes..."
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={150}
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="maintenanceTasksCompleted"
                                field="files"
                            />
                        </IonCol>
                        {equipmentSpareParts?.length ?
                            <IonCol size="12" className={`expander ${showMoreInfo ? 'reduce' : ''}`}>
                                <div className="more-info-dropdown no-select" onClick={(e) => toggleShowMoreInfo()}>Spare Parts Used <SeaIcon icon={showMoreInfo ? 'moveUp' : 'moveDown'} /></div>
                            </IonCol>
                            : null}
                        {showMoreInfo ? <IonCol size="12">
                            <SeaSparePartsAdjustments
                                sparePartsStock={sparePartsStock}
                                setSparePartsStock={setSparePartsStock}
                                initialSparePartsStock={initialSparePartsStock}
                                spareParts={equipmentSpareParts}
                            />
                        </IonCol> : null}
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">
                        {historyItemToUpdate ? 'Save Changes' : 'Submit'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default CompleteJobList;