import React, { useEffect, useState } from 'react';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { formatValue } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderCategoryNames } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import reporting, { colours } from '../../../../lib/reporting';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalBarGraph, { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import InjuryLocationsReportPdf from '../../../../exports/Reporting/HealthSafety/InjuryLocationsReportPdf';
import './ViewInjuryLocationsReport.css';

interface ViewInjuryLocationsReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
    yLabelWidth?: number,
    multiColoured?: boolean,
}

const ViewInjuryLocationsReport: React.FC<ViewInjuryLocationsReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredIncidents,
    yLabelWidth,
    multiColoured,
}) => {
    const injuryLocations = sharedState.injuryLocations.use(showModal);
    const {selectedVesselId} = useReportSettings();
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (showModal) {
            logModalView('IncidentStatusesReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={exportType === 'pdf' &&
                <InjuryLocationsReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredIncidents={filteredIncidents}
                    yLabelWidth={yLabelWidth}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
            />

            <SeaHorizontalBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                sortData={true}
                units="People Injured"
                yLabelWidth={yLabelWidth}
                colourPalette={multiColoured ? reporting.colours.default : [colours.lavender]}
                hashNamesForColours={multiColoured ? true : false}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="injury locations"
                    isLoading={!filteredIncidents}
                    hasNoData={filteredIncidents?.length === 0}
                    isUsingFilter={true}
                />
                
                <div className={`incidents by-injury-location ${(filteredIncidents && filteredIncidents.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Name</div>
                        <div>Injury Locations</div>
                        <div>Vessel</div>
                    </div>
                    {mapArrayWithLimit(filteredIncidents, (incident: Incident) => {
                        const injuryIds: string[] = Array.from(new Set(incident.injuries?.flatMap((injury) => injury.locationIds || [])));
                        return (
                            <div
                                key={incident.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    {formatDate(incident.whenEvent)}
                                </div>
                                <div>
                                    {formatValue(incident.name)}
                                </div>
                                <div>
                                    {formatValue(renderCategoryNames(injuryIds, injuryLocations))}
                                </div>
                                <div>
                                    {renderVesselName(incident.vesselId)}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
               
            </div>
        </SeaModal>
    );
};

export default ViewInjuryLocationsReport;
