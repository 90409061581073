import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';

type FetchedItem = {
    id: string;
    state: string;
    [key: string]: any;
} | null;

// This was necessary fr linking as we cannot rely on archived/deleted items being available
export const useFetchSingleItem = (collection: string, id: string, abort?: boolean): FetchedItem | undefined => {
    const [item, setItem] = useState<FetchedItem>();

    useEffect(() => {
        setItem(undefined);
        if (collection && id && !abort && collection !== 'external') {
            const docRef = doc(firestore, collection, id);
            getDoc(docRef)
                .then((docSnap) => {
                    if (docSnap.exists()) {
                        setItem({
                            id: docSnap.id,
                            state: docSnap.data().state,
                            ...docSnap.data()
                        });
                    } else {
                        setItem(null);
                    }
                })
                .catch((error) => {
                    if (error.code === 'permission-denied') {
                        console.log(`User doesn't have permission to read document in ${collection} with id ${id}`);
                        return null;
                    } else {
                        console.error(`Error fetching document from ${collection} with id ${id}:`, error);
                        return null;
                    }
                });
        }
    }, [abort, collection, id]);

    return item;
};

export const fetchSingleItem = (collection: string, id: string): Promise<FetchedItem> => {
    const docRef = doc(firestore, collection, id);
    return getDoc(docRef)
        .then((docSnap) => {
            if (docSnap.exists()) {
                return {
                    id: docSnap.id,
                    state: docSnap.data().state,
                    ...docSnap.data()
                };
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error(`Error fetching document from ${collection} with id ${id}:`, error);
            return null;
        });
};