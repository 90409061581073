import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, subtractInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { getDefaultCategoryId, makeCategoryId } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { CompanyDocument } from '../../../../shared-state/CompanyDocuments/companyDocuments';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, seaFilesToValue } from '../../../../lib/files';
import { LinkType } from '../../../../shared-state/Core/links';
import { handleLinkUpdates } from '../../../../lib/links';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaLinkMultiList from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';
import SeaEditDocumentation, { SeaDocumentation, confirmAnyDocumentationLoss, getFilesToUploadForSeaDocumentation, hasSeaDocumentationChanged, makeSeaDocumentation, seaDocumentationToFilesValue, seaDocumentationToSfdocValue } from '../../../../components/SeaEditDocumentation/SeaEditDocumentation';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';

interface EditCompanyDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: CompanyDocument,
}

const EditCompanyDocument: React.FC<EditCompanyDocumentProps> = ({showModal, setShowModal, itemToUpdate, level = 1}) => {
    const user = sharedState.user.use(showModal)!;
    const licenseeId = sharedState.licenseeId.use(showModal)!;
    const companyDocumentCategories = sharedState.companyDocumentCategories.use(showModal);
    const [documentation, setDocumentation] = useState<SeaDocumentation>({
        type: 'sfdoc',
        files: [],
        sfdoc: {}
    });
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const existingLinks = useItemLinks(itemToUpdate?.id);
    const [links, setLinks] = useState<LinkType[]>([]);


    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                type: itemToUpdate.type ? ''+itemToUpdate.type : '',
                whenExpires: itemToUpdate.whenExpires ? formatSeaDate(itemToUpdate.whenExpires) : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
                emailReminder: itemToUpdate.emailReminder ? ''+itemToUpdate.emailReminder : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
            };
        } else {
            return {
                title: '',
                type: 'renewable',
                whenExpires: '',
                interval: '',
                emailReminder: '',
                categoryId: getDefaultCategoryId('General', companyDocumentCategories),
            };
        }
    }, [itemToUpdate, companyDocumentCategories]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setLinks(existingLinks ?? []);
        setValues(initialValues);
        setDocumentation(makeSeaDocumentation(itemToUpdate));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            type: Yup.string().max(500).required(),
            whenExpires: Yup.date().when('type', {is: 'renewable', then: (schema) => schema.required().min(...notTooOld)}),
            interval: Yup.string().when('type', {is: 'renewable', then: (schema) => schema.max(4).required()}),
            emailReminder: Yup.string().when('type', {is: 'renewable', then: (schema) => schema.max(200)}),
            categoryId: Yup.string().max(500).required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('companyDocument')) { return; }
            confirmAnyDocumentationLoss(documentation, makeSeaDocumentation(itemToUpdate), confirmAction).then(() => {
                const filesToUpload = getFilesToUploadForSeaDocumentation(documentation);
                uploadFiles(filesToUpload).then(() => {
                    // Process form
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'companyDocuments');
                    let itemId: string;
                    let whenToRemind = undefined;
                    if (data.type === 'renewable' && data.whenExpires && data.emailReminder) {
                        whenToRemind = subtractInterval(data.whenExpires, data.emailReminder);
                    };
                    const filesValue = seaDocumentationToFilesValue(documentation);
                    const sfdocValue = seaDocumentationToSfdocValue(documentation);
                    if (itemToUpdate) {
                        itemId = itemToUpdate.id;
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = itemToUpdate.id;
                        batch.set(
                            doc(firestore, 'companyDocuments', itemToUpdate.id),
                            {
                                updatedBy: user.id,
                                whenUpdated: batchTrace.whenAction,
                                title: data.title,
                                type: data.type,
                                categoryId: makeCategoryId(
                                    data.categoryId,
                                    companyDocumentCategories,
                                    deleteValue,
                                    batch,
                                    'companyDocumentCategories',
                                    'licenseeId',
                                    licenseeId as string
                                ),
                                whenExpires: (data.type === 'renewable' && data.whenExpires) ? toMillis(data.whenExpires) : deleteValue,
                                emailReminder: (data.type === 'renewable' && data.emailReminder) ? data.emailReminder : deleteValue,
                                interval: (data.type === 'renewable' && data.interval) ? data.interval : deleteValue,
                                whenToRemind: whenToRemind ? whenToRemind : deleteValue,
                                files: filesValue ? filesValue : deleteValue,
                                sfdoc: sfdocValue ? sfdocValue : deleteValue,
                                touched: serverTimestamp()
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, filesToUpload, 'companyDocuments', itemToUpdate.id);
                        logAction(
                            batch,
                            'Update',
                            'companyDocuments',
                            itemToUpdate.id,
                            data.title
                        );
                    } else {
                        const newRef = doc(collection(firestore, 'companyDocuments'));
                        itemId = newRef.id;
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = newRef.id;
                        batch.set(newRef, {
                            licenseeId: licenseeId,
                            addedBy: user.id,
                            whenAdded: batchTrace.whenAction,
                            title: data.title,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                companyDocumentCategories,
                                undefined,
                                batch,
                                'companyDocumentCategories',
                                'licenseeId',
                                licenseeId
                            ),
                            type: data.type,
                            whenExpires: (data.type === 'renewable' && data.whenExpires) ? toMillis(data.whenExpires) : undefined,
                            emailReminder: (data.type === 'renewable' && data.emailReminder) ? data.emailReminder : undefined,
                            interval: (data.type === 'renewable' && data.interval) ? data.interval : undefined,
                            whenToRemind: whenToRemind,
                            state: 'active',
                            files: filesValue ? filesValue : undefined,
                            sfdoc: sfdocValue ? sfdocValue : undefined,
                            touched: serverTimestamp()
                        });

                        saveFileRefs(batch, filesToUpload, 'companyDocuments', newRef.id);
                        logAction(
                            batch,
                            'Add',
                            'companyDocuments',
                            newRef.id,
                            data.title
                        );
                    }

                    onCollectionUpdated(batch, 'companyDocuments');

                    handleLinkUpdates(
                        batch,
                        links,
                        existingLinks,
                        itemId,
                        'companyDocuments'
                    )

                    batchTrace.data = {
                        data,
                        documentation,
                        itemToUpdate
                    };
                    batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} company document ${data.title}`);
                    batch.commit().then(() => {
                        batchTrace.reportSuccess();
                    }).catch((error) => {
                        batchTrace.reportError(error.message, error);
                    });

                    setShowModal(false);
                }).catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload company document files`, error.message, error, {
                            filesToUpload: seaFilesToValue(filesToUpload),
                            data,
                            itemToUpdate
                        });
                    }
                });
            }).catch(() => {
                // Ignore cancellation of documentation loss
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasSeaDocumentationChanged(documentation, makeSeaDocumentation(itemToUpdate))
        );
    }, [values, itemToUpdate, initialValues, documentation]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Company Document' : 'Add New Company Document'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
            confirmDismissDirty={{
                title: "Are you sure you want to leave this form without saving first?",
                no: "Cancel",
                yes: "Yes, leave form"
            }}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="Document Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                //disabled={itemToUpdate ? true : false}
                                label="Document Type"
                                name="type"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                            >
                                <IonSelectOption value="renewable">Renewable Document</IonSelectOption>
                                <IonSelectOption value="nonExpiring">Non-Expiring Document</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={companyDocumentCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        {values.type === 'renewable' && 
                            <>
                                <IonCol size="6">
                                    <SeaDate
                                        label="Expiry Date"
                                        name="whenExpires"
                                        value={values.whenExpires}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenExpires ? errors.whenExpires : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelectInterval
                                        label="Renewal Interval"
                                        name="interval"
                                        value={values.interval}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.interval ? errors.interval : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelectEmailReminder
                                        label="Set Email Reminder"
                                        name="emailReminder"
                                        value={values.emailReminder}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.emailReminder ? errors.emailReminder : ''}
                                    />
                                </IonCol>
                            </>
                        }
                        <IonCol size="12">
                            <SeaEditDocumentation
                                label="Documentation"
                                title={values.title}
                                documentation={documentation}
                                setDocumentation={setDocumentation}
                                collection="companyDocuments"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaLinkMultiList
                                selectedCollection='companyDocuments'
                                selectedItemId={itemToUpdate?.id || ''}
                                values={links}
                                setValues={setLinks}
                                linkOptions={['scheduledMaintenanceTasks', 'external']}
                                deleteable
                                confirmDelete
                                level={level+1}
                                showVesselNameInCategory
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Save Changes' : 'Save Document'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditCompanyDocument;
