import React, { useCallback, useMemo } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { pdfStyles, styleItemColumn, limitPdfText } from "../../../lib/pdf";
import { formatValue } from "../../../lib/util";
import { formatDate, formatShortTimeDurationHrsMinsView } from "../../../lib/datesAndTime";
import { renderFullName } from "../../../shared-state/Core/users";
import { sharedState } from "../../../shared-state/shared-state";
import { getImgSrcFromString } from "../../../lib/files";
import { Job, jobPriorities } from "../../../shared-state/VesselMaintenance/jobs";
import SeaPdfGenerator from "../../../components/SeaPdfGenerator/SeaPdfGenerator";
import PdfItemsHeader from "../../../components/PdfItemsHeader/PdfItemsHeader";
import CriticalFlagPDF from "../../../assets/svg/CriticalFlagPDF";

interface JobListPdfProps {
    onCompleted: () => void;
    categoriesFiltered?: {
        [key: string]: Job[];
    };
}

const JobListPdf: React.FC<JobListPdfProps> = ({ onCompleted, categoriesFiltered }) => {
    const vessel = sharedState.vessel.use();
    const licenseeSettings = sharedState.licenseeSettings.use();

    const hasTimeTrackingEnabled = useMemo(() => {
        return licenseeSettings?.hasSafetyCheckTaskTime ?? false;
    }, [licenseeSettings]);

    const title = useMemo(() => {
        return `Job List - ${vessel?.name}`;
    }, [vessel?.name]);

    const MakePDF = useCallback(() => {
        return (
            <Document title={title} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader heading={title} userFullName={renderFullName()} />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(1.6)}>
                            <Text>Task</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Job #</Text>
                        </View>
                        <View style={styleItemColumn(0.8)}>
                            <Text>Assigned</Text>
                        </View>
                        {hasTimeTrackingEnabled && (
                            <View style={styleItemColumn(0.8)}>
                                <Text>Est Time</Text>
                            </View>
                        )}
                        <View style={styleItemColumn(0.8)}>
                            <Text>Job Tags</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date Added</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Due Date</Text>
                        </View>
                        <View style={pdfStyles.criticalColumn}>
                            <Text>Critical</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {Object.keys(jobPriorities).map((priorityId) => {
                        if (categoriesFiltered && categoriesFiltered[priorityId].length > 0) {
                            return (
                                <React.Fragment key={priorityId}>
                                    <View wrap={false} style={pdfStyles.itemsCategory}>
                                        <Text>{jobPriorities[priorityId as keyof typeof jobPriorities]}</Text>
                                    </View>
                                    {categoriesFiltered[priorityId]?.map((job: Job, index: number) => {
                                        const file = job.files && job.files.length > 0 ? job.files[0] : undefined;
                                        const imgSrc = file ? getImgSrcFromString(file, "tiny") : undefined;
                                        return (
                                            <React.Fragment key={job.id}>
                                                {index > 0 && <View style={pdfStyles.itemsRowSpacer}></View>}
                                                <View wrap={false} style={pdfStyles.itemsRow}>
                                                    <View style={pdfStyles.thumbColumn}>{imgSrc && <Image src={imgSrc} style={pdfStyles.thumbImg} />}</View>
                                                    <View style={styleItemColumn(1.6)}>
                                                        <Text wrap={true}>{formatValue(limitPdfText(job.task, 80))}</Text>
                                                    </View>
                                                    <View style={pdfStyles.numColumn}>
                                                        <Text>{formatValue(job.jobNum)}</Text>
                                                    </View>
                                                    <View style={styleItemColumn(0.8)}>
                                                        <Text>{formatValue(job.assignedTo?.name)}</Text>
                                                    </View>
                                                    {hasTimeTrackingEnabled && (
                                                        <View style={styleItemColumn(0.8)}>
                                                            <Text>{formatValue(job.estimatedTime ? formatShortTimeDurationHrsMinsView(job.estimatedTime) : "-")}</Text>
                                                        </View>
                                                    )}
                                                    <View style={styleItemColumn(0.8)}>
                                                        <Text>{formatValue(job.tags && limitPdfText(job.tags.join(", "), 40))}</Text>
                                                    </View>
                                                    <View style={pdfStyles.dateColumn}>
                                                        <Text>{job.whenAdded ? formatDate(job.whenAdded) : "-"}</Text>
                                                    </View>
                                                    <View style={pdfStyles.dateColumn}>
                                                        <Text>{job.whenDue ? formatDate(job.whenDue) : "-"}</Text>
                                                    </View>
                                                    <View style={pdfStyles.criticalColumn}>{job.equipment?.isCritical && <CriticalFlagPDF />}</View>
                                                </View>
                                            </React.Fragment>
                                        );
                                    })}
                                    <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                                </React.Fragment>
                            );
                        }
                        return undefined;
                    })}
                </Page>
            </Document>
        );
    }, [categoriesFiltered, title, hasTimeTrackingEnabled]);

    return <SeaPdfGenerator onCompleted={onCompleted} pdfTitle={title} MakePdf={MakePDF} requestedFrom="jobs" />;
};

export default JobListPdf;
