import { collection, DocumentData, QueryDocumentSnapshot, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { ArchivableDocument, CreateableDocument, SharedStateConfig, SyncableDocument, UpdateableDocument, sharedState } from "../shared-state";

export interface LinkType extends CreateableDocument, UpdateableDocument, ArchivableDocument, SyncableDocument {
    id: string;
    aType: string;
    aId: string;
    aCategoryId?: string;
    bType: string;
    bId: string;
    bCategoryId?: string;
    vesselIds: string[];
}

export type LinksData = {
    byId: {
        [id: string]: LinkType
    }
};

export const linksConfig: SharedStateConfig<LinksData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds', 'licenseeId'],
    countLiveDocs: () => Object.keys(sharedState.links.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (licenseeId) {
            
            return setupArrayQueryListener(
                'links',
                collection(firestore, 'links'),
                [
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active')
                ],
                'vesselIds',
                'array-contains-any',
                ['na', ...(vesselIds || [])], // na is added to include links that are not specific to any vessel
                [],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean // (not needed as there is no sorting)
                ) => { // processDocs
                    done();
                    
                    const byId = {} as {
                        [linkId: string]: LinkType
                    };
                    docs.forEach((doc) => {
                        const link = {
                            id: doc.id,
                            ...doc.data()
                        } as LinkType;
                        byId[link.id] = link;
                    });

                    set({
                        byId
                    });

                }, (error) => {
                    done();
                    console.log(`Failed to get links for vesselIds=${vesselIds}`, error);
                }
            );
        }
    }
};
