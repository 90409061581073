import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from '../../shared-state/Core/userPermissions';
import { formatMonthISO } from '../../lib/datesAndTime';
import { SeaCrewHour } from '../../components/SeaCrewHours/SeaCrewHours';
import { ForecastData } from '../../components/SeaWindyMap/SeaWindyMap';
import { registerSignature } from "../FileSyncSystem/filesToCache";

//
// Loads voyages for current vessel
//

export interface LogbookSettings {
    actions?: string[];
    safetyBriefing?: string;
    destinations?: string[];
    startupChecks?: string[];
    shutdownChecks?: string[];
    customTextFields?: string[];
    customTextFieldsData?: {isRequired?: boolean}[];
    customHourFields?: string[];
    stops?: string[];
    crewHours?: string;
    fuel?: string;
    hideTides?: boolean;
}

export interface EngineHour {
    id: string,
    start: number;
    end: number;
    hours: number;
    initialHours?: number;
    startHours: number;
    endHours: number;
}

export interface VoyageCheck {
    value?: 'pass' | 'fail' | 'N/A';
    check?: string;
    completed: boolean;
    notes?: string;
}

export interface VoyageStop {
    location: string;
    time?: number;
    whenArrived?: string;
    paxOn?: string;
    paxOff?: string;
    whenDeparted?: string;
}

export interface VoyageTrip {
    destination?: string;
    departureTime?: number;
    arrivalTime?: number;
    masterId?: string;
    crewInvolved: string[];
    departureFrom?: string;
    whenDeparted: string;
    paxOn: string;
    destinationTo: string;
    whenArrived: string;
    stops?: VoyageTripStop[];
    customText?: CustomFields[];
  }

  export interface VoyageTripStop {
    location?: string;
    whenArrived?: string;
    paxOff?: string;
    paxOn?: string;
    whenDeparted?: string;
  }

export interface VoyageDay {
    notes?: string;
    customText?: CustomFields[];
    crewHours?: {
        [crewId: string]: SeaCrewHour;
    };
    customHours?: CustomFields[];
    totalHours?: number;
}

export interface CustomFields {
  [key: string]: string | number;
}

export interface TidalData {
  stationName: string;
  tides: Array<{
    stage: string;
    time: string;
    height: number;
  }>;
  when: string;
}

export interface Voyage extends CreateableDocument, UpdateableDocument {
    additionalPassengersOrDetails?: string;
    crewHours?: SeaCrewHour[];
    customHourFields?: CustomFields[];
    customTextFields?: CustomFields[];
    days?: VoyageDay[];
    deletedBy?: string;
    departureFrom?: string;
    destinationTo?: string;
    engineHours: EngineHour[];
    forecast?: ForecastData;
    fuelBunkered?: number;
    fuelBunkeredCost?: number;
    fuelEnd?: number;
    fuelStart?: number;
    fuelExported?: number;
    licenseeId: string;
    logbookSettings?: LogbookSettings;
    masterIds: string[];
    name?: string;
    operation?: string;
    pax?: number;
    personnelInvolved?: string[];
    safetyBriefCompleted?: boolean;
    shutdownChecks?: VoyageCheck[];
    shutdownNotes?: string;
    signature?: string;
    skipper?: string;
    startupChecks: VoyageCheck[];
    startupNotes?: string;
    state: string;
    stops: VoyageStop[];
    stopsOrRouteDetails?: string;
    totalHours?: number;
    tripType: string;
    trips?: VoyageTrip[];
    vesselId: string;
    voyageNotes?: string;
    whenArrived?: number;
    whenDeleted?: number;
    whenDeparted: number;
    tidalData?: TidalData; // Add the appropriate type for tidalData
    showSafetyBriefing?: boolean;
    weatherForecast?: string;
    weatherObservation?: any; // Add the appropriate type for weatherObservation
    tripReported?: boolean;
    tripReportedTo?: string;
    whenTripReported?: number;
    files?: string[];
}

export type VoyagesData = {
    all: Voyage[],
    byCategory: {
        [category: string]: Voyage[]
    },
    categories: string[],
    active: Voyage | undefined
};

export const voyagesConfig: SharedStateConfig<VoyagesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.voyages.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId && canView('logbook')) {
            return onSnapshot(
                query(
                    collection(firestore, 'voyages'),
                    where('vesselId', '==', vesselId),
                    where('state', 'in', ['started', 'completed']),
                    orderBy('whenDeparted', 'desc')
                ),
                (snap) => {
                    done();
                    let _activeVoyage: Voyage | undefined = undefined;
                    const _voyages = snap.docs.map((doc) => {
                        const voyage = {
                            id: doc.id,
                            ...doc.data()
                        } as Voyage;
                        if (voyage.state === 'started') {
                            _activeVoyage = voyage;
                        }
                        return voyage;
                    }).sort((a, b) => {
                        if (a.state === 'started') return -1;
                        if (b.state === 'started') return 1;
                        if (a.whenArrived === undefined) return -1;
                        if (b.whenArrived === undefined) return 1;
                        return b.whenArrived - a.whenArrived;
                    });

                    const byCategory = {} as {
                        [category: string]: Voyage[]
                    };
                    const categories: string[] = [];
                    _voyages.forEach((item) => {
                        registerSignature(item.signature, 'voyages', item);
                        let whenArrived = item.whenArrived ? formatMonthISO(item.whenArrived) : formatMonthISO(Date.now());
                        if (byCategory[whenArrived] === undefined) {
                            categories.push(whenArrived);
                            byCategory[whenArrived] = [];
                        }
                        byCategory[whenArrived].push(item);
                    })
                    categories.sort().reverse();

                    set({
                        all: _voyages,
                        byCategory,
                        categories,
                        active: _activeVoyage
                    });

                }, (error) => {
                    done();
                    // This should be very rare
                    console.log(`Failed to access voyages for vesselId ${vesselId}, ${error.message}`);
                }
            );
        } else {
            done();
        }   
    },

};
