import React, { useEffect, useMemo, useState } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { formatDate, formatInterval, warnDays } from '../../../../lib/datesAndTime';
import { canEdit, permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from 'firebase/firestore';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { SplittableBatch } from '../../../../lib/firebase';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import { DrillReport } from '../../../../shared-state/VesselSafety/drillReports';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { useItemLinks } from '../../../../shared-state/Core/useItemLinks';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditDrill from '../EditDrill/EditDrill';
import EditDrillReport from '../EditDrillReport/EditDrillReport';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import ViewDrillReport from '../ViewDrillReport/ViewDrillReport';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import './ViewDrill.css';

interface ViewDrillProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem?: Drill;
    level?: number;
    viewOnly?: boolean;
}

const ViewDrill: React.FC<ViewDrillProps> = ({ showModal, setShowModal, selectedItem, level = 1, viewOnly }) => {
    const drillReports = sharedState.drillReports.use(showModal);
    const links = useItemLinks(selectedItem?.id);
    const users = sharedState.users.use(showModal);

    const excludedUsers = useMemo(() => {
        const excludedUsers = selectedItem?.notAssignedTo;

        if (!excludedUsers) return [];

        const result = users?.all.filter((user) => !!user.id && excludedUsers.includes(user.id));

        return result ?? [];
    }, [users, selectedItem]);

    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditDrillModal, setShowEditDrillModal] = useState(false);
    const [showEditDrillReportModal, setShowEditDrillReportModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState<DrillReport>();
    const [showDrillReportModal, setShowDrillReportModal] = useState(false);

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    // Keep selectedReport fresh
    useEffect(() => {
        if (selectedReport?.id && drillReports?.byId[selectedReport.id]) {
            setSelectedReport(drillReports.byId[selectedReport.id]);
        }
    }, [drillReports, selectedReport?.id]);

    const onEditItem = () => {
        setShowEditDrillModal(true);
    };
    const onAddNewDrillReport = () => {
        setShowEditDrillReportModal(true);
    };
    const onViewDrillReport = (report: DrillReport) => {
        setSelectedReport(report);
        setShowDrillReportModal(true);
    };

    if (!selectedItem) {
        return null;
    }

    return (
        <SeaModal title={`Drill - ${selectedItem.name}`} showModal={showModal} setShowModal={setShowModal} size="wide" level={level} viewOnly={viewOnly}>
            <SeaGrid>
                <SeaGridCell label="Interval" w="33">
                    {formatInterval(selectedItem?.interval)}
                </SeaGridCell>
                {selectedItem?.whenDue && (
                    <SeaGridCell label="Next Due" w="33">
                        {formatDate(selectedItem?.whenDue)}
                    </SeaGridCell>
                )}
                <SeaGridCell label="Excluded Users" w="100">
                    {excludedUsers.length > 0 ? excludedUsers.map((user) => renderFullNameForUserId(user.id)).join(', ') : '-'}
                </SeaGridCell>
                {links && <SeaLinks links={links} ids={selectedItem?.id ? [selectedItem?.id] : []} level={level + 1} viewOnly={viewOnly} />}
            </SeaGrid>

            {!viewOnly && (
                <div className="view-modal-buttons">
                    <RequirePermissions role="drills" level={permissionLevels.EDIT}>
                        <SeaButton zone="white" onClick={(e) => onEditItem()}>
                            <SeaIcon icon="edit" slot="start" />
                            Edit Drill
                        </SeaButton>
                        <RequirePermissions role="drills" level={permissionLevels.FULL}>
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    //onDeleteItem(, , true);
                                    deleteIfConfirmed(
                                        'drills',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, 'drills');
                                            setShowModal(false);
                                        },
                                        'drill',
                                        selectedItem?.name,
                                        [selectedItem.vesselId]
                                    );
                                }}
                            >
                                Delete Drill
                            </SeaLinkButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>
            )}

            <div className="end-info-line"></div>

            <div className="columns wrap">
                <h2>Drill History</h2>
                {!viewOnly && (
                    <div className="right">
                        <RequirePermissions role="drills" level={permissionLevels.CREATE}>
                            <SeaButton zone="white" onClick={(e) => onAddNewDrillReport()}>
                                <SeaIcon icon="notes" slot="start" />
                                Create Drill Report
                            </SeaButton>
                        </RequirePermissions>
                    </div>
                )}
            </div>

            <SeaNoData dataName="drill" isHistory={true} isLoading={!drillReports} hasNoData={!drillReports?.byDrillId[selectedItem?.id] || drillReports.byDrillId[selectedItem.id].length === 0} />
            <div className={`drill-history ${(drillReports?.byDrillId[selectedItem?.id]?.length || 0) > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Completed By</div>
                </div>
                {mapArrayWithLimit(drillReports?.byDrillId[selectedItem?.id], (item: DrillReport, index) => {
                    return (
                        <div key={`${item.id}-${index}`} className={`sea-card sea-row-history ${viewOnly ? '' : 'clickable'}`} onClick={(e) => !viewOnly && onViewDrillReport(item)}>
                            <div>{formatDate(item.whenCompleted)}</div>
                            <div>
                                {item?.crewInvolvedIds?.map((crewId, index) => {
                                    if (renderFullNameForUserId(crewId)) {
                                        if (index === 0) {
                                            return renderFullNameForUserId(crewId);
                                        } else {
                                            return `, ${renderFullNameForUserId(crewId)}`;
                                        }
                                    } else {
                                        return '-';
                                    }
                                })}
                                {!item?.crewInvolvedIds?.length && '-'}
                            </div>
                        </div>
                    );
                })}
                {limitTriggerElement}
            </div>

            {showModal && (
                <>
                    {selectedReport && <ViewDrillReport showModal={showDrillReportModal} setShowModal={setShowDrillReportModal} selectedItem={selectedReport} level={level + 1} />}
                    {canEdit('drills') && <EditDrillReport showModal={showEditDrillReportModal} setShowModal={setShowEditDrillReportModal} drillTypeId={selectedItem?.id} level={level + 1} />}
                    {canEdit('drills') && <EditDrill showModal={showEditDrillModal} setShowModal={setShowEditDrillModal} itemToUpdate={selectedItem} level={level + 1} existingLinks={links} />}
                </>
            )}
        </SeaModal>
    );
};

export default ViewDrill;
