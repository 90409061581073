import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { CreateableDocument, sharedState, UpdateableDocument } from '../shared-state';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';

export interface RiskReviewed extends CreateableDocument, UpdateableDocument {
    files: string[];
    notes?: string;
    reviewedBy: string;
    riskId: string;
    state: string;
    vesselIds: string[];
    whenReviewed: number;
}

export type RisksReviewedData = {
    all: RiskReviewed[];
    byRiskId: Record<string, RiskReviewed[]>;
}

export const useRisksReviewedDateRange = (dateRange?: DateRange) => {
    const vesselIds = sharedState.vesselIds.use(dateRange ? 1 : 0);
    const [risksReviewed, setRisksReviewed] = useState<RisksReviewedData>();

    useEffect(() => {
        setRisksReviewed(undefined);
        if (dateRange && vesselIds?.length) {
            return onSnapshot(
                query(
                    collection(firestore, 'risksReviewed'),
                    where('vesselIds', 'array-contains-any', vesselIds),
                    where('state', '==', 'active'),
                    where('whenReviewed', '>=', dateRange.from),
                    where('whenReviewed', '<=', dateRange.to + (24*60*60*1000)),
                    orderBy('whenReviewed', 'desc')
                ),
                (snap) => {
                    const byRiskId: Record<string, RiskReviewed[]> = {};
                    const all = snap.docs.map((doc) => {
                        const riskReviewed = {
                            id: doc.id,
                            ...doc.data()
                        } as RiskReviewed;
                        if (!byRiskId[riskReviewed.riskId]) {
                            byRiskId[riskReviewed.riskId] = [];
                        }
                        byRiskId[riskReviewed.riskId].push(riskReviewed);
                        return riskReviewed;
                    });
                    setRisksReviewed({
                        all,
                        byRiskId
                    });
    
                }, (error) => {
                    console.log(`Failed to access risks reviewed for vesselIds ${vesselIds.join(', ')} `, error);
                }
            );
        }
    }, [dateRange, vesselIds])
    
    return risksReviewed;
};
