import React, { useCallback, useMemo } from 'react';
import { IonCheckbox, IonInput } from '@ionic/react';
import { confirmAction } from '../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { OptionType } from '../../modals/CompanyDocuments/CustomForms/CompleteCustomForm/CompleteCustomForm';
import SeaDraggables from '../SeaDraggables/SeaDraggables';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaButton from '../SeaButton/SeaButton';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaMultiSelect from '../SeaMultiSelect/SeaMultiSelect';
import './SeaInputList.css';

type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';

export type CustomFieldType = {
    fieldName: string;
    columnName: string;
    fieldType: 'boolean' | 'string' | 'number' | 'string[]';
    fieldInputType?: 'checkbox' | 'text' | 'radio' | 'multiselect';
    textInputType?: TextFieldTypes;
    fieldOptions?: OptionType[];
    showLabel?: boolean;
};

interface SeaInputListProps {
    zone?: 'blue' | 'white' | 'grey';
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    type?: 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week';
    name?: string;
    label?: string;
    values?: string[];
    setValues?: (values: string[]) => void;
    ids?: string[];
    setIds?: (ids: string[]) => void;
    customFields?: CustomFieldType[];
    customData?: any[];
    setCustomData?: (customData: any[]) => void;
    maxWidth?: string; // maxWidth of inputs
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    minLength?: number;
    maxLength?: number;
    readonly?: boolean;
    addNewText?: string;
    confirmDelete?: boolean;
    itemName?: string;
    draggable?: boolean;
}

const SeaInputList: React.FC<SeaInputListProps> = ({
    zone,
    inputmode,
    type,
    name,
    label,
    values,
    setValues,
    ids,
    setIds,
    customData,
    setCustomData,
    customFields,
    maxWidth,
    placeholder,
    error,
    disabled,
    minLength,
    maxLength,
    readonly,
    addNewText,
    confirmDelete,
    itemName = 'item',
    draggable = false,
}) => {
    const onInputChanged = useCallback(
        (e: CustomEvent, index: number) => {
            const newValue = e.detail.value;

            // Check if the new value is different from the old value. Used to prevent resetting data when a row is removed/added.
            if (newValue !== values?.[index]) {
                const _values = [...(values as string[])];
                _values[index] = newValue;
                if (setValues) {
                    setValues(_values);
                }
            }
        },
        [setValues, values]
    );

    const onCustomDataChanged = useCallback(
        (e: CustomEvent | string[], index: number, field: string, input: string) => {
            if (['checkbox', 'radio'].includes(input)) {
                const newValue = input === 'checkbox' || input === 'radio' ? (e as CustomEvent).detail.checked : (e as CustomEvent).detail.value;
                if (newValue !== customData?.[index][field]) {
                    const _customData = [...(customData as any[])];
                    _customData[index][field] = newValue;
                    if (setCustomData) {
                        setCustomData(_customData);
                    }
                }
            } else if (e as string[]) {
                const _customData = [...(customData as any[])];

                if (!_customData[index]) {
                    _customData[index] = [];
                }
                _customData[index][field] = [...(e as string[])];
                if (setCustomData) {
                    setCustomData(_customData);
                }
            }
        },
        [customData, setCustomData]
    );

    const removeValue = useCallback(
        (index: number) => {
            if (ids && setIds) {
                let _ids = [...(ids as string[])];
                _ids.splice(index, 1);
                // if (_ids.length == 0) {
                //     _ids = [''];
                // }
                setIds(_ids);
            }
            if (setValues) {
                let _values = [...(values as string[])];
                _values.splice(index, 1);
                // if (_values.length == 0) {
                //     _values = [''];
                // }
                setValues(_values);
            }
            if (customData && setCustomData) {
                let _customData = [...(customData as any[])];
                _customData.splice(index, 1);
                // if (_ids.length == 0) {
                //     _ids = [''];
                // }
                setCustomData(_customData);
            }
        },
        [customData, ids, setCustomData, setIds, setValues, values]
    );

    const onRemoveValue = useCallback(
        (index: number) => {
            if (confirmDelete && values && values[index].trim().length > 0) {
                confirmAction(`Are you sure you want to delete this ${itemName}?`, `Yes, delete ${itemName}`)
                    .then(() => {
                        removeValue(index);
                    })
                    .catch(() => {});
            } else {
                removeValue(index);
            }
        },
        [confirmDelete, values, itemName, removeValue]
    );

    const addNewInput = () => {
        if (setValues) {
            const _values = [...(values as string[]), ''];
            setValues(_values);
        }
        if (ids && setIds) {
            const _ids = [...(ids as string[]), ''];
            setIds(_ids);
        }
        if (customData && setCustomData) {
            const _customData = [...(customData as any[]), {}];
            setCustomData(_customData);
        }
    };

    const onDrop = useCallback(
        (startIndex: number, endIndex: number) => {
            if (startIndex === endIndex) return;

            if (setValues) {
                const newValues = [...(values as string[])];
                const [removed] = newValues.splice(startIndex, 1);
                newValues.splice(endIndex, 0, removed);
                setValues(newValues);
            }

            if (ids && setIds) {
                const newIds = [...ids];
                const [removed] = newIds.splice(startIndex, 1);
                newIds.splice(endIndex, 0, removed);
                setIds(newIds);
            }

            if (customData && setCustomData) {
                const newCustomData = [...customData];
                const [removed] = newCustomData.splice(startIndex, 1);
                newCustomData.splice(endIndex, 0, removed);
                setCustomData(newCustomData);
            }
        },
        [values, setValues, ids, setIds, customData, setCustomData]
    );

    const renderItems = useMemo(
        () =>
            values &&
            values.map((value, index) => (
                <div className="input-list-row" key={`${index}-${ids?.[index]}`}>
                    <div className={`input-list-column ${customFields?.map((field) => field.fieldInputType).join(' ')}`}>
                        <IonInput
                            name={`${index}-${ids?.[index]}`}
                            value={values?.[index]}
                            className={`sea-input ${zone}-zone ${error ? 'has-error' : ''}`}
                            inputmode={inputmode}
                            type={type}
                            placeholder={placeholder}
                            disabled={disabled}
                            minlength={minLength}
                            maxlength={maxLength}
                            readonly={readonly}
                            onIonChange={(e) => onInputChanged(e, index)}
                        />
                    </div>
                    <div className={`input-list-column custom-data ${customFields?.map((field) => field.fieldInputType).join(' ')}`}>
                        {customFields &&
                            customFields.map((customField) => {
                                if (customField.fieldInputType === 'checkbox') {
                                    return (
                                        <div className="sea-checkbox-container beside-input" key={`${customField}`}>
                                            <IonCheckbox
                                                name={`${index}-${ids?.[index]}-${customField}`}
                                                className="sea-checkbox"
                                                value={customData && customData[index] && customData[index][customField.fieldName] ? customData[index][customField.fieldName] : false}
                                                checked={customData && customData[index] && customData[index][customField.fieldName] ? customData[index][customField.fieldName] : false}
                                                onIonChange={(e) => onCustomDataChanged(e, index, customField.fieldName, 'checkbox')}
                                                disabled={disabled}
                                            />
                                        </div>
                                    );
                                }
                                if (customField.fieldInputType === 'multiselect') {
                                    return (
                                        <div className="sea-select-container beside-input multiselect" key={`${customField}`}>
                                            <SeaMultiSelect
                                                modalTitle={customField.columnName}
                                                options={customField.fieldOptions}
                                                values={customFields && customFields.length > 0 ? customData?.[index]?.[customField.fieldName] ?? undefined : undefined}
                                                setValues={(e) => onCustomDataChanged(e, index, customField.fieldName, 'multiselect')}
                                            />
                                        </div>
                                    );
                                }
                                return undefined;
                            })}
                        <div className={`trash ${disabled ? 'disabled' : ''}`} onClick={() => (disabled ? {} : onRemoveValue(index))}>
                            <SeaIcon slot="icon-only" icon="trash" />
                        </div>
                    </div>
                </div>
            )),
        [values, ids, customFields, zone, error, inputmode, type, placeholder, disabled, minLength, maxLength, readonly, onInputChanged, customData, onCustomDataChanged, onRemoveValue]
    );

    return (
        <>
            <div className="input-list-header">
                <div className={`input-list-column ${customFields?.map((field) => field.fieldInputType)}`}>{label && <SeaLabel>{label}</SeaLabel>}</div>
                <div className={`input-list-column custom-data label ${customFields?.map((field) => (field.showLabel ? field.fieldInputType : '')).join(' ')}`}>
                    {customFields &&
                        customFields.map((customField) => {
                            if (customField.showLabel) {
                                return <SeaLabel key={customField.columnName}>{customField.columnName}</SeaLabel>;
                            }
                            return null;
                        })}
                </div>
            </div>
            {draggable && !disabled ? (
                <SeaDraggables type="list" items={values ?? []} onDrop={onDrop}>
                    {renderItems}
                </SeaDraggables>
            ) : (
                renderItems
            )}
            <div className="sea-add-new-button">
                <SeaButton zone="white" shape="circle" onClick={() => addNewInput()} disabled={disabled}>
                    <SeaIcon slot="icon-only" icon="add" />
                </SeaButton>
                <div className={`text ${disabled ? 'disabled' : ''}`} onClick={() => (disabled ? {} : addNewInput())}>
                    {addNewText ? addNewText : 'Add New'}
                </div>
            </div>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

export default SeaInputList;
