import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IonInput } from '@ionic/react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaTime.css';

// Function to pad the start of a string with zeros
const padStart = (value?: string, isYear?: boolean) => {
    if (!value || value === '') return '--';
    if (!isYear) {
        value = value.padStart(2, '0');
    }
    // Remove the leading zero if value is more than 2 characters in length
    if (value.length > (isYear ? 4 : 2)) {
        value = value.slice(1);
    }
    return value;
}

interface SeatimeProps {
    zone?: 'blue' | 'white' | 'grey',
    id?: string, // required for pulse
    name?: string,
    label?: string,
    help?: SeaHelp,
    value?: string,
    error?: string,
    disabled?: boolean,
    readonly?: boolean,
    required?: boolean,
    setToCurrentTime?: () => void,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    onkeyup?: (e: CustomEvent) => void
}

const Seatime = React.forwardRef<HTMLInputElement, SeatimeProps>(({
    id,
    zone,
    name,
    label,
    help,
    value,
    error,
    disabled,
    readonly,
    required,
    setToCurrentTime,
    onchange,
    onblur,
    onfocus,
    onkeyup
}, forwardedRef: any) => {

    // Function to get the value of date, hours, or minutes from a string
    const getValue = useCallback((value: string | undefined, type: 'hours' | 'minutes') => {
        if (!value) return '00';

        const [hours, minutes] = value.split(':');
        const valueMap = {
            'hours': hours,
            'minutes': minutes
        };
        return valueMap[type as 'hours' | 'minutes'] || '00';
    }, []);

    // Define state variables for date, hours, and minutes
    const [hours, setHours] = useState(getValue(value, 'hours'));
    const [minutes, setMinutes] = useState(getValue(value, 'minutes'));

    // Define refs for the IonInput and IonDatetime components
    const ref = useRef<HTMLIonInputElement>(null);
    const _time = useMemo(() => `${padStart(hours)}:${padStart(minutes)}`, [hours, minutes])

    // Define a memoized value for time and newValue
    const newValue = useMemo(() => {
        return _time;
    }, [_time]);

    // Define a state variable for timeError
    const [timeError, setTimeError] = useState<string | null>(null);

    const displayError = useMemo(() => timeError || error, [error, timeError]);

    // Function to open the IonDatetime picker
    // const openDatetime = (e: React.MouseEvent) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     datetimeRef.current?.open();
    // }


    // Function to validate the time format
    const validateTime = (timeString: string) => {
        const [hours, minutes] = timeString.split(':');
        if (hours === '00' && minutes === '00') {
            setTimeError(null);
            return;
        }

        if (Number.isInteger(parseInt(hours)) && Number.isInteger(parseInt(minutes))) {
            setTimeError(null);
        } else {
            setTimeError('Invalid time format (HH:MM)');
        }
    };

    // Function to validate the date format
    // const validateDate = (dateString: string | null) => {
    //     const [year, month, day] = (dateString || '').split('-');
    //     if (year === 'yyyy' && month === 'mm' && day === 'dd') {
    //         setDateError(null);
    //         return;
    //     }
    //     const time = new Date(`${year}-${month}-${day}T00:00Z`);
    //     if (time.getTime() > 0) {
    //         setDateError(null);
    //     }
    //     else {
    //         setDateError('Invalid date format (dd/mm/yyyy)');
    //     }
    // };

    // Function to handle changes in the IonInput and IonDatetime components
    const onIonChange = (e: CustomEvent, type: 'hours' | 'minutes' | 'time') => {
        if (e.detail) {
            let { value } = e.detail;
            if (type === 'time') {
                validateTime(value);
                const [hours, minutes] = value.split(':');
                setHours(padStart(hours));
                setMinutes(padStart(minutes))
            } else if (type === 'hours') {
                padStart(value)
                validateTime(`${value}:${minutes}`);
                setHours(value);
            } else if (type === 'minutes') {
                padStart(value)
                validateTime(`${hours}:${value}`);
                setMinutes(value);
            }
            const ionInputElement = e.target as HTMLIonInputElement;
            ionInputElement.blur();
        }
        if (ref.current) {
            ref.current.focus();
        }
    }

    const onFocus = async (e: CustomEvent) => {
        const ionInputElement = e.target as HTMLIonInputElement;
        const inputElement = await ionInputElement.getInputElement();
        inputElement.select();
        if (onfocus) {
            onfocus(e);
        }
    };

    const onBlur = async (e: CustomEvent, type: 'hours' | 'minutes') => {
        const ionInputElement = e.target as HTMLIonInputElement;
        const inputElement = await ionInputElement.getInputElement();
        if (!inputElement.value) inputElement.value = '--';
        if (type === 'hours') {
            validateTime(`${inputElement.value}:${minutes}`);
            setHours(inputElement.value);
        } else if (type === 'minutes') {
            validateTime(`${hours}:${inputElement.value}`);
            setMinutes(inputElement.value);
        }
        if (onblur) {
            onblur(e);
        }
    }


    // Update state variables when the value prop changes
    React.useEffect(() => {
        setHours(getValue(value, 'hours'));
        setMinutes(getValue(value, 'minutes'));
    }, [getValue, value]);

    // Determine the color based on the zone prop
    const color = useMemo(() => {
        switch (zone) {
            case 'blue':
                return 'light'; // white
            case 'white':
            case 'grey':
                return 'primary'; // blue
        }
    }, [zone]);

    return (
        <>
            {/* Display label if provided */}
            {label && <SeaLabel help={help}>{label}</SeaLabel>}
            <div className={`sea-input-container sea-input ${zone}-zone${displayError ? ' has-error' : ''}${disabled ? ' disabled' : ''}`}>

                <div className={`time-container`}>
                    {/* Hours Input */}
                    <IonInput
                        id={`${id}-hours`}
                        className={`sea-input hours ${zone}-zone${disabled ? ' disabled' : ''}`}
                        color={color}
                        inputmode="numeric"
                        type="text"
                        pattern="\d*"
                        name={`${name}-hours`}
                        value={hours}
                        disabled={disabled}
                        readonly={readonly}
                        required={required}
                        onIonChange={(e) => onIonChange(e, 'hours')}
                        onIonBlur={(e) => onBlur(e, 'hours')}
                        onIonFocus={onFocus}
                        onIonInput={onkeyup}
                    ></IonInput>
                    <span className="separator">hrs :</span>
                    {/* Minutes Input */}
                    <IonInput
                        id={`${id}-minutes`}
                        className={`sea-input minutes ${zone}-zone${disabled ? ' disabled' : ''}`}
                        color={color}
                        inputmode="numeric"
                        type="text"
                        pattern="\d*"
                        name={`${name}-minutes`}
                        value={minutes}
                        disabled={disabled}
                        readonly={readonly}
                        required={required}
                        onIonChange={(e) => onIonChange(e, 'minutes')}
                        onIonBlur={(e) => onBlur(e, 'minutes')}
                        onIonFocus={onFocus}
                        onIonInput={onkeyup}
                    ></IonInput>
                    <span className="separator">mins</span>

                </div>

            </div>
            {/* Hidden input used for the form */}
            <IonInput
                ref={ref}
                id={id}
                style={{ display: 'none' }} // hide this input, but keep it for the form
                className={`sea-input ${zone}-zone${displayError ? ' has-error' : ''}${disabled ? ' disabled' : ''}`}
                color={color}
                inputmode="text"
                type="time"
                name={name}
                value={newValue}
                disabled={disabled}
                readonly={readonly}
                required={required}
                onIonChange={onchange}
                onIonBlur={onblur}
                onIonFocus={onfocus}
                onIonInput={onkeyup}
            ></IonInput>
            <SeaInputError>{displayError}</SeaInputError>
        </>
    );
});

export default Seatime;
