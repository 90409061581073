import { onSnapshot, query, collection, where, orderBy } from 'firebase/firestore';
import { sharedState } from "../shared-state";
import { registerFiles, registerRichText } from '../FileSyncSystem/filesToCache';
import { firestore } from '../../lib/firebase';
import { canView } from '../Core/userPermissions';
import { useEffect, useMemo, useState } from 'react';
import { SOP } from './vesselSOPS';
import { useLicenseeSOPCategories } from './useLicenseeSOPCategories';
import { renderVesselName } from '../Core/vessels';
import { renderCategoryName } from '../../lib/categories';

type LicenseeSOPsData = {
    byId: { [id: string]: SOP },
    byCategoryId: { [id: string]: { name: string, vesselName: string, vesselId: string, items: SOP[] } } 
};


export const useLicenseeSOPs = (priority: number | boolean, vesselIds?: string[]) => {
    const [licenseeSOPs, setLicenseeSOPs] = useState<LicenseeSOPsData>();
    const licenseeId = sharedState.licenseeId.use(priority);
    const licenseeSOPCategories = useLicenseeSOPCategories(priority);
    const _vesselIds = sharedState.vesselIds.use(!vesselIds && priority);

    const selectedVesselIds = useMemo(() => {
        if (vesselIds) {
            return vesselIds;
        }
        return _vesselIds;
    }, [vesselIds, _vesselIds]);

    useEffect(() => {
        setLicenseeSOPs(undefined);
        if (
            selectedVesselIds &&
            licenseeSOPCategories &&
            canView('standardOperatingProcedures')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'SOPs'),
                    where('vesselId', 'in', selectedVesselIds),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    const items = snap.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    } as SOP));

                    // Sort items by vessel name
                    items.sort((a, b) => renderVesselName(a.vesselId).localeCompare(renderVesselName(b.vesselId)));

                    const byId: { [id: string]: SOP } = {};
                    const byCategoryId: { [id: string]: { name: string, vesselName: string, vesselId: string, items: SOP[] } }  = {};

                    items.forEach((sop) => {
                        byId[sop.id] = sop;
                        if (byCategoryId[sop.categoryId] === undefined) {
                            byCategoryId[sop.categoryId] = { name: renderCategoryName(sop.categoryId, licenseeSOPCategories), vesselName: renderVesselName(sop.vesselId), vesselId: sop.vesselId, items: [] };
                        }
                        byCategoryId[sop.categoryId].items.push(sop);
                        registerFiles(sop.files, 'SOPs', sop);
                        registerRichText(sop.sfdoc, 'SOPs');
                    });

                    const sortedByCategoryId = Object.keys(byCategoryId)
                        .sort((a, b) => {
                            const vesselNameA = byCategoryId[a].vesselName;
                            const vesselNameB = byCategoryId[b].vesselName;
                            return vesselNameA.localeCompare(vesselNameB);
                        })
                        .reduce((acc, key) => {
                            acc[key] = byCategoryId[key];
                            return acc;
                        }, {} as { [id: string]: { name: string, vesselName: string, vesselId: string, items: SOP[] } });

                    setLicenseeSOPs({ byId, byCategoryId: sortedByCategoryId });
                },
                (error) => {
                    console.error(`Failed to access SOPs data for licensee ${licenseeId}`, error);
                    setLicenseeSOPs(undefined);
                }
            );
        }
    }, [licenseeId, licenseeSOPCategories, selectedVesselIds, priority]);

    return licenseeSOPs;
};