import React, { useState, useMemo } from 'react';
import { canView } from '../../../../shared-state/Core/userPermissions';
import { UserType } from '../../../../shared-state/Core/user';
import { sharedState } from '../../../../shared-state/shared-state';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';
import UserProfileTab from './UserProfileTab/UserProfileTab';
import UserFormsAndDocumentsTab from './UserFormsAndDocumentsTab/UserFormsAndDocumentsTab';
import UserCertificatesTab from './UserCertificatesTab/UserCertificatesTab';
import UserDrillsTab from './UserDrillsTab/UserDrillsTab';
import UserTrainingTab from './UserTrainingTab/UserTrainingTab';
import LicenseeSettingsTab from './LicenseeSettingsTab/LicenseeSettingsTab';
import SeaTimeTab from './SeaTimeTab/SeaTimeTab';
import EditUserModal from '../EditUserModal/EditUserModal';
import CrewParticularsDetailPdf from '../../../../exports/Crew/CrewParticulars/CrewParticularsDetailPdf';
import './ViewUserModal.css';

interface ViewUserModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedUser?: UserType;
}

const ViewUserModal: React.FC<ViewUserModalProps> = ({ showModal, setShowModal, selectedUser }) => {
    const user = sharedState.user.use(showModal)!;
    const userId = sharedState.userId.use(showModal);
    const userDetails = sharedState.userDetails.use(showModal);
    const [tab, setTab] = useState('profile');
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    const selectedUserDetails = useMemo(() => {
        if (showModal && selectedUser?.id && userDetails?.byId) {
            return userDetails.byId[selectedUser.id];
        }
        return undefined;
    }, [showModal, selectedUser, userDetails]);

    const canViewCertificates = useMemo(() => {
        return selectedUser && (canView('crewCertificates') || selectedUser.id === userId);
    }, [selectedUser, userId]);

    const canViewDrills = useMemo(() => {
        return selectedUser && (canView('drills') || selectedUser.id === userId);
    }, [selectedUser, userId]);

    const canViewTraining = useMemo(() => {
        return selectedUser && (canView('crewTraining') || selectedUser.id === userId);
    }, [selectedUser, userId]);

    const canViewLicenseeSettings = useMemo(() => {
        return user?.isLicensee && selectedUser?.isLicensee;
    }, [selectedUser, user]);

    return (
        <SeaModal
            title={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            onOpened={() => console.log(`Viewing crew ${selectedUser?.id}`)}
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="profile" mode="forms">
                        Profile
                    </SeaTab>
                    <SeaTab tab="docs" mode="forms">
                        Forms / Documents
                    </SeaTab>
                    <SeaTab tab="seaTime" mode="forms">
                        Sea Time
                    </SeaTab>
                    <SeaTab tab="certificates" mode="forms" hide={!canViewCertificates}>
                        Certificates
                    </SeaTab>
                    <SeaTab tab="drills" mode="forms" hide={!canViewDrills}>
                        Drills
                    </SeaTab>
                    <SeaTab tab="training" mode="forms" hide={!canViewTraining}>
                        Training
                    </SeaTab>
                    <SeaTab tab="licenseeSettings" mode="forms" hide={!canViewLicenseeSettings}>
                        Licensee Settings
                    </SeaTab>
                </SeaTabsGroup>
            }
            onPdf={exportType === 'pdf' && <CrewParticularsDetailPdf onCompleted={() => setExportType(undefined)} selectedUser={selectedUser} selectedUserDetails={selectedUserDetails} />}
            setExportType={setExportType}
        >
            {selectedUser && selectedUserDetails && (
                <>
                    <SeaTabContent tab="profile" selectedTab={tab}>
                        <UserProfileTab showModal={showModal} setShowModal={setShowModal} selectedUserDetails={selectedUserDetails} setShowEditUserModal={setShowEditUserModal} selectedUser={selectedUser} />
                    </SeaTabContent>
                    <SeaTabContent tab="docs" selectedTab={tab}>
                        <UserFormsAndDocumentsTab showModal={showModal} selectedUser={selectedUser} />
                    </SeaTabContent>
                    <SeaTabContent tab="seaTime" selectedTab={tab}>
                        <SeaTimeTab showModal={showModal} selectedUser={selectedUser} />
                    </SeaTabContent>
                    {canViewCertificates && (
                        <SeaTabContent tab="certificates" selectedTab={tab}>
                            <UserCertificatesTab showModal={showModal} selectedUser={selectedUser} />
                        </SeaTabContent>
                    )}
                    {canViewDrills && (
                        <SeaTabContent tab="drills" selectedTab={tab}>
                            <UserDrillsTab showModal={showModal} selectedUser={selectedUser} />
                        </SeaTabContent>
                    )}
                    {canViewTraining && (
                        <SeaTabContent tab="training" selectedTab={tab}>
                            <UserTrainingTab showModal={showModal} selectedUser={selectedUser} />
                        </SeaTabContent>
                    )}
                    {canViewLicenseeSettings && (
                        <SeaTabContent tab="licenseeSettings" selectedTab={tab}>
                            <LicenseeSettingsTab showModal={showModal} />
                        </SeaTabContent>
                    )}
                </>
            )}
            {showModal && (
                <>
                    <EditUserModal showModal={showEditUserModal} setShowModal={setShowEditUserModal} userToEdit={selectedUser} userDetailsToEdit={selectedUserDetails} level={2} />
                </>
            )}
        </SeaModal>
    );
};

export default ViewUserModal;
