import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $insertGeneratedNodes } from '@lexical/clipboard';
import { $generateNodesFromDOM } from '@lexical/html';
import { $createParagraphNode, $createTextNode, $getNodeByKey, $getRoot, $getSelection, $isParagraphNode, $isRangeSelection, $isTextNode, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_HIGH, COMMAND_PRIORITY_LOW, createCommand, DEPRECATED_$isGridSelection, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND, LexicalCommand, LexicalNode, NodeKey, NodeMutation, PASTE_COMMAND } from 'lexical';
import { $createPageNode, $isPageNode, PageNode } from '../../nodes/PageNode/PageNode';
import { HistoryState } from '@lexical/react/LexicalHistoryPlugin';

export const INSERT_PAGE_BREAK_COMMAND: LexicalCommand<string | 'end' | undefined> = createCommand('INSERT_PAGE_BREAK_COMMAND');
export const DELETE_PAGE_COMMAND: LexicalCommand<string> = createCommand('DELETE_PAGE_COMMAND');
export const MERGE_PAGES_COMMAND: LexicalCommand<string> = createCommand('MERGE_PAGES');

export const $getEnclosingPage = (_node: LexicalNode) => {
    let node = _node;
    while (
        node &&
        node.getType() !== 'root' &&
        node.getParent() &&
        node.getParent()!.getType() !== 'root'
    ) {
        node = node.getParent()!;
    }
    return node;
};

// Find the the oldest ancestor that isn't a page
export const $getPageLevelAncestor = (_node: LexicalNode) => {
    let node = _node;
    while (
        node &&
        node.getType() !== 'root' &&
        // node.getType() !== 'page' &&
        node.getParent() &&
        node.getParent()!.getType() !== 'root' &&
        node.getParent()!.getType() !== 'page'
    ) {
        node = node.getParent()!;
    }
    return node;
};

// Determines if node is part of an empty page.
// node could be a PageNode, ParagraphNode, or TextNode and still return true
// ...as long the page involved is empty or has completely empty text (including no carriage returns)
export const $isPageEmpty = (node: LexicalNode | null) => {
    if (node) {
        let pageLevelAncestor;
        if ($isPageNode(node)) {
            pageLevelAncestor = node.getFirstChild();
        } else {
            pageLevelAncestor = $getPageLevelAncestor(node);
        }
        if (pageLevelAncestor === null) {
            return true;
        }

        if (
            pageLevelAncestor.getPreviousSibling() === null &&
            pageLevelAncestor.getNextSibling() === null &&
            $isParagraphNode(pageLevelAncestor) && (
                pageLevelAncestor.getFirstChild() === null || (
                    $isTextNode(pageLevelAncestor.getFirstChild()) &&
                    pageLevelAncestor.getFirstChild()?.getTextContentSize() === 0
                )
            )
        ) {
            return true;
        }
        return false;
    }
};


// 
// Merges pageNode with the next page.
// Visually looks equivalent to removing a page break.
//
export const $mergeWithNextPage = (pageNode: LexicalNode | null) => {
    if (pageNode && $isPageNode(pageNode)) {
        const nextPage = pageNode.getNextSibling();
        if (nextPage && $isPageNode(nextPage)) {
            pageNode.append(...nextPage.getChildren());
            nextPage.remove();
        }
    }
};


// Animates a new page in so the user can tell something has happened
const animatePage = (pageKey: string) => {
    // Cute animation
    const duration = 300;
    const startTime = Date.now();
    const interval = setInterval(() => {
        try {
            const element = document.getElementById(`page${pageKey}`);
            if (element) {
                const elapsed = Date.now() - startTime;
                if (elapsed >= duration) {
                    element!.style.opacity = '1';
                    element!.style.top = '0px';
                    clearInterval(interval);
                } else {
                    let t = (elapsed / duration);
                    t = 1 - (1 - t) * (1 - t); // Quad Ease Out
                    element!.style.opacity = ''+t;
                    element!.style.top = ((1 - t) * 32)+'px';
                }
            } else {
                clearInterval(interval);
            }
        } catch (e) {
            console.log(`Error animating in page!`, e);
            clearInterval(interval);
        }
    }, 1);
};


export default function LexPagePlugin({
    historyState
}: {
    historyState?: HistoryState
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                INSERT_PAGE_BREAK_COMMAND,
                (nodeKey: string | undefined) => {
                    let focusNode: (LexicalNode | null) = null;

                    if (nodeKey === undefined) {
                        const selection = $getSelection();
                        if (!$isRangeSelection(selection)) {
                            return false;
                        }
                        focusNode = selection.focus.getNode() as (LexicalNode | null);
                    } else if (nodeKey === 'end') {
                        // Special case: append new page to end of document
                        const lastPage = $getRoot().getLastChild() as PageNode;
                        const newPageNode = $createPageNode();
                        const paragraphNode = $createParagraphNode();
                        paragraphNode.append($createTextNode(''));
                        newPageNode.append(paragraphNode);
                        lastPage.insertAfter(newPageNode);
                        animatePage(newPageNode.getKey());
                    } else {
                        // Get focusNode using a different method!

                        const pageNode = $getNodeByKey(nodeKey, editor.getEditorState());
                        if (pageNode) {
                            focusNode = (pageNode as PageNode).getFirstChild();
                        }
                    }

                    if (focusNode !== null) {
                        const newPageNode = $createPageNode();

                        // Focus on the oldest ancestor that isn't a page
                        focusNode = $getPageLevelAncestor(focusNode); // Find the oldest ancestor that ins't a page

                        const focusPageNode = focusNode?.getParent();

                        if (focusNode && focusPageNode && focusPageNode.getType() === 'page') {
                            if (focusNode.getPreviousSibling() === null) {
                                // We are focused on the first node within a page,
                                // Therefore, let's just insert a page above this page with a blank paragraphNode
                                const paragraphNode = $createParagraphNode();
                                paragraphNode.append($createTextNode(''));
                                newPageNode.append(paragraphNode);
                            } else {
                                // We are focused on a node within the page that isn't at the top.
                                // Therefore, we'll need to split the page into two.

                                // Move all previous siblings into the new pageNode.
                                const previousSiblings = focusNode.getPreviousSiblings();
                                previousSiblings.forEach((node, index) => {
                                    node.remove();
                                    newPageNode.append(node);
                                });
                            }
                            // Insert our new page above the current page being focused on
                            focusPageNode.insertBefore(newPageNode);
                            animatePage(newPageNode.getKey());
                        }
                    }

                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerCommand(
                MERGE_PAGES_COMMAND,
                (nodeKey: string) => {
                    const pageNode = $getNodeByKey(nodeKey, editor.getEditorState()) as PageNode;
                    const previousPageNode = pageNode.getPreviousSibling() as PageNode;
                    if (previousPageNode) {
                        // Move all children from current page into the previous page
                        pageNode.getChildren().forEach((node: LexicalNode) => {
                            node.remove();
                            previousPageNode.append(node);
                        });
                        // Delete current page
                        pageNode.remove();
                    }
                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerCommand(
                DELETE_PAGE_COMMAND,
                (nodeKey: string) => {
                    const pageNode = $getNodeByKey(nodeKey, editor.getEditorState());
                    if (pageNode) {
                        if (pageNode.getPreviousSibling() === null && pageNode.getNextSibling() == null) {
                            // This is the last page, therefore just delete all contents instead
                            (pageNode as PageNode).getChildren().forEach((childNode: LexicalNode) => {
                                childNode.remove();
                            });
                            // We want a paragraph node in our now empty page
                            const paragraphNode = $createParagraphNode();
                            paragraphNode.append($createTextNode(''));
                            pageNode.append(paragraphNode);
                        } else {
                            pageNode.remove();
                        }
                    }

                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerMutationListener(
                PageNode,
                (mutatedNodes, { updateTags, dirtyLeaves, prevEditorState }) => {
                    let hasBeenCorrected = false;
                    mutatedNodes.forEach((mutation: NodeMutation, nodeKey: NodeKey) => {
                        if (mutation === 'created') {
                            const pageNode = $getNodeByKey(nodeKey, editor.getEditorState());
                            if (pageNode && pageNode.__parent !== 'root') {
                                hasBeenCorrected = true;

                                editor.update(() => {
                                    const pageLevelAncestor = $getPageLevelAncestor(pageNode);
                                    const enclosingPage = pageLevelAncestor.getParent();
                                    if (pageLevelAncestor.getPreviousSibling() === null) {
                                        // Insert pageNode before containing page
                                        pageLevelAncestor.getParent()?.insertBefore(pageNode);
                                    } else if (pageLevelAncestor.getNextSibling() === null) {
                                        // Insert pageNode after containing page
                                        pageLevelAncestor.getParent()?.insertAfter(pageNode);
                                    } else {
                                        // Move previous siblings into a fresh page inserted before containing page
                                        const newPageNode = $createPageNode();
                                        pageLevelAncestor.getParent()?.insertBefore(newPageNode);
                                        newPageNode.append(...pageLevelAncestor.getPreviousSiblings());
                                        newPageNode.insertAfter(pageNode);
                                    }
                                    if (enclosingPage && enclosingPage.isEmpty()) {
                                        // We have left an empty node behind...
                                        enclosingPage.remove();
                                    }
                                });
                            }
                        }
                    });
                    if (hasBeenCorrected) {
                        setTimeout(() => {
                            historyState?.undoStack.pop(); // Get rid of the last HistoryStateEntry as it represents an incorrect use of PageNodes
                        });
                    }
                },
                // { skipInitialization: true }
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                (event) => {
                    const selection = $getSelection();
                    if (!$isRangeSelection(selection)) {
                        return false;
                    }
                    const focusNode = selection.focus.getNode() as (LexicalNode | null);
                    if (!focusNode) {
                        return false;
                    }

                    if ($isPageEmpty(focusNode)) {
                        editor.update(() => {
                            const enclosingPage = $getEnclosingPage(focusNode);
                            enclosingPage.remove();
                        });
                    } else {
                        if (
                            (
                                $isTextNode(focusNode) &&
                                selection?.focus?.offset === focusNode.getTextContentSize()
                            ) || (
                                $isParagraphNode(focusNode) &&
                                focusNode.getChildrenSize() === 0
                            )
                        ) {
                            let node = focusNode as (LexicalNode | null)
                            let isTheLastNode = true;
                            while (node && !$isPageNode(node)) {
                                if (node.getNextSibling() !== null) {
                                    isTheLastNode = false;
                                    break;
                                }
                                node = node.getParent();
                            }
                            if (isTheLastNode) {
                                editor.update(() => {
                                    $mergeWithNextPage($getEnclosingPage(focusNode));
                                });
                            }
                        }
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                (event) => {
                    const selection = $getSelection();
                    if (!$isRangeSelection(selection)) {
                        return false;
                    }
                    const focusNode = selection.focus.getNode() as (LexicalNode | null);
                    if (!focusNode) {
                        return false;
                    }

                    if ($isPageEmpty(focusNode)) {
                        editor.update(() => {
                            const enclosingPage = $getEnclosingPage(focusNode);
                            enclosingPage.remove();
                        });
                    } else {
                        if (
                            (
                                $isTextNode(focusNode) &&
                                selection?.focus?.offset === 0
                            ) || (
                                $isParagraphNode(focusNode) &&
                                focusNode.getChildrenSize() === 0
                            )
                        ) {
                            let node = focusNode as (LexicalNode | null)
                            let isTheFirstNode = true;
                            while (node && !$isPageNode(node)) {
                                if (node.getPreviousSibling() !== null) {
                                    isTheFirstNode = false;
                                    break;
                                }
                                node = node.getParent();
                            }
                            if (isTheFirstNode) {
                                const enclosingPage = $getEnclosingPage(focusNode);
                                const previousPage = enclosingPage?.getPreviousSibling();
                                editor.update(() => {
                                    $mergeWithNextPage(previousPage);
                                });
                            }
                        }
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                PASTE_COMMAND,
                (event, editor) => {
                    const selection = $getSelection();
                    const clipboardData = (event instanceof InputEvent || event instanceof KeyboardEvent) ? null : event.clipboardData;
                    if (clipboardData != null && ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))) {
                        const lexicalString = clipboardData.getData('application/x-lexical-editor');
                        if (lexicalString) {
                            // Clipboard has lexical content, therefore let default PASTE_COMMAND get processed
                            //console.log(`PASTE_COMMAND lexicalString`, lexicalString);
                            return false; // Not handled... (other listeners can also respond to this command)
                        }

                        const htmlString = clipboardData.getData('text/html');
                        if (htmlString) {
                            // Clipboard has html content but no lexical content.
                            // Therefore, it's probably being pasted from an external source.
                            // Let's handle it here so we can manipulate the nodes.
                            try {
                                const parser = new DOMParser();
                                const dom = parser.parseFromString(htmlString, 'text/html');
                                const nodes = $generateNodesFromDOM(editor, dom);
                                // console.log(`Generated nodes from HTML`, nodes);

                                const newNodes = [] as LexicalNode[];
                                let currentPage: PageNode;
                                nodes.forEach((node, index) => {
                                    // console.log(`nodes[${index}] ${node.getKey()} ${node.getType()}`, node);
                                    // console.log(`nodes[${index}] ${node.getKey()} ${node.getType()} children`, node.getChildren());
                                    if (node.getType() === 'sea-section' && node.getTag() === 'h1') {
                                        currentPage = $createPageNode();
                                        newNodes.push(currentPage);
                                    }
                                    if (currentPage) {
                                        currentPage.append(node);
                                    } else {
                                        newNodes.push(node);
                                    }
                                });

                                editor.update(() => {
                                    // console.log(`PASTE_COMMAND nodes ready to paste`, newNodes);
                                    $insertGeneratedNodes(editor, newNodes, selection);
                                });

                                return true; // Don't allow the inbuilt PAGE_COMMAND listener to run

                            } catch (e) {
                                console.error(e);
                            } // Fail silently.
                        }
                        //console.log(`PASTE_COMMAND htmlString`, htmlString);

                        // const text = clipboardData.getData('text/plain') || clipboardData.getData('text/uri-list');
                        // console.log(`PASTE_COMMAND text`, text);
                        // We don't care about plain text - let the default PASTE_COMMAND listenener process it
                        return false; // Not handled... (other listeners can also respond to this command)
                    }

                    return false; // Not handled... (other listeners can also respond to this command)
                },
                COMMAND_PRIORITY_HIGH
            )
        );

    }, [editor, historyState]);

    return null;
}

/*
function HorizontalRulePlugin() {
    const [editor] = LexicalComposerContext.useLexicalComposerContext();
    react.useEffect(() => {
        return editor.registerCommand(LexicalHorizontalRuleNode.INSERT_HORIZONTAL_RULE_COMMAND, type => {
            const selection = lexical.$getSelection();
      
            if (!lexical.$isRangeSelection(selection)) {
                return false;
            }
      
            const focusNode = selection.focus.getNode();
      
            if (focusNode !== null) {
                const horizontalRuleNode = LexicalHorizontalRuleNode.$createHorizontalRuleNode();
                utils.$insertNodeToNearestRoot(horizontalRuleNode);
            }
      
            return true;
        }, lexical.COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}
  
exports.HorizontalRulePlugin = HorizontalRulePlugin;
*/