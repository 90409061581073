import { useMemo, useState } from "react";
import { CsvColumn, CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvString } from "../../lib/csv";
import { sharedState } from "../../shared-state/shared-state";
import { CustomFormCompleted, useCustomFormsCompleted } from "../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted";
import { formatDate, formatDateLonger, formatDatetime, formatDatetimeLonger } from "../../lib/datesAndTime";
import { renderVesselsList } from "../../shared-state/Core/vessels";
import { makeFormElements } from "../../lib/customForms";
import { renderFullNameForUserId } from "../../shared-state/Core/users";
import { renderCamelCase } from "../../lib/util";
import { DateRange } from "../../components/SeaDateRange/SeaDateRange";
import { CustomFormVersion } from "../../shared-state/CompanyDocuments/CustomForms/customFormVersions";
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';
import SeaExportFilterModal from "../../components/SeaExporter/SeaExportFilterModal";

type CustomFormCsvCsvProps = {
    onCompleted: () => void,
    selectedItemId: string,
    level?: number
}

const CustomFormCsv = ({onCompleted, selectedItemId, level = 1}: CustomFormCsvCsvProps) => {
    const customForms = sharedState.customForms.use();
    const customFormVersions = sharedState.customFormVersions.use();
    const [dateRange, setDateRange] = useState<DateRange>();
    const customFormsCompleted = useCustomFormsCompleted(selectedItemId, dateRange);

    const customForm = useMemo(() => {
        return customForms?.byId[selectedItemId];
    }, [selectedItemId, customForms?.byId]);

    const title = useMemo(() => {
        if (!customForm) return 'Custom-Form';
        return 'Custom-Form: ' + customForm.title;
    }, [customForm]);

    const orderedVersions = useMemo(() => {
        const versions = customFormVersions?.byFormIdAndVersion[selectedItemId];
        if (!versions || !customFormsCompleted) return undefined;
        
        return Object.keys(versions)
            .filter(key => customFormsCompleted?.byVersion[Number(key)]) 
            .sort((a, b) => Number(b) - Number(a))
            .map(key => versions[Number(key)]);
    }, [customFormVersions?.byFormIdAndVersion, customFormsCompleted, selectedItemId]);

    const formElementColumns = useMemo(() => {
        if (!orderedVersions || !customForm) return [];
        const columns: CsvColumn<CustomFormCompleted>[] = [];
        const columnLabels: Record<string, number> = {};
        const columnOrder: Record<string | number, (string | number)[]> = {};

        for (const version of orderedVersions) {
            const formElements = makeFormElements(version, customForm);
            let index = 0;
            for (const element of formElements) {
                let n = element.n || index;
                if (element.id === 'checks') {
                    let _columns = element.options?.map((option, i) => ({
                        id: element.id + '_' + n + '_' + (option ?? 'Unlabelled'),
                        name: option ?? 'Unlabelled',
                        value: (form: CustomFormCompleted) => form.data['e' + n]?.[i]
                    }));
                    if (!_columns) continue;

                    // Check if the column already exists using the id
                    for (const newColumn of _columns) {
                        if (columnOrder[newColumn.id]) continue;
                        columnOrder[newColumn.id] = [newColumn.name];
                        const existingIndex = columns.length - 1 - columns.slice().reverse().findIndex(col => col.id?.startsWith(element.id + '_' + n + '_'));
                        if (existingIndex !== -1) {
                            columns.splice(existingIndex + 1, 0, newColumn);
                        } else {
                            columns.push(newColumn);
                        }
                    };
                    continue;
                }
                let id = element.id + '_' + (element.n || index);
                if (['line', 'spacer', 'text', 'heading'].includes(element.id) || columnOrder[id]) {
                    continue;
                }
                if (!columnOrder[id]) {
                    columnOrder[id] = [];
                }
                index++;
                
                if (columnOrder[id].includes(version.version)) {
                    continue;
                }
                columnOrder[id].push(version.version);
                
                let valueFormatter;
                let label = element.label || renderCamelCase(element.id);
                
                // Replace commas, apostrophes, and double quotes in the label for CSV compatibility
                label = label.replace(/,/g, '‚').replace(/'/g, '’').replace(/"/g, '“');

                if (!columnLabels[label]) {
                    columnLabels[label] = 1;
                } else {
                    columnLabels[label] = columnLabels[label] + 1;
                    label = label + ' ' + columnLabels[label];
                }

                switch (element.id) {
                    case 'date':
                        valueFormatter = (form: CustomFormCompleted) => formatDateLonger(form.data['e' + n]);
                        break;
                    case 'datetime':
                        valueFormatter = (form: CustomFormCompleted) => formatDatetime(form.data['e' + n]);
                        break;
                    case 'datetime-local':
                        valueFormatter = (form: CustomFormCompleted) => formatDatetimeLonger(form.data['e' + n]);
                        break;
                    case 'checkbox':
                        valueFormatter = (form: CustomFormCompleted) => formatCsvBoolean(form.data['e' + n]);
                        break;
                    case 'number':
                        valueFormatter = (form: CustomFormCompleted) => form.data['e' + n];
                        break;
                    case 'input':
                    case 'textarea':
                        valueFormatter = (form: CustomFormCompleted) => formatCsvString(form.data['e' + n]);
                        break;
                    case 'heading':
                        valueFormatter = (form: CustomFormCompleted) => '';
                        label = formatCsvString(element.heading);
                        break;
                    case 'text':
                        valueFormatter = (form: CustomFormCompleted) => '';
                        label = formatCsvString(element.text);
                        break;
                    case 'vessels':
                        valueFormatter = (form: CustomFormCompleted) => renderVesselsList(form.data['e' + n]);
                        break;
                    case 'crew':
                        valueFormatter = (form: CustomFormCompleted) => form.data['e' + n].map(renderFullNameForUserId).join(', ');
                        break;
                    case 'signature':
                    case 'yesno':
                        valueFormatter = (form: CustomFormCompleted) => formatCsvBoolean(form.data['e' + n]);
                        break;
                    default:
                        valueFormatter = (form: CustomFormCompleted) => form.data['e' + n];
                }

                // Check if the column already exists using the id
                const existingIndex = columns.length - 1 - columns.slice().reverse().findIndex(col => col.id === id);
                if (existingIndex !== -1) {
                    columns.splice(existingIndex + 1, 0, {
                        id: id,
                        name: label,
                        value: valueFormatter
                    });
                } else {
                    columns.push({
                        id: id,
                        name: label,
                        value: valueFormatter
                    });
                }
                
            }
        }
        return columns;
    }, [orderedVersions, customForm]);
    
    const csvConfig: CsvConfig<CustomFormVersion> | undefined = useMemo(() => {
        if (!customFormsCompleted) return undefined;

        return [
            {
                name: 'Version',
                value: (version) => formatCsvDate(version.version)
            },
            {
                name: 'Completed',
                items: (version) => customFormsCompleted.byVersion[version.version],
                columns: () => [
                    {
                        name: 'By',
                        value: (form: CustomFormCompleted) => renderFullNameForUserId(form.addedBy)
                    },
                    {
                        name: 'Date',
                        value: (form: CustomFormCompleted) => formatDate(form.whenAdded)
                    },
                    {
                        name: 'Vessels',
                        value: (form: CustomFormCompleted) => renderVesselsList(form.vesselIds)
                    },
                    ...formElementColumns
                ]
            }
        ];
    }, [customFormsCompleted, formElementColumns]);

    if (!dateRange) {
        return (
            <SeaExportFilterModal onExport={setDateRange} onClose={onCompleted} level={level} />
        )
    }
    return <SeaCSVGenerator name={title} onCompleted={onCompleted} config={csvConfig} data={orderedVersions} dateRange={dateRange} />;
};

export default CustomFormCsv