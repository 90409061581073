import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { Equipment } from './equipment';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface MaintenanceTaskCompleted extends CreateableDocument, UpdateableDocument {
    completedBy: string;
    description?: string;
    engineHours?: number;
    engineId?: string;
    equipment?: Equipment;
    equipmentId?: string;
    files?: string[];
    isCritical?: boolean;
    jobId?: string;
    location?: string;
    locationId?: string;
    jobTags?: string[];
    jobNum?: string;
    maintenanceTags?: string[];
    maintenanceTaskId?: string;
    notes?: string;
    spareParts?: {
        [sparePartId: string]: {
            used?: number
            added?: number
        }
    };
    state: string;
    task?: string;
    type: string;
    vesselId: string;
    whenCompleted: number;
    timesCompleted?: number;
    actualTime?: number;
}

export type MaintenanceTasksCompletedData = {
    all: MaintenanceTaskCompleted[],
    byMaintenanceTaskId: {
        [taskId: string]: MaintenanceTaskCompleted[]
    },
    filterOptions: {
        systemIds: string[],
        equipmentIds: string[],
        completedBy: string[]
    }
};

export const maintenanceTasksCompletedConfig: SharedStateConfig<MaintenanceTasksCompletedData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSystems', 'equipment'],
    countLiveDocs: () => sharedState.maintenanceTasksCompleted.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSystems = sharedState.vesselSystems.current;
        const equipment = sharedState.equipment.current;
        if (
            vesselId &&
            vesselSystems &&
            equipment?.byId &&
            canView('maintenanceSchedule')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'maintenanceTasksCompleted'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'completed'),
                    orderBy('whenCompleted', 'desc')
                ),
                (snap) => {
                    done();
                    const rawMaintenanceTasksCompleted = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as MaintenanceTaskCompleted;
                    });
                    if (rawMaintenanceTasksCompleted) {
                        const all = [...rawMaintenanceTasksCompleted];
                        all.forEach((item) => {
                            if (item.equipmentId) {
                                item.equipment = equipment.byId[item.equipmentId];
                            }
                        });
            
                        const filterOptions = {
                            systemIds: [] as string[],
                            equipmentIds: [] as string[],
                            completedBy: [] as string[],
                        };
            
                        const hasFilter = {
                            systemIds: {} as { [id: string]: boolean },
                            equipmentIds: {} as { [id: string]: boolean },
                            completedBy: {} as { [id: string]: boolean },
                        };
            
                        const byMaintenanceTaskId = {} as {
                            [taskId: string]: MaintenanceTaskCompleted[]
                        };
            
                        all.forEach((item: MaintenanceTaskCompleted) => {
                            registerFiles(item.files, 'maintenanceTasksCompleted', item);
                            if (item?.equipment?.systemId) {
                                hasFilter.systemIds[item.equipment.systemId] = true;
                            }
                            if (item?.equipment?.id) {
                                hasFilter.equipmentIds[item.equipment.id] = true;
                            }
                            if (
                                item.completedBy &&
                                hasFilter.completedBy[item.completedBy] === undefined
                            ) {
                                hasFilter.completedBy[item.completedBy] = true;
                                filterOptions.completedBy.push(item.completedBy);
                            }
                            if (item.maintenanceTaskId) {
                                if (
                                    byMaintenanceTaskId[item.maintenanceTaskId] ===
                                    undefined
                                ) {
                                    byMaintenanceTaskId[item.maintenanceTaskId] = [];
                                }
                                byMaintenanceTaskId[item.maintenanceTaskId].push(item);
                            }
                        });
            
                        equipment.all?.forEach((item: Equipment) => {
                            if (hasFilter.equipmentIds[item.id]) {
                                filterOptions.equipmentIds.push(item.id);
                            }
                        });
            
                        vesselSystems.ids?.forEach((id: string) => {
                            if (hasFilter.systemIds[id]) {
                                filterOptions.systemIds.push(id);
                            }
                        });
            
                        set({
                            all,
                            byMaintenanceTaskId,
                            filterOptions,
                        });
                    } else {
                        done();
                    }
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access maintenanceTasksCompleted for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    }
};