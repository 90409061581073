import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonButton, IonCol, IonGrid, IonItem, IonModal, IonRow, IonSpinner } from '@ionic/react';
import { confirmAction } from '../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { LinkType } from '../../shared-state/Core/links';
import { LinkSide, SeaLinkItem } from '../SeaLinks/SeaLinks';
import { renderLinkLabelFromCollection, renderLinkName } from '../../lib/links';
import { convertLinkSideFromLinkType } from '../../lib/links';
import { useMaintenanceTasksForVessels } from '../../shared-state/VesselMaintenance/useMaintenanceTasksForVessels';
import { sharedState } from '../../shared-state/shared-state';
import { useLicenseeSOPs } from '../../shared-state/VesselDocuments/useLicenseeSOPS';
import { canView, getPermissionRoleFromCollection, PermissionRole } from '../../shared-state/Core/userPermissions';
import { useLicenseeVesselDocuments } from '../../shared-state/VesselDocuments/useLicenseeVesselDocuments';
import SeaInput from '../SeaInput/SeaInput';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaButton from '../SeaButton/SeaButton';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaTabsGroup from '../SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../SeaTab/SeaTab';
import SeaTabContent from '../SeaTabContent/SeaTabContent';
import SeaSearchbar from '../SeaSearchbar/SeaSearchbar';
import SeaNoData from '../SeaNoData/SeaNoData';
import './SeaLinkMultiList.css';
import { renderVesselName } from '../../shared-state/Core/vessels';

export type LinkItem = {
    id: string;
    name: string;
    vesselId: string;
};

export type LinkCollectionOption = 'customForms' | 'drills' | 'external' | 'risks' | 'scheduledMaintenanceTasks' | 'SOPs' | 'trainingTasks' | 'companyDocuments' | 'vesselDocuments' | '';

export type LinkOptionsType = {
    collection: LinkCollectionOption;
    label: string;
    items: LinkItem[]; // These are always required to ensure the link label can be displayed
    categories?: {
        // These are optional to allow for categories to be added to the link list. They will supersede items if both are present.
        id: string;
        name: string;
        items: LinkItem[];
        vesselId?: string;
    }[];
};

interface SeaLinkMultiListProps {
    selectedItemId: string;
    selectedCollection: string;
    selectedItemVesselId?: string;
    linkOptions: LinkCollectionOption[];
    values: LinkType[];
    setValues: (values: LinkType[]) => void;
    label?: string;
    width?: string;
    error?: string;
    addNewText?: string;
    deleteable?: boolean;
    confirmDelete?: boolean;
    modalTitle?: string;
    level?: number;
    vesselIds?: string[];
    showVesselNameInCategory?: boolean;
}

const SeaLinkMultiList: React.FC<SeaLinkMultiListProps> = ({
    selectedItemId,
    selectedCollection,
    selectedItemVesselId = 'na',
    label,
    values,
    setValues,
    width = 'calc(100% - 44px)',
    error,
    addNewText = 'Add Link',
    deleteable,
    confirmDelete,
    linkOptions,
    modalTitle,
    level = 1,
    vesselIds,
    showVesselNameInCategory = false,
}) => {
    const orderedLinkOptions = useMemo(() => {
        return linkOptions.sort((a, b) => {
            if (a === 'external') return 1;
            if (b === 'external') return -1;
            return a.localeCompare(b);
        });
    }, [linkOptions]);

    const _vesselIds = sharedState.vesselIds.current;
    const [currentTab, setCurrentTab] = useState<LinkCollectionOption>(orderedLinkOptions?.[0] || '');
    const [modalOpen, setModalOpen] = useState(false);
    const [externalLinks, setExternalLinks] = useState<LinkSide[]>(values.filter((v) => v.bType === 'external')?.map((v) => ({ collection: 'external', id: v.id, linkId: v.bId })) || []);
    const filteredVesselIds = useMemo(() => {
        let v = vesselIds || _vesselIds;
        return v?.filter((id) => id !== 'na') || [];
    }, [_vesselIds, vesselIds]);

    const linkSides: LinkSide[] = useMemo(() => values.map((link) => convertLinkSideFromLinkType(link, selectedItemId)), [selectedItemId, values]);

    const allowedLinkOptions = useMemo(() => {
        return orderedLinkOptions.filter((option) => {
            const role = getPermissionRoleFromCollection(option);
            return option === 'external' || canView(role as PermissionRole);
        });
    }, [orderedLinkOptions]);

    const handleAddNewClick = () => {
        setModalOpen(true);
    };

    const handleChange = (option: { linkId: string; collection: string; vesselId: string }) => {
        const _values = [...values];
        const existingIndex = _values.findIndex((v) => v.aId === option.linkId || v.bId === option.linkId);

        if (existingIndex !== -1) {
            _values.splice(existingIndex, 1);
        } else {
            _values.push({
                aId: selectedItemId,
                bId: option.linkId,
                aType: selectedCollection,
                bType: option.collection,
                vesselIds: [selectedItemVesselId, option.vesselId],
            } as LinkType);
        }
        _values.sort((a, b) => a.aType.localeCompare(b.aType));
        setValues(_values);
    };

    const onRemoveValue = (collection: string) => {
        if (confirmDelete) {
            confirmAction(`Are you sure you want to remove these ${collection === 'external' ? 'external links' : `links to ${collection}`}?`, 'Yes, remove links')
                .then(() => {
                    removeValue(collection);
                })
                .catch(() => {});
        } else {
            removeValue(collection);
        }
    };

    const removeValue = (collection: string) => {
        const _values = values.filter((value) => value.aType !== collection && value.bType !== collection);
        setValues(_values);
    };

    const addExternalLink = () => {
        setExternalLinks((prev) => [
            ...prev,
            {
                collection: 'external',
                id: '',
                linkId: '',
            },
        ]);
    };

    const changeExternalLink = (e: React.ChangeEvent<HTMLInputElement>, link: LinkSide, index: number) => {
        setExternalLinks((prev) => {
            const _externalLinks = [...prev];
            _externalLinks[index] = {
                ..._externalLinks[index],
                linkId: e.target.value,
            };
            return _externalLinks;
        });
    };

    const removeExternalLink = (index: number) => {
        if (externalLinks[index].id && confirmDelete) {
            confirmAction(`Are you sure you want to delete this link?`, `Yes, delete link`)
                .then(() => {
                    setExternalLinks((prev) => prev.filter((_, i) => i !== index));
                })
                .catch(() => {});
        } else {
            setExternalLinks((prev) => prev.filter((_, i) => i !== index));
        }
    };

    const handleModalDone = () => {
        const filteredValues = [...values].filter((val) => val.bType !== 'external');
        const validExternalLinks = externalLinks
            .filter((link) => link.linkId && link.linkId !== '')
            .map(
                (link) =>
                    ({
                        id: '',
                        aId: selectedItemId,
                        aType: selectedCollection,
                        bId: link.linkId,
                        bType: 'external',
                        vesselIds: [selectedItemVesselId],
                    } as LinkType)
            );
        setValues([...filteredValues, ...validExternalLinks]);
        setCurrentTab(orderedLinkOptions?.[0] || '');
        setModalOpen(false);
    };

    if (!orderedLinkOptions) {
        return null;
    }

    return (
        <>
            <div className="form-line"></div>
            <h4>{label}</h4>
            {orderedLinkOptions.map((option) => {
                const role = getPermissionRoleFromCollection(option);
                const hasPermission = option === 'external' ? true : canView(role as PermissionRole);
                const filteredValues = linkSides.filter((value) => value.collection === option);
                if (filteredValues.length === 0) {
                    return null;
                }
                return (
                    <React.Fragment key={option}>
                        <label className="sea-label">{renderLinkLabelFromCollection(option, true)}</label>
                        <div className="input-list-row">
                            <div style={{ width: width, flexGrow: 1 }}>
                                <IonButton
                                    color="grey"
                                    className="select-button"
                                    disabled={!hasPermission}
                                    onClick={() => {
                                        if (hasPermission) {
                                            setCurrentTab(option);
                                            setModalOpen(true);
                                        }
                                    }}
                                >
                                    <p className="sea-link input ">
                                        {hasPermission ? (
                                            option === 'external' ? (
                                                filteredValues.map((link) => link.linkId).join(', ')
                                            ) : (
                                                filteredValues.map((link, index) => <SeaLinkItem className="sea-link-value" key={link.linkId + option} link={link} collection={option} index={index} totalItems={filteredValues.length} />)
                                            )
                                        ) : (
                                            <span className="sea-link text disabled">
                                                {filteredValues.length} x {renderLinkLabelFromCollection(option)}
                                            </span>
                                        )}
                                    </p>
                                    <div className="select-icon icon" role="presentation">
                                        <div className="select-icon-inner"></div>
                                    </div>
                                </IonButton>
                            </div>
                            {deleteable && (
                                <div className={`trash ${allowedLinkOptions.includes(option) ? '' : 'disabled'}`} onClick={() => (allowedLinkOptions.includes(option) ? onRemoveValue(option) : null)}>
                                    <SeaIcon slot="icon-only" icon="trash" />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                );
            })}
            <div className="sea-add-new-button">
                <SeaButton zone="white" shape="circle" onClick={handleAddNewClick}>
                    <SeaIcon slot="icon-only" icon="add" />
                </SeaButton>
                <div className="text" onClick={handleAddNewClick}>
                    {addNewText}
                </div>
            </div>
            <IonModal
                className={`centered-modal level-${level}`}
                isOpen={modalOpen}
                onWillDismiss={() => {
                    setModalOpen(false);
                    setCurrentTab(orderedLinkOptions[0] || '');
                }}
                showBackdrop={true}
                backdropDismiss={true}
            >
                <RenderOptions
                    onClick={(link, option) => {
                        handleChange({
                            linkId: link.id,
                            collection: option.collection,
                            vesselId: link.vesselId,
                        });
                    }}
                    linkOptions={allowedLinkOptions}
                    title={modalTitle}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    values={values}
                    externalLinks={externalLinks}
                    addExternalLink={addExternalLink}
                    changeExternalLink={changeExternalLink}
                    removeExternalLink={removeExternalLink}
                    handleDone={handleModalDone}
                    vesselIds={filteredVesselIds}
                    showVesselNameInCategory={showVesselNameInCategory}
                />
            </IonModal>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

interface RenderOptionsProps {
    values: LinkType[];
    title?: string;
    currentTab: LinkCollectionOption;
    setCurrentTab: React.Dispatch<React.SetStateAction<LinkCollectionOption>>;
    linkOptions?: LinkCollectionOption[];
    onClick?: (option: LinkItem, optionGroup: LinkOptionsType) => void;
    externalLinks: LinkSide[];
    addExternalLink: () => void;
    changeExternalLink: (e: React.ChangeEvent<HTMLInputElement>, link: LinkSide, index: number) => void;
    removeExternalLink: (index: number) => void;
    allowCustom?: boolean;
    handleDone: () => void;
    vesselIds?: string[];
    showVesselNameInCategory?: boolean;
}

const RenderOptions: React.FC<RenderOptionsProps> = ({
    values,
    title = 'Links',
    currentTab,
    setCurrentTab,
    linkOptions,
    onClick,
    externalLinks,
    addExternalLink,
    changeExternalLink,
    removeExternalLink,
    handleDone,
    vesselIds,
    showVesselNameInCategory,
}) => {
    const [searchText, setSearchText] = useState('');
    const vesselDrills = sharedState.vesselDrills.use(currentTab === 'drills');
    const risks = sharedState.risks.use(currentTab === 'risks');
    const riskCategories = sharedState.riskCategories.use(currentTab === 'risks');
    const trainingTasks = sharedState.trainingTasks.use(currentTab === 'trainingTasks');
    const scheduledMaintenanceTasks = useMaintenanceTasksForVessels(currentTab === 'scheduledMaintenanceTasks', vesselIds);
    const customForms = sharedState.customForms.use(currentTab === 'customForms');
    const customFormCategories = sharedState.customFormCategories.use(currentTab === 'customForms');
    const licenseeSOPs = useLicenseeSOPs(currentTab === 'SOPs', vesselIds);
    const companyDocuments = sharedState.companyDocuments.use(currentTab === 'companyDocuments');
    const companyDocumentCategories = sharedState.companyDocumentCategories.use(currentTab === 'companyDocuments');
    const licenseeVesselDocuments = useLicenseeVesselDocuments(currentTab === 'vesselDocuments', vesselIds);

    // Reset search text when currentTab changes
    useEffect(() => {
        setSearchText('');
    }, [currentTab]);

    const getLinkCategoryName = useCallback(
        (name: string, vesselName?: string) => {
            return `${showVesselNameInCategory && vesselName ? vesselName + ' - ' : ''}${name}`;
        },
        [showVesselNameInCategory]
    );

    const currentOption: LinkOptionsType | undefined = useMemo(() => {
        let result: LinkOptionsType | undefined;

        switch (currentTab) {
            case 'external':
                result = {
                    collection: 'external',
                    label: 'External Links',
                    items: externalLinks.map((link) => ({
                        id: link.id,
                        name: link.linkId,
                        vesselId: 'na',
                    })),
                };
                break;
            case 'scheduledMaintenanceTasks':
                if (!scheduledMaintenanceTasks) return undefined;
                result = {
                    collection: 'scheduledMaintenanceTasks',
                    label: 'Maintenance Tasks',
                    categories: Object.entries(scheduledMaintenanceTasks?.bySystemId).map(([systemId, system]) => ({
                        id: systemId,
                        name: getLinkCategoryName(system.name, system.vesselName),
                        items: scheduledMaintenanceTasks.bySystemId[systemId].tasks.map((item) => ({
                            id: item.id,
                            name: renderLinkName(item.task || 'placeholder task', item.state),
                            vesselId: item.vesselId ?? 'na',
                        })),
                    })),
                    items: Object.values(scheduledMaintenanceTasks.byId).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.task || 'placeholder task', item.state),
                        vesselId: item.vesselId ?? 'na',
                    })),
                };
                break;
            case 'drills':
                if (!vesselDrills) return undefined;
                result = {
                    collection: 'drills',
                    label: 'Drills',
                    items: Object.values(vesselDrills.byId || {}).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.name || 'placeholder drill', item.state),
                        vesselId: item.vesselId ?? 'na',
                    })),
                };
                break;
            case 'risks':
                if (!risks || !riskCategories) return undefined;
                result = {
                    collection: 'risks',
                    label: 'Risk Assessments',
                    categories: Object.keys(risks.byCategoryId).map((id) => ({
                        id,
                        name: getLinkCategoryName(riskCategories.byId[id].name),
                        items: risks.byCategoryId[id].map((risk) => ({
                            id: risk.id,
                            name: renderLinkName(risk.name, risk.state),
                            vesselId: 'na',
                        })),
                    })),
                    items: Object.values(risks.byId).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.name, item.state),
                        vesselId: 'na',
                    })),
                };
                break;
            case 'trainingTasks':
                if (!trainingTasks) return undefined;
                if (vesselIds?.length === 1) {
                    result = {
                        collection: 'trainingTasks',
                        label: 'Crew Training',
                        items: trainingTasks.allByVesselId[vesselIds[0]].map((item) => ({
                            id: item.id,
                            name: renderLinkName(item.task || 'placeholder training', item.state),
                            vesselId: item.vesselId ?? 'na',
                        })),
                    };
                } else {
                    result = {
                        collection: 'trainingTasks',
                        label: 'Crew Training',
                        items: Object.values(trainingTasks.byId || {}).map((item) => ({
                            id: item.id,
                            name: renderLinkName(item.task || 'placeholder training', item.state),
                            vesselId: item.vesselId ?? 'na',
                        })),
                        categories: Object.entries(trainingTasks.allByVesselId)
                            .filter(([categoryId]) => vesselIds?.includes(categoryId))
                            .map(([categoryId, category]) => ({
                                id: categoryId,
                                name: renderVesselName(categoryId),
                                items: category.map((item) => ({
                                    id: item.id,
                                    name: renderLinkName(item.task || 'placeholder training', item.state),
                                    vesselId: item.vesselId ?? 'na',
                                })),
                            })),
                    };
                }
                break;
            case 'customForms':
                if (!customForms || !customFormCategories) return undefined;
                result = {
                    collection: 'customForms',
                    label: 'Forms/Checklists',
                    categories: Object.keys(customForms.byCategoryId).map((id) => ({
                        id,
                        name: getLinkCategoryName(customFormCategories.byId[id].name),
                        items: customForms.byCategoryId[id].map((task) => ({
                            id: task.id,
                            name: renderLinkName(task.title, task.state),
                            vesselId: 'na',
                        })),
                    })),
                    items: Object.values(customForms.byId).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.title, item.state),
                        vesselId: 'na',
                    })),
                };
                break;
            case 'SOPs':
                if (!licenseeSOPs) return undefined;
                result = {
                    collection: 'SOPs',
                    label: 'Standard Operating Procedures',
                    categories: Object.entries(licenseeSOPs.byCategoryId).map(([systemId, category]) => ({
                        id: systemId,
                        name: getLinkCategoryName(category.name, category.vesselName),
                        items: category.items.map((item) => ({
                            id: item.id,
                            name: renderLinkName(item.title, item.state),
                            vesselId: category.vesselId,
                        })),
                    })),
                    items: Object.values(licenseeSOPs.byId).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.title, item.state),
                        vesselId: item.vesselId ?? 'na',
                    })),
                };
                break;
            case 'companyDocuments':
                if (!companyDocuments || !companyDocumentCategories) return undefined;
                result = {
                    collection: 'companyDocuments',
                    label: 'Company Documents',
                    categories: Object.keys(companyDocuments.byCategoryId).map((id) => ({
                        id: id,
                        name: getLinkCategoryName(companyDocumentCategories.byId[id].name),
                        items: companyDocuments.byCategoryId[id].map((doc) => ({
                            id: doc.id,
                            name: renderLinkName(doc.title, doc.state),
                            vesselId: 'na',
                        })),
                    })),
                    items: Object.values(companyDocuments.byId).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.title, item.state),
                        vesselId: 'na',
                    })),
                };
                break;
            case 'vesselDocuments':
                if (!licenseeVesselDocuments) return undefined;
                result = {
                    collection: 'vesselDocuments',
                    label: 'Vessel Documents',
                    categories: Object.entries(licenseeVesselDocuments.byCategoryId).map(([id, category]) => ({
                        id: id,
                        name: getLinkCategoryName(category.name, category.vesselName),
                        items: category.items.map((item) => ({
                            id: item.id,
                            name: renderLinkName(item.title, item.state),
                            vesselId: item.vesselId,
                        })),
                    })),
                    items: Object.values(licenseeVesselDocuments.byId).map((item) => ({
                        id: item.id,
                        name: renderLinkName(item.title, item.state),
                        vesselId: item.vesselId,
                    })),
                };
                break;
            default:
                return undefined;
        }

        // Apply filtering logic
        if (result) {
            const filterItems = (items: LinkItem[]) => items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));

            if (result.categories) {
                result = {
                    ...result,
                    categories: result.categories
                        .map((category) => {
                            const isCategoryMatch = category.name.toLowerCase().includes(searchText.toLowerCase());
                            return {
                                ...category,
                                items: isCategoryMatch ? category.items : filterItems(category.items),
                            };
                        })
                        .sort((a, b) => a.name.localeCompare(b.name)),
                };
            }

            if (result.items) {
                result = {
                    ...result,
                    items: filterItems(result.items),
                };
            }
        }

        return result;
    }, [
        companyDocumentCategories,
        companyDocuments,
        currentTab,
        customFormCategories,
        customForms,
        externalLinks,
        getLinkCategoryName,
        licenseeSOPs,
        licenseeVesselDocuments,
        riskCategories,
        risks,
        scheduledMaintenanceTasks,
        searchText,
        trainingTasks,
        vesselDrills,
    ]);

    const hasNoResults = useMemo(() => {
        if (!currentOption) return false;
        if (!currentOption.categories && currentOption.items && currentOption.items.length === 0) return true;
        if (currentOption.categories && currentOption.categories.every((category) => category.items.length === 0)) return true;
        return false;
    }, [currentOption]);

    return (
        <>
            <div className="links-title-container">
                {title && (
                    <div className="links-title">
                        <h5>{title}</h5>
                    </div>
                )}
            </div>
            <div className="sea-select-multi-links">
                <SeaTabsGroup selectedTab={currentTab} setTab={(tab) => setCurrentTab(tab as LinkCollectionOption)} mode="forms" mini={true} allowFades={false}>
                    {linkOptions?.map((option) => (
                        <SeaTab key={option} tab={option} mode="forms">
                            {renderLinkLabelFromCollection(option, false, true)}
                        </SeaTab>
                    ))}
                </SeaTabsGroup>
            </div>
            {currentTab !== 'external' && (
                <div className="links-search-bar">
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
            )}
            <div className="sea-tab-content-container">
                <div className="no-data-container">
                    <SeaNoData dataName={currentOption?.label || 'links'} hasNoData={hasNoResults && currentTab !== 'external'} isUsingFilter={searchText} />
                </div>
                {currentOption ? (
                    <SeaTabContent key={currentOption.collection + '-tab-content'} tab={currentOption.collection} selectedTab={currentTab} className={`sea-tab-content ${currentOption.collection === 'external' ? 'external-links' : ''}`}>
                        {currentTab === 'external' ? (
                            <IonGrid className="form-grid">
                                {externalLinks.map((link, index) => (
                                    <IonRow className="input-list-row" key={link.id + index}>
                                        <IonCol size="12" style={{ display: 'flex', flexGrow: 1, flexDirection: 'row' }}>
                                            <SeaInput zone="white" value={link.linkId} onblur={(e) => changeExternalLink(e as any, link, index)} />
                                            <div>
                                                <div className="trash" onClick={() => removeExternalLink(index)}>
                                                    <SeaIcon slot="icon-only" icon="trash" />
                                                </div>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                ))}
                                <div className="sea-add-new-button">
                                    <SeaButton zone="white" shape="circle" onClick={addExternalLink}>
                                        <SeaIcon slot="icon-only" icon="add" />
                                    </SeaButton>
                                    <div className="text" onClick={addExternalLink}>
                                        Add External Link
                                    </div>
                                </div>
                            </IonGrid>
                        ) : currentOption.categories ? (
                            currentOption.categories?.map((category) => {
                                if (category.items.length === 0) {
                                    return null;
                                }
                                return (
                                    <React.Fragment key={category.id}>
                                        <div className="category-heading">{category.name}</div>
                                        {category.items.map((item, index) => (
                                            <IonItem key={item.id + index} button={true} onClick={() => onClick?.(item, currentOption)} lines={index === category.items.length - 1 ? 'none' : 'full'}>
                                                {item.name}
                                                {values.some((value) => value.aId === item.id || value.bId === item.id) && <SeaIcon slot="end" icon="tick" />}
                                            </IonItem>
                                        ))}
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            currentOption.items.map((item) => (
                                <IonItem key={item.id} button={true} onClick={() => onClick?.(item, currentOption)}>
                                    {item.name}
                                    {values.some((value) => value.aId === item.id || value.bId === item.id) && <SeaIcon slot="end" icon="tick" />}
                                </IonItem>
                            ))
                        )}
                    </SeaTabContent>
                ) : (
                    <IonSpinner name="crescent" className="sea-spinner" />
                )}
            </div>
            <div className="done-button-container">
                <SeaButton onClick={handleDone}>Done</SeaButton>
            </div>
        </>
    );
};

export default SeaLinkMultiList;
