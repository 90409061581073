import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { Category } from '../../lib/categories';

export type LicenseeVesselSystemData = {
    byId: { [id: string]: Category };
    byVesselId: { [id: string]: Category[] };
    bySystemId: { [id: string]: Category[] };
};

export const useLicenseeVesselSystems = (vesselIds: string[] | undefined) => {
    const [licenseeVesselSystemsData, setLicenseeVesselSystemsData] = useState<LicenseeVesselSystemData>();

    useEffect(() => {
        setLicenseeVesselSystemsData(undefined);
        if (vesselIds?.length) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselSystems'),
                    where('vesselId', 'in', vesselIds),
                    where('state', '==', 'active')
                ),
                (snapshot) => {
                    const byId: { [id: string]: Category } = {};
                    const byVesselId: { [id: string]: Category[] } = {};
                    const bySystemId: { [id: string]: Category[] } = {};
                    snapshot.forEach((doc) => {
                        const system = {
                            id: doc.id,
                            ...doc.data(),
                        } as Category;
                        byId[system.id] = system;
                        if (!byVesselId[system.vesselId]) {
                            byVesselId[system.vesselId] = [];
                        }
                        byVesselId[system.vesselId].push(system);
                        if (!bySystemId[system.systemId]) {
                            bySystemId[system.systemId] = [];
                        }
                        bySystemId[system.systemId].push(system);
                    });
                    setLicenseeVesselSystemsData({ byId, byVesselId, bySystemId });
                },
                (error) => {
                    console.error('Error fetching license vesselSystems:', error);
                }
            );
        }

        return () => {
            setLicenseeVesselSystemsData(undefined);
        };
    }, [vesselIds]);

    return licenseeVesselSystemsData;
};