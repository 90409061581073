import React, { useMemo, useState, useEffect } from "react";
import { formatTextAreaText, formatValue, renderCamelCase } from "../../../../lib/util";
import { formatDate, formatInterval, formatShortTimeDurationHrsMinsView, warnDays } from "../../../../lib/datesAndTime";
import { permissionLevels, canView, canCreate, canEdit } from "../../../../shared-state/Core/userPermissions";
import { renderCategoryName } from "../../../../lib/categories";
import { renderFullNameForUserId } from "../../../../shared-state/Core/users";
import { sharedState } from "../../../../shared-state/shared-state";
import { Equipment } from "../../../../shared-state/VesselMaintenance/equipment";
import { SparePart } from "../../../../shared-state/VesselMaintenance/spareParts";
import { ScheduledMaintenanceTask } from "../../../../shared-state/VesselMaintenance/maintenanceSchedule";
import { MaintenanceTaskCompleted } from "../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted";
import { Job } from "../../../../shared-state/VesselMaintenance/jobs";
import SeaModal from "../../../../components/SeaModal/SeaModal";
import SeaIcon from "../../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../../components/SeaButton/SeaButton";
import SeaFileImage from "../../../../components/SeaFileImage/SeaFileImage";
import SeaGrid from "../../../../components/SeaGrid/SeaGrid";
import SeaGridCell from "../../../../components/SeaGridCell/SeaGridCell";
import RequirePermissions from "../../../../components/RequirePermissions/RequirePermissions";
import SeaTabsGroup from "../../../../components/SeaTabsGroup/SeaTabsGroup";
import SeaTab from "../../../../components/SeaTab/SeaTab";
import SeaTabContent from "../../../../components/SeaTabContent/SeaTabContent";
import SeaNoData from "../../../../components/SeaNoData/SeaNoData";
import SeaStatusDueHours from "../../../../components/SeaStatusDueHours/SeaStatusDueHours";
import SeaStatusDueDate from "../../../../components/SeaStatusDueDate/SeaStatusDueDate";
import CompleteMaintenanceSchedule from "../../MaintenanceSchedule/CompleteMaintenanceSchedule/CompleteMaintenanceSchedule";
import ViewSparePart from "../../SparePartsList/ViewSparePart/ViewSparePart";
import ViewMaintenanceSchedule from "../../MaintenanceSchedule/ViewMaintenanceSchedule/ViewMaintenanceSchedule";
import EditJobList from "../../JobList/EditJobList/EditJobList";
import EditMaintenanceSchedule from "../../MaintenanceSchedule/EditMaintenanceSchedule/EditMaintenanceSchedule";
import CompleteJobList from "../../JobList/CompleteJobList/CompleteJobList";
import "./ViewEquipment.css";

interface ViewEquipmentProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem: Equipment;
    level: number;
    setShowEditItemModal: (showModal: boolean) => void;
}

const ViewEquipment: React.FC<ViewEquipmentProps> = ({ showModal, setShowModal, selectedItem, level, setShowEditItemModal }) => {
    const vessel = sharedState.vessel.use(showModal);
    const contacts = sharedState.contacts.use(showModal);
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use(showModal);
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(showModal);
    const maintenanceTasksCompleted = sharedState.maintenanceTasksCompleted.use(showModal);
    const spareParts = sharedState.spareParts.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);

    const hasTimeTrackingEnabled = useMemo(() => {
        // return false;

        return licenseeSettings?.hasMaintenanceTaskTime ?? false;
    }, [licenseeSettings]);

    const [tab, setTab] = useState("tasks");
    const [showEditMaintenanceScheduleModal, setShowEditMaintenanceScheduleModal] = useState(false);
    const [showEditJobListModal, setShowEditJobListModal] = useState(false);
    const [editScheduledTaskCompletedModal, setEditScheduledTaskCompletedModal] = useState<{
        show: boolean;
        selectedItem: ScheduledMaintenanceTask | undefined;
        historyItemToUpdate: MaintenanceTaskCompleted | undefined;
    }>({
        show: false,
        selectedItem: undefined,
        historyItemToUpdate: undefined,
    });
    const [editJobCompleteModal, setEditJobCompleteModal] = useState<{
        show: boolean;
        selectedItem: Job | undefined;
        historyItemToUpdate: MaintenanceTaskCompleted | undefined;
    }>({
        show: false,
        selectedItem: undefined,
        historyItemToUpdate: undefined,
    });
    const [viewSparePartModal, setViewSparePartModal] = useState<{
        show: boolean;
        selectedItem: SparePart | undefined;
        level: number;
    }>({
        show: false,
        selectedItem: undefined,
        level: 1,
    });
    const [viewScheduledMaintenanceTaskModal, setViewScheduledMaintenanceTaskModal] = useState<{
        show: boolean;
        selectedItem: ScheduledMaintenanceTask | undefined;
        level: number;
    }>({
        show: false,
        selectedItem: undefined,
        level: 1,
    });

    // Keep viewScheduledMaintenanceTaskModal.selectedItem fresh
    useEffect(() => {
        if (viewScheduledMaintenanceTaskModal?.selectedItem?.id && scheduledMaintenanceTasks?.byId[viewScheduledMaintenanceTaskModal.selectedItem.id]) {
            setViewScheduledMaintenanceTaskModal((current) => {
                return {
                    ...current,
                    selectedItem: viewScheduledMaintenanceTaskModal.selectedItem?.id ? scheduledMaintenanceTasks?.byId[viewScheduledMaintenanceTaskModal.selectedItem.id] : undefined,
                };
            });
        }
    }, [scheduledMaintenanceTasks, viewScheduledMaintenanceTaskModal?.selectedItem?.id]);

    // Keep viewSparePartModal.selectedItem fresh
    useEffect(() => {
        if (viewSparePartModal?.selectedItem?.id && spareParts?.byId[viewSparePartModal.selectedItem.id]) {
            setViewSparePartModal((current) => {
                return {
                    ...current,
                    selectedItem: viewSparePartModal.selectedItem?.id ? spareParts.byId[viewSparePartModal.selectedItem?.id] : undefined,
                };
            });
        }
    }, [spareParts, viewSparePartModal?.selectedItem?.id]);

    const associatedTasks = useMemo(() => {
        if (showModal && selectedItem && scheduledMaintenanceTasks?.byId) {
            const tasks: ScheduledMaintenanceTask[] = [];
            Object.keys(scheduledMaintenanceTasks.byId).forEach((key: string) => {
                if (scheduledMaintenanceTasks.byId[key]?.equipmentId === selectedItem.id) {
                    tasks.push(scheduledMaintenanceTasks.byId[key]);
                }
            });
            tasks.sort((a, b) => {
                if (!a.task && !b.task) return 0;
                if (!a.task) return 1;
                if (!b.task) return -1;

                return a.task.localeCompare(b.task);
            });
            return tasks;
        }
        return [];
    }, [showModal, selectedItem, scheduledMaintenanceTasks]);

    const associatedTasksCompleted = useMemo(() => {
        if (showModal && selectedItem && maintenanceTasksCompleted) {
            const tasksCompleted: MaintenanceTaskCompleted[] = [];
            maintenanceTasksCompleted.all.forEach((taskCompleted) => {
                if (taskCompleted.equipmentId === selectedItem.id) {
                    tasksCompleted.push(taskCompleted);
                }
            });
            tasksCompleted.sort((a, b) => {
                return b.whenCompleted - a.whenCompleted;
            });
            return tasksCompleted;
        }
        return [];
    }, [showModal, selectedItem, maintenanceTasksCompleted]);

    const equipmentSpareParts = useMemo(() => {
        if (showModal && selectedItem && spareParts) {
            const _spareParts: SparePart[] = [];
            spareParts.all.forEach((sparePart) => {
                if (sparePart.equipmentIds) {
                    for (let i = 0; i < sparePart.equipmentIds.length; i++) {
                        if (sparePart.equipmentIds[i] === selectedItem.id) {
                            _spareParts.push(sparePart);
                            break;
                        }
                    }
                }
            });
            return _spareParts;
        }
        return undefined;
    }, [showModal, selectedItem, spareParts]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    const onViewScheduledMaintenanceTask = (item: ScheduledMaintenanceTask) => {
        setViewScheduledMaintenanceTaskModal({
            show: true,
            selectedItem: item,
            level: level + 1,
        });
    };

    const onEditScheduledMaintenanceTaskCompleted = (item: MaintenanceTaskCompleted) => {
        console.log("Editing maintenance task", item);
        setEditScheduledTaskCompletedModal({
            show: true,
            selectedItem: item.maintenanceTaskId ? scheduledMaintenanceTasks?.byId[item.maintenanceTaskId] : undefined,
            historyItemToUpdate: item,
        });
    };

    const onViewSparePart = (item: SparePart) => {
        setViewSparePartModal({
            show: true,
            selectedItem: item,
            level: level + 1,
        });
    };

    const onAddJobTask = () => {
        setShowEditJobListModal(true);
    };

    const onAddScheduledTask = () => {
        setShowEditMaintenanceScheduleModal(true);
    };

    return (
        <SeaModal title={selectedItem?.equipment} showModal={showModal} setShowModal={setShowModal} size="wide">
            <div className="info-image">
                <SeaFileImage files={selectedItem?.files} size="medium" showOthers />
            </div>
            <SeaGrid>
                <SeaGridCell label="System" w="50">
                    {renderCategoryName(selectedItem?.systemId, vesselSystems)}
                </SeaGridCell>
                <SeaGridCell label="Location" w="50">
                    {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                </SeaGridCell>
                <SeaGridCell label="Critical Equipment" w="50" help={{ text: "Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew." }}>
                    {formatValue(selectedItem?.isCritical ? "Yes" : "No")}
                </SeaGridCell>
                <SeaGridCell label="Make" w="50">
                    {formatValue(selectedItem?.make)}
                </SeaGridCell>
                <SeaGridCell label="Model" w="50">
                    {formatValue(selectedItem?.model)}
                </SeaGridCell>
                <SeaGridCell label="Serial" w="50">
                    {formatValue(selectedItem?.serial)}
                </SeaGridCell>
                <SeaGridCell label="Equipment Notes" w="100">
                    {formatValue(formatTextAreaText(selectedItem?.notes))}
                </SeaGridCell>
                <SeaGridCell label="Contact" w="100">
                    {contacts?.byId && selectedItem?.contactIds && selectedItem?.contactIds?.length > 0
                        ? selectedItem.contactIds
                              .map((contactId: string) => {
                                  const contact = contacts.byId[contactId];
                                  return `${contact.name}${contact.company ? ` (${contact.company})` : ""}${contact.number ? `, ${contact.number}` : ""}${contact.email ? `, ${contact.email}` : ""}`;
                              })
                              .join("\n \n")
                        : "-"}
                </SeaGridCell>
                {!vessel?.isShoreFacility && (
                    <SeaGridCell label="Manual" w="100">
                        <div style={{ whiteSpace: "pre-line" }}>
                            {equipmentManualDocuments?.byId &&
                                selectedItem?.equipmentDocumentIds?.map((manualId: string) => {
                                    const manual = equipmentManualDocuments.byId[manualId];
                                    if (manual) {
                                        return (
                                            <div key={manualId} className="columns" style={{ marginBottom: "6px" }}>
                                                <div>
                                                    <SeaFileImage files={manual.files} size="tiny" showOthers />
                                                </div>
                                                <div style={{ paddingLeft: "8px", alignSelf: "center" }}>{manual.title}</div>
                                            </div>
                                        );
                                    }
                                    return undefined;
                                })}
                            {(!selectedItem?.equipmentDocumentIds || selectedItem.equipmentDocumentIds.length === 0) && "-"}
                        </div>
                    </SeaGridCell>
                )}
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions role="maintenanceSchedule" level={permissionLevels.EDIT}>
                    <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}>
                        <SeaIcon icon="edit" slot="start" />
                        Edit Item
                    </SeaButton>
                </RequirePermissions>
            </div>

            <SeaTabsGroup key="viewEquipment" selectedTab={tab} setTab={setTab} mode="forms" mini>
                <SeaTab tab="tasks" mode="forms">
                    Scheduled Tasks
                </SeaTab>
                <SeaTab tab="equipmentHistory" mode="forms" requireRole="maintenanceHistory">
                    Equipment History
                </SeaTab>
                <SeaTab tab="spareParts" mode="forms" requireRole="sparePartsList">
                    Spare Parts
                </SeaTab>
            </SeaTabsGroup>

            {canView("maintenanceSchedule") && (
                <SeaTabContent tab="tasks" selectedTab={tab}>
                    {canCreate("maintenanceSchedule") && (
                        <div className="add-task-button-container">
                            <SeaButton onClick={(e) => onAddJobTask()}>
                                <SeaIcon slot="start" icon="add" />
                                Add Job List Task
                            </SeaButton>
                            <SeaButton onClick={(e) => onAddScheduledTask()}>
                                <SeaIcon slot="start" icon="add" />
                                Add Scheduled Task
                            </SeaButton>
                        </div>
                    )}
                    <SeaNoData dataName="scheduled tasks" isHistory={true} isLoading={!associatedTasks} hasNoData={associatedTasks && associatedTasks.length === 0} />
                    <div className={`associated-tasks-history has-status ${associatedTasks?.length ? "reveal" : "conceal"}`}>
                        <div className="sea-row-history headings-history">
                            <div>Task</div>
                            <div>Interval</div>
                            <div>Next Due</div>
                            {hasTimeTrackingEnabled && <div>Estimated Time</div>}
                            <div>Status</div>
                        </div>
                        {associatedTasks?.map((item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row-history clickable`} onClick={(e) => onViewScheduledMaintenanceTask(item)}>
                                    <div className="truncate">{formatValue(item.task)}</div>
                                    <div className="truncate">
                                        {(item.intervalType === "weekMonth" || item.intervalType === "weekMonthAndHours") && formatInterval(item.intervalWeekMonth)}
                                        {item.intervalType === "weekMonthAndHours" && <br />}
                                        {(item.intervalType === "engineHours" || item.intervalType === "weekMonthAndHours") && `${item.intervalEngineHours} Hours`}
                                    </div>
                                    <div className="truncate">
                                        {(item.intervalType === "weekMonth" || item.intervalType === "weekMonthAndHours") && formatDate(item.whenDue)}
                                        {item.intervalType === "weekMonthAndHours" && <br />}
                                        {(item.intervalType === "engineHours" || item.intervalType === "weekMonthAndHours") && `${item.engineHoursDue} Hours`}
                                    </div>
                                    {hasTimeTrackingEnabled && <div className="truncate">{formatValue(item.estimatedTime ? formatShortTimeDurationHrsMinsView(item.estimatedTime) : "-")}</div>}
                                    <div className="truncate">{item.useHours ? <SeaStatusDueHours engineHoursLeft={item.engineHoursLeft} /> : <SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.maintenanceSchedule[0]} />}</div>
                                </div>
                            );
                        })}
                    </div>
                </SeaTabContent>
            )}

            {canView("maintenanceHistory") && (
                <SeaTabContent tab="equipmentHistory" selectedTab={tab}>
                    <SeaNoData dataName="maintenance task" isHistory={true} isLoading={!associatedTasksCompleted} hasNoData={associatedTasksCompleted && associatedTasksCompleted.length === 0} />
                    <div className={`equipment-tasks-history has-status ${associatedTasksCompleted?.length ? "reveal" : "conceal"}`}>
                        <div className="sea-row-history headings-history">
                            <div>Date</div>
                            <div>Task</div>
                            <div>Notes</div>
                            <div>Type</div>
                            <div>Completed By</div>
                            {hasTimeTrackingEnabled && <div>Actual Time</div>}
                            <div className={"task-image"}></div>
                        </div>
                        {associatedTasksCompleted?.map((item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row-history ${canEdit("maintenanceHistory") ? "clickable" : ""}`} onClick={(e) => canEdit("maintenanceHistory") && onEditScheduledMaintenanceTaskCompleted(item)}>
                                    <div>{formatDate(item.whenCompleted)}</div>
                                    <div className="truncate">{formatValue(item.task)}</div>
                                    <div className="truncate-3">{formatValue(formatTextAreaText(item.notes))}</div>
                                    <div className="truncate">{formatValue(item.type === "unscheduled" ? "Job" : renderCamelCase(item.type))}</div>
                                    <div className="truncate">{renderFullNameForUserId(item.completedBy as string)}</div>
                                    {hasTimeTrackingEnabled && <div className="truncate">{formatValue(item.actualTime ? formatShortTimeDurationHrsMinsView(item.actualTime) : "-")}</div>}
                                    <div className={"task-image"}>
                                        <SeaFileImage files={item.files} size="tiny" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {editScheduledTaskCompletedModal.historyItemToUpdate && (
                        <CompleteMaintenanceSchedule
                            showModal={editScheduledTaskCompletedModal.show}
                            setShowModal={(showModal: boolean) =>
                                setEditScheduledTaskCompletedModal({
                                    ...editScheduledTaskCompletedModal,
                                    show: showModal,
                                })
                            }
                            setShowParentModal={(showModal: boolean) => {}}
                            selectedItem={editScheduledTaskCompletedModal.selectedItem as ScheduledMaintenanceTask}
                            historyItemToUpdate={editScheduledTaskCompletedModal.historyItemToUpdate}
                            completedScheduledMaintenanceTasks={editScheduledTaskCompletedModal.selectedItem?.id ? maintenanceTasksCompleted?.byMaintenanceTaskId[editScheduledTaskCompletedModal.selectedItem.id] : undefined}
                            level={2}
                        />
                    )}
                    {editJobCompleteModal.selectedItem && (
                        <CompleteJobList
                            showModal={editJobCompleteModal.show}
                            setShowModal={(showModal: boolean) =>
                                setEditJobCompleteModal({
                                    ...editJobCompleteModal,
                                    show: showModal,
                                })
                            }
                            setShowParentModal={(showModal: boolean) => {}}
                            selectedItem={editJobCompleteModal.selectedItem}
                            historyItemToUpdate={editJobCompleteModal.historyItemToUpdate}
                            level={2}
                        />
                    )}
                </SeaTabContent>
            )}

            {canView("sparePartsList") && (
                <SeaTabContent tab="spareParts" selectedTab={tab}>
                    <SeaNoData dataName="spare parts" isHistory={false} isUsingFilter={true} isLoading={!equipmentSpareParts} hasNoData={equipmentSpareParts && equipmentSpareParts.length === 0} />
                    <div className={`spare-parts-history has-thumbs ${equipmentSpareParts?.length ? "reveal" : "conceal"}`}>
                        <div className="sea-row-history headings-history">
                            <div></div>
                            <div>Item</div>
                            <div>Quantity</div>
                            <div>Location</div>
                            <div>Part #</div>
                        </div>
                        {equipmentSpareParts?.map((item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row-history clickable`} onClick={(e) => onViewSparePart(item)}>
                                    <div>
                                        <SeaFileImage files={item.files} size="tiny" />
                                    </div>
                                    <div className="truncate">{formatValue(item.item)}</div>
                                    <div className="truncate">{formatValue(item.quantity)}</div>
                                    <div className="truncate">{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                    <div className="truncate">{formatValue(item.partNum)}</div>
                                </div>
                            );
                        })}
                    </div>
                </SeaTabContent>
            )}

            {showModal && (
                <>
                    {canView("maintenanceSchedule") && (
                        <ViewMaintenanceSchedule
                            showModal={viewScheduledMaintenanceTaskModal.show}
                            setShowModal={(showModal: boolean) =>
                                setViewScheduledMaintenanceTaskModal({
                                    ...viewScheduledMaintenanceTaskModal,
                                    show: showModal,
                                })
                            }
                            selectedItem={viewScheduledMaintenanceTaskModal.selectedItem}
                            level={viewScheduledMaintenanceTaskModal.level}
                        />
                    )}
                    {canView("sparePartsList") && viewSparePartModal.selectedItem && (
                        <ViewSparePart
                            showModal={viewSparePartModal.show}
                            setShowModal={(showModal: boolean) =>
                                setViewSparePartModal({
                                    ...viewSparePartModal,
                                    show: showModal,
                                })
                            }
                            selectedItem={viewSparePartModal.selectedItem}
                            level={viewSparePartModal.level}
                        />
                    )}
                    {canEdit("maintenanceSchedule") && (
                        <EditMaintenanceSchedule showModal={showEditMaintenanceScheduleModal} setShowModal={setShowEditMaintenanceScheduleModal} level={level + 1} fromEquipmentId={selectedItem.id} fromLocationId={selectedItem.locationId} />
                    )}
                    {canEdit("maintenanceSchedule") && <EditJobList showModal={showEditJobListModal} setShowModal={setShowEditJobListModal} level={level + 1} fromEquipmentId={selectedItem.id} fromLocationId={selectedItem.locationId} />}
                </>
            )}
        </SeaModal>
    );
};

export default ViewEquipment;
