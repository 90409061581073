import {  collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from 'react';
import { SafetyCheckItem } from './safetyCheckItems';
import { CreateableDocument, UpdateableDocument } from '../shared-state';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface SafetyCheckCompleted extends CreateableDocument, UpdateableDocument {
    completedBy: string;
    files?: string[];
    isCritical?: boolean;
    name?: string;
    notes?: string;
    safetyCheckId: string;
    shouldReportFault: boolean;
    state: string;
    vesselId: string;
    whenCompleted: number;
    item: SafetyCheckItem;
    itemId: string;
    interval: string;
    timesCompleted?: number;
    actualTime: number;
}

export type CompletedSafetyCheckItemsData = SafetyCheckCompleted[];

export const useCompletedSafetyCheckItems = (selectedSafetyCheck: SafetyCheckItem | undefined) => {
    const [completedSafetyCheckItems, setCompletedSafetyCheckItems] = useState<CompletedSafetyCheckItemsData>();

    useEffect(() => {
        setCompletedSafetyCheckItems(undefined);
        if (selectedSafetyCheck?.id) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckCompleted'),
                    where('safetyCheckId', '==', selectedSafetyCheck.id),
                    where('state', '==', 'active'),
                    orderBy('whenCompleted', 'desc')
                ),
                (snap) => {
                    const _completedSafetyChecks = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data()
                        } as SafetyCheckCompleted;
                        registerFiles(item.files, 'safetyCheckCompleted', item);
                        return item;
                    });
                    setCompletedSafetyCheckItems(_completedSafetyChecks);
                }, (error) => {
                    console.log(`Failed to access Safety Check Completed id=${selectedSafetyCheck.id}`, error);
                }
            );
        }
    }, [selectedSafetyCheck?.id]);

    return completedSafetyCheckItems;
};
