import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from '../shared-state';
import { canView } from '../../shared-state/Core/userPermissions';
import { makeDateTime } from '../../lib/datesAndTime';
import { UserType } from '../Core/user';
import { renderFullName } from '../Core/users';
import { Drill } from './drills';
import { registerFiles, registerSignature } from '../FileSyncSystem/filesToCache';

//
// Load drill reports
//

export interface DrillReport extends CreateableDocument, UpdateableDocument {
    crewInvolvedIds: string[];
    drillIds: string[];
    /* @deprecated  - use `drillIds` instead. By saving crew data against the drill itself, we now only need the ID */
    drills: Drill[];
    equipment?: string;
    files: string[];
    furtherTraining?: string;
    location?: string;
    modification?: string;
    scenario?: string;
    signature: string;
    state: string;
    vesselId: string;
    whenCompleted: number;
    whenDue?: number;
    lastDone?: number;
    daysOverdue?: number;
    history?: string;
}

export type DrillReportsData = {
    users: UserType[];
    byUserId: {
        [userId: string]: DrillReport[];
    };
    byDrillAndUser: {
        [compositeId: string]: {
            whenDue: number;
            whenDueDiff: number;
            report: DrillReport;
        };
    };
    allReports: DrillReport[];
    byDrillId: {
        [drillId: string]: DrillReport[];
    };
    byId: {
        [drillId: string]: DrillReport;
    };
};

export const drillReportsConfig: SharedStateConfig<DrillReportsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'users', 'vesselDrills', 'todayMillis'],
    countLiveDocs: () => sharedState.drillReports.current?.allReports.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const users = sharedState.users.current;
        const vesselDrills = sharedState.vesselDrills.current;
        const todayMillis = sharedState.todayMillis.current;
        if (vesselId && users && vesselDrills && canView('drills')) {
            return onSnapshot(
                query(collection(firestore, 'drillReports'), where('vesselId', '==', vesselId), where('state', '==', 'active'), orderBy('whenCompleted', 'desc')),
                (snap) => {
                    done();
                    const reports = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as DrillReport;
                    });

                    // We need: byTaskAndUser --> will be set to the latest report
                    // We need: users --> users that have completed at least one drill, sorted alphabetically
                    const byDrillAndUser = {} as {
                        [compositeId: string]: {
                            whenDue: number;
                            whenDueDiff: number;
                            report: DrillReport;
                        };
                    };
                    const byUserId = {} as {
                        [userId: string]: DrillReport[];
                    };
                    const drillUsers = [] as UserType[];

                    const today = makeDateTime(todayMillis);
                    reports.forEach((report: DrillReport) => {
                        // report.whenDueDiff = Math.round(
                        //     makeDateTime(report.whenDue).diff(today, ['days']).days
                        // );

                        report.crewInvolvedIds?.forEach((userId: string) => {
                            if (users?.byId[userId]?.state === 'active' && users.byId[userId].vesselIds?.indexOf(report.vesselId) !== -1) {
                                if (!byUserId[userId]) {
                                    byUserId[userId] = [];
                                    drillUsers.push(users.byId[userId]);
                                }
                                byUserId[userId].push(report);

                                report.drills?.forEach((drill: Drill) => {
                                    if (vesselDrills.byId[drill.id]?.state === 'active') {
                                        const compositeId = `${drill.id}${userId}`;
                                        if (byDrillAndUser[compositeId] === undefined) {
                                            byDrillAndUser[compositeId] = {
                                                whenDue: drill.whenDue as number,
                                                whenDueDiff: Math.round(makeDateTime(drill.whenDue).diff(today, ['days']).days),
                                                report,
                                            };
                                        } else if ((drill.whenDue || 0) > byDrillAndUser[compositeId].whenDue) {
                                            byDrillAndUser[compositeId].whenDue = drill.whenDue as number;
                                            byDrillAndUser[compositeId].whenDueDiff = Math.round(makeDateTime(drill.whenDue).diff(today, ['days']).days);
                                        }
                                    }
                                });
                            }
                        });
                    });
                    drillUsers.sort((a, b) => {
                        return renderFullName(a).localeCompare(renderFullName(b));
                    });

                    const allReports = [] as DrillReport[];
                    const byDrillId = {} as {
                        [drillId: string]: DrillReport[];
                    };
                    const byId = {} as {
                        [drillId: string]: DrillReport;
                    };

                    reports.forEach((report: DrillReport) => {
                        registerFiles(report.files, 'drillReports', report);
                        registerSignature(report.signature, 'drillReports', report);
                        byId[report.id] = report;
                        allReports.push(report);

                        report.drills?.forEach((drill: Drill) => {
                            if (byDrillId[drill.id] === undefined) {
                                byDrillId[drill.id] = [];
                            }
                            byDrillId[drill.id].push(report);
                        });
                    });

                    set({
                        users: drillUsers,
                        byUserId,
                        byDrillAndUser,
                        allReports,
                        byDrillId,
                        byId,
                    });
                },
                (error) => {
                    done();
                    console.log(`Failed to access drill reports for vessel id=${vesselId}`, error);
                }
            );
        } else {
            done();
        }
    },
};
