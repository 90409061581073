import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { renderCategoryName } from "../../lib/categories";
import { Equipment } from './equipment';
import { warnDays } from "../../lib/datesAndTime";

export interface ScheduledMaintenanceTask extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    description?: string;
    engineHoursDue?: number;
    engineHoursLastService?: number;
    engineId?: string;
    equipment?: Equipment;
    equipmentId: string;
    intervalEngineHours?: number;
    intervalType: string;
    intervalWeekMonth?: string;
    location?: string;
    locationId?: string;
    state: string;
    task?: string;
    vesselId: string;
    whenDeleted?: number;
    whenDue?: number;
    whenLastService: number;
    priority?: number;
    searchText?: string;
    maintenanceTags?: string[];
    useHours?: boolean;
    engineHoursLeft?: number;
    hasFault?: boolean;
    isCritical?: boolean;
    files?: string[];
    estimatedTime?: number;
}

export type ScheduledMaintenanceTasksData = {
    count: number;
    prioritised: ScheduledMaintenanceTask[];
    byId: { [id: string]: ScheduledMaintenanceTask };
    systemIds: string[];
    bySystemId: { [id: string]: ScheduledMaintenanceTask[] };
    equipmentIds: string[];
    byEquipmentId: { [id: string]: ScheduledMaintenanceTask[] };
    filterOptions: {
        systemIds: string[];
        equipmentIds: string[];
    };
    top5: ScheduledMaintenanceTask[];
    numHighestPriority: number;
};

export const scheduledMaintenanceTasksConfig: SharedStateConfig<ScheduledMaintenanceTasksData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSystems', 'vesselLocations', 'equipment', 'engines', 'todayMillis'], 
    countLiveDocs: () => sharedState.scheduledMaintenanceTasks.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSystems = sharedState.vesselSystems.current;
        const vesselLocations = sharedState.vesselLocations.current;
        const equipment = sharedState.equipment.current;
        const engines = sharedState.engines.current;
        const todayMillis = sharedState.todayMillis.current;
        if (
            vesselId &&
            vesselSystems?.byId &&
            vesselLocations?.byId &&
            equipment?.byId &&
            engines?.byId &&
            canView('maintenanceSchedule')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'scheduledMaintenanceTasks'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active')
                ),
                (snap) => {
                    done();
                    const rawScheduledMaintenanceTasks = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as ScheduledMaintenanceTask;
                    });
                    // Decorate tasks with a priority property that we can sort by
                    // We will relate days to engine hours: 7 days = 50 engine hours
                    const byId = {} as {
                        [id: string]: ScheduledMaintenanceTask
                    };
                    const bySystemId = {} as {
                        [id: string]: ScheduledMaintenanceTask[]
                    };
                    const byEquipmentId = {} as {
                        [id: string]: ScheduledMaintenanceTask[]
                    };

                    rawScheduledMaintenanceTasks.forEach((task) => {
                        task.searchText = task.task;
                        // Inject equipment item
                        task.equipment = equipment.byId[task.equipmentId];
                        if (task.equipment) {
                            if (byEquipmentId[task.equipmentId] === undefined) {
                                byEquipmentId[task.equipmentId] = [];
                            }
                            byEquipmentId[task.equipmentId].push(task);
                            task.searchText += task.equipment.equipment;
                            task.searchText += renderCategoryName(
                                task.equipment.locationId,
                                vesselLocations
                            );
                            if (task.equipment.make)
                                task.searchText += task.equipment.make;
                            if (task.equipment.model)
                                task.searchText += task.equipment.model;
                            if (task.equipment.serial)
                                task.searchText += task.equipment.serial;
                            if (task.maintenanceTags) {
                                task.searchText += task.maintenanceTags.join('');
                            }
                        }
                        if (task.description) task.searchText += ` ${task.description}`;
                        byId[task.id] = task;
                        task.priority = Number.MAX_SAFE_INTEGER;
                        task.useHours = false;
                        if (task.whenDue) {
                            // Calculate days away relative to today (lesser number = higher priority)
                            let weekMonthOrder = task.whenDue - (todayMillis as number);
                            weekMonthOrder /= 24 * 60 * 60 * 1000; // Convert to days
                            task.priority = weekMonthOrder;
                        }
                        if (
                            task.engineId &&
                            task.engineHoursDue &&
                            engines?.byId[task.engineId]?.hours !== undefined
                        ) {
                            // Calculate engine hours away relative to current engine hours (lesser number = higher priority)
                            task.engineHoursLeft =
                                task.engineHoursDue - engines.byId[task.engineId].hours;
                            const engineHoursOrder = (task.engineHoursLeft / 50) * 7; // Convert to 'engine days'
                            if (engineHoursOrder < task.priority) {
                                task.useHours = true;
                                task.priority = engineHoursOrder;
                            }
                        }
                        if (task.priority === Number.MAX_SAFE_INTEGER) {
                            task.priority = 0; // Safety net
                        }

                        if (task.equipment?.systemId) {
                            const system = vesselSystems.byId[task.equipment.systemId];
                            if (system) {
                                task.searchText += ` ${system.name}`;
                            }
                            if (bySystemId[task.equipment.systemId] === undefined) {
                                bySystemId[task.equipment.systemId] = [];
                            }
                            bySystemId[task.equipment.systemId].push(task);
                        }

                        task.searchText = task.searchText?.toLowerCase();
                    });

                    // prioritised (using mixed sorting)
                    let prioritised = [...rawScheduledMaintenanceTasks]
                    prioritised.sort((a, b) => {
                        return (a.priority || 0) - (b.priority || 0);
                    });
                    let numHighestPriority = 0;
                    for (let i = 0; i < prioritised.length; i++) {
                        if ((prioritised[i].priority || 0) < warnDays.maintenanceSchedule[0]) {
                            numHighestPriority++;
                        }
                        if ((prioritised[i].priority || 0) >= warnDays.maintenanceSchedule[warnDays.maintenanceSchedule.length - 1]) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        }
                    }
                    const top5 = prioritised.slice(0, 5).filter(p => (p.priority || 0) < warnDays.maintenanceSchedule[0]);
                    const systemIds = [] as string[];
                    vesselSystems.ids?.forEach((id: string) => {
                        if (bySystemId[id]) {
                            systemIds.push(id);
                            bySystemId[id].sort((a, b) => {
                                return `${(a.equipment as Equipment)?.equipment || ''}${a.task || ''}`.localeCompare(
                                    `${(b.equipment as Equipment)?.equipment || ''}${b.task || ''}`
                                );
                            });
                        }
                    });

                    const equipmentIds = [] as string[];
                    equipment.all?.forEach((item: Equipment) => {
                        if (byEquipmentId[item.id]) {
                            equipmentIds.push(item.id);
                        }
                    });

                    const filterOptions = {
                        systemIds,
                        equipmentIds
                    };

                    set({
                        filterOptions,
                        count: prioritised.length,
                        prioritised, // due within last in array warnDays.maintenanceSchedule (90)
                        top5,
                        byId,
                        systemIds,
                        bySystemId, // Note: the same object is also within filterOptions
                        equipmentIds,
                        byEquipmentId,
                        numHighestPriority
                    });
                    
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access scheduledMaintenanceTasks for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    }
};
