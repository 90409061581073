import React, { useState } from 'react';
import { canEdit } from '../../../../shared-state/Core/userPermissions';
import { getBasedOnBackgroundColour } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import { openContextualHelp } from '../../../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaModalScrollableArea from '../../../../components/SeaModalScrollableArea/SeaModalScrollableArea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import EditRiskMatrix from '../EditRiskMatrix/EditRiskMatrix';
import './ViewRiskMatrix.css';
import RiskMatrixPdf from '../../../../exports/HealthSafety/Risks/RiskMatrixPdf';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

interface Consequence {
    name: string,
    description: string,

}

interface ViewRiskMatrixProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    allowEdit: boolean,
    level: number,
    likelihoodId: number,
    consequenceId: number,
    type: string
}

const ViewRiskMatrix: React.FC<ViewRiskMatrixProps> = ({showModal, setShowModal, allowEdit, level, likelihoodId, consequenceId, type}) => {
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const [showEditRiskMatrixModal, setShowEditRiskMatrixModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    const onEditMatrix = () => {
        setShowEditRiskMatrixModal(true);
    };


    return (
        <SeaModal
            title={type ? `${type === 'preControls' ? 'Pre' : 'Post'} Controls Risk` : 'Risk Matrix'}
            showModal={showModal}
            setShowModal={setShowModal}
            size="semi-wide"
            level={level}
            onPdf={exportType === 'pdf' && <RiskMatrixPdf onCompleted={() => setExportType(undefined)} />}
            setExportType={setExportType}
        >
            {licenseeSettings?.riskRegister &&
                <>
                    {type &&
                        <>
                            This describes the inherent risk {type === 'preControls' ? 'BEFORE' : 'AFTER'} our controls are put into place.
                            <p>
                                A risk rating is derived from both the likelihood and consequence categories selected (see the tables below).
                            </p>

                            <h2 className="matrix-table-heading">Risk Matrix</h2>
                        </>
                    }
                    <SeaModalScrollableArea>
                        <table className="risk-matrix">
                            <tbody>
                                <tr>
                                    <td className="no-border">{type && <b>Risk Rating</b>}</td>
                                    <td
                                        className="type"
                                        colSpan={licenseeSettings.riskRegister.consequences.columns.length}
                                    >
                                        Consequence
                                    </td>
                                </tr>
                                <tr>
                                    <td className="type">
                                        Likelihood
                                    </td>
                                    {licenseeSettings.riskRegister.consequences.columns.map((id: number) => {
                                        const consequence = licenseeSettings.riskRegister.consequences[`C${id}`] as Consequence;
                                        return (
                                            <td
                                                key={id}
                                                className={`label no-select${ id === consequenceId ? ' selected' : '' }`}
                                                onClick={(e) => {
                                                    openContextualHelp(e, {
                                                        text: consequence.description
                                                    });
                                                }}
                                            >
                                                {consequence.name}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {licenseeSettings.riskRegister.likelihoods.rows.map((rowId: number) => {
                                    const likelihood = licenseeSettings.riskRegister.likelihoods[`L${rowId}`] as Consequence;
                                    return (
                                        <tr key={rowId}>
                                            <td
                                                className={`label no-select${ rowId === likelihoodId ? ' selected' : '' }`}
                                                onClick={(e) => {
                                                    openContextualHelp(e, {
                                                        text: likelihood.description
                                                    });
                                                }}
                                            >
                                                {likelihood.name}
                                            </td>
                                            {licenseeSettings.riskRegister.consequences.columns.map((columnId: number) => {
                                                const raw = licenseeSettings.riskRegister.matrix[`${rowId}-${columnId}`];
                                                const colour = raw.substring(0, 6);
                                                const name = raw.substring(7);
                                                return (
                                                    <td
                                                        key={columnId}
                                                        style={{
                                                            backgroundColor: `#${colour}`,
                                                            color: getBasedOnBackgroundColour(colour)
                                                        }}
                                                        className={(rowId === likelihoodId && columnId === consequenceId) ? 'selected' : ''}
                                                    >
                                                        {name}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SeaModalScrollableArea>
                    <h2 className="matrix-table-heading">Evaluation Table</h2>
                    <SeaModalScrollableArea>
                        <table className="risk-matrix simple">
                            <tbody>
                                <tr>
                                    <td className="type">Evaluation</td>
                                    <td className="type" style={{ textAlign: 'left' }}>Description</td>
                                </tr>
                                {licenseeSettings.riskRegister.evaluations.map((item) => {
                                    const selectedName = licenseeSettings?.riskRegister?.matrix[`${likelihoodId}-${consequenceId}`]?.substring(7)?.toLowerCase();
                                    return (
                                        <tr key={item.name}>
                                            <td
                                                className={(selectedName && selectedName === (item?.name?.toLowerCase())) ? 'selected' : ''}
                                                style={{
                                                    backgroundColor: `#${item.color}`,
                                                    color: getBasedOnBackgroundColour(item.color)
                                                }}
                                            >
                                                {item.name}
                                            </td>
                                            <td className="description">{item.description}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SeaModalScrollableArea>
                    <h2 className="matrix-table-heading">Likelihood Table</h2>
                    <SeaModalScrollableArea>
                        <table className="risk-matrix simple">
                            <tbody>
                                <tr>
                                    <td className="type">Category</td>
                                    <td className="type" style={{ textAlign: 'left' }}>Description</td>
                                </tr>
                                {licenseeSettings.riskRegister.likelihoods.rows.map((id: number) => {
                                    const likelihood = licenseeSettings.riskRegister.likelihoods[`L${id}`] as Consequence;
                                    return (
                                        <tr key={id}>
                                            <td className={`label${ id === likelihoodId ? ' selected' : '' }`}>{likelihood.name}</td>
                                            <td className="description">{likelihood.description}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SeaModalScrollableArea>
                    <h2 className="matrix-table-heading">Consequence Table</h2>
                    <SeaModalScrollableArea>
                        <table className="risk-matrix simple">
                            <tbody>
                                <tr>
                                    <td className="type">Category</td>
                                    <td className="type" style={{ textAlign: 'left' }}>Description</td>
                                </tr>
                                {licenseeSettings.riskRegister.consequences.columns.map((columnId: number) => {
                                    const consequence = licenseeSettings.riskRegister.consequences[`C${columnId}`] as Consequence;
                                    return (
                                        <tr key={columnId}>
                                            <td className={`label${ columnId === consequenceId ? ' selected' : '' }`}>{consequence.name}</td>
                                            <td className="description">{consequence.description}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SeaModalScrollableArea>
                </>
            }

            {allowEdit &&
                <div className="view-modal-buttons">
                    {canEdit('hazardRegister') &&
                        <SeaButton
                            zone="white"
                            onClick={(e) => onEditMatrix()}
                        >
                            <SeaIcon icon="edit" slot="start" />
                            Edit Risk Matrix
                        </SeaButton>
                    }
                </div>
            }

            {showModal &&
                <>
                    <EditRiskMatrix
                        showModal={showEditRiskMatrixModal}
                        setShowModal={setShowEditRiskMatrixModal}
                    />
                    
                </>
            }
        </SeaModal>
    );
};

export default ViewRiskMatrix;
