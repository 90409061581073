import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { injuryOutcomes } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaPieGraph from '../../../../components/reporting/SeaPieGraph/SeaPieGraph';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import InjuryOutcomesReportPdf from '../../../../exports/Reporting/HealthSafety/InjuryOutcomesReportPdf';
import './ViewInjuryOutcomesReport.css';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

interface ViewIncidentInjuryOutcomesReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
}

const ViewIncidentInjuryOutcomesReport: React.FC<ViewIncidentInjuryOutcomesReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredIncidents,
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (showModal) {
            logModalView('IncidentTypesReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={exportType === 'pdf' &&
                <InjuryOutcomesReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredIncidents={filteredIncidents}
                />
            }
            setExportType={setExportType}
        >
            <SeaReportSettings mode="modal" />

            <SeaPieGraph
                mode="modal"
                data={graphData}
                visible={showModal}
                showAllLabels={true}
            />

            <div className="reporting-data">
                <SeaNoData
                    dataName="incidents with outcomes"
                    isLoading={!filteredIncidents}
                    hasNoData={filteredIncidents?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`incidents by-injury ${(filteredIncidents && filteredIncidents.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Outcome</div>
                        <div>Name</div>
                        <div>Vessel</div>
                    </div>
                    {mapArrayWithLimit(filteredIncidents, (incident: Incident) => {
                        return (
                            <div
                                key={incident.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    {formatDate(incident.whenEvent)}
                                </div>
                                <div className="truncate">
                                    {incident.injuries?.map((injury) => injury.outcome ? injuryOutcomes[injury.outcome] : '').join(', ')}
                                </div>
                                <div>
                                    {formatValue(incident.name)}
                                </div>
                                <div>
                                    {renderVesselName(incident.vesselId)}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
        </SeaModal>
    );
};

export default ViewIncidentInjuryOutcomesReport;
