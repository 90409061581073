import React, { useEffect, useMemo, useState } from 'react';
import { formatTextAreaText, toFloat } from '../../lib/util';
import { formatSeaDate, formatSeaDatetime, formatDateLonger, formatDatetimeLonger } from '../../lib/datesAndTime';
import { IonSelectOption } from '@ionic/react';
import { sharedState } from '../../shared-state/shared-state';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { VesselOptions } from '../../shared-state/Core/vessels';
import { seaFilesToValue } from '../../lib/files';
import SeaContextualHelp, { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaCheckbox from '../SeaCheckbox/SeaCheckbox';
import SeaInput from '../SeaInput/SeaInput';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaDate from '../SeaDate/SeaDate';
import SeaDatetime from '../SeaDatetime/SeaDatetime';
import SeaSignature from '../SeaSignature/SeaSignature';
import SeaFileUpload from '../SeaFileUpload/SeaFileUpload';
import SeaChecks from '../SeaChecks/SeaChecks';
import SeaFileImage from '../SeaFileImage/SeaFileImage';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaTextarea from '../SeaTextarea/SeaTextarea';
import SeaYesNo from '../SeaYesNo/SeaYesNo';
import SeaMultiSelect from '../SeaMultiSelect/SeaMultiSelect';
import './CustomFormElement.css';

export interface CustomFormElementType {
    id: string,
    width: number,
    name?: string,
    label?: string,
    type?: "number" | "search" | "time" | "text" | "tel" | "url" | "email" | "date" | "datetime-local" | "month" | "password" | "week" | undefined,
    required?: boolean,
    requiredError?: string,
    options?: string[],
    lines?: number,
    heading?: string,
    size?: string,
    text?: string,
    isSpecial?: boolean,
    n?: number,
    o?: number,
    allowMultiple?: boolean,
    forVesselIds?: string[],
    vesselOptions?: VesselOptions[],
    height?: number,
    error?: string,
    value?: any,
    initialValue?: any,
    isDirty?: boolean,
    help?: SeaHelp,
    setValue?: (value: any) => void,
    onChange?: (value: any) => void,
    onBlur?: (value: any) => void,
    onFocus?: (value: any) => void,
    onKeyUp?: (value: any) => void,
    onEnter?: (value: any) => void,
    onLeave?: (value: any) => void,
    onInput?: (value: any) => void,
}

interface CustomFormElementProps {
    element: CustomFormElementType,
    setElement?: (element: CustomFormElementType) => void,
    mode?: 'edit' | 'complete' | 'view' | 'pdf',
    selectedElement: CustomFormElementType | undefined,
    onSelectElement: (selectedElement: CustomFormElementType | undefined) => void,
    hasSubmitted?: boolean,
    // value?: any,
    // setValue?: (value: any) => void
}

const CustomFormElement: React.FC<CustomFormElementProps> = ({ element, setElement, mode, selectedElement, onSelectElement, hasSubmitted }) => {
    const vessels = sharedState.vessels.use();
    const [isTouched, setIsTouched] = useState(false);

    const editElement = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        onSelectElement(element);
    };

    const isSelected = (element === selectedElement);
    const isViewMode = (mode === 'view');

    // Make sure we have processed any error present at initialisation
    useEffect(() => {
        if (element && setElement && element.error === undefined && mode === 'complete') {
            setElement({
                ...element,
                error: determineError(element.value),
            });
        }
    }, [element]);

    const changeValue = (newValue: any) => {
        if (setElement) {
            setElement({
                ...element,
                value: newValue,
                error: determineError(newValue),
                isDirty: isDirty(newValue)
            })
        }
    };

    const determineError = (value: any) => {
        if (element.required && !value) {
            return 'This field is required';
        }
        if (element.id === 'input') {
            if (value.length > 500) {
                return 'Must be less than 500 characters';
            }
            // if (element.type === 'number') {
            //     console.log(`value`, value);
            //     try {
            //         if (isNaN(parseFloat(value))) {
            //             return 'Must be a valid number';
            //         }
            //     } catch (e) {
            //         return 'Must be a valid number';
            //     }
            // }
        }
        return '';
    }

    const isDirty = (value: any) => {
        return element.initialValue !== value;
    };

    const getViewOnlyTextElement = (element: CustomFormElementType, value: any, minHeight?: number) => {
        return <>
            {element.label &&
                <SeaLabel>{element.label}</SeaLabel>
            }
            <div className='sea-input view-only-input' style={{ minHeight: minHeight ? (minHeight+'px') : 'var(--input-height)' }}>
                {value}
            </div>
        </>;
    };

    const getHelpfulLink = (element: CustomFormElementType, mode: "checkbox" | "label" | "text" | undefined ) => {
        if (element.help) {
            return (
                <SeaContextualHelp
                    help={element.help}
                    mode={mode}
                />
            );
        }
        return undefined;
    };
    const getHelpfulLabel = (element: CustomFormElementType) => {
        if (element.label || element.help) {
            return (
                <SeaLabel help={element.help}>
                    {element.label}
                </SeaLabel>
            );
        }
        return undefined;
    }

    const content = useMemo(() => {
        switch (element.id) {
            case 'heading':
                return <h2 className={`cf-${element.size}`}>
                    {element.heading}
                    {getHelpfulLink(element, 'text')}
                </h2>;
            case 'text':
                return <div className={`cf-${element.size}`}>
                    {formatTextAreaText(element.text)}
                    {getHelpfulLink(element, 'text')}
                </div>;
            case 'line':
                return <div><div className="dividing-line"></div></div>
            case 'spacer':
                return <div><div style={{ height: toFloat(element.height, 20)+'px'}}></div></div>
            case 'checkbox':
                return <>
                    <SeaCheckbox
                        name={`cb${element.n}`}
                        checked={element.value ? true : false}
                        setFieldValue={(name: string, value: boolean) => {
                            changeValue(value);
                            setIsTouched(true);
                        }}
                        error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                        viewOnly={isViewMode}
                        //disabled={isViewMode}
                    >
                        {element.label}
                        {getHelpfulLink(element, 'checkbox')}
                    </SeaCheckbox>
                </>;
            case 'input':
                return isViewMode ? (
                    getViewOnlyTextElement(element, formatTextAreaText(element.value))
                ) : (<>
                    {getHelpfulLabel(element)}
                    <SeaInput
                        value={element.value}
                        onchange={(e) => changeValue(e.detail.value)}
                        onblur={(e: any) => {
                            setIsTouched(true);
                            changeValue(e.srcElement.value);
                        }}
                        type={element.type ? element.type : 'text'}
                        inputmode={element.type === 'number' ? 'numeric' : undefined}
                        error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                    />
                </>);
            case 'textarea':
                return isViewMode ? (
                    getViewOnlyTextElement(
                        element,
                        formatTextAreaText(element.value),
                        toFloat(element.lines, 3) * 15 + 37
                    )
                ) : (<>
                    {getHelpfulLabel(element)}
                    <SeaTextarea
                        value={element.value}
                        onchange={(e) => changeValue(e.detail.value)}
                        onblur={(e: any) => {
                            setIsTouched(true);
                            changeValue(e.srcElement.value);
                        }}
                        height={
                            37 + (
                                15 * toFloat(element.lines, 0)
                            )
                        }
                        error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                    />
                </>);
            case 'dropdown':
                return (<>
                    {getHelpfulLabel(element)}
                    <div style={{ pointerEvents: isViewMode ? 'none' : 'all' }}>
                        <SeaSelect
                            name={`dd${element.n}`}
                            value={element.value ? element.value : ''}
                            onchange={(e) => {
                                setIsTouched(true);
                                changeValue(e.detail.value);
                            }}
                            error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                        >
                            <IonSelectOption key={element.n} value="">Not Set</IonSelectOption>
                            {element.options?.map((option: string, index: number) => {
                                return (
                                    <IonSelectOption key={`${element.n}-${index}`} value={option}>
                                        {option}
                                    </IonSelectOption>
                                );
                            })}
                        </SeaSelect>
                    </div>
                </>);
            case 'yesno':
                return (
                    <SeaYesNo
                        name={`cb${element.n}`}
                        setFieldValue={(name: string, value: (-1|0|1)) => {
                            changeValue(value);
                            setIsTouched(true);
                        }}
                        value={element.value === undefined ? 0 : element.value}
                        error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                        viewOnly={isViewMode}
                        //label={element.label}
                        disabled={isViewMode}
                    >
                        {element.label}
                        {getHelpfulLink(element, 'checkbox')}
                    </SeaYesNo>
                );
            case 'checks':
                return (<>
                    {getHelpfulLabel(element)}
                    <SeaChecks
                        //note="Please Note: You can edit these in your logbook settings."
                        data={element.options?.map((option: string, index: number) => {
                            return {
                                check: option,
                                value: element.value ? element.value[index] : ''
                            };
                        })}
                        setData={(data) => {
                            const values = [] as any[];
                            data.forEach((o: any) => {
                                values.push(o.value);
                            });
                            changeValue(values);
                        }}
                    />
                </>);
            case 'date':
                return isViewMode ? (
                    getViewOnlyTextElement(element, formatDateLonger(element.value))
                ) : (<>
                    {getHelpfulLabel(element)}
                    <SeaDate
                        name={`date${element.n}`}
                        value={element.value ? element.value : formatSeaDate()}
                        onchange={(e) => {
                            //changeValue(e.detail.value)
                            // Let onblur apply changes.
                            // This is to prevent the old value being reapplied
                        }}
                        onblur={(e) => {
                            setIsTouched(true);
                            changeValue(e.detail.target.value);
                        }}
                        error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                    />
                </>);
            case 'datetime':
                return isViewMode ? (
                    getViewOnlyTextElement(element, formatDatetimeLonger(element.value))
                ) : (<>
                    {getHelpfulLabel(element)}
                    <SeaDatetime
                        name={`dt${element.n}`}
                        value={element.value ? element.value : formatSeaDatetime()}
                        onchange={(e) => {
                            changeValue(e.detail.value);
                        }}
                        onblur={(e) => {
                            setIsTouched(true);
                        }}
                        error={(element.error && (isTouched || hasSubmitted)) ? element.error : ''}
                    />
                </>);
            case 'files':
                return isViewMode ? (
                    <>
                        {getHelpfulLabel(element)}
                        <SeaFileImage
                            files={seaFilesToValue(element.value)}
                            size="tiny"
                            showOthers={true}
                            mode="customForms"
                        />
                    </>
                ) : (<>
                    {getHelpfulLabel(element)}
                    <SeaFileUpload
                        files={element.value}
                        setFiles={(files) => {
                            setIsTouched(true);
                            changeValue(files);
                        }}
                        collection="customFormsCompleted"
                        field={`data.e${element.n}`}
                        disableDrop={mode === 'edit'}
                        contained
                        // allowDocuments={true}
                        // allowMultiple={true}
                    />
                </>);
            case 'signature':
                return (
                    <SeaSignature
                        collection="customFormsCompleted"
                        label={
                            <div>
                                {element.label}
                                {getHelpfulLink(element, 'label')}
                            </div>
                        }
                        field={`data.e${element.n}`}
                        file={element.value}
                        setFile={(file) => {
                            setIsTouched(true);
                            changeValue(file);
                        }}
                        isRequired={hasSubmitted && element.required}
                        isRequiredError="A signature here is required"
                        viewOnly={isViewMode}
                    />
                );
            case 'vessels':
                return isViewMode ?
                (
                    getViewOnlyTextElement(element, formatTextAreaText(
                        element.value.reduce((result: string, vesselId: string) => {
                            return `${result}${result.length > 0 ? ', ' : ''}${
                                (vessels?.byId && vessels.byId[vesselId])
                                ?
                                vessels.byId[vesselId].name
                                :
                                '???'
                            }`;
                        }, '')
                    ))
                ) : (
                    <SeaMultiSelect
                        label={element.label}
                        values={element.value}
                        //setValues={setForVesselIds}
                        //options={}
                        useAllOption={true}
                        //required={true}
                        //isSubmitting={isSubmitting}
                        emptyText="Not Set"
                    />
                );
            case 'crew':
                return isViewMode ?
                (
                    getViewOnlyTextElement(element, formatTextAreaText(
                        element.value.reduce((result: string, personnelId: string) => {
                            return `${result}${result.length > 0 ? ', ' : ''}${
                                renderFullNameForUserId(personnelId)
                            }`;
                        }, '')
                    ))
                ) : (
                    <SeaMultiSelect
                        label={element.label}
                        values={element.value}
                        //setValues={setForVesselIds}
                        //options={}
                        useAllOption={true}
                        emptyText="Not Set"
                        //required={true}
                        //isSubmitting={isSubmitting}
                    />
                );
        }
        return <></>;
    }, [element, hasSubmitted, isTouched]);

    if (mode === 'edit') {
        return <div className={`element editing ${element.id}`}>
            {content}
            <div className={`no-select overlay ${isSelected ? 'selected' : ''} ${element.isSpecial ? 'special' : ''}`} onClick={(e) => editElement(e)}>

            </div>
        </div>
    }

    if (isViewMode) {
        return <div className={`element editing ${element.id} view-only`}>
            {content}
            {/* <div className={`no-select overlay view-only`}>
            </div> */}
        </div>
    }
    return (
        <div className={`element ${isSelected ? 'selected' : ''}`}>
            {content}
        </div>
    );
};

export default CustomFormElement;
