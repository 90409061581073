import { useMemo, useState } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvNamesFromIds, formatCsvUserNamesFromIds } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { UserDetails } from '../../../shared-state/Crew/userDetails';
import { UserType } from '../../../shared-state/Core/user';
import { renderCategoryNames } from '../../../lib/categories';
import { renderCamelCase } from '../../../lib/util';
import { useLicenseeSeaTime } from '../../../shared-state/Crew/useLicenseeSeaTimeRecord';
import { SeaTimeRecord } from '../../../shared-state/Crew/useSkipperSeaTimeRecords';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { DateRange } from '../../../components/SeaDateRange/SeaDateRange';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';
import SeaExportFilterModal from '../../../components/SeaExporter/SeaExportFilterModal';

type CrewParticularsCsvProps = {
    onCompleted: () => void
}

type CrewParticulars = UserDetails & UserType

const formatSeaTimeHours = (hours: number[] | undefined) => {
    if (!hours) {
        return ''
    }
    return hours.reduce((sum, current) => sum + current, 0).toString();
}

const formatOfficialSeaTimeHours = (hours: number[] | undefined) => {
    if (!hours) {
        return ''
    }
    return (hours.reduce((sum, current) => sum + Math.min(current, 8), 0)).toString();
}

const CrewParticularsCsv = ({ onCompleted }: CrewParticularsCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const userDetails = sharedState.userDetails.use();
    const users = sharedState.users.use();
    const userRoles = sharedState.userRoles.use();
    const vessels = sharedState.vessels.use();
    const seaTime = useLicenseeSeaTime(dateRange);


    const csvConfig: CsvConfig<CrewParticulars> | undefined = useMemo(() => {
        if (!userRoles || !vessels || !seaTime) {
            return undefined;
        }
        return [
            {
                name: 'Name',
                value: (item: CrewParticulars) => renderFullNameForUserId(item.id)
            },
            {
                name: 'Email',
                value: (item: CrewParticulars) => item.email || ''
            },
            {
                name: 'Job title/position',
                value: (item: CrewParticulars) => item.position || ''
            },
            {
                name: 'Inducted date',
                value: (item: CrewParticulars) => formatCsvDate(item.whenInducted)
            },
            {
                name: 'Login access',
                value: (item: CrewParticulars) => formatCsvBoolean(item.isLoginDisabled)
            },
            // {
            //     name: 'Profile',
            //     value: (item: CrewParticulars) => item.profile || ''
            // },
            {
                name: 'Roles/Departments',
                value: (item: CrewParticulars) => renderCategoryNames(item.roleIds, userRoles)
            },
            {
                name: 'Date of Birth',
                value: (item: CrewParticulars) => formatCsvDate(item.dateOfBirth)
            },
            {
                name: 'Phone #',
                value: (item: CrewParticulars) => item.contactNumber || ''
            },
            {
                name: 'Address',
                value: (item: CrewParticulars) => item.address || ''
            },
            {
                name: 'Passport #',
                value: (item: CrewParticulars) => item.passportNumber || ''
            },
            {
                name: 'Next of Kin',
                value: (item: CrewParticulars) => item.nextOfKin?.name || ''
            },
            {
                name: 'Next of Kin relationship',
                value: (item: CrewParticulars) => item.nextOfKin?.relationship || ''
            },
            {
                name: 'Next of Kin contact',
                value: (item: CrewParticulars) => item.nextOfKin?.phone || ''
            },
            {
                name: 'Medical Doctor',
                value: (item: CrewParticulars) => item.medicalDoctor?.name || ''
            },
            {
                name: 'Doctor contact',
                value: (item: CrewParticulars) => item.medicalDoctor?.phone || ''
            },
            {
                name: 'Medical issues',
                value: (item: CrewParticulars) => item.currentMedicalIssues || ''
            },
            {
                name: 'Current medication',
                value: (item: CrewParticulars) => item.currentMedication || ''
            },
            {
                name: 'Previous injuries/surgeries',
                value: (item: CrewParticulars) => item.previousInjuryOrSurgery || ''
            },
            {
                name: 'Allergies',
                value: (item: CrewParticulars) => item.allergies || ''
            },
            {
                name: 'Blood Type',
                value: (item: CrewParticulars) => item.bloodType || ''
            },
            {
                name: 'Vessel/Facility access',
                value: (item: CrewParticulars) => formatCsvNamesFromIds(item.vesselIds, vessels?.byId)
            },
            {
                name: 'Status',
                value: (item: CrewParticulars) => renderCamelCase(item.state)
            },
            {
                name: 'Sea Time',
                items: (item: CrewParticulars) => seaTime.byUserId[item.id],
                columns: (item?: CrewParticulars) => [
                    {
                        name: 'Date',
                        value: (childItem: SeaTimeRecord) => formatCsvDate(childItem.whenVoyage)
                    },
                    {
                        name: 'Vessel',
                        value: (childItem: SeaTimeRecord) => vessels.byId[childItem.vesselId]?.name || ''
                    },
                    {
                        name: 'Skipper',
                        value: (childItem: SeaTimeRecord) => formatCsvUserNamesFromIds(childItem.masterIds)
                    },
                    {
                        name: 'From',
                        value: (childItem: SeaTimeRecord) => childItem.fromLocation || ''
                    },
                    {
                        name: 'To',
                        value: (childItem: SeaTimeRecord) => childItem.toLocation || ''
                    },
                    {
                        name: 'Days',
                        value: (childItem: SeaTimeRecord) => childItem.days?.toString() || ''
                    },
                    {
                        name: 'Hours',
                        value: (childItem: SeaTimeRecord) => formatSeaTimeHours(childItem.hours?.[item!.id])
                    },
                    {
                        name: 'Official hours',
                        value: (childItem: SeaTimeRecord) => formatOfficialSeaTimeHours(childItem.hours?.[item!.id])
                    },
                ]
            },
           
        ];
    }, [seaTime, userRoles, vessels]);

    const crewData = useMemo(() => {
        if (!users || !userDetails) {
            return undefined;
        }

        const crewData: CrewParticulars[] = [];

        for (const user of users.all) {
            if (!user.id) {
                continue;
            }
            const userDetail = userDetails.byId[user.id];

            crewData.push({
                ...user,
                ...userDetail,
            });
        }

        return crewData;

    }, [users, userDetails]);

    if (!dateRange) {
        return (
            <SeaExportFilterModal onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Crew-Particulars'} onCompleted={onCompleted} config={csvConfig} data={crewData} dateRange={dateRange} />
};

export default CrewParticularsCsv;