import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { sharedState } from "../shared-state";
import { MaintenanceTaskCompleted } from './maintenanceTasksCompleted';

type VesselMaintenanceTasksCompletedData = {
    all: MaintenanceTaskCompleted[];
    byMaintenanceId: Record<string, MaintenanceTaskCompleted[]>;
} | undefined

export const useVesselMaintenanceTasksCompleted = (dateRange: DateRange | undefined): VesselMaintenanceTasksCompletedData| undefined => {
    const [completedTasksData, setCompletedTasksData] = useState<VesselMaintenanceTasksCompletedData>();
    const vesselId = sharedState.vesselId.current;
    const equipment = sharedState.equipment.current;

    useEffect(() => {
        setCompletedTasksData(undefined);
    
        if (vesselId && equipment && dateRange) {
            return onSnapshot(
                query(
                    collection(firestore, 'maintenanceTasksCompleted'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'completed'),
                    where('whenCompleted', '>=', dateRange.from),
                    where('whenCompleted', '<=', dateRange.to + (24*60*60*1000)),
                    orderBy('whenCompleted', 'desc')
                ),
                (snap) => {
                    const rawMaintenanceTasksCompleted = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as MaintenanceTaskCompleted;
                    });
                    if (rawMaintenanceTasksCompleted) {
                        const all = [...rawMaintenanceTasksCompleted];
                        all.forEach((item) => {
                            if (item.equipmentId) {
                                item.equipment = equipment.byId[item.equipmentId];
                            }
                        });
            
                        const byMaintenanceId = {} as {
                            [taskId: string]: MaintenanceTaskCompleted[]
                        };
            
                        all.forEach((item: MaintenanceTaskCompleted) => {
                            if (item.maintenanceTaskId) {
                                if (
                                    byMaintenanceId[item.maintenanceTaskId] ===
                                    undefined
                                ) {
                                    byMaintenanceId[item.maintenanceTaskId] = [];
                                }
                                byMaintenanceId[item.maintenanceTaskId].push(item);
                            }
                        });

                        console.log('completedTasks', all.length, dateRange, byMaintenanceId);

                        setCompletedTasksData({ all, byMaintenanceId });
                    }
                },
                (error) => {
                    // This should be very rare
                    console.log(
                        `Failed to access maintenanceTasksCompleted for vessel ${vesselId}`,
                        error
                    );
                }
            )
        }
    }, [vesselId, dateRange, equipment]);

    return completedTasksData;
};