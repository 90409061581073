import { useState, useEffect } from 'react';
import { LinkType } from './links';
import { sharedState } from '../shared-state';

//
// Filters all links containing on a specific itemId.
//

export const useItemLinks = (itemId?: string) => {
    const _links = sharedState.links.use();
    const [links, setLinks] = useState<LinkType[]>();

    useEffect(() => {
        setLinks(undefined);
        if (itemId && _links?.byId) {
            const filteredLinks = Object.values(_links.byId).filter((link) => {
                return link.aId === itemId || link.bId === itemId;
            });
            setLinks(filteredLinks);
        }
    }, [itemId, _links]);

    return links;
};
