import React, { useEffect, useMemo, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, formatInterval, warnDays } from '../../../../lib/datesAndTime';
import { renderFullName } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { sharedState } from '../../../../shared-state/shared-state';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import { DrillReport } from '../../../../shared-state/VesselSafety/drillReports';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import ViewDrillReport from '../ViewDrillReport/ViewDrillReport';
import './ViewDrillUser.css';
import drills from '../../../../pages/VesselSafety/Drills/Drills';

interface ViewDrillUserProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedDrill: Drill;
    selectedUser: UserType | undefined;
}

const ViewDrillUser: React.FC<ViewDrillUserProps> = ({ showModal, setShowModal, selectedDrill, selectedUser }) => {
    const drillReports = sharedState.drillReports.use(showModal);
    const [drillUserHistory, setDrillUserHistory] = useState<DrillReport[]>();
    const [whenDue, setWhenDue] = useState(0);
    const [selectedReport, setSelectedReport] = useState<DrillReport>();
    const [showDrillReportModal, setShowDrillReportModal] = useState(false);

    // Keep selectedReport fresh
    useEffect(() => {
        if (selectedReport?.id && drillReports?.byId[selectedReport.id]) {
            setSelectedReport(drillReports.byId[selectedReport.id]);
        }
    }, [drillReports, selectedReport?.id]);

    useEffect(() => {
        setDrillUserHistory(undefined);
        setWhenDue(0);
        if (showModal && selectedDrill?.id && selectedUser?.id && drillReports?.byUserId[selectedUser.id]) {
            const history: DrillReport[] = [];
            const reports = drillReports.byUserId[selectedUser.id];
            let foundOne = false;
            reports.forEach((report) => {
                if (selectedUser.id && report.crewInvolvedIds.indexOf(selectedUser.id) !== -1) {
                    report?.drills.forEach((drill: Drill) => {
                        if (drill.id === selectedDrill.id) {
                            history.push(report);
                            if (!foundOne) {
                                foundOne = true;
                                if (!!selectedDrill.crew && !!selectedUser.id && selectedDrill.crew[selectedUser.id!]) {
                                    // If the drill has been migrated, use the whenDue off the drill
                                    setWhenDue(selectedDrill.crew[selectedUser.id].whenDue);
                                } else {
                                    setWhenDue(drill.whenDue || 0);
                                }
                            }
                        }
                    });
                }
            });
            setDrillUserHistory(history);
        }
    }, [showModal, selectedDrill, selectedUser, drillReports]);

    const onViewDrillReport = (report: DrillReport) => {
        setSelectedReport(report);
        setShowDrillReportModal(true);
    };

    const isUserAssignedToDrill = useMemo(() => {
        if (!selectedUser?.id || !selectedDrill.notAssignedTo) return true; // Default to true

        return !selectedDrill.notAssignedTo.includes(selectedUser.id);
    }, [selectedUser, selectedDrill]);

    const isDrillMissing = useMemo(() => {
        if (!drillUserHistory) return true;

        return drillUserHistory?.length === 0;
    }, [drillUserHistory]);

    return (
        <SeaModal
            title={
                <>
                    <span style={{ marginRight: '12px' }}>
                        {renderFullName(selectedUser)} - {selectedDrill?.name}
                    </span>
                    {!!whenDue && isUserAssignedToDrill && <SeaStatusDueDate whenDue={whenDue} warnDays={warnDays.drills[0]} inline={true} />}
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="semi-thin"
        >
            <SeaGrid>
                <SeaGridCell label="Drill Interval" w="50">
                    {formatInterval(selectedDrill?.interval)}
                </SeaGridCell>
                <SeaGridCell label="Next Due" w="50">
                    {!!whenDue && formatDate(whenDue)}
                </SeaGridCell>
            </SeaGrid>

            <div className="end-info-line"></div>

            <div className="columns">
                <h2>Drill History</h2>
            </div>

            <SeaNoData dataName="drill" isHistory={true} isLoading={!drillUserHistory && !isDrillMissing} hasNoData={drillUserHistory?.length === 0 || isDrillMissing} />
            <div className={`drill-user-history ${drillUserHistory && drillUserHistory.length > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Location</div>
                </div>
                {drillUserHistory?.map((report) => {
                    return (
                        <div key={report.id} className="sea-card sea-row-history clickable" onClick={(e) => onViewDrillReport(report)}>
                            <div>{formatDate(report.whenCompleted)}</div>
                            <div className="truncate-3">{formatValue(report.location)}</div>
                        </div>
                    );
                })}
            </div>
            {showModal && selectedReport && <ViewDrillReport showModal={showDrillReportModal} setShowModal={setShowDrillReportModal} selectedItem={selectedReport} level={2} />}
        </SeaModal>
    );
};

export default ViewDrillUser;
