import React, { useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import SeaDateRange, { DateRange } from '../SeaDateRange/SeaDateRange';
import SeaModal from '../SeaModal/SeaModal';
import SeaButton from '../SeaButton/SeaButton';

type SeaExporterFilterProps = {
    onExport: (dateRange?: DateRange) => void;
    onClose: () => void; // Required as this is used to set exportType to undefined
    type?: 'csv' | 'pdf';
    hideDateRange?: boolean;
    level?: number;
    disabled?: boolean;
    children?: React.ReactNode;
}

const SeaExportFilterModal = ({onExport, onClose, type = 'csv', hideDateRange, level = 1, disabled, children}: SeaExporterFilterProps) => {
    const [showDateRangeModal, setShowDateRangeModal] = useState(true);
    const todayMillis = sharedState.todayMillis.use()!;
    const [dateRange, setDateRange] = useState<DateRange>({
        from: todayMillis - (365 * 24 * 60 * 60 * 1000),
        to: todayMillis + (1 * 24 * 60 * 60 * 1000)
    });

    const onSubmit = () => {
        onExport(hideDateRange ? undefined :dateRange);
        setShowDateRangeModal(false);
    }

    return (
        <SeaModal
            title={`Export ${type === 'csv' ? 'CSV' : 'PDF'}`}
            showModal={showDateRangeModal}
            setShowModal={setShowDateRangeModal}
            size="thin"
            noContentPadding={false}
            onClosed={onClose}
            level={level}
        >
             <IonGrid>
                {!hideDateRange && <IonRow>
                    <IonCol>
                        <SeaDateRange
                            label="Date Range"
                            zone="white"
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                    </IonCol>
                </IonRow>}
                {children && (
                    <IonRow>
                        <IonCol>
                            {children}
                        </IonCol>
                    </IonRow>
                )}
                <IonRow>
                    <IonCol>
                        <div className="view-modal-buttons">
                            <SeaButton disabled={disabled} onClick={onSubmit} size='wide'>
                                Export
                            </SeaButton>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </SeaModal>
    )
}

export default SeaExportFilterModal