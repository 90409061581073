import * as Yup from 'yup';

// Global form validation comments
Yup.setLocale({
  mixed: {
      required: 'This field is required',
  },
  string: {
      email: 'Please enter a valid email',
      url: 'Must be a valid URL',
      min: 'Must be at least ${min} characters', // eslint-disable-line no-template-curly-in-string
      max: 'Must be less than ${max} characters', // eslint-disable-line no-template-curly-in-string
  },
  date: {
    max: 'Cannot be in the future',
    min: 'Cannot be in the past'
  },
  number: {
    min: 'Must be at least ${min}', // eslint-disable-line no-template-curly-in-string
    max: 'Must be less than ${max}' // eslint-disable-line no-template-curly-in-string
  },
  array: {
    max: 'Cannot have more than ${max} engines' // eslint-disable-line no-template-curly-in-string
  }
})

export const notTooOld = [
  new Date(0),
  'This is over 50 years ago!'
] as [Date, string];

export default Yup
