import React, { useState, useMemo } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { formatTextAreaText, formatValue, formatCurrency } from '../../../../lib/util';
import { formatDate, formatEmailReminder, formatShortTimeDurationHrsMinsView } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { renderCategoryName } from '../../../../lib/categories';
import { SplittableBatch } from '../../../../lib/firebase';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Job } from '../../../../shared-state/VesselMaintenance/jobs';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditJobList from '../EditJobList/EditJobList';
import UpdateJobList from '../UpdateJobList/UpdateJobList';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import CompleteJobList from '../CompleteJobList/CompleteJobList';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import JobItemPdf from '../../../../exports/VesselMaintenance/JobList/JobItemPdf';

interface ViewJobListProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: Job,
}

const ViewJobList: React.FC<ViewJobListProps> = ({ showModal, setShowModal, selectedItem }) => {
    const equipment = sharedState.equipment.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
    const [showCompleteItemModal, setShowCompleteItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();
    const licenseeSettings = sharedState.licenseeSettings.use();

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    const onUpdateItem = () => {
        setShowUpdateItemModal(true);
    };

    const onCompleteJob = () => {
        setShowCompleteItemModal(true);
    };

    const selectedEquipment = useMemo(() => {
        if (
            equipment?.byId &&
            selectedItem?.equipmentId &&
            equipment.byId[selectedItem.equipmentId]
        ) {
            return equipment.byId[selectedItem.equipmentId];
        }
        return undefined;
    }, [equipment, selectedItem]);


    if (!selectedItem) {
        return <></>;
    }

    return (
        <SeaModal
            title="Job Item"
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            onOpened={() => {
                console.log(`Viewing Job ${selectedItem?.id}`);
            }}
            onPdf={exportType === 'pdf' && <JobItemPdf
                onCompleted={() => setExportType(undefined)}
                selectedItem={selectedItem}
            />}
            setExportType={setExportType}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Task" w="75">
                    {formatValue(formatTextAreaText(selectedItem?.task))}
                </SeaGridCell>
                <SeaGridCell label="Job #" w="25">
                    {formatValue(selectedItem?.jobNum)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Description" w="100">
                    {formatTextAreaText(selectedItem?.description)}
                    <RequirePermissions
                        role="jobList"
                        level={permissionLevels.COMPLETE}
                    >
                        {selectedItem?.description &&
                            <div style={{ height: '10px' }}></div>
                        }
                        <SeaButton onClick={(e) => onUpdateItem()}>Update Task</SeaButton>
                    </RequirePermissions>
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Priority" w="33">
                    {selectedItem?.priority && selectedItem?.priority.charAt(1).toUpperCase() + selectedItem?.priority.slice(2)}
                </SeaGridCell>
                <SeaGridCell label="Assigned To" w="33">
                    {formatValue(selectedItem?.assignedTo?.name)}
                    {selectedItem?.assignedTo?.contactId && ' (Contact)'}
                </SeaGridCell>
                <SeaGridCell label="Job Tags" w="33">
                    {formatValue(selectedItem?.tags?.join(', '))}
                </SeaGridCell>
            </SeaGrid>

            <SeaGrid>
                <SeaGridCell label="System" w="33">
                    {formatValue(renderCategoryName(selectedEquipment?.systemId, vesselSystems))}
                </SeaGridCell>
                <SeaGridCell label="Equipment" w="33">
                    {formatValue(selectedEquipment?.equipment)}
                    {selectedEquipment?.state === 'deleted' && ' (deleted)'}
                </SeaGridCell>
                <SeaGridCell label="Location" w="33">
                    {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Maintenance Tags" w="33">
                    {formatValue(selectedItem?.maintenanceTags?.join(', '))}
                </SeaGridCell>
                <SeaGridCell label="Critical Equipment" w="33" help={{ text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew. You can change whether an equipment item is critical or not through the Equipment List page.' }}>
                    {formatValue(selectedEquipment ? selectedEquipment.isCritical ? 'Yes' : 'No' : '')}
                </SeaGridCell>
                <SeaGridCell label="Due Date" w="33">
                    {formatValue(selectedItem?.whenDue && formatDate(selectedItem?.whenDue))}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Email Reminder" w="33">
                    {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                </SeaGridCell>
                <SeaGridCell label="Estimated Job Cost" w="33">
                    {selectedItem?.estimatedCost ? formatCurrency(selectedItem.estimatedCost, 2) : '-'}
                </SeaGridCell>
                <SeaGridCell label="Added by" w="33">
                    {selectedItem?.addedBy ? formatValue(renderFullNameForUserId(selectedItem.addedBy)) : '-'}
                </SeaGridCell>
            </SeaGrid>
            {

                licenseeSettings?.hasMaintenanceTaskTime &&
                <SeaGrid>
                    <SeaGridCell label="Estimated Time" w="33" help={{ text: 'This is the estimated time to complete this job.' }}>
                        {formatValue(selectedItem?.estimatedTime ? formatShortTimeDurationHrsMinsView(selectedItem?.estimatedTime) : '')}
                    </SeaGridCell>
                </SeaGrid>

            }




            {selectedItem?.state === 'completed' || selectedItem?.state === 'deleted'
                ? <div>Job has been marked as {selectedItem?.state}</div>
                : <div className="view-modal-buttons">
                    <RequirePermissions
                        role="jobList"
                        level={permissionLevels.COMPLETE}
                    >
                        <RequirePermissions
                            role="jobList"
                            level={permissionLevels.EDIT}
                        >
                            <div>
                                <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Job</SeaButton>
                            </div>
                        </RequirePermissions>
                        <RequirePermissions
                            role="jobList"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer-wide"></div>
                            <div>
                                <SeaLinkButton
                                    mode="standard-link"
                                    onClick={(e) => {
                                        deleteIfConfirmed(
                                            'jobs',
                                            selectedItem.id,
                                            (batch: WriteBatch | SplittableBatch) => {
                                                onCollectionUpdated(batch, 'jobs');
                                                setShowModal(false);
                                            },
                                            'job',
                                            selectedItem?.task,
                                            [selectedItem.vesselId]
                                        );
                                    }}
                                >
                                    Delete Job
                                </SeaLinkButton>
                            </div>
                        </RequirePermissions>
                        <div className="right">
                            <SeaButton zone="white" type="submit" size="wide" onClick={(e) => onCompleteJob()}><SeaIcon icon="tick" slot="start" />Mark Job as Complete</SeaButton>
                        </div>
                    </RequirePermissions>
                </div>
            }

            {showModal &&
                <>
                    <UpdateJobList
                        showModal={showUpdateItemModal}
                        setShowModal={setShowUpdateItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    <EditJobList
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    <CompleteJobList
                        showModal={showCompleteItemModal}
                        setShowModal={setShowCompleteItemModal}
                        setShowParentModal={setShowModal}
                        selectedItem={selectedItem}
                        level={2}
                    />
                </>
            }
        </SeaModal>
    );
};

export default ViewJobList;
