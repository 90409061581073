import { useMemo } from "react";
import { CsvConfig, formatCsvDate } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { renderCategoryName } from '../../../lib/categories';
import { renderCamelCase } from '../../../lib/util';
import { formatEmailReminder } from "../../../lib/datesAndTime";
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type CrewCertificatesCsvProps = {
    onCompleted: () => void
}

const CrewCertificatesCsv = ({ onCompleted }: CrewCertificatesCsvProps) => {
    const crewCertificates = sharedState.crewCertificates.use();
    const crewCertificateTitles = sharedState.crewCertificateTitles.use();

    const csvConfig: CsvConfig<CrewCertificate> | undefined = useMemo(() => {
        if (!crewCertificateTitles) {
            return undefined;
        }
        return [
            {
                name: 'Certificate',
                value: (item) => renderCategoryName(item.titleId, crewCertificateTitles)
            },
            {
                name: 'Issued by',
                value: (item) => item.issuedBy || ''
            },
            {
                name: 'Issue date',
                value: (item) => formatCsvDate(item.whenIssued)
            },
            {
                name: 'Expiry',
                value: (item) => formatCsvDate(item.whenExpires)
            },
            {
                name: 'Status',
                value: (item) => renderCamelCase(item.state)
            },
            {
                name: 'Reminder',
                value: (item) => formatEmailReminder(item.emailReminder)
            },
        ];
    }, [crewCertificateTitles]);

    return <SeaCSVGenerator name={'Crew-Certificates'} onCompleted={onCompleted} config={csvConfig} data={Object.values(crewCertificates?.byId || {})} />
};

export default CrewCertificatesCsv;