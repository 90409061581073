import React, { ReactNode } from 'react';
import { IonSelect } from '@ionic/react';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaSelect.css';

//
// Expects a series of <IonSelectOption> as children
//

interface SeaSelectProps {
    children: ReactNode,
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    help?: any,
    value?: string | boolean | number,
    selectedText?: string,
    placeholder?: string,
    error?: string,
    warn?: boolean,
    disabled?: boolean,
    width?: string,
    popoverSize?: 'wide'
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    oncancel?: (e: CustomEvent) => void
}

const SeaSelect = React.forwardRef<HTMLInputElement, SeaSelectProps>(({ children, zone, name, label, help, value, selectedText, placeholder, error, warn, disabled, width, popoverSize, onchange, onblur, onfocus, oncancel }, forwardedRef:any) => {

    const onIonBlur = (e: CustomEvent) => {
        // Do nothing. This gets fired just from clicking on select.
        // Instead, fire onblur after change, because changing usually means leaving the input
    };

    const onIonChange = (e: CustomEvent) => {
        if (onchange) {
            onchange(e);
        }
        if (onblur) {
            onblur(e);
        }
    };

    return (
        <>
            {label && <SeaLabel zone={zone} help={help}>{label}</SeaLabel>}
            <IonSelect
                className={`sea-input sea-select ${zone}-zone ${(error || warn) ? 'has-error' : ''}`}
                style={{ width: width }}
                ref={forwardedRef}
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                selectedText={selectedText}
                onIonChange={onIonChange}
                onIonBlur={onIonBlur}
                onIonFocus={onfocus}
                onIonCancel={oncancel}
                mode="ios"
                interface="popover"
                interfaceOptions={{
                    //mode: "ios",
                    className: `sea-select-popover${popoverSize ? ` ${popoverSize}` : ''}`
                }}
            >
                {children}
            </IonSelect>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
});

export default SeaSelect;
