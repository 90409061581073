import React, { useState, useRef, useEffect, useMemo } from 'react';
import { isPlatform } from '@ionic/core';
import './SeaDraggables.css';

interface SeaDraggablesProps {
    type?: 'tiles' | 'customForms' | 'list';
    items: any[];
    onReorder?: (newOrder: any[]) => void; // If provided, will return the items in their new order
    onDrop?: (initialIndex: number, newIndex: number) => void; // If provided, will return the initial and new index of the item that was dropped
    setIsInternalDrag?: (isInternalDrag: boolean) => void;
    selectedIndex?: number | null;
    setSelectedIndex?: (index: number | null) => void;
    flexDirection?: 'row' | 'column';
    nonDraggableStartComponent?: React.ReactNode; // If provided, will add a non-draggable item at the start of the list
    nonDraggableEndComponent?: React.ReactNode; // If provided, will add a non-draggable item at the end of the list
    children: React.ReactNode[] | undefined;
}

const SeaDraggables: React.FC<SeaDraggablesProps> = ({
    type = 'tiles',
    items,
    onReorder,
    onDrop,
    setIsInternalDrag,
    selectedIndex,
    setSelectedIndex,
    flexDirection = 'row',
    nonDraggableStartComponent,
    nonDraggableEndComponent,
    children,
}) => {
    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
    const [draggedOverIndex, setDraggedOverIndex] = useState<number | null>(null);
    const [draggable, setDraggable] = useState(type === 'tiles');
    const dragItem = useRef<number | null>(null);

    const notEnoughDraggableItems = useMemo(() => {
        if (!Array.isArray(children) || children.length <= 1) {
            return true;
        }
        const draggableChildren = children.filter((child) => child && !(child as React.ReactElement).props.className.includes('non-draggable'));
        return draggableChildren.length <= 1;
    }, [children]);

    const dragHandle = (
        <div className={`drag-handle ${type}`} draggable={false} onMouseUp={() => setDraggable(false)} onMouseDown={() => setDraggable(true)}>
            <img alt="Drag Icon" src="/assets/lex/draggable-block-menu.svg" draggable={false} onMouseUp={() => setDraggable(false)} onMouseDown={() => setDraggable(true)} />
        </div>
    );

    const handleDragStart = (index: number, event: React.DragEvent<Element> | React.TouchEvent<Element>, itemToDrag?: JSX.Element) => {
        if (setIsInternalDrag) {
            setIsInternalDrag(true);
        }
        dragItem.current = index;
        setDraggingIndex(index);
    };

    const handleDragEnter = (index: number) => {
        if (dragItem.current === null) return;
        setDraggedOverIndex(index);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
        if (draggedOverIndex !== null && dragItem.current !== null) {
            if (draggedOverIndex !== dragItem.current) {
                const newItems = [...items];
                const [draggedItem] = newItems.splice(dragItem.current, 1);
                newItems.splice(draggedOverIndex, 0, draggedItem);
                if (onReorder) {
                    onReorder(newItems);
                }
                if (onDrop) {
                    onDrop(dragItem.current, draggedOverIndex);
                }
            }
        }
        setSelectedIndex?.(draggedOverIndex);
        if (type !== 'tiles') {
            setDraggable(false);
        }
        setHighlightedIndex(draggedOverIndex);
        setDraggingIndex(null);
        setDraggedOverIndex(null);
        dragItem.current = null;
        if (setIsInternalDrag) {
            setIsInternalDrag(false);
        }
        document.body.classList.remove('dragging');
    };

    useEffect(() => {
        if (selectedIndex !== undefined) {
            setHighlightedIndex(selectedIndex);
        }
    }, [selectedIndex, items]);

    if (!isPlatform('desktop') || notEnoughDraggableItems) {
        return (
            <>
                {nonDraggableStartComponent}
                {children}
                {nonDraggableEndComponent}
            </>
        );
    }

    const canAddDragHandle = (type: string, index: number) => {
        return ['div', 'span', React.Fragment].includes((children?.[index] as React.ReactElement).type as string) && (['list'].includes(type) || highlightedIndex === index) && !notEnoughDraggableItems;
    };

    return (
        <div className={`sea-draggables draggable-${type} ${flexDirection}`}>
            {nonDraggableStartComponent && <div className="non-draggable-item">{nonDraggableStartComponent}</div>}
            {children?.map((child, index) => {
                const childElement = child as React.ReactElement;
                const { className = '', onClick } = childElement.props;
                let dragHandleElement = canAddDragHandle(type, index) ? dragHandle : null;

                // Determine if the element is non-draggable
                if (className.includes('non-draggable')) {
                    return childElement;
                }

                const newClassName = `${className} draggable-item draggable-${type} ${draggedOverIndex === index ? 'dragging-over' : draggingIndex === index ? 'dragging' : ''} ${draggingIndex === index ? 'dragging-active' : ''} ${
                    type !== 'customForms' ? 'has-outline' : ''
                } ${dragHandleElement ? 'draggable' : 'non-draggable'}`;

                return React.cloneElement(childElement, {
                    className: newClassName,
                    draggable: draggable,
                    // draggable: true,
                    onDragEnter: () => handleDragEnter(index),
                    onDragOver: handleDragOver,
                    onDragEnd: handleDragEnd,
                    onDragStart: (event: React.DragEvent) => {
                        handleDragStart(index, event);
                    },
                    onClick: (event: React.MouseEvent) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setHighlightedIndex(index);
                        setSelectedIndex?.(index);
                        onClick?.(event);
                    },
                    children: dragHandleElement ? [dragHandleElement, childElement.props.children] : childElement.props.children,
                });
            })}
            {nonDraggableEndComponent && <div className="non-draggable-item">{nonDraggableEndComponent}</div>}
        </div>
    );
};

export default SeaDraggables;
