import { useMemo, useState } from "react";
import { CsvColumn, CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvForecast, formatCsvNameFromIds, formatCsvUserNamesFromIds } from "../../lib/csv";
import { IonSelectOption } from "@ionic/react";
import { Voyage, VoyageDay, VoyageTrip, VoyageTripStop } from "../../shared-state/VesselLogbook/voyages";
import { useVoyages } from "../../shared-state/VesselLogbook/useVoyages";
import { DateRange } from "../../components/SeaDateRange/SeaDateRange";
import { renderVesselName } from "../../shared-state/Core/vessels";
import { sharedState } from "../../shared-state/shared-state";
import { renderFullNameForUserId } from "../../shared-state/Core/users";
import { renderCamelCase } from "../../lib/util";
import { calculateTotalHours, combineVoyageCrewHours, renderDayName } from "../../modals/VesselLogbook/voyage-util";
import SeaSelect from "../../components/SeaSelect/SeaSelect";
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';
import SeaExportFilterModal from "../../components/SeaExporter/SeaExportFilterModal";

type LogbookCsvProps = {
    onCompleted: () => void
}

const LogbookCsv = ({onCompleted}: LogbookCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const [tripType, setTripType] = useState<'singleDay' | 'multiDay' | 'multiTrip'>('singleDay');
    const voyages = useVoyages(tripType, dateRange)
    const engines = sharedState.engines.use();
    const users = sharedState.users.use();

    const csvConfig: CsvConfig<Voyage> | undefined = useMemo(() => {
        if (!engines || !users) return;

        return [
            ...(tripType !== 'multiTrip' ? [{
                name: 'Name',
                value: (item: Voyage) => item.name
            }] : []),
            {
                name: 'Trip Type',
                value: (item) => renderCamelCase(item.tripType)
            },
            {
                name: 'Vessel',
                value: (item) => renderVesselName(item.vesselId)
            },
            {
                name: 'Operation',
                value: (item) => renderCamelCase(item.operation || '')
            },
            {
                name: 'Master',
                value: (item) => formatCsvUserNamesFromIds(item.masterIds) || item.skipper
            },
            {
                name: 'Crew Onboard',
                value: (item) => formatCsvUserNamesFromIds(item.personnelInvolved)
            },
            {
                name: 'Departure',
                value: (item) => `${item.departureFrom || ''} - ${formatCsvDate(item.whenDeparted)}`
            },
            {
                name: 'Weather Forecast',
                value: (item) => item.weatherForecast ?? formatCsvForecast(item.forecast)
            },
            {
                name: 'Arrival',
                value: (item) => `${item.destinationTo ? `${item.destinationTo} - ` : ''}${formatCsvDate(item.whenArrived)}`
            },
            {
                name: 'PAX Carried',
                value: (item) => item.pax || '0'
            },
            {
                name: 'Safety Briefing Completed',
                value: (item) => formatCsvBoolean(item.safetyBriefCompleted)
            },
            {
                name: 'Startup Checks',
                value: (item) => item.startupChecks?.map(check => `${check.check}: ${renderCamelCase(check.value ?? '')}`).join('; ')
            },
            {
                name: 'Shutdown Checks',
                value: (item) => item.shutdownChecks?.map(check => `${check.check}: ${renderCamelCase(check.value ?? '')}`).join('; ')
            },
            {
                name: 'Startup Notes',
                value: (item) => item.startupNotes
            },
            {
                name: 'Shutdown Notes',
                value: (item) => item.shutdownNotes
            },
            {
                name: 'Fuel Onboard (Start) (Litres)',
                value: (item) => item.fuelStart
            },
            {
                name: 'Fuel Bunkered (Litres)',
                value: (item) => item.fuelBunkered
            },
            {
                name: 'Fuel Bunkered Cost',
                value: (item) => item.fuelBunkeredCost
            },
            {
                name: 'Fuel Onboard (End) (Litres)',
                value: (item) => item.fuelEnd
            },
            {
                name: 'Fuel Used (Litres)',
                value: (item) => {
                    if (item.fuelStart !== undefined && item.fuelBunkered !== undefined && item.fuelEnd !== undefined) {
                        return (item.fuelStart - item.fuelEnd + item.fuelBunkered);
                    }
                    return '';
                }
            },
            {
                name: 'Engine Hours on Arrival',
                value: (item: Voyage) => item.engineHours?.map(eh => `${formatCsvNameFromIds(eh.id, engines.byId)}: ${eh.hours}`).join('\r\n')
            },
            {
                name: `${tripType === 'multiDay' ? 'Total ' : ''}Crew Hours`,
                value: (item: Voyage) => {
                    if (tripType === 'multiDay') {
                        const combinedHours = combineVoyageCrewHours(item.days);
                        return combinedHours.map(ch => `${renderFullNameForUserId(ch.id)}: ${ch.hours}hrs`).join('\r\n');
                    } else {
                        return item.crewHours?.map(ch => `${renderFullNameForUserId(ch.id)}: ${ch.hours}hrs`).join('\r\n');
                    }
                }
            },
            ...(tripType === 'multiTrip' ? [
                {
                    name: 'Total PAX Carried',
                    value: (item: Voyage) => item.pax || '0'
                },
                {
                    name: 'Trips',
                    items: (item: Voyage) => item.trips || [],
                    columns: (item: Voyage, index: number) => [
                        {
                            name: 'No.',
                            value: () => `Trip ${index + 1}`
                        },
                        {
                            name: 'Master',
                            value: (trip: VoyageTrip) => renderFullNameForUserId(trip.masterId)
                        },
                        {
                            name: 'Crew Onboard',
                            value: (trip: VoyageTrip) => formatCsvUserNamesFromIds(trip.crewInvolved)
                        },
                        {
                            name: 'Departure',
                            value: (trip: VoyageTrip) => `${trip.departureFrom || ''} - ${formatCsvDate(trip.whenDeparted)}`
                        },
                        {
                            name: 'PAX On',
                            value: (trip: VoyageTrip) => trip.paxOn
                        },
                        {
                            name: 'Custom Data',
                            value: (trip: VoyageTrip) => trip.customText?.map((t) => `${t.name}: ${t.value || '-'}`).join('\r\n')
                        },
                        {
                            name: 'Stops',
                            items: (trip: VoyageTrip) => trip.stops || [],
                            columns: (stop: VoyageTripStop, index: number) => [
                                {
                                    name: 'No.',
                                    value: () => `Stop ${index + 1}`
                                },
                                {
                                    name: 'Location',
                                    value: (stop: VoyageTripStop) => stop.location
                                },
                                {
                                    name: 'Arrival Time',
                                    value: (stop: VoyageTripStop) => formatCsvDate(stop.whenArrived)
                                },
                                {
                                    name: 'PAX Off',
                                    value: (stop: VoyageTripStop) => stop.paxOff
                                },
                                {
                                    name: 'PAX On',
                                    value: (stop: VoyageTripStop) => stop.paxOn
                                },
                                {
                                    name: 'Departure Time',
                                    value: (stop: VoyageTripStop) => stop.paxOn
                                },
                            ]
                        }
                    ]
                }
            ] : tripType === 'multiDay' ? [
                {
                    name: 'Days',
                    items: (item: Voyage) => item.days || [],
                    columns: (item: Voyage, index: number) => [
                        {
                            name: 'Day',
                            value: () => renderDayName(item.whenDeparted, index)
                        },
                        {
                            name: 'Notes',
                            value: (day: VoyageDay) => day.notes
                        },
                        {
                            name: 'Custom Text',
                            value: (day: VoyageDay) => day.customText?.map((t) => `${t.name}: ${t.value || '-'}`).join('\r\n')
                        },
                        {
                            name: 'Custom Hours',
                            value: (day: VoyageDay) => day.customHours?.map((h) => `${h.name}: ${h.value || '-'}`).join('\r\n')
                        },
                        {
                            name: 'Total Task Hours',
                            value: (day: VoyageDay) => calculateTotalHours(day.customHours)
                        },
                        {
                            name: 'Crew Hours',
                            value: (day: VoyageDay) => {
                                if (!day.crewHours) return '';
                                return Object.entries(day.crewHours)
                                    .map(([crewId, crewHour]) => `${renderFullNameForUserId(crewHour.id)}: ${crewHour.hours}hrs`)
                                    .join('\r\n');
                            }
                        }
                    ]
                }
            ] : [
                {
                    name: 'Stops / Anchorages',
                    value: (item: Voyage) => item.stopsOrRouteDetails || (item.stops ? item.stops.join(', ') : '')
                },
                {
                    name: 'Voyage Notes',
                    value: (item: Voyage) => item.voyageNotes
                },
                {
                    name: 'Custom Text',
                    value: (day: Voyage) => day.customTextFields?.map((t) => `${t.name}: ${t.value || '-'}`).join('\r\n')
                },
                {
                    name: 'Custom Hours',
                    value: (day: Voyage) => day.customHourFields?.map((h) => `${h.name}: ${h.value || '-'}`).join('\r\n')
                },
            ]),
        ] as CsvColumn<Voyage, undefined>[];
    }, [engines, tripType, users]);


    if (!dateRange) {
        return (
            <SeaExportFilterModal onClose={onCompleted} onExport={setDateRange}>
                <SeaSelect value={tripType} label="Trip Type" onchange={(e) => setTripType(e.detail.value)} zone="white">
                    <IonSelectOption value="singleDay">Single Day Voyage</IonSelectOption>
                    <IonSelectOption value="multiDay">Multi-Day Voyage</IonSelectOption>
                    <IonSelectOption value="multiTrip">Multi-trip Voyage</IonSelectOption>
                </SeaSelect>
            </SeaExportFilterModal>
        )
    }
    
    return <SeaCSVGenerator name={'Logbook'} onCompleted={onCompleted} config={csvConfig} data={voyages?.all} dateRange={dateRange} filtersForFilename={[renderCamelCase(tripType)]}/>;
};

export default LogbookCsv